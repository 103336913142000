.full-width-grid {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
  max-width: 1920px;

  @media #{$bp-medium} {
    grid-auto-flow: column;
  }

  .tile-wrap {
    overflow: hidden;

    .tile {
      display: flex;
      height: 100%;
      justify-content: center;
      overflow: hidden;
      position: relative;
      width: 100%;

      img {
        height: 100%;
        transition: transform 200ms ease-out;
      }

      .text-container {
        align-items: center;
        display: flex;
        flex-direction: column;
        inset: 40% 0 -40%;

        padding: 2rem;
        position: absolute;
        word-break: break-word;

        @media #{$bp-medium} {
          inset: 30% 0 -30%;

          .text-wrap {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            max-height: 100%;
            max-width: 100%;
            transition: transform 200ms ease-out;
          }
        }

        span {
          * {
            color: unset;
            font-size: unset;
            font-weight: unset;
            line-height: unset;
          }
        }

        .title,
        .teaser {
          color: $text-color-1;
          text-align: center;
        }

        .title {
          @include line-clamp(3, 32px);
          font-size: 28px;
          hyphens: auto;
          line-height: 32px;
        }

        .teaser {
          @include line-clamp(18, 22px);
          display: block;
          font-size: 18px;
          line-height: 22px;
          opacity: unset;

          overflow-y: hidden;
          position: relative;
          text-overflow: ellipsis;
          transition: opacity 200ms ease-out;

          @media #{$bp-medium} {
            display: none;
            opacity: 0;
          }

          @media #{$bp-mega-large} {
            padding: 0 2rem;
          }
        }
      }

      .overlay {
        background: $accent-color;
        opacity: 0;
        pointer-events: none;
        transition: opacity 200ms ease-out;
      }
      @media #{$bp-medium} {
        &:hover {
          img {
            transform: scale(1.05);
          }

          .text-wrap {
            // calc ist anscheind nötig weil sonst error kommt :(
            transform: translateY(calc(max(-40%, -180px)));

            .teaser {
              display: block;
              opacity: 1;
            }
          }

          .overlay {
            inset: 0;
            opacity: 0.5;
            position: absolute;
          }
        }
      }
    }
  }
}
