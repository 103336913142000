.full-image {
  display: flex;
  height: 45vw;
  text-align: center;

  .active-image {
    background-position: center;
    background-size: cover;
    // box-direction: normal;
    -webkit-box-orient: vertical;
    -webkit-box-pack: center;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
  }

  .textbox {
    align-content: flex-end;
    align-items: flex-end;
    display: flex;
    height: 100%;
    width: 100%;
  }

  .text {
    align-items: flex-end;
    color: $text-color-1;
    left: 0;
    margin: 0 auto;
    max-width: 960px;
    opacity: 1;
    padding-bottom: 30px;
    right: 0;
    text-align: left;
    width: 100%;

    @media #{$bp-medium} {
      max-width: 720px;
    }

    @media #{$bp-large} {
      max-width: 960px;
    }

    @media #{$bp-extra-large} {
      max-width: 1110px;
    }

    h5 {
      color: inherit;
      margin: 0;
    }

    h3 {
      color: inherit;
      display: none;
      font-weight: 500;
      margin: 10px 0 0;

      @media #{$bp-small} {
        display: block;
      }

      @media #{$bp-large} {
        margin: 25px 0 0;
      }

      @media #{$bp-extra-large} {
        margin: 44px 0 0;
      }
    }
  }

  .overlaybox {
    align-content: flex-end;
    align-items: flex-end;
    display: none;
    height: 100%;
  }

  .overlaytext {
    align-items: flex-end;
    color: $text-color-1;
    font-size: 27px;
    left: 0;
    line-height: 35px;
    margin: 0 auto;
    max-width: 960px;
    opacity: 1;
    padding-bottom: 30px;
    right: 0;
    text-align: left;
    width: 100%;

    @media #{$bp-medium} {
      font-size: 30px;
      line-height: 40px;
      max-width: 720px;
    }

    @media #{$bp-large} {
      font-size: 40px;
      line-height: 54px;
      max-width: 960px;
    }

    @media #{$bp-extra-large} {
      font-size: 64px;
      line-height: 74px;
      max-width: 1110px;
    }
  }

  &:hover {
    background-color: $accent-color;

    .overlaybox::before {
      background-color: $accent-color;
      content: ' ';
      height: 100%;
      left: 0;
      opacity: 0.55;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;
    }

    .textbox {
      display: none;
    }

    .overlaybox {
      display: flex;
      position: relative;
      z-index: 1;
    }
  }
}
