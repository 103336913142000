.reference-text {
  img {
    display: block;
    margin-bottom: 2rem;
    max-height: 300px;
    max-width: 300px;
  }

  h3 {
    border-top: 2px solid $area-color-14;
    margin: 0 0 1rem;
    padding-top: 1rem;
  }
}
