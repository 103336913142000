.image-and-text {
  margin: 0 auto;
  max-width: 1140px;
  padding: 40px;

  header {
    margin: 0 0 40px;
    overflow-wrap: break-word;
    width: 100%;
  }

  h3 {
    color: $text-color-3;
    font-size: 48px;
    font-weight: 400;
    line-height: 58px;
  }

  p {
    color: $text-color-3;
    font-size: 18px;
    margin: 0 0 2rem;
  }

  @media #{$bp-large} {
    padding: 40px;
  }

  .wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    @media #{$bp-medium} {
      flex-wrap: nowrap;
    }

    > div {
      flex: 0 1 100%;

      @media #{$bp-medium} {
        flex-basis: calc(49%);
      }

      &.extra-small {
        @media #{$bp-medium} {
          flex: 0 0 26%;
        }
      }

      &.small {
        @media #{$bp-medium} {
          flex: 0 0 33%;
        }
      }

      &.medium {
        @media #{$bp-medium} {
          flex: 0 0 39%;
        }
      }

      &.large {
        @media #{$bp-medium} {
          flex: 0 0 49%;
        }
      }
    }

    .right {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &.reverse .wrap {
    flex-direction: row-reverse;
    justify-content: space-between;

    .more-button {
      align-self: flex-start;
    }
  }

  img {
    border-radius: 4px;
    height: auto;
    width: 100%;
  }

  section {
    display: block;
    width: 100%;
  }

  .subline {
    font-size: 12px;
    font-style: italic;
    margin: 1rem 0;

    @media #{$bp-medium} {
      margin: 1rem 0 0;
    }
  }
}
