@keyframes jump {
  0% {
    transform: translateY(0);
  }

  20% {
    transform: translateY(-10px);
  }

  40% {
    transform: translateY(-8px);
  }

  80% {
    transform: translateY(-3px);
  }

  100% {
    transform: translateY(0);
  }
}

.pricing {
  .control {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: 37px;

    span {
      &.active {
        color: $accent-color;
      }
    }

    .slider {
      background: $accent-color;
      border-radius: 30px;
      height: 30px;
      margin: 0 20px;
      position: relative;
      width: 60px;

      .inner {
        background: $area-color-1;
        border-radius: 100%;
        height: 24px;
        position: absolute;
        top: 3px;
        transition: left 120ms ease-out;
        width: 24px;

        &[data-active='0'] {
          left: 3px;
        }

        &[data-active='1'] {
          left: 33px;
        }
      }
    }
  }

  .pillars {
    .top,
    .bottom {
      display: flex;
      flex-flow: column;

      justify-content: space-between;
      width: 100%;

      @media #{$bp-medium} {
        flex-flow: row nowrap;
        min-width: 720px;
      }

      > div {
        flex-basis: calc((100% - 48px) / 3);
        flex-grow: 0;
        flex-shrink: 0;
      }
    }

    &[data-count='2'] {
      .top,
      .bottom {
        > div {
          flex-basis: calc((100% - 24px) / 2);
        }
      }
    }

    .pillar {
      display: flex;
      flex-flow: column wrap;
      padding-top: 24px;

      @media #{$bp-medium} {
        padding-top: 84px;
      }

      &:hover {
        animation: 200ms jump;
      }

      &.accent-pillar {
        padding-top: 24px;

        @media #{$bp-medium} {
          padding-top: 0;
        }
      }
    }
  }

  .header,
  .body {
    animation-duration: 400ms;
  }

  .top {
    align-items: stretch;

    .header {
      align-items: center;
      background: $area-color-6;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      display: flex;
      flex: 0 0 254px;
      flex-flow: column nowrap;
      justify-content: flex-start;

      padding: 0 20px;

      * {
        color: $text-color-1;
      }

      .headline {
        flex: 0 0 auto;
        font-size: 21px;
        font-weight: bold;
        line-height: 26px;
        margin-top: 40px;
        text-align: center;
        vertical-align: center;
      }

      .delimiter {
        background: $background-color;
        flex: 0 0 5px;
        height: 5px;
        margin-top: 12px;
        width: 60px;
      }

      .stick-bottom {
        align-items: center;
        bottom: 0;
        display: flex;
        flex: 1 1 100%;
        flex-flow: column nowrap;
        justify-content: flex-end;
      }

      .price-prefix {
        font-size: 24px;
      }

      .price {
        font-size: 32px;
        font-weight: bold;
        line-height: 42px;

        &.asterisk::after {
          content: ' *';
          font-size: 18px;
          vertical-align: top;
        }
      }

      .billing-info:not(:empty) {
        display: block;
        font-weight: bolder;
        margin: 8px 0 32px;
        text-align: center;
      }

      .subline:not(:empty) {
        line-height: 18px;
        margin: auto 0 32px;
        text-align: center;
      }

      &.accent {
        background: $accent-color;
        flex: 0 0 338px;
        margin-top: 0;
      }

      .price-wrap {
        margin-top: 12px;
      }
    }
  }

  .body {
    align-items: center;
    background: $background-color-alternate;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;
    justify-content: flex-start;
    padding: 20px;

    .additional-info {
      font-size: 12px;
      height: 12px;
      width: 100%;

      &:not(:empty)::before {
        content: '* ';
        font-size: 18px;
        vertical-align: top;
      }
    }

    .more-button {
      margin: 25px 5px 50px;
      width: 100%;
    }

    .headline,
    ul {
      width: 100%;
    }

    .headline {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 9px;
    }

    .delimiter {
      align-self: flex-start;
      background: $accent-color;
      height: 3px;
      width: 40px;
    }

    ul {
      list-style: none;
      padding-inline-start: 0;

      li {
        line-height: 24px;
        padding-left: 1.3em;

        &::before {
          color: $accent-color;
          content: '\e822';
          display: inline-block;
          font-family: 'tta-iconfont';
          margin-left: -1.3em;
          width: 1.3em;
        }
      }
    }
  }

  .form {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .currency-selector {
    background: $background-color-alternate;
    margin: 20px 0 0;
    width: 200px;
  }
}
