.modal {
  align-items: center;
  background-color: transparentize($area-color-8, 0.6);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;

  &.container {
    position: absolute;
    z-index: 10;
  }

  .modal-window {
    background-color: $area-color-1;
    border-radius: 5px 5px 0 0;
    box-shadow: 0 0 10px transparentize($area-color-8, 0.7);
    display: block;
    height: auto;
    padding: 25px;
    width: 100%;

    .button-container {
      align-items: center;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      margin-top: 10px;
      width: 100%;

      button {
        width: 100%;

        &.accept {
          @include button();
        }

        &.abort {
          @include button-outline();
        }

        @media #{$bp-medium} {
          width: auto;
        }
      }

      @media #{$bp-medium} {
        flex-flow: row nowrap;
      }
    }
    @media #{$bp-medium} {
      border-radius: 5px;
      width: 70%;
    }

    @media #{$bp-large} {
      width: 40%;
    }
  }
  @media #{$bp-medium} {
    justify-content: center;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  &.loader {
    justify-content: center;

    .star {
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-name: rotate;
      height: 229px;
      position: relative;
      transform-origin: center center;
      width: 264px;
    }
  }
}
