/* static fallback fonts */
// normal 100 - 900
@font-face {
  font-display: swap;
  font-family: 'Hanken Grotesk';
  font-style: normal;
  font-weight: 100; // Thin
  src: url('/group/static/HankenGrotesk/static/HankenGrotesk-Thin.woff2')
    format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Hanken Grotesk';
  font-style: normal;
  font-weight: 200; // ExtraLight
  src: url('/group/static/HankenGrotesk/static/HankenGrotesk-ExtraLight.woff2')
    format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Hanken Grotesk';
  font-style: normal;
  font-weight: 300; // Light
  src: url('/group/static/HankenGrotesk/static/HankenGrotesk-Light.woff2')
    format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Hanken Grotesk';
  font-style: normal;
  font-weight: 400; // Regular
  src: url('/group/static/HankenGrotesk/static/HankenGrotesk-Regular.woff2')
    format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Hanken Grotesk';
  font-style: normal;
  font-weight: 500; // Medium
  src: url('/group/static/HankenGrotesk/static/HankenGrotesk-Medium.woff2')
    format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Hanken Grotesk';
  font-style: normal;
  font-weight: 600; // SemiBold
  src: url('/group/static/HankenGrotesk/static/HankenGrotesk-SemiBold.woff2')
    format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Hanken Grotesk';
  font-style: normal;
  font-weight: 700; // Bold
  src: url('/group/static/HankenGrotesk/static/HankenGrotesk-Bold.woff2')
    format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Hanken Grotesk';
  font-style: normal;
  font-weight: 800; // ExtraBold
  src: url('/group/static/HankenGrotesk/static/HankenGrotesk-ExtraBold.woff2')
    format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Hanken Grotesk';
  font-style: normal;
  font-weight: 900; // Black
  src: url('/group/static/HankenGrotesk/static/HankenGrotesk-Black.woff2')
    format('woff2');
}

// italic 100 - 900
@font-face {
  font-display: swap;
  font-family: 'Hanken Grotesk';
  font-style: italic;
  font-weight: 100; // Thin
  src: url('/group/static/HankenGrotesk/static-italic/HankenGrotesk-ThinItalic.woff2')
    format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Hanken Grotesk';
  font-style: italic;
  font-weight: 200; // ExtraLight
  src: url('/group/static/HankenGrotesk/static-italic/HankenGrotesk-ExtraLightItalic.woff2')
    format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Hanken Grotesk';
  font-style: italic;
  font-weight: 300; // Light
  src: url('/group/static/HankenGrotesk/static-italic/HankenGrotesk-LightItalic.woff2')
    format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Hanken Grotesk';
  font-style: italic;
  font-weight: 400; // Regular
  src: url('/group/static/HankenGrotesk/static-italic/HankenGrotesk-Italic.woff2')
    format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Hanken Grotesk';
  font-style: italic;
  font-weight: 500; // Medium
  src: url('/group/static/HankenGrotesk/static-italic/HankenGrotesk-MediumItalic.woff2')
    format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Hanken Grotesk';
  font-style: italic;
  font-weight: 600; // SemiBold
  src: url('/group/static/HankenGrotesk/static-italic/HankenGrotesk-SemiBoldItalic.woff2')
    format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Hanken Grotesk';
  font-style: italic;
  font-weight: 700; // Bold
  src: url('/group/static/HankenGrotesk/static-italic/HankenGrotesk-BoldItalic.woff2')
    format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Hanken Grotesk';
  font-style: italic;
  font-weight: 800; // ExtraBold
  src: url('/group/static/HankenGrotesk/static-italic/HankenGrotesk-ExtraBoldItalic.woff2')
    format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Hanken Grotesk';
  font-style: italic;
  font-weight: 900; // Black
  src: url('/group/static/HankenGrotesk/static-italic/HankenGrotesk-BlackItalic.woff2')
    format('woff2');
}

/* fonts with variable weight */
@supports (font-variation-settings: normal) {
  //normal
  @font-face {
    font-display: swap;
    font-family: 'Hanken Grotesk';
    font-style: normal;
    font-weight: 100 900;
    src: url('/group/static/HankenGrotesk/HankenGrotesk-VariableFont_wght.woff2')
      format('woff2 supports variations'),
      url('/group/static/HankenGrotesk/HankenGrotesk-VariableFont_wght.woff2')
      format('woff2-variations');
  }

  //italic
  @font-face {
    font-display: swap;
    font-family: 'Hanken Grotesk';
    font-style: italic;
    font-weight: 100 900;
    src: url('/group/static/HankenGrotesk/HankenGrotesk-Italic-VariableFont_wght.woff2')
      format('woff2 supports variations'),
      url('/group/static/HankenGrotesk/HankenGrotesk-Italic-VariableFont_wght.woff2')
      format('woff2-variations');
  }
}
