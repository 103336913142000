.three-column-contact {
  .content-container {
    @media #{$bp-large} {
      display: flex;
      justify-content: space-between;
    }
  }

  .sub-content {
    > *:not(:last-child) {
      padding-bottom: 2rem;
    }
  }

  .content {
    margin: 0 0 2rem;
    width: 100%;

    &:first-child {
      @media #{$bp-large} {
        margin-left: 0;
      }
    }

    &:last-child {
      @media #{$bp-large} {
        margin-right: 0;
      }
    }

    p {
      margin: 0;
    }

    hr {
      border-top: 0;
      padding: 0 !important;
      width: 100%;
    }

    .image-wrap {
      display: flex;

      :where(picture, img) {
        width: 100%;
      }

      &.small {
        picture {
          width: 60%;
        }
      }

      &.medium {
        picture {
          width: 75%;
        }
      }

      &[class^='vertical'],
      &[class*=' vertical'] {
        flex-basis: 100%;
        flex-shrink: 1;
      }

      &.horizontal-left {
        justify-content: flex-start;
      }

      &.horizontal-center {
        justify-content: center;
      }

      &.horizontal-right {
        justify-content: flex-end;
      }

      &.vertical-top {
        align-items: start;
      }

      &.vertical-center {
        align-items: center;
      }

      &.vertical-bottom {
        align-items: flex-end;
      }
    }

    .contact-person {
      margin: 0;
      padding: 0;
    }

    .for-contact {
      flex-shrink: 0;
    }

    @media #{$bp-large} {
      display: flex;
      flex-basis: calc((100% - 6rem) / 3);
      flex-direction: column;
      margin: 0;
    }

    > .three-headlines {
      color: $text-color-3;
      font-size: 28px;
      font-weight: 400;
      line-height: 38px;
      margin: 0;
      padding: 0 0 1rem;

      @media #{$bp-medium} {
        font-size: 28px;
        line-height: 38px;
      }
      @media #{$bp-large} {
        font-size: 28px;
        line-height: 38px;
      }
    }
  }

  .variation-links {
    .richtext-container {
      display: flex;
      flex-direction: column-reverse;
    }

    .sub-content {
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-end;

      p {
        border-bottom: 1px solid $text-color-3;
        margin-bottom: 1rem;
        padding-bottom: 1rem;

        &:first-child {
          border-bottom: 0;
        }
      }

      a {
        color: $text-color-3;

        &:hover {
          color: $accent-color;
        }
      }

      .image-wrap {
        padding-bottom: 1rem;

        &[class^='vertical'],
        &[class*=' vertical'] {
          flex-basis: auto;
          flex-shrink: 1;
        }
      }
    }

    .person-wrap {
      display: none;
    }
  }

  //Define Order of Sub-Contents
  .sub-content {
    padding-bottom: 2rem;

    &.order-1 {
      order: 1;
    }

    &.order-2 {
      order: 2;
    }

    &.order-3 {
      order: 3;
      padding-bottom: 0;
    }
  }

  //Contact-person only three-columns customization
  .person {
    flex-basis: calc(100%);
    margin: 0 0 1rem;

    @media #{$bp-medium} {
      margin: 0 0 2rem;
    }

    .generic-button {
      color: $text-color-1;
    }
  }
}
