.detail-page {
  display: grid;
  grid-template-columns: 80fr 20fr;

  > section,
  > footer {
    display: inline-block;
    grid-column: 1;

    &:nth-child(2),
    &:nth-child(n + 6) {
      grid-column: 1 / span 2;
    }

    &.gridr1 {
      grid-row: 1;
    }

    &.gridr2 {
      grid-row: 2;
    }

    &.gridr3 {
      grid-row: 3;
    }

    &.gridr4 {
      grid-row: 4;
    }

    &.gridr5 {
      grid-row: 5;
    }
  }

  .sidebar-row {
    box-shadow: inset 0 0 10px 0 transparentize($shade-type-1, 0.81);
    grid-column: 2;
    grid-row: 2 / 5;
    height: 100%;

    &.sticky .sidebar-content {
      height: auto;
      padding-bottom: 80px;

      @supports (position: sticky) {
        position: sticky;
        top: 0;
      }
      @supports not (position: sticky) {
        position: static;
      }
    }

    &.parallax .sidebar-content {
      position: relative;
    }
  }
}
