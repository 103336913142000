%richtext-content {
  position: relative;

  a[id][name] {
    position: absolute;
    top: calc(-1 * var(--topnav-height));
  }

  p,
  &.job-content {
    font-size: 18px;
    line-height: 1.62;
    margin: 0 0 2rem;

    &:last-child {
      margin: 0;
    }
  }

  a {
    color: $accent-color;
    font-weight: 500;
  }

  ol li {
    padding: 0 0 0 10px;
  }

  ul {
    display: table;
    list-style: none;
    padding-inline-start: 0;

    &:last-child {
      margin: 0;
    }

    li {
      display: flex;
      display: table-row;
      font-size: 18px;
      line-height: 1.62;
      list-style: none;
      margin: 0 0 15px;

      &::before {
        color: $accent-color;
        content: '\e822';
        display: inline-block;
        display: table-cell;
        font-family: 'tta-iconfont';
        margin-left: calc(-1.3em + 15px);
        margin-right: 15px;
        padding: 0 10px 0 0;
        text-align: right;
        //width: 1.3em;
        width: auto;
      }
    }
  }

  // X-TODO raus wegen vcard. gucken ob wichtig
  // _:-ms-lang(x),
  // ul {
  //   padding: 0;
  // }

  h2,
  h3,
  h4,
  h5 {
    margin: 0 0 1rem;
  }
}

.richtext-content {
  @extend %richtext-content;
}

.richtext {
  // Module Specific start
  @include style-of-link;
  margin: 0 auto;
  max-width: 1110px;

  section {
    display: block;
    width: 100%;
  }

  .content {
    margin: 30px auto 0;
    max-width: 1110px;

    &.right {
      text-align: right;
    }

    &.center {
      text-align: center;
    }

    &.left {
      text-align: left;
    }
  }

  ul li.custom-ticon {
    margin: 0 0 25px;
  }
  // Module Specific end
}
