.event-details {
  .details {
    @media #{$bp-medium} {
      display: flex;
    }

    .date {
      width: 100%;
      @media #{$bp-large} {
        width: 20%;
      }
    }

    .place {
      margin: 20px 0 0;
      width: 100%;
      @media #{$bp-medium} {
        margin: 0;
      }
      @media #{$bp-large} {
        width: 40%;
      }
    }

    .context {
      margin: 20px 0 0;
      width: 100%;

      @media #{$bp-medium} {
        margin: 0;
      }
      @media #{$bp-large} {
        width: 20%;
      }
    }

    .execution-guarantee {
      margin: 1rem auto 1.75rem 0;
      width: 100%;

      @media #{$bp-medium} {
        margin: 0.55rem auto 0.55rem 0;
        width: 40%;
      }

      @media #{$bp-large} {
        margin: 0.25rem auto 0 0;
        width: auto;
      }

      .description {
        @media #{$bp-large} {
          height: 1.25rem;
        }
      }
    }

    .execution-guarantee-flag {
      background-color: $bg-color-ffffff;
      border: 2px solid $accent-color;
      border-radius: 3px;
      color: $accent-color;
      font-size: 1rem;
      letter-spacing: 0.05em;
      margin: 1rem auto 1.75rem 0;
      padding: 0.25rem 0.75rem;
      text-align: center;
      text-transform: uppercase;

      @media #{$bp-medium} {
        letter-spacing: 0;
      }

      @media #{$bp-large} {
        letter-spacing: 0.05em;
        margin: 0 auto 0 0;
      }

      &::before {
        color: $accent-color;
        content: '\f058';
        font-family: 'Font Awesome 5 Free';
        margin: 0 0.25rem 0 0;
      }
    }

    p {
      margin: 0;
    }

    .description {
      color: $text-color-3;
      font-size: 13px;
    }

    .desc-content {
      font-size: 18px;
      font-weight: 500;
      margin: 10px 0 5px;
    }
  }

  .button-container {
    margin: 1rem 0 0;

    &.hidden {
      display: none;
    }

    &.show {
      align-items: center;
      display: flex;
    }

    &.left {
      justify-content: flex-start;
    }

    &.center {
      justify-content: center;
    }

    &.right {
      justify-content: flex-end;
    }
  }
}
