%horizontal-boxes {
  $small-height: 140px;
  $large-height: 215px;

  .horizontal-boxes {
    @extend %facade;

    &.open {
      .facade .plus-icon {
        opacity: 0;
      }
    }

    .content {
      display: grid;
      flex: 0 1 0;
      gap: 10px;
      grid-auto-flow: row;
      grid-auto-rows: 45px;
      margin: 0;
      padding: 10px;

      @media #{$bp-medium} {
        gap: 1rem;
        grid-auto-columns: 1fr;
        grid-auto-flow: column;
        grid-auto-rows: auto;
        padding: 0 1rem 1rem;
      }
    }

    .info-box {
      display: grid;
      grid-template-columns: 1fr 4fr 1fr;
      padding: 10px;
      place-content: center;

      .plus-icon {
        place-self: center;
      }
    }

    .info-box-title {
      grid-column: 2;
      text-align: center;
    }
  }
}

@mixin horizontal-boxes-color-template($darkcolor, $lightcolor) {
  background-color: $darkcolor;

  .facade .plus-icon::before {
    color: $lightcolor;
  }

  &:not(.open) {
    &:hover {
      background-color: lighten($darkcolor, 3);
    }
  }

  .info-box {
    background-color: $lightcolor;

    &:hover {
      background-color: lighten($lightcolor, 3);
    }

    .plus-icon::before {
      color: $darkcolor;
    }
  }
}
