$sizes: 16px, 15px, 14px, 13px, 12px, 11px, 10px, 9px, 8px;

.sticky-media-sidebar {
  $desktop-content-width: 350px;
  $icon-dim: 40px;

  display: flex;
  position: fixed;
  right: 0;
  top: 50%;
  // 50% Translate für perfekte Zentrierung im mobilen weiter unten, wegen 3 Jahre altem Webkit Bug: https://bugs.chromium.org/p/chromium/issues/detail?id=20574 🥶

  transition: all 200ms ease-in-out;
  // z-index: -1; // Klingt  komisch, ist aber so.
  z-index: 100; //z-index höher gesetzt sein (click-trigger und mobile positioning)

  @media #{$bp-large} {
    height: 300px;
    transform: translateY(-50%);
  }

  @media #{$bp-insanely-large} {
    height: 630px;
    transform: translateY(-50%);
  }

  &.collapsed {
    @media #{$bp-large} {
      right: (-350px);
    }

    .container-content {
      display: none;

      @media #{$bp-large} {
        display: flex;
      }
    }
  }

  .container-controls,
  .mobile-toggle {
    transform: translateY(-50%);
    @media #{$bp-large} {
      transform: none;
    }
  }

  .container-controls {
    a {
      height: $icon-dim;
      justify-content: center;
      width: auto;
    }
  }

  .mobile-toggle {
    background-color: $accent-color;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    display: flex;
    justify-content: center;
    margin-top: $icon-dim;
    width: 24px;

    @media #{$bp-large} {
      display: none;
    }

    a {
      justify-content: center;
    }
  }

  .container-controls {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: $icon-dim;
    margin-top: $icon-dim;
    transition: all 100ms ease-in-out;
    width: $icon-dim;

    @media #{$bp-large} {
      gap: 3px;
    }

    &.collapsed {
      // Only mobile, ignored on desktop
      transform: translateX(-1 * #{$icon-dim});
      width: 0;
      @media #{$bp-large} {
        transform: none;
        width: auto;
      }
    }

    div {
      background-color: $accent-color;
      display: flex;
      height: $icon-dim;
      justify-content: center;
      width: $icon-dim;

      &.active {
        background-color: darken($accent-color, 12);
      }
    }
  }

  .wrapper {
    height: calc(100vh - 4rem - var(--topnav-height) - var(--meta-nav-top));
    min-height: 100%;
    right: 0;

    @media #{$bp-large} {
      background: transparent;
      display: flex;
      flex-direction: row;
      height: auto;
      overflow: hidden;
      padding: 0;
      position: relative;
      top: 0;
      width: $desktop-content-width;
    }
  }

  .container-content {
    background-color: transparentize($color: $area-color-2, $amount: 0.5);
    display: block;
    height: min-content;
    left: 0;
    padding: 2rem 2rem calc(2rem + var(--topnav-height) + var(--meta-nav-top));
    position: fixed;
    right: 0;
    top: calc(var(--topnav-height));
    transition: var(--meta-nav-transition);
    transform: translateY(var(--meta-nav-top, 0));

    @media #{$bp-large} {
      background: transparent;
      border-radius: 10px 0 0 10px;
      overflow: hidden;
      padding: 0;
      position: static;
    }

    &.collapsed {
      transform: translateX(100%);
    }

    &.shadow {
      box-shadow: 0 0 15px 0 transparentize($area-color-8, 0.6);
    }

    .checkmark,
    .dummy-input,
    input,
    select,
    textarea {
      background-color: $area-color-14;
    }

    ul {
      list-style: none;
      padding-inline-start: 0;
    }

    li {
      border-bottom: 1px solid $area-color-14;

      &:first-child {
        .social-icon {
          margin-top: 0;
        }
      }

      &:last-child {
        border-bottom: 0;
      }
    }

    .infos-container {
      display: flex;
      flex-direction: column;

      span {
        line-height: 1.3;
      }
    }

    .control-bar {
      background-color: $accent-color;
      border-radius: 10px 10px 0 0;
      display: flex;
      justify-content: space-between;
      padding: 10px 0 10px 25px;

      @media #{$bp-large} {
        border-radius: 10px 0 0;
      }

      a {
        margin: 2px 5px 0 0;
        width: $icon-dim;
      }

      i {
        display: flex;
        justify-content: center;
      }

      .message {
        @include line-clamp(3, 86px);
        color: $text-color-1;
        font-weight: 600;
        margin-right: 5px;
      }
    }

    .content {
      background-color: $area-color-1;
      border-radius: 20px 20px 10px 10px;
      height: 0;
      overflow: hidden;
      max-height: 100%;
      display: flex;
      flex-direction: column;

      @media #{$bp-large} {
        border-radius: 10px 0 0 10px;
        flex: 0 0 auto;
        width: 350px;
      }

      &.active {
        height: min-content;
        overflow: visible;
      }

      &.content-contact {
        display: none;

        @media #{$bp-large} {
          display: block;
        }

        &.active {
          display: block;
        }
      }
    }

    .sub-content {
      min-height: 300px;
      padding: 0 25px;
      overflow: auto;
      overscroll-behavior: contain;
      @media #{$bp-large} {
        max-height: 58vh;
      }
    }

    .arrow-left,
    .arrow-right {
      a {
        justify-content: center;
      }

      i {
        color: $text-color-3;
      }
    }

    .centered-column {
      display: flex;
      flex: 1 0 calc(100% - 60px);
      flex-direction: column;
      justify-content: space-between;

      i {
        color: $text-color-3;
      }

      ul {
        margin: 0;
      }

      .persons {
        height: 95%;
      }

      .person-single {
        display: block;
        height: 0;
        opacity: 0;

        &.active {
          height: auto;
          opacity: 1;
        }
      }

      .dots {
        height: 5%;
      }
    }

    .arrow-right,
    .arrow-left {
      display: flex;
      justify-content: center;
      min-height: calc(100% - #{$icon-dim});
      width: 30px;
    }

    .dots {
      display: flex;
      justify-content: center;
      list-style: none;
      margin: 0;

      li {
        border-bottom: 0;
        cursor: pointer;
        width: 24px;

        &::before {
          background-color: $area-color-1;
          border: 1px solid $text-color-3;
          border-radius: 10px;
          content: '';
          height: 10px;
          outline: 0;
          position: absolute;
          width: 10px;
        }
      }

      .active {
        &::before {
          background-color: $accent-color;
          border-color: $accent-color;
        }
      }
    }

    .icons {
      display: flex;

      a {
        justify-content: center;
      }

      i {
        color: $text-color-12;
        margin-right: 1rem;
      }
    }

    .person {
      display: flex;
      flex-direction: column;

      span {
        color: $accent-color;
        font-size: 14px;
        line-height: 1.3;

        &:first-child {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }

    .person-img {
      background-image: url('/group/static/Profilbild_Unbestimmt.svg');
      // background-position: center;
      background-size: cover;
      height: 68px;
      width: 68px;
    }

    .social-icon {
      display: flex;
      height: $icon-dim;
      margin: 15px 0;

      i {
        background-color: $accent-color;
        border-radius: 5px;
        display: flex;
        height: 100%;
        justify-content: center;
        margin-right: 1rem;
        width: $icon-dim;

        &::before {
          font-size: 24px;
          padding-top: 7px;
        }
      }

      span {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
      }
    }
  }

  .contact-infos {
    display: flex;
    min-height: 45px;
    padding: 5px 0;

    &:first-child {
      height: 90px;
      padding-top: 0;
    }

    &:not(:first-child) {
      > .info,
      > .mail {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    .info {
      margin-right: 5px;
      width: 68px;

      &.person-img-container {
        margin-right: 1rem;
      }
    }

    .number {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .mail {
      white-space: nowrap;
      width: calc(100% - 68px - 1rem);

      span {
        width: min-content;
      }
      /* stylelint-disable */
      // Disable Stylelint to use scss lists and foreach-variables
      @each $size in $sizes {
        &.size-#{$size} {
          //Workaround for firefox: font-size not applied: inline-style on element (first version)
          font-size: $size;
        }
      }
      /* stylelint-enable */
    }
  }

  .content-email {
    .sub-content {
      padding: 10px 25px;
    }
  }

  .content-social-media {
    height: min-content;
  }

  .content-button-info {
    .sub-content {
      padding: 25px;
    }

    .custom-headline,
    p {
      margin: 0 0 1rem 0;
    }
  }

  .content-contact {
    .sub-content {
      display: flex;
      height: calc(100% - #{$icon-dim});
      min-height: 270px;
      overflow: hidden;
      padding: 10px 0;
    }

    .navigation-disabled {
      padding: 25px;

      .arrow,
      .dots {
        display: none;
      }
    }
  }

  a {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  i {
    color: $text-color-1;
  }

  .form {
    .form-buttons {
      display: flex;
      flex-basis: 100%;
      flex-direction: row;
      justify-content: flex-start;
      max-width: 100%;

      .cancel {
        @extend %background-variations;
        @extend %background-variations-outline;
        @include button();
        @include button-outline-block($accent-color);
      }

      .submit {
        @extend %background-variations;
        @extend %background-variations-outline;
        @include button();
        flex-basis: 100%;
        margin-right: 0;
      }

      button:last-child {
        margin: 0 0 auto;
      }
    }
  }
}
