.content-view3 {
  .contact {
    background-color: $area-color-1;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    height: 155px;
    margin: 10px 0 0;

    @media #{$bp-medium} {
      height: 246px;
    }
    @media #{$bp-large} {
      height: 340px;
    }

    &.company {
      background-color: $area-color-4;

      * {
        color: $accent-color;
      }
    }

    > img {
      border-radius: 4px;
      margin-right: 20px;
      width: 157px;
      @media #{$bp-medium} {
        width: 246px;
      }
      @media #{$bp-large} {
        width: 356px;
      }
    }

    * {
      color: $text-color-3;
    }

    .person-information {
      line-height: 15px;
      margin: 0 0 10px;
      @media #{$bp-medium} {
        line-height: 22px;
        margin: 10px 0 20px;
      }
    }

    .title {
      font-size: 11px;
      font-weight: 500;
      @media #{$bp-medium} {
        font-size: 1rem;
        line-height: 2rem;
      }
    }

    .name {
      font-size: 15px;
      font-weight: 700;
      @media #{$bp-medium} {
        font-size: 2.25rem;
        line-height: 3rem;
      }
      @media #{$bp-large} {
        font-size: 3rem;
        margin-bottom: 0;
      }
    }

    .position {
      font-size: 11px;
      font-weight: 600;
      @media #{$bp-medium} {
        font-size: 1.125rem;
        line-height: 2rem;
        margin: 0;
      }
      @media #{$bp-large} {
        font-size: 1.5rem;
        line-height: 2rem;
        margin: 30px 0 0;
      }
    }

    .company-name {
      font-size: 11px;
      @media #{$bp-medium} {
        font-size: 1.125rem;
        line-height: 16px;
        margin: 0 0 25px;
      }
      @media #{$bp-large} {
        font-size: 1.5rem;
        line-height: 20px;
      }
    }

    .contact-info {
      font-size: 11px;
      line-height: 15px;
      @media #{$bp-medium} {
        font-size: 18px;
        line-height: 22px;
      }
    }

    .text-box {
      margin: 20px auto auto 0;
      @media #{$bp-medium} {
        margin: 0;
      }
    }
  }
}
