%pimcore {
  .grecaptcha-badge {
    visibility: hidden;
  }

  .editmode {
    width: 100%;

    [id^='tooltip'] *,
    [role='tooltip'] *,
    [data-role='tooltip'],
    .x-tip-body-default,
    .x-tip-body-default * {
      /* stylelint-disable */
      color: white !important;
      /* stylelint-enable */
      font-weight: bold;
    }

    > article {
      margin-top: 0 !important;
    }

    .grid-container {
      display: grid;
      gap: 1rem;
      grid-template-columns: repeat(2, 1fr);

      // Overwrite
      /* stylelint-disable-next-line selector-class-pattern */
      .pimcore_tag_link {
        width: 95%;
      }

      /* stylelint-disable-next-line selector-class-pattern */
      .pimcore_tag_image img {
        max-height: 250px;
      }

      .span-all-cols {
        grid-column: 1 / -1;
      }
    }

    @for $i from 1 through 12 {
      /* stylelint-disable-next-line aditayvm/at-rule-no-children */
      .cols-#{$i} {
        grid-template-columns: repeat($i, 1fr);

        &.grid-col-auto {
          grid-template-columns: repeat($i, auto);
        }
      }
    }

    .repeating-block {
      background: $text-color-2-light;
      border-radius: 4px;
      padding: 1rem;
    }

    .areablock {
      margin-bottom: 2rem;
      padding: 1rem 2rem 0;

      .head {
        background: $area-color-4;
        border-radius: 5px;
        margin-bottom: 1rem;
        padding: 5px 5px 5px 15px;

        h3::before {
          @extend %font-icon-style;
          content: '\e823';
          float: right;
          font-family: 'tta-iconfont';
        }
      }

      &.collapsed {
        h3::before {
          content: '\e820';
        }

        .content {
          height: 0;
          overflow: hidden;
        }
      }
    }

    .collapse-trigger {
      cursor: pointer;
    }

    .collapse-target.collapsed {
      height: 0;
      overflow: hidden;
    }

    hr {
      border-top: 1px solid $default-text-color;
    }

    .deprecated::after {
      color: $company-color-nov; // he
      content: '*';
    }

    .headline-notes {
      position: relative;
      &-input {
        inset: 50% 100px auto 240px;
        transform: translateY(-50%);
        position: absolute;
      }
    }

    .x-boundlist-item-over .icon-select {
      filter: invert(1);
    }

    .x-boundlist-item-selected .icon-select {
      filter: invert(1);
    }
  }
}
