.searchbox-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;

  @media #{$bp-small} {
    flex-wrap: nowrap;
  }

  .searchbox-input {
    flex: 0 0 100%;
    @media #{$bp-small} {
      flex-shrink: 1;
    }
  }

  button.clear,
  button.submit {
    flex: 0 1 100%;
    height: 48px;
    margin-top: 1rem;
    padding: 0;
    @media #{$bp-small} {
      flex: 0 1 auto;
      margin: 0 0 0 1rem;
      width: 4rem;
    }
  }

  .clear {
    @include button-block($bg-color-be0002);
    opacity: 1;
    transition: all 200ms;
    width: 100%;

    &.collapsed {
      border: 0;
      height: 0;
      margin: 0;
      opacity: 0;
      @media #{$bp-small} {
        height: auto;
        width: 0;
      }
    }
  }
}
