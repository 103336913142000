@each $name, $color in $global-palette {
  /* stylelint-disable-next-line aditayvm/at-rule-no-children */
  .#{$name} {
    .heading {
      color: $color !important;
    }

    a.button {
      background-color: $color !important;
      border: $color !important;

      &:hover {
        color: $text-color-1;
      }
    }

    .horizontal-line {
      background-color: $color !important;
    }

    .circle {
      border-color: $color !important;

      &.has-colored-center .colored-center::after {
        background-color: $color;
      }
    }
  }
}
