.image-header-icons {
  // full view height minus header
  height: calc(100svh - (var(--meta-nav-height) + var(--topnav-height)));
  min-height: 600px;
  position: relative;

  > * {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  picture.fullsize-header-image > img {
    opacity: 0; // opacity rule will be overwritten by js when img is loaded
    transition: opacity 0.3s ease;
  }

  picture.preview-image,
  picture.fullsize-header-image {
    overflow: hidden;

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  .preview-image > img {
    filter: blur(3vw);
    transform: scale(1.1);
  }

  .image-overlay {
    --overlay-color-left: transparent;
    --overlay-color-right: var(--overlay-color-left, transparent);
    background: linear-gradient(
      to right,
      var(--overlay-color-left),
      var(--overlay-color-right)
    );
  }

  .content-wrap {
    display: flex;
    flex-flow: column;
  }

  .static-content {
    flex: 1;
    max-height: calc((100%) - 100px);
    padding: 24px;
    width: 100%;
  }

  .text-wrap {
    @extend %content-column;
    display: flex;
    flex-flow: column;
    gap: 25px;
    height: 100%;
    justify-content: center;
    margin: 0 auto;
    width: 100%;

    @media #{$bp-large} {
      gap: 40px;
    }
  }

  .title {
    color: $text-color-1;
    font-size: 48px;
    font-weight: 700;
    line-height: 54px;

    @media #{$bp-medium} {
      font-size: 64px;
      line-height: 70px;
    }

    @media #{$bp-large} {
      font-size: 80px;
      line-height: 90px;
    }
  }

  .teaser {
    @include line-clamp(4, 25px);
    line-height: 25px;

    @media #{$bp-medium} {
      @include line-clamp(4, 32px);
      line-height: 32px;
    }

    @media #{$bp-large} {
      @include line-clamp(4, 30px);
      line-height: 30px;
    }
  }

  .teaser,
  swiper-slide span {
    color: $text-color-1;
    font-size: 16px;
    font-weight: 400;

    @media #{$bp-medium} {
      font-size: 18px;
    }

    @media #{$bp-large} {
      font-size: 21px;
      font-weight: 600;
    }
  }

  .button {
    @include button;
    font-size: 16px;
    max-width: fit-content;

    &:hover {
      border-color: $area-color-1;
    }

    @media #{$bp-medium} {
      font-size: 18px;
      max-height: fit-content;
    }
  }

  .icon-slider {
    background-color: rgba(0, 0, 0, 0.35);
    height: 120px;
    overflow: hidden;

    @media #{$bp-medium} {
      height: 130px;
    }

    @media #{$bp-large} {
      height: 150px;
    }

    swiper-container {
      @extend %content-column;
      height: 100%;
      margin: 0 auto;
    }

    swiper-slide {
      align-self: center;
      padding: 16px 16px 24px;
      width: 100%; // 1 on small screens

      @media #{$bp-small} {
        width: 50%; // 2 on medium screens
      }

      @media #{$bp-large} {
        width: calc(100% / 3); // 3 on large screens
      }

      a.icon-link {
        align-items: center;
        display: flex;
        gap: 15px;
        height: 100%;
        justify-content: center;

        &:hover {
          picture,
          i {
            background-color: $area-color-1;
          }

          i::before {
            color: $accent-color;
          }
        }
      }

      picture,
      i {
        background-color: transparent;
        border: 2px solid $area-color-1;
        border-radius: 100%;
        flex-shrink: 0;
        height: 51px;
        width: 51px;
      }

      img {
        height: 100%;
        object-fit: contain;
        padding: 8px;
        width: 100%;
      }

      i::before {
        color: $text-color-1;
        display: block;
        font-size: 26px;
        line-height: 47px;
        text-align: center;
      }

      span {
        @include line-clamp(3, (16px * 1.4));
        font-weight: 700;
        line-height: 1.4;
        max-height: 100%;
        overflow: hidden;

        @media #{$bp-medium} {
          @include line-clamp(3, (18px * 1.4));
        }
        @media #{$bp-large} {
          @include line-clamp(3, (21px * 1.4));
          font-weight: 600;
        }
      }
    }
  }

  swiper-container::part(bullet),
  swiper-container::part(bullet-active) {
    background-color: $area-color-1;
    height: 10px;
    margin: 0 6px;
    width: 10px;
  }

  swiper-container::part(bullet-active) {
    opacity: 0.5;
  }
}
