.image-slider .thumbnail-wrap {
  .column {
    &:nth-child(n) {
      .thumb-overlay {
        background-color: darken($color-palette-1, 4);
      }
    }

    &.active {
      .thumb-overlay {
        background-color: $color-palette-1;
      }
    }
  }
}
