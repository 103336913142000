.person-wrap {
  $margin: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  // @media #{$bp-medium} {
  //   margin-left: -1 * $margin;
  // }
  // @media #{$bp-large} {
  //   padding: 0 17px;
  // }

  .module-header {
    width: 100%;
  }

  .person {
    align-items: center;
    background: $transparent;
    display: flex;
    flex-basis: 100%;
    min-height: 150px;
    overflow: hidden;

    .image-container {
      background-image: url('/group/static/Profilbild_Unbestimmt.svg');
      background-size: cover;
      border-radius: 50%;
      display: inline-block;
      height: 150px;
      min-height: 150px;
      min-width: 150px;
      overflow: hidden;
      position: relative;

      > * {
        left: 0;
        position: absolute;
        top: 0;
      }

      &:hover {
        .overlay-contact {
          background-color: transparentize($accent-color, 0.25);
          color: $area-color-1;
          opacity: 1;
          visibility: visible;
        }
      }

      .overlay-contact {
        background-color: transparentize($accent-color, 1);
        background-image: url('/group/static/Hovereffekt_Ansprechpartner.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 60%;
        color: $transparent;
        display: none;
        height: 100%;
        opacity: 0;
        transition: all linear 400ms;
        visibility: hidden;
        width: 100%;

        @media #{$bp-large} {
          display: block;
        }
      }
    }

    @media #{$bp-medium} {
      flex: 0 0 calc((100% - #{2 * $margin}) / 2);
      margin-right: $margin;
    }
  }

  .info {
    display: inline-flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 11px 0 11px 1rem;

    span {
      color: $text-color-3;
      line-height: 16px;

      &.line {
        margin-bottom: 0;

        &:first-child {
          margin-bottom: 10px;
        }
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .generic-button {
      margin-top: 1rem;
      max-width: 156px;

      @media #{$bp-large} {
        display: none;
      }
    }
  }

  .name {
    color: $text-color-3;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
  }

  .person-info {
    display: flex;
    flex-direction: column;
    font-size: 11px;
    margin-bottom: 0;
  }

  .person {
    .person-info {
      .mailto {
        margin-top: 10px;
      }
    }
  }

  .social {
    font-size: 11px;

    i {
      color: $text-color-3;
    }
  }
}
