$company: 'novgis';

/* Palette */
$darkred: rgba(140, 0, 42, 1);
$medred: hsl(349, 100%, 40%);
$pinkred: rgba(255, 0, 46, 1);
$chathams: rgba(19, 56, 129, 1);
$darkgrey: rgb(62, 61, 64);
$medgrey: rgba(112, 113, 114, 1);
$lightgrey: rgba(177, 179, 180, 1);

//Font Families
$font-base: 'Segoe UI', sans-serif;
$vcard-font: $font-base;
$vcard-call: $pinkred;

//Element Colors
$accent-color: $medred;
$accent-color1: transparentize($darkgrey, 0.15);
// $accent-color2: $medred;
$accent-color3: rgba(109, 110, 113, 1);
$accent-color4: rgba(167, 169, 172, 1);
$accent-color-70: transparentize($accent-color, 0.3);
$accent-color-30: transparentize($accent-color, 0.7);
$accent-color10: rgba(102, 102, 102, 1);

//Text Colors
$text-color-2: $accent-color;
$text-color-3: $darkgrey;
$text-color-21: $medred;

$border-color-3: rgb(166, 168, 169);

//Shade & Area Colors ------------------------------------
$area-color-3: $lightgrey;
$area-color-14: rgba(237, 240, 244, 1);
$area-color-22: rgba(242, 239, 237, 1);

$shade-type-3: rgba(237, 240, 244, 1);

$headline-color: $accent-color;
$footer-background: $chathams;
$background-color: rgba(255, 255, 255, 1);
$background-color-alternate: $area-color-3;

//Border Colors ------------------------------------------
$border-color-3: rgba(166, 168, 169, 1);

//Printcard ---------------------------------
$printcard-red: rgb(222, 10, 27);
$printcard-darkred: rgb(140, 0, 42);
$printcard-blue: rgb(19, 56, 129);
