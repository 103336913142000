.accordion {
  .slice {
    // background-color: $accent-color;
    background-color: var(--accordion-background-color, #{$accent-color});
    border-color: var(--accordion-background-color, #{$accent-color});

    &:not(.is-active) .head * {
      color: $text-color-1;
    }

    &:not(.is-active) .head i {
      color: var(--accordion-text-color, #{$text-color-1});
    }
  }
}
