.content-block {
  border: 1px solid transparent;
  border-radius: 1rem;
  flex: 0 1 calc(100% - (#{$mobile-center-width / 2} + #{$mobile-spacing-left}));
  padding-left: 1rem;
  @media #{$bp-medium} {
    flex-basis: calc(100% - (#{$center-width / 2}));
  }

  &.replacement-margin-top {
    @media #{$bp-medium} {
      margin-top: 1rem;
    }
  }

  > * {
    display: block;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  .date {
    font-size: 1rem;
    line-height: 1;
    margin: 0;
    margin-top: 1rem;
    min-height: 1rem;
  }

  .heading {
    color: $accent-color;
    line-height: 2rem;
    margin-top: 0.8rem;
  }

  .date + .heading {
    margin-top: 0;
  }

  .image {
    background-position: center;
    background-size: cover;
    border-radius: 4px;
    height: 200px;
    width: 100%;
  }

  .button {
    @include button;
    width: auto;
  }
  @media #{$bp-medium} {
    padding: 1.5rem;
  }
}

%row-block-footer-overrides-content-block {
  img.image {
    align-self: center;
    height: auto;

    @media #{$bp-medium} {
      max-height: 350px;
      width: auto;
    }
  }

  .button {
    align-self: center;
  }

  .center-line {
    background-color: $border-color-3;
  }

  .circle.center {
    $wh: 4rem;
    height: $wh;
    width: $wh;
  }

  .content-block {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;

    @media #{$bp-medium} {
      margin-top: 1rem;
    }

    > * {
      @media #{$bp-medium} {
        text-align: center;
      }
    }
  }
}
