//Font Families
$font-base: 'Hanken Grotesk';

//Element Colors
$accent-color: rgb(180, 11, 26); // #B40B1A

$color-palette-1: $accent-color;
$color-palette-2: rgba(240, 146, 145, 1);

//Text Colors
$text-color-2: $accent-color;
$text-color-3: rgb(66, 67, 69); // #424345
$text-color-7: rgba(100, 99, 99, 1);

$area-color-15: rgba(100, 99, 99, 1); // #646363
$area-color-11: rgba(243, 243, 243, 1); // #f3f3f3

$default-text-color: $text-color-3;
$background-color-alternate: $area-color-11;
$background-color-alternate-transparent: transparent;
$background-color-footer: rgb(66, 67, 69); // #424345

// printcard -------------------------------
$printcard-red: rgb(183, 14, 12);
$printcard-gray: rgb(100, 99, 99);
