@mixin setTransformOrigin($columns, $class) {
  grid-template-columns: repeat(#{$columns}, 1fr);
  .#{$class} {
    transform-origin: center;

    &:where(.first-row) {
      transform-origin: top;
    }

    &:where(.bottom-row) {
      transform-origin: bottom;
    }
    @if $columns != 1 {
      &:where(:nth-child(#{$columns}n + 1)) {
        transform-origin: left;

        &:where(.first-row) {
          transform-origin: left top;
        }

        &:where(.bottom-row) {
          transform-origin: left bottom;
        }
      }
      &:where(:nth-child(#{$columns}n)) {
        transform-origin: right;

        &:where(.first-row) {
          transform-origin: right top;
        }

        &:where(.bottom-row) {
          transform-origin: right bottom;
        }
      }
    }

    &.active {
      @if $columns == 1 {
        transform: scale(1.05);
      } @else if $columns == 2 {
        transform: scale(1.5);
      } @else {
        transform: scale($columns / 2);
      }
    }
  }
}

// Now i am sorry :/
.gallery-grid {
  .wrapper {
    @include setTransformOrigin(1, 'gallery-grid-img');
    align-items: center;
    display: grid;
    flex-flow: row wrap;

    &.small {
      @include setTransformOrigin(2, 'gallery-grid-img');
      @media #{$bp-small} {
        @include setTransformOrigin(4, 'gallery-grid-img');
      }
      @media #{$bp-medium} {
        @include setTransformOrigin(6, 'gallery-grid-img');
      }
      @media #{$bp-large} {
        @include setTransformOrigin(8, 'gallery-grid-img');
      }
    }

    &.normal {
      @media #{$bp-small} {
        @include setTransformOrigin(2, 'gallery-grid-img');
      }
      @media #{$bp-medium} {
        @include setTransformOrigin(3, 'gallery-grid-img');
      }
      @media #{$bp-large} {
        @include setTransformOrigin(4, 'gallery-grid-img');
      }
    }

    &.large {
      @media #{$bp-small} {
        @include setTransformOrigin(1, 'gallery-grid-img');
      }
      @media #{$bp-medium} {
        @include setTransformOrigin(2, 'gallery-grid-img');
      }
      @media #{$bp-large} {
        @include setTransformOrigin(3, 'gallery-grid-img');
      }
    }
  }

  p {
    margin: 0 0 2rem;
  }

  .gallery-grid-img {
    // flex-basis: (100%);
    position: relative;
    transition: transform 200ms ease-in-out, box-shadow 200ms ease-out;
    z-index: 10;

    img {
      display: block;
      height: auto;
      width: 100%;
    }

    span.image-description {
      background-color: transparentize($area-color-8, 0.2);
      color: $text-color-1;
      display: none;
      min-width: 100%;
      padding: 10px;
      position: absolute;
    }
  }

  .gallery-grid-darkend-background {
    background: #000;
    inset: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    transition: opacity 200ms 200ms ease-in-out;
    z-index: 999;
  }

  .active {
    box-shadow: 0 0 20px 2px transparentize($area-color-8, 0.5);
    // transform: scale(1.05);
    transform-origin: center;
    z-index: 1000;

    // @media #{$bp-small} {
    //   transform: scale(1.4);
    // }
    // @media #{$bp-medium} {
    //   transform: scale(1.5);
    // }
    // @media #{$bp-extra-large} {
    //   transform: scale(1.5);
    // }

    img {
      margin-bottom: -1px;
    }

    span.image-description {
      display: block !important;
    }

    ~ .gallery-grid-darkend-background {
      opacity: 0.8;
    }
  }
}
