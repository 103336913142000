.content-image-slider {
  @extend %content-column;
  max-height: 590px;
  overflow: hidden;
  position: relative;

  .slide-container {
    align-content: center;
    display: flex;
    flex-flow: row nowrap;
  }

  .image-container {
    align-items: center;
    display: flex;
    height: 300px;
    min-width: 100%;
    transition: transform 200ms ease-in-out;

    @media #{$bp-medium} {
      height: 590px;
    }

    a {
      height: 100%;
      margin: 0 auto;
      width: 100%;
    }

    .slide {
      flex: 0 0 calc(100vw - (100vw - 100%)); // VW without Scrollbar
      height: 100%;
      margin: 0 auto;
      padding: 0 2rem;
      width: 100%;
      @media #{$bp-medium} {
        padding: 0 4rem;
      }

      picture {
        align-items: center;
        display: flex;
        height: 100%;
      }

      img {
        display: block;
        margin: 0 auto;

        max-height: 100%;
        max-width: 100%;
      }

      &.small {
        img {
          @media #{$bp-medium} {
            // max-height: 75%;
            max-width: 50%;
          }
          place-self: center;
        }
      }
      // Default
      /*       &.large {
        img {
          max-height: 100%;
          max-width: 100%;
        }
      } */
    }
  }

  .tabs-container {
    display: none;
    justify-content: center;
    margin: 0 0 1rem;
    position: relative;
    top: -2rem;
    width: 100%;

    &.indicator-visible {
      display: flex;
    }

    li {
      cursor: pointer;
      flex-grow: 1;
      height: 18px;
      margin-right: 1rem;
      padding: 7px;
      position: relative;
      width: 14px;

      @media #{$bp-medium} {
        width: 24px;
      }

      &::before {
        background-color: $indicator-inactive-color;
        border: 1px solid $indicator-inactive-color;
        content: '';
        height: 1px;
        left: 50%;
        max-width: 18px;
        outline: 0;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 30px;

        @media #{$bp-medium} {
          max-width: 30px;
        }
      }

      &.active {
        &::before {
          background-color: $accent-color;
          border-color: $accent-color;
        }
      }
    }

    .controls {
      align-items: center;
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }

  .arrow {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 30px;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;

    @media #{$bp-medium} {
      height: 48px;
      width: 48px;
    }

    i {
      font-size: 25px;
      height: 30px;
      width: 30px;

      &.fa-chevron-left,
      &.fa-chevron-right {
        color: $accent-color;
        line-height: 1.2;
        padding-left: 5px;
      }

      @media #{$bp-medium} {
        font-size: 40px;
        height: 48px;
        width: 48px;

        &.fa-chevron-left,
        &.fa-chevron-right {
          padding-left: 10px;
        }
      }
    }
  }

  .left {
    left: 0;
  }

  .right {
    right: 0;
  }
}
