.filter-liste {
  .head {
    h4 {
      font-weight: 400;
      line-height: 30px;
      margin: 5px 0 20px;
    }
  }

  .container {
    align-items: center;
    border-bottom: 2px solid $area-color-14;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 1rem 0;

    &:first-of-type {
      border-top: 4px solid $area-color-11;
    }

    &:last-of-type {
      border-bottom: 4px solid $area-color-14;
    }

    .main {
      flex-basis: 100%;
      flex-shrink: 0;
    }

    p {
      display: block;
      font-size: 18px;
      line-height: 24px;
      margin: 0.25rem 0 0;

      @media #{$bp-large} {
        font-size: 18px;
        line-height: 24px;
      }
    }

    span.subhead {
      color: $accent-color;
      display: block;
      font-size: 20px;
      line-height: 26px;

      @media #{$bp-large} {
        font-size: 24px;
        line-height: 32px;
      }
    }

    .date {
      flex: auto;

      span {
        display: block;
      }
    }

    .execution-guarantee-flag {
      background-color: $bg-color-ffffff;
      border: 2px solid $accent-color;
      border-radius: 3px;
      color: $accent-color;
      font-size: 1rem;
      letter-spacing: 0.05em;
      margin: 0.25rem auto 0.25rem 0;
      padding: 0.25rem 0.75rem;
      text-align: center;
      text-transform: uppercase;

      @media #{$bp-medium} {
        letter-spacing: 0.04em;
        margin: 0.2rem auto 0.2rem 0;
        padding: 0.1rem 0.75rem;
      }

      @media #{$bp-large} {
        letter-spacing: 0.05em;
        margin: 0 auto 0 0;
        padding: 0.25rem 0.75rem;
      }

      &::before {
        color: $accent-color;
        content: '\f058';
        font-family: 'Font Awesome 5 Free';
        margin: 0 0.25rem 0 0;
      }
    }

    .location {
      display: block;

      @media #{$bp-small} {
        display: inline;
      }
    }

    .checkmark[data-checked='true'] {
      align-items: center;
      display: flex;
      flex-basis: 3rem;

      @media #{$bp-small} {
        margin-left: auto;
      }

      &::after {
        color: $accent-color;
        content: '\e815';
        font-family: 'tta-iconfont';
        font-size: 2rem;
        margin: 0 0.5rem;
      }
    }
  }

  .more-button {
    margin: 50px auto 0;
    text-transform: uppercase;

    a {
      font-size: 14.665px;
      font-weight: 500;
    }
  }
}

.split .filter-liste {
  padding-top: 0 !important;
}
