.modal.captcha {
  place-content: center;

  .modal-window {
    display: grid;
    gap: 2rem;
    padding: 2rem;
    width: calc(300px + 5rem + 10px);

    > * {
      display: contents;
    }

    h2 {
      font-size: 30px;
      line-height: 1.62;
    }

    .img-wrap {
      align-items: center;
      background-color: $accent-color;
      border-radius: 5px;
      display: flex;
      overflow: hidden;

      i {
        color: white;
        cursor: pointer;
        display: grid;
        font-size: 1rem;
        height: 100%;
        padding: 5px;
        place-content: center;
      }
    }

    .error {
      color: red;
      font-size: 16px;
      font-weight: 700;
    }

    form {
      display: grid;
      gap: 1rem;
      justify-content: initial;
      margin: 0;

      .button {
        @include button();
      }
    }
  }

  div.options {
    align-items: center;
    display: flex;
    height: 64px;
    justify-content: space-around;

    img {
      border-radius: 10px;
      display: block;
      height: 64px;
      opacity: 1;
      padding: 10px;
      transition: all 400ms;
      width: 64px;

      &:hover {
        @media #{$bp-medium} {
          background-color: $area-color-14;
        }
      }

      &:active {
        transform: scale(0.98);
      }

      &.wrong {
        background-color: transparentize($bg-color-be0002, 0.7);
      }

      &.correct {
        background-color: transparentize($bg-color-009848, 0.7);
      }

      &.invisible {
        opacity: 0;
      }
    }
  }
}
