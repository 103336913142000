.content-view1 {
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1140px;

  @media #{$bp-medium} {
    flex-direction: row;
  }

  // Right is default

  section {
    display: block;
    flex: 0 1 100%;
    margin: 0 1rem 0 0;
    width: 100%;

    header {
      margin-bottom: 12px;
    }
  }

  @mixin row-view($rowstartsat) {
    flex-flow: column nowrap;

    @media #{$rowstartsat} {
      flex-flow: row nowrap;
    }

    section {
      margin: 0 0 1rem;
      @media #{$rowstartsat} {
        margin: 0 1rem 0 0;
      }
    }

    &.left {
      flex-flow: column-reverse nowrap;
      @media #{$rowstartsat} {
        flex-flow: row-reverse nowrap;
      }

      section {
        margin: 1rem 0 0;

        @media #{$rowstartsat} {
          margin: 0 0 0 1rem;
        }
      }
    }
  }

  &.small {
    @include row-view($rowstartsat: '(min-width: 0px)');

    .contact-container {
      max-width: fake-grid-item-width(2, 1rem);
      @media #{$bp-small} {
        max-width: fake-grid-item-width(3, 1rem);
      }
      @media #{$bp-medium} {
        max-width: fake-grid-item-width(4, 1rem);
      }
      @media #{$bp-large} {
        max-width: 154px; // Large vp
      }

      h3 {
        font-size: 13px;
        line-height: 1.15;
      }

      span {
        font-size: 11px;
        line-height: 1.15;
      }
    }
  }

  &.medium {
    @include row-view($rowstartsat: $bp-small);

    .contact-container {
      @media #{$bp-small} {
        max-width: fake-grid-item-width(2, 1rem);
      }

      @media #{$bp-medium} {
        max-width: fake-grid-item-width(3, 1rem);
      }

      h3 {
        @media #{$bp-small} {
          font-size: 22px;
          line-height: 1.15;
        }
      }

      span {
        @media #{$bp-small} {
          font-size: 18px;
          line-height: 1.15;
        }
      }
    }
  }

  &.large {
    @include row-view($rowstartsat: $bp-medium);

    .contact-container {
      @media #{$bp-medium} {
        max-width: fake-grid-item-width(2, 1rem);
      }

      @media #{$bp-large} {
        max-width: fake-grid-item-width(3, 1rem);
      }
    }
  }

  &.only-contact {
    flex-flow: row wrap;

    .contact-container {
      margin-bottom: 2rem;
    }

    &.small {
      .contact-container {
        max-width: fake-grid-item-width(2, 2rem);

        @media #{$bp-small} {
          max-width: fake-grid-item-width(3, 2rem);
        }
        @media #{$bp-medium} {
          max-width: fake-grid-item-width(4, 2rem);
        }
        @media #{$bp-large} {
          max-width: fake-grid-item-width(5, 2rem);
        }
        @media #{$bp-extra-large} {
          max-width: fake-grid-item-width(6, 2rem);
        }
      }
    }

    &.medium {
      .contact-container {
        max-width: fake-grid-item-width(3, 2rem);

        @media #{$bp-medium} {
          max-width: fake-grid-item-width(4, 2rem);
        }
      }
    }

    &.large {
      .contact-container {
        max-width: fake-grid-item-width(2, 2rem);

        @media #{$bp-medium} {
          max-width: fake-grid-item-width(3, 2rem);
        }
      }
    }
  }

  @import 'contact-container';
}
