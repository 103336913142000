//Element Colors
$accent-color: rgba(255, 102, 0, 1);
$accent-color-70: rgba(255, 102, 0, 0.7);
$accent-color-30: rgba(255, 102, 0, 0.3);

//Text Colors
$text-color-2: rgba(255, 102, 0, 1);

//Printcard ---------------------------------
$printcard-orange: rgb(242, 140, 0);
$printcard-gray: $area-color-15;

$nav-font-color: $text-color-3;
