$sizes: 24px, 23px, 22px, 21px, 20px, 19px, 18px, 17px, 16px, 15px, 14px, 13px,
  12px;

.icon-grid {
  --button-color: #{$headline-color};
  --button-hover-background: #{$accent-color};
  --button-hover-color: #fff;

  $accent-color-2: $accent-color !default; //prevent es-lint to reorder variables
  overflow: hidden;

  .icon-grid-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .icon-grid-text {
      color: var(--ss-text-color, inherit);
      line-height: 24px;
      margin-bottom: auto;
      padding-bottom: 15px;
    }

    .link-button {
      border: 2px solid var(--local-border-color);
      border-radius: 5px;
      color: var(--button-color, inherit);
      display: block;

      font-size: 12px;
      margin: auto 0 1rem;
      outline: 1px solid var(--button-color, inherit);
      overflow-x: hidden;
      padding: 0.5em 1em;
      text-overflow: ellipsis;
      transition: background-color 200ms ease-out, color 200ms ease-out,
        outline 200ms ease-out;

      &:hover {
        background: var(--button-hover-background);
        color: var(--button-hover-color);
        outline: 1px solid var(--button-hover-color);
      }

      @media #{$bp-small} {
        font-size: unset;
      }
    }

    .icon-grid-headline {
      align-items: center;

      border-bottom: 1px solid $shade-type-2;
      color: var(--ss-headline-color, #{$shade-type-4});
      display: flex;
      flex-direction: row;

      font-size: 12px;
      line-height: 16px;
      margin-bottom: 15px;
      overflow-x: hidden;
      padding-bottom: 15px;
      text-overflow: ellipsis;
      @media #{$bp-medium} {
        padding-bottom: 0;

        &.icon-grid-force-headline-lines-1 {
          height: 55px;

          h4 {
            -webkit-line-clamp: 1;
          }
        }

        &.icon-grid-force-headline-lines-2 {
          height: 85px;

          h4 {
            -webkit-line-clamp: 2;
          }
        }

        &.icon-grid-force-headline-lines-3 {
          height: 115px;

          h4 {
            -webkit-line-clamp: 3;
          }
        }

        &.icon-grid-force-headline-lines-4 {
          height: 145px;

          h4 {
            -webkit-line-clamp: 4;
          }
        }

        &.icon-grid-force-headline-lines-5 {
          height: 175px;

          h4 {
            -webkit-line-clamp: 5;
          }
        }
      }

      h4 {
        -webkit-box-orient: vertical;
        color: inherit;
        display: -webkit-box;
        font-size: 24px;
        -webkit-line-clamp: 5;
        line-height: 30px;
        max-height: 100%;
        overflow: hidden;
        overflow-wrap: break-word;
        padding-right: 10px; //prevent braking too long words
        text-overflow: ellipsis;
        width: 100%;
        word-break: normal;
      }

      @media #{$bp-medium} {
        font-size: 24px;
        line-height: 26px;
        min-width: 100%;
        overflow: unset;
        text-overflow: unset;
        width: fit-content;
      }
    }

    &.scheme1 {
      --button-color: #{$headline-color};
      --button-hover-background: #{$accent-color};
      --button-hover-color: #fff;

      .icon-grid-card {
        background-color: $area-color-1;
        color: $accent-color;
      }

      .icon-grid-headline {
        border-bottom-color: $area-color-4;
        border-width: 2px;
        color: var(--ss-headline-color, #{$headline-color});
      }

      i,
      .icon-grid-icon {
        &::before {
          color: var(--icongrid-icon-color, $accent-color);
        }
      }
    }

    &.scheme2 {
      --button-color: #{$headline-color};
      --button-hover-background: #{$accent-color};
      --button-hover-color: #fff;

      //background-color: $accent-color;

      .icon-grid-card {
        background-color: $area-color-4;
      }

      i::before,
      .icon-grid-icon::before {
        color: var(--icongrid-icon-color, $accent-color);
      }

      .icon-grid-headline {
        color: var(--ss-headline-color, $accent-color);
      }

      .icon-grid-text {
        color: var(--ss-text-color, $accent-color);
      }
    }

    &.greytile {
      .icon-grid-card {
        background-color: $area-color-4;
      }
    }

    &.scheme3 {
      --button-color: #{$text-color-1};
      --button-hover-background: #{$text-color-1};
      --button-hover-color: #{$accent-color-2};

      .icon-grid-card {
        background-color: var(--icongrid-card-color, $accent-color);
      }

      i::before,
      .icon-grid-icon::before {
        color: var(--icongrid-icon-color, $text-color-1);
      }

      .icon-grid-text {
        color: var(--ss-text-color, $text-color-1);
      }

      .icon-grid-headline {
        border-bottom-color: var(--ss-headline-color, $text-color-1);
        color: var(--ss-headline-color, $text-color-1);
      }
    }

    &.scheme4,
    &.scheme5 {
      --button-color: transparent;
      --button-hover-background: transparent;
      --button-hover-color: transparent;

      i::before,
      .icon-grid-icon::before {
        color: var(--icongrid-icon-color, $area-color-1);
      }

      .icon-grid-text {
        color: var(--ss-text-color, $area-color-1);
      }

      .icon-grid-headline {
        border-bottom-color: var(--ss-headline-color, $area-color-1);
      }

      .icon-grid-card {
        background-color: transparent;
      }
    }

    .no-background {
      .icon-grid-card {
        background-color: inherit;
      }
    }
  }

  @media #{$bp-mobile} {
    .mobile-slider {
      flex-wrap: nowrap;
      max-width: 100%;
      overflow-x: scroll;
      padding-bottom: 15px;

      &::-webkit-scrollbar {
        height: 10px;
        scrollbar-width: thin;
        width: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $shade-type-2;
        border-radius: 8px;
      }

      &::-webkit-scrollbar-track {
        background-color: $shade-type-3;
        border: 1px solid $shade-type-4;
        border-radius: 8px;
      }
    }
  }

  .icon-grid-card {
    background-color: $area-color-14;
    border-radius: 4px;
    color: $shade-type-4;
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    margin: 0;
    min-width: calc(20% - 20px); //fallback &.fifth
    overflow: hidden;
    padding: 28px 10px 13px;
    text-align: center;
    text-overflow: ellipsis;
    word-break: break-word;

    &.full,
    &.half,
    &.third,
    &.quarter,
    &.fifth {
      min-width: 100%;
    }

    img {
      max-width: 100%;
      object-fit: scale-down;
    }

    > i::before {
      @extend %font-icon-style;
    }

    @media #{$bp-small} {
      &.half,
      &.quarter {
        /*quarter as half*/
        min-width: calc(50% - 20px);
      }
    }

    @media #{$bp-medium} {
      font-size: 16px;
      line-height: 24px;
      padding: 32px 28px 44px;

      &.fifth {
        /*fifth as half*/
        min-width: calc(50% - 20px);
      }

      &.third {
        min-width: calc(33.33333% - 20px);
      }
    }

    @media #{$bp-large} {
      padding: 28px 25px 13px;

      &.quarter {
        min-width: calc(25% - 20px);
      }

      &.fifth {
        min-width: calc(20% - 20px);
      }
    }

    &.customcolors {
      --button-color: var(--icongrid-text-color, #{$accent-color});
      --button-hover-background: var(--icongrid-text-color);
      --button-hover-color: #{$accent-color-2};

      background-color: var(--icongrid-card-color, #{$accent-color}) !important;
      color: $area-color-1;
      display: block;

      .icon-grid-headline {
        border-bottom-color: var(
          --icongrid-text-color,
          var(--ss-headline-color, #{$accent-color})
        );
        border-width: 2px;
        color: var(
          --icongrid-text-color,
          var(--ss-headline-color, #{$accent-color})
        );
      }

      .icon-grid-text {
        color: var(
          --icongrid-text-color,
          var(--ss-text-color, #{$accent-color})
        );
      }

      .icon-grid-icon {
        text-align: center;
      }

      i,
      .icon-grid-icon::before {
        color: var(--icongrid-icon-color, #{$accent-color});
      }
    }
  }

  .icon-grid-icon {
    padding-bottom: 15px;
    text-align: center;
  }

  i,
  .icon-grid-icon {
    &::before {
      color: $shade-type-4;
      font-size: 3rem;
    }

    &.small {
      margin-top: 2rem;

      &::before {
        font-size: 3rem;
      }
    }

    &.medium {
      &::before {
        font-size: 4rem;
      }
    }

    &.large::before {
      font-size: 5rem;
    }
  }

  .icon-grid-header {
    margin-bottom: 20px;
  }

  span.subheadline {
    font-size: 18px;

    @media #{$bp-medium} {
      font-size: 24px;
    }
  }
}

@import './icon-grid-hover';
