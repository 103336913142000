* {
  font-family: $font-base, 'Segoe UI', ui-sans-serif, sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 600;
}

h1 {
  font-size: 28pt;
}

h2 {
  color: $accent-color;
  font-size: 26pt;
  margin: 0;
}

h3 {
  font-size: 24pt;
}

h4 {
  font-size: 20pt;
}

h5 {
  font-size: 18pt;
}

p {
  font-size: 13.5pt;
  font-weight: 300;
}
