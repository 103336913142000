.printcard-container {
  @import '../../../styles/base/variables_novgis';

  &.nov {
    * {
      font-family: 'Segoe UI', sans-serif;
      font-weight: 100;
    }

    .highlight {
      &.left,
      &.right {
        .top {
          background-color: $printcard-darkred;
        }

        .bottom {
          background-color: $printcard-red;
        }
      }
    }

    .content-container {
      .company-logo {
        height: 69px;
        right: 53px;
        top: 97px;
        width: 408px;
      }
    }

    .person {
      color: $printcard-blue;
      font-size: 34px;
      font-weight: 600;
      line-height: 40px;
    }

    .company {
      color: $printcard-blue;
      font-size: 30px;
      font-weight: 600;
      line-height: 38px;
    }

    .title {
      font-size: 30px;
      font-weight: 100;
      line-height: 40px;
    }

    .place,
    .email,
    .telephone {
      font-size: 30px;
      font-weight: 100;
      line-height: 38px;
    }

    .contact-details {
      height: 114px;
      margin: 21px 0 29px 54px;
    }

    .backside-container {
      background-color: $printcard-darkred;

      .company-details {
        display: flex;
        height: 250px;
        justify-content: flex-start;

        .left {
          // justify-content: flex-start;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          width: 275px;
        }

        .right {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          width: 300px;
        }
      }
    }
  }
}
