.map-module {
  height: 500px;
  padding: 0 !important;
  position: relative;
  @media #{$bp-medium} {
    height: $max-width-medium * (500 / 1110);
  }
  @media #{$bp-large} {
    height: $max-width-large * (500 / 1110);
  }
  @media #{$bp-extra-large} {
    height: $max-width-xl * (500 / 1110);
  }

  .preview-image {
    display: block;
    height: auto;
    height: 100%;
    margin: auto;
    opacity: 0;
    overflow: hidden;
    position: relative;
    transition: all 100ms ease-out;
    @media #{$bp-medium} {
      width: $max-width-medium;
    }
    @media #{$bp-large} {
      width: $max-width-large;
    }
    @media #{$bp-extra-large} {
      width: $max-width-xl;
    }

    &.visible {
      opacity: 1;
    }

    img {
      height: 100%;
      left: 50%;
      position: relative;
      transform: translateX(-50%);
    }

    .info {
      align-items: center;
      color: $text-color-1;
      display: none;
      flex-direction: column;
      font-size: 12px;
      height: 100%;
      justify-content: center;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;

      > * {
        color: inherit;
        text-align: center;
      }

      @media #{$bp-medium} {
        font-size: 16px;
      }
      @media #{$bp-large} {
        font-size: 18px;
      }

      .header {
        font-size: 225%;
      }

      .details {
        font-size: 100%;
      }

      a {
        color: $accent-color;
      }

      .buttons {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: 1rem;

        .deny {
          @include button-outline;
        }
      }
    }

    &:hover:not(.denied) {
      img {
        filter: blur(1.5px);
      }

      .info {
        background-color: transparentize($color: $shade-type-1, $amount: 0.6);
        display: flex;
        padding: 0 25px;
      }
    }
  }

  .dynamic-container {
    height: 100%;
    position: absolute;
    width: 100%;
  }
}
