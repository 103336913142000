.employee-showcase {
  background-repeat: no-repeat;
  min-height: 250px;

  .employee-box {
    background-color: transparentize($accent-color, $amount: 0.7);
    min-width: 375px;
    padding: 15px 25px 30px;
    width: 25%;

    @media #{$bp-small} {
      min-width: 400px;
    }

    p {
      color: $text-color-1;
      font-size: 15px;
      line-height: 25px;
    }
  }

  .employee-button {
    background-color: $area-color-2;
    color: $text-color-1;
    height: 30px;
    padding-top: 5px;
    text-align: center;
  }

  .module-content {
    padding: 50px 0;
  }
}
