* {
  box-sizing: border-box;
  color: $text-color-3;
  font-family: $font-base;
}

*:focus,
*:active {
  outline: none;
}

html {
  --accent-color: #{$accent-color};
  --background-color-alternate: #{$background-color-alternate};
  scroll-behavior: smooth;
  // For Stylesections in new dropdown. to be extended.
}

body {
  //width: 100vw;
  --meta-nav-top: #{0px};
  --topnav-height: #{$topnav-height-mobile};
  background-color: $background-color;
  font-size: 18px;
  margin: 0;
  overflow-x: hidden;
  padding: 0;

  @media #{$bp-large} {
    --topnav-height: #{$topnav-height-desktop};
  }

  &.has-meta-nav {
    --meta-nav-height: #{0px}; // to keep the px for calc
    --meta-nav-transition: transform 300ms ease-out;
    @media #{$bp-large} {
      --meta-nav-height: 39px;
    }

    &:not(.no-part) {
      --meta-nav-height: 39px;
    }

    /*   &.meta-nav-active.no-part {
      @media #{$bp-large} {
        --meta-nav-top: var(--meta-nav-height);
      }
    }
     */
    &.meta-nav-active {
      --meta-nav-top: var(--meta-nav-height);
    }
  }

  &.disable-scroll {
    overflow-y: hidden;
  }

  &.no-part {
    .meta-nav {
      display: none;
      @media #{$bp-large} {
        display: flex;
      }
    }
  }

  /*   &.has-mobile-nav {
    --topnav-height: #{$topnav-height-mobile};
  } */

  &.sidebar-layout {
    display: grid;
    grid-template-columns: 0 1fr;
    @media #{$bp-extra-large} {
      grid-template-columns: clamp(300px, 20vw, 600px) 1fr;
    }

    &.collapsed {
      grid-template-columns: 0 1fr;

      > aside {
        @media #{$bp-large} {
          z-index: 101;
        }
      }
    }

    &:not(.collapsed) {
      > aside {
        > * {
          transform: translateY(var(--meta-nav-top, 0));
        }
        // top: var(--topnav-height);
        @media #{$bp-large} {
          width: auto;
          z-index: 111;
        }

        @media #{$bp-extra-large} {
          width: auto;
          z-index: inherit;
        }
      }

      .attribution-flag {
        opacity: 0;

        @media #{$bp-large} {
          opacity: 1;
        }
      }
    }

    > aside {
      display: block;
      /* So richtig verstehe ich es nicht. Ich meine -2 sollte statt 99 funktionieren, weil die letzte gridline -1 ist und der Footer von -1 bis -1 geht. Geht aber nicht, 99 aber schon. */
      grid-row: 1 / 99;
      max-width: 100vw;
      position: relative;
      top: var(--topnav-height);
      z-index: 105;
    }

    > article {
      max-width: 100vw;
      min-width: 0;
      @media #{$bp-large} {
        grid-column: 2 / 3;
      }

      /*       .module {
        @media #{$bp-large} {
          margin-left: 4rem;
          margin-right: 4rem;
          width: calc(100% - 8rem);
        }
        @media #{$bp-mega-large} {
          margin: auto;
          width: 100%;
        }
      } */
    }

    .footer-modules {
      @media #{$bp-large} {
        grid-column: 2 / 3;
      }
    }

    .footer-container {
      z-index: 101;
      @media #{$bp-large} {
        grid-column: 1 / -1;
        z-index: 110;
      }
    }
  }

  > header {
    position: fixed;
    top: 0;
    z-index: 120;
  }

  .join-to-next {
    overflow: hidden;
  }

  .join-to-next + .content-row {
    .module {
      padding-top: 0;
    }
  }

  > article {
    //height: calc(100vh - 60px);
    margin-top: calc(var(--topnav-height) + var(--meta-nav-height, 0px));
    // overflow-x: hidden;
    position: relative;

    &.search-active {
      margin-top: calc(var(--topnav-height) + var(--meta-nav-height, 0px));

      .mobile-nav {
        box-shadow: none;
      }
    }

    .spacer {
      background: $area-color-11;
      display: block;
      height: 150px;
      width: 100%;
    }

    > .content-row {
      //background: $background-color;
      background: transparent;
      overflow: visible;

      &.alternative-color {
        background: $background-color-alternate;
      }
    }
  }

  > footer,
  .content-row {
    overflow-x: hidden;
    //min-height: 250px;
    width: 100%;
  }

  &.modal-open {
    overflow: hidden;

    > article {
      filter: blur(2px);
    }
  }

  &.topnav-hidden {
    > article {
      margin-top: 0 !important;
    }

    .topnavigation {
      display: none;
    }
  }
}

.elements-below-nav {
  display: flex;
  justify-content: flex-end;
  margin: 0 1rem 0 0;

  @media #{$bp-large} {
    margin: 0;
    margin-right: calc((100% - #{$max-width-large}) / 2);
    position: fixed;
    right: 0;
  }
  @media #{$bp-extra-large} {
    margin-right: calc((100% - #{$max-width-xl}) / 2);
  }
}

h1 {
  color: $text-color-3;
  font-size: 32px;
  font-weight: 600;
  line-height: 42px;
  margin: 0;
  @media #{$bp-medium} {
    font-size: 48px;
    line-height: 58px;
  }
  @media #{$bp-large} {
    font-size: 64px;
    line-height: 74px;
  }
}

h2 {
  color: $headline-color;
  font-size: 28px;
  font-weight: 600;
  line-height: 38px;
  margin: 0;
  @media #{$bp-medium} {
    font-size: 36px;
    line-height: 46px;
  }
  @media #{$bp-large} {
    font-size: 48px;
    line-height: 58px;
  }
}

h3 {
  color: $text-color-3;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  margin: 0;
  margin: 0 0 12px;
  @media #{$bp-medium} {
    font-size: 24px;
    line-height: 32px;
    margin: 0;
  }
  @media #{$bp-large} {
    font-size: 36px;
    line-height: 42px;
  }
}

h4 {
  color: $text-color-3;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  margin: 0;
  @media #{$bp-large} {
    font-size: 24px;
    line-height: 32px;
  }
}

h5 {
  color: $text-color-3;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
  @media #{$bp-large} {
    font-size: 24px;
    line-height: 26px;
  }
}

p {
  color: $text-color-3;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.62;

  a {
    color: $accent-color;
  }

  &.medium {
    font-size: 14px;
    line-height: 1.3;
  }

  &.small {
    font-size: 12px;
    line-height: 1.4;
  }
}

a {
  cursor: pointer;
  text-decoration: none;

  &.button {
    background-color: $area-color-1;
    border: 1px solid transparent;
    border-radius: 3px;
    color: $text-color-3;
    display: inline-block;
    font-size: 14px;
    min-width: 90px;
    padding: 12px 16px 10px;
    width: auto;

    &:hover {
      background-color: $area-color-11;
    }
  }
}

.module-full {
  margin: 0 auto 4rem;
  width: 100%;

  .module-content {
    margin: 0 auto;
    padding: 0 24px;
    width: 100%;
    @media #{$bp-small} {
      padding: 0 39px;
    }
    @media #{$bp-medium} {
      max-width: 738px;
      padding: 0;
    }
    @media #{$bp-large} {
      max-width: 960px;
    }
    @media #{$bp-extra-large} {
      max-width: 1110px;
    }
  }
}

nav.content-row,
section.content-row,
section.footer-container {
  .default-row {
    margin: 0 auto;
    max-width: 1110px;
    padding: 50px;
  }

  .module {
    @extend %module;
  }
}

header.module-header {
  margin: 0 0 1rem;
}

.box-shadow {
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.33);
}

.hidden {
  display: none !important;

  &-mobile {
    display: none !important;
    @media #{$bp-medium} {
      display: unset !important;
    }
  }
}

.generic-button {
  @include button();

  &.light {
    background-color: $area-color-1;
  }
}

.more-button {
  @extend %background-variations !optional;
  @extend %background-variations-outline !optional;
  @include button-block();

  padding: 0;
  width: 180px;

  &:hover {
    @extend %background-variations-button-shadow !optional;
  }

  &:focus {
    border: 0;
    outline: none;
  }

  a {
    color: $text-color-1;
    font-size: 14px;
  }
}

.bold {
  font-weight: 700;

  &::before {
    font-weight: initial;
  }

  &::after {
    font-weight: initial;
  }
}

ul,
ol {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.62;
}

.alternative-color + div.go-to-top {
  background-color: $area-color-11;
}

div.go-to-top {
  align-items: flex-end;
  display: flex;

  button.go-top-button {
    background-color: $accent-color;
    border: 0;
    border-radius: 4px;
    font-size: 0.8rem;
    height: 48px;
    margin: 0 32px 32px auto;
    width: 48px;

    i::before {
      color: $text-color-1;
      transform: rotate(180deg) scale(4.2);
    }
  }
}

code {
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  font-size: 18px !important;

  * {
    font-family: inherit;
    font-size: inherit;
  }
}

.clearfix {
  clear: both;
}

.image-description {
  font-size: 12px;
  font-style: italic;
}

.image-full-width {
  height: auto !important;
  width: 100% !important;
}

.image-full-height {
  height: 100% !important;
  width: auto !important;
}

iframe {
  border: none;
  max-width: 100%;
}

a.jump-fix {
  content: '';
  position: relative;
  top: calc(-1 * var(--topnav-height));
}

@import 'profile-page';
@import 'pimcore';
@if not variable-exists(above-the-fold) {
  html {
    @extend %pimcore;
    @extend %profile-page;
  }
}
