@font-face {
  font-family: 'tta-iconfont';
  font-style: normal;
  font-weight: normal;
  src: url('/group/static/tta-iconfont.eot');
  src: url('/group/static/tta-iconfont.eot#iefix') format('embedded-opentype'),
    url('/group/static/tta-iconfont.woff2') format('woff2'),
    url('/group/static/tta-iconfont.woff') format('woff'),
    url('/group/static/tta-iconfont.ttf') format('truetype'),
    url('/group/static/tta-iconfont.svg#tta-iconfont') format('svg');
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?94072301#fontello') format('svg');
  }
}
*/

[class^='icon-']::before,
[class*=' icon-']::before {
  @extend %font-icon-style;
  font-family: 'tta-iconfont';

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  font-weight: normal;
}

.icon-twitter::before {
  content: '\e800';
} /* '' */
.icon-twitter-negativ::before {
  content: '\e801';
} /* '' */
.icon-linkedin-negativ::before {
  content: '\e802';
} /* '' */
.icon-facebook-negativ::before {
  content: '\e803';
} /* '' */
.icon-facebook::before {
  content: '\e804';
} /* '' */
.icon-instagram-1::before {
  content: '\e805';
} /* '' */
.icon-rss-negativ::before {
  content: '\e806';
} /* '' */
.icon-linkedin::before {
  content: '\e807';
} /* '' */
.icon-phone::before {
  content: '\e808';
} /* '' */
.icon-xing-negativ::before {
  content: '\e809';
} /* '' */
.icon-xing::before {
  content: '\e80a';
} /* '' */
.icon-youtube::before {
  content: '\e80b';
} /* '' */
.icon-filter::before {
  content: '\e80c';
} /* '' */
.icon-add::before {
  content: '\e80d';
} /* '' */
.icon-alert::before {
  content: '\e80e';
} /* '' */
.icon-arrow-down::before {
  content: '\e80f';
} /* '' */
.icon-arrow-left::before {
  content: '\e810';
} /* '' */
.icon-arrow-right::before {
  content: '\e811';
} /* '' */
.icon-arrow-up::before {
  content: '\e812';
} /* '' */
.icon-avatar::before {
  content: '\e813';
} /* '' */
.icon-cancel-close::before {
  content: '\e814';
} /* '' */
.icon-check::before {
  content: '\e815';
} /* '' */
.icon-clock::before {
  content: '\e816';
} /* '' */
.icon-comment-off::before {
  content: '\e817';
} /* '' */
.icon-heart-off::before {
  content: '\e818';
} /* '' */
.icon-language::before {
  content: '\e819';
} /* '' */
.icon-mail::before {
  content: '\e81a';
} /* '' */
.icon-menu::before {
  content: '\e81b';
} /* '' */
.icon-more::before {
  content: '\e81c';
} /* '' */
.icon-quote-66::before {
  content: '\e81d';
} /* '' */
.icon-quote-99::before {
  content: '\e81e';
} /* '' */
.icon-search::before {
  content: '\e81f';
} /* '' */
.icon-simple-arrow-down::before {
  content: '\e820';
} /* '' */
.icon-simple-arrow-left::before {
  content: '\e821';
} /* '' */
.icon-simple-arrow-right::before {
  content: '\e822';
} /* '' */
.icon-simple-arrow-up::before {
  content: '\e823';
} /* '' */
.icon-warning::before {
  content: '\e824';
} /* '' */
.icon-rss::before {
  content: '\e825';
} /* '' */
.icon-cloud::before {
  content: '\e826';
} /* '' */
