@keyframes scale {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1);
  }

  to {
    transform: scale(1.4);
  }
}

.loading-placeholder {
  align-items: center;
  display: flex;
  height: 510px;
  justify-content: center;
  width: 100%;

  div {
    animation-direction: alternate;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-name: scale;
    animation-timing-function: ease-in-out;
    background-color: transparentize($default-text-color, 0.5);
    height: 50px;
    margin: 1rem;
    width: 50px;

    &:nth-child(1) {
      animation-delay: 0;
    }

    &:nth-child(2) {
      animation-delay: 200ms;
    }

    &:nth-child(3) {
      animation-delay: 400ms;
    }
  }
}
