.lightbox {
  background-color: rgba(0, 0, 0, 0.5);
  inset: 0;
  overflow: hidden;
  overflow-y: auto;
  padding-top: calc(var(--topnav-height) + var(--meta-nav-top));
  position: fixed;
  z-index: 1000;

  .wrap {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    place-items: center;

    @media #{$bp-medium} {
      grid-template-columns: 50px auto 50px;
    }
  }

  i,
  h3,
  h4,
  .description,
  .center-image {
    grid-column: 2;
  }

  * {
    color: white;
  }

  i {
    cursor: pointer;
    font-size: 30px;
    margin-bottom: 2rem;
    place-self: start end;

    @media #{$bp-medium} {
      grid-column: 3;
      margin-bottom: 0;
    }
  }

  :where(h3, h4, .description) {
    @media #{$bp-medium} {
      max-width: 90vw;
      min-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  img {
    border-radius: 4px;
    height: auto;
    max-height: 60vh;
    max-width: 100%;

    @media #{$bp-medium} {
      max-height: 70vh;
    }

    &.svg {
      height: 80vh;
      width: 100%;
    }
  }
}
