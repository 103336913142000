.image-slider-maincontent-simple {
  display: flex;
  flex-direction: column;
  height: 310px;
  justify-content: flex-end;
  overflow: hidden;
  position: relative;

  @media #{$bp-medium} {
    height: 380px;
  }
  @media #{$bp-large} {
    height: 440px;
  }

  img {
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  .wrap {
    align-items: center;
    background-color: $is-simple;
    display: flex;
    min-height: 100px;
    padding: 1rem 24px;

    @media #{$bp-small} {
      padding: 2rem 39px;
    }

    @media #{$bp-large} {
      min-height: 150px;
    }

    .title {
      @extend %content-column;
      color: $text-color-1;
      font-family: $font-base;
      font-size: 28px;
      font-weight: 500;
      line-height: 1.32;
      margin: auto;
      text-align: center;

      @media #{$bp-small} {
        font-size: 30px;
      }
      @media #{$bp-medium} {
        font-size: 36px;
      }
      @media #{$bp-large} {
        font-size: 40px;
        line-height: 1.62;
      }
    }
  }
}
