.gallery-slider {
  margin-top: 10px;

  .swiper {
    display: block;
    height: 100%;
    width: 100%;
  }

  swiper-slide,
  .swiper-slide {
    background: $area-color-1;
    font-size: 18px;
    text-align: center;
    width: auto;

    img {
      &.small {
        height: 150px;
      }

      &.medium {
        height: 250px;
      }

      &.large {
        height: 400px;
      }
    }
  }

  swiper-container::part(wrapper) {
    align-items: center;
  }

  swiper-container::part(button-prev),
  swiper-container::part(button-next) {
    color: $area-color-5;
  }

  swiper-container::part(bullet-active) {
    background-color: $area-color-5;
  }
}
