.image-slider {
  max-height: 590px;
  position: relative;
  //margin-bottom: 0;

  &.arrow-navigation {
    display: none;
  }

  .container {
    position: relative;
  }

  .main-text {
    animation: fadein 2500ms forwards;
    display: block;
    opacity: 0; // Will be faded in
    width: 100%;

    span,
    h1 {
      @include line-clamp(4, 45px);
      color: $text-color-1;
      font-size: 40px;
      font-weight: 700;
      line-height: 45px;

      @media #{$bp-medium} {
        @include line-clamp(2, 60px);
        font-size: 50px;
        font-weight: 600;
        line-height: 60px;
        margin: 20px 0 0;
        max-width: 100%;
      }
      @media #{$bp-large} {
        margin: 13px 0 0;
        // width: 70%;
      }
    }
  }

  .teasertext {
    animation: fadein 2500ms forwards;
    display: none;
    opacity: 0; // Will be faded in

    span {
      @include line-clamp(2, 24px);
      color: $text-color-1;
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;

      @media #{$bp-medium} {
        line-height: 22px;
        max-height: (2 * 22px);
      }
    }

    @media #{$bp-medium} {
      display: flex;
      padding-top: 1rem;
      width: 100%;
    }

    @media #{$bp-medium} {
      line-height: 22px;
      overflow: initial;
      text-overflow: initial;
      white-space: normal;
    }
  }

  .more-button {
    display: none;
  }

  .slide {
    background-position: center;
    background-size: cover;
    display: none;
    flex-flow: column nowrap;
    height: 590px;
    justify-content: flex-end;
    overflow: hidden;
    position: relative;

    &.active {
      display: flex;
    }

    &::after {
      background-color: transparentize($area-color-2, 0.35);
      bottom: 0;
      bottom: 0;
      content: '';
      height: 50%;
      position: absolute;
      width: 100%;
      z-index: 1;
    }

    .slider-content {
      @extend %vertical-center;
      align-items: center;
      height: 50%;
      margin: 0 auto;
      padding: 0 24px 22px;
      width: 100%;
      z-index: 2;

      @media #{$bp-medium} {
        line-height: 22px;
        max-width: $max-width-medium;
        padding-left: 0;
        padding-right: 0;
      }

      @media #{$bp-large} {
        max-width: $max-width-large;
        padding: 1rem 0 calc(64px + 1rem); // Thumbnail wrap height + min spacing
      }

      @media #{$bp-extra-large} {
        max-width: $max-width-xl;
      }
    }

    &:first-child:last-child {
      // Only one slide case
      .slider-content {
        padding-bottom: 0;
        padding-top: 0;
      }
    }
  }

  .only-title {
    max-height: 45vw;

    padding: 0;

    &::after,
    .slider-content {
      height: 100%;

      @media #{$bp-medium} {
        height: 50%;
      }
    }

    .main-text {
      span,
      h1 {
        font-size: 30px;
        @media #{$bp-large} {
          font-size: 40px;
          width: 100%;
        }
      }
    }

    @media #{$bp-small} {
      max-height: 30vw;
    }
    @media #{$bp-large} {
      max-height: 25vw;
    }
    @media #{$bp-extra-large} {
      max-height: 15vw;
    }
  }

  .thumbnail-wrap {
    display: none;
    flex-flow: row nowrap;
    height: 200px;
    justify-content: center;
    overflow: hidden;
    padding: 0 24px;
    width: 100%;
    z-index: 20;

    &.desktop {
      display: none;

      @media #{$bp-large} {
        display: flex;
      }
    }

    .arrow {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      top: 0;
      width: 40px;
      z-index: 20;
    }

    i::before {
      color: $text-color-1;
      font-size: 15px;
      font-size: 30px;
      left: -1px;
      line-height: 70px;
      position: relative;

      @media #{$bp-large} {
        font-size: 30px;
      }
    }

    i {
      cursor: pointer;
      height: 60px;
      text-align: center;
      width: 70px;
    }

    .left {
      left: 0;
    }

    .right {
      right: 0;
    }

    i:hover::before {
      text-shadow: 0 0 2px $text-color-1;
    }

    .column {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: flex-end;

      &.active {
        .thumb-overlay {
          height: 100%;
        }
      }

      .text-image {
        margin: 9px 7px 8px;
        max-height: 23px;
        max-width: 94%;
        min-height: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .thumb-overlay {
        height: 75%;
      }

      &:nth-child(3n-2) {
        .thumb-overlay {
          background-color: $color-palette-2;
        }
      }

      &:nth-child(3n-1) {
        .thumb-overlay {
          background-color: $color-palette-3;
        }
      }

      &:nth-child(3n) {
        .thumb-overlay {
          background-color: $color-palette-4;
        }
      }
    }

    .column-container {
      display: none;
      width: 100%;

      @media #{$bp-medium} {
        max-width: 545px;
      }

      @media #{$bp-large} {
        display: flex;
        max-width: $max-width-large;
      }

      @media #{$bp-extra-large} {
        max-width: $max-width-xl;
      }
    }

    @media #{$bp-extra-large} {
      bottom: 0;
      display: flex;
      height: 64px;
      margin: 0 auto;
      // max-width: 1297px;
      padding: 0;
      position: absolute;
    }
  }

  .column {
    background-position: center;
    background-size: cover;
    cursor: pointer;
    display: none;
    height: 100%;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    transition: opacity 0.2s;
    width: calc(50% - 24px);
    z-index: 0;

    &.focus {
      @media #{$bp-medium} {
        display: none;
      }
      @media #{$bp-large} {
        display: block;
      }
    }

    img {
      display: block;
      height: 100%;
      width: 100%;
      @media #{$bp-small} {
        height: auto;
        width: 100%;
      }
    }

    .text-image {
      color: $area-color-1;
      font-weight: 400;
      left: 50%;
      position: absolute;
      top: 48%;
      transform: translate(-50%, -50%);
      width: 100%;
    }

    > div {
      background-position: center;
      background-size: cover;
      height: 100%;
      width: 100%;
    }

    @media #{$bp-medium} {
      margin-top: 0;
      transition: none;
      width: 25%;
    }

    @media #{$bp-large} {
      display: block;
      flex-basis: 0;
      flex-basis: 25%;
      flex-grow: 1;
      flex-grow: 0;
      flex-shrink: 1;
      left: auto;
      opacity: 1;
      position: relative;
      right: auto;
      width: auto;
    }
  }

  .arrow-pagination {
    display: none;
  }

  .slider-container {
    display: flex;
    flex-direction: row;
    height: 3px;
    justify-content: center;
    list-style-type: none;
    margin-top: 0;
    padding: 0 30px;
    position: relative;
    top: -24px;
    width: 100%;
    z-index: 20;

    .arrow {
      display: flex;
    }

    i::before {
      color: $text-color-1;
      font-size: 15px;
    }

    i {
      cursor: pointer;
      text-align: center;
    }

    i:hover::before {
      text-shadow: 0 0 2px $text-color-1;
    }

    @media #{$bp-extra-large} {
      display: none;
    }
  }

  .images-nav {
    display: flex;
    flex-direction: row;
    height: 3px;
    justify-content: center;
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative;
    top: 5px;

    li {
      background-color: $area-color-15;
      border-radius: 20px;
      cursor: pointer;
      flex-grow: 1;
      height: 10px;
      margin: 0 7px;
      max-width: 10px;
      outline: 0;
      width: 10px;

      &.active {
        background-color: $area-color-1;
      }
    }
  }
}

.image-slider.keyvisual {
  .slide {
    position: relative;
  }

  img {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
