//Define accent color in vcard form
$company-palette: (
  'company-novacapta ': $company-color-nov,
  'company-ars ': $company-color-ars,
  'company-cloudpilots ': $company-color-cpl,
  'company-edcom ': $company-color-edc,
  'company-synaigy ': $company-color-syn,
  'company-pks': $company-color-pks,
  'company-cwx': $company-color-cwx
);

%checkmark-colors {
  @each $name, $value in $company-palette {
    .#{$name} {
      .checkmark-wrap {
        .active {
          background-color: $value !important;
          border-color: $value !important;
        }
      }
    }
  }
}

.vcard-form-container {
  @extend %checkmark-colors;
}

.order-vcard {
  .category-header,
  .vcard-header {
    margin: 0 0 1rem;
  }

  .config-container {
    display: flex;
    margin: 1rem 0 0;
  }

  .categoryelements {
    width: 50%;
  }

  .checkbox-vcard {
    flex-basis: 50%;
    height: 49px;
    overflow: hidden;

    label {
      align-items: center;
      cursor: pointer;
      display: inline-block;
      display: flex;
      font-size: 10px;
      font-weight: 400;
      hyphens: auto;
      margin-bottom: 21px;
      position: relative;
      text-transform: none;
      user-select: none;
      word-break: break-word;

      @media #{$bp-small} {
        font-size: 14px;
        word-break: normal;
      }

      @media #{$bp-medium} {
        font-size: 16px;
      }
    }

    .checkmark-wrap {
      align-self: flex-start;
      display: inline-block;
      flex-shrink: 0;
      height: 16px;
      position: relative;
      width: 16px;
      @media #{$bp-small} {
        height: 25px;
        width: 25px;
      }

      div {
        border: 2px solid;
        border-radius: 2px;
        display: grid;
        height: 100%;
        place-items: center;
        position: absolute;
        width: 100%;
        @media #{$bp-small} {
          border-radius: 4px;
          border-width: 3px;
        }
      }

      .active {
        background-color: $accent-color;
        border-color: $accent-color;
        opacity: 0;
        transition: opacity 50ms ease;
      }

      ::before {
        color: $area-color-1;
        font-size: 12px;
        margin: 0;

        @media #{$bp-small} {
          font-size: 18px;
        }

        @media #{$bp-medium} {
        }
      }

      .inactive {
        border-color: $text-color-3;
      }
    }

    .checkmark-wrap.checked {
      .active {
        opacity: 1;
      }
    }

    .checkbox-name {
      margin: 0 10px;
    }

    /*     .checkmark {
          border: 2px solid $area-color-1;
          border-radius: 2px;
          cursor: pointer;
          display: inline-block;
          height: 16px;
          left: 0;
          position: relative;
          top: 2px;
          width: 16px;
          @media #{$bp-small} {
            border-radius: 4px;
            border-width: 3px;
            height: 25px;
            width: 25px;
          }
        } */

    .count {
      color: lighten($text-color-3, 15%);
    }

    @media #{$bp-medium} {
      flex-basis: 33%;
    }

    @media #{$bp-large} {
      flex-basis: 25%;
    }

    /*     @media #{$bp-extra-large} {
          flex-basis: 20%;
        } */
  }

  .checkbox-input {
    cursor: pointer;
    display: none;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;
  }
}
