//Element Colors
$accent-color: rgba(0, 105, 180, 1);
$accent-color2: rgba(227, 6, 19, 1);
$accent-color-70: rgba(9, 105, 177, 0.7);
$accent-color-30: rgba(9, 105, 177, 0.3);

//Text Colors
$text-color-2: rgba(0, 105, 180, 1);
$text-color-3: rgba(0, 0, 0, 1);
$text-color-7: rgba(0, 0, 0, 1);

// Image Slider Overlays
$is-overlay-blue: transparentize(rgb(0, 105, 180), 0.5);
$is-overlay-red: transparentize(rgb(227, 6, 19), 0.5);
$is-overlay-gray: transparentize(rgb(97, 97, 96), 0.5);
$is-simple: rgba(69, 69, 69, 0.6);

// GIS Color-palette
$color-palette-1: rgba(222, 222, 222, 1);
$color-palette-2: rgba(227, 6, 19, 1);
$color-palette-3: rgba(0, 105, 180, 1);
$color-palette-4: rgba(97, 97, 96, 1);
$color-palette-5: rgba(0, 0, 0, 1);

$font-base: 'Open Sans', sans-serif;

//Named-Colors ------------------------------------
// $headline-color: $text-color-3;
// $background-color: $area-color-1;
// $background-color-transparent: transparent;
// $background-color-alternate: $area-color-11;
// $background-color-alternate-transparent: rgba(0, 0, 0, 0.15);
$default-text-color: $text-color-3;

$topnav-height-desktop: 110px;
$topnav-height-mobile: 80px;
