$company: 'edc';
$font-base: 'Segoe UI', sans-serif;
$vcard-font: 'Roboto', sans-serif;

//Element Colors
$accent-color: rgba(0, 130, 148, 1);
$accent-color-70: rgba(0, 130, 148, 0.7);
$accent-color-30: rgba(0, 130, 148, 0.3);

//Text Colors
$text-color-2: rgba(0, 130, 148, 1);
$text-color-10: rgba(228, 229, 230, 1);

//Shade & Area Colors ------------------------------------
$area-color-14: rgba(239, 239, 240, 1);

// Baukasten Color-palette
$color-palette-1: rgba(134, 181, 193, 1);
$color-palette-2: $accent-color;
$color-palette-3: rgba(150, 148, 149, 1);
$color-palette-4: rgba(118, 158, 169, 1);
$color-palette-5: rgba(165, 196, 206, 1);
// $color-palette-6: rgba(100, 98, 99, 1);

//Printcard ---------------------------------
$printcard-turquoise: rgb(0, 145, 161);
$printcard-gray: rgb(100, 99, 99);
