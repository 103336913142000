.edit-profile-form,
.blog-proposal-form {
  .is-invalid label {
    color: $invalid;
  }

  .invalid-feedback {
    color: $invalid;
    display: none;
    font-weight: bold;
  }

  .is-invalid .invalid-feedback {
    display: block;
  }

  .form-buttons {
    margin-top: 20px;

    .success-message {
      color: $valid;
    }

    .error-message {
      color: $invalid;
    }
  }

  .tabview-tabs {
    border-bottom: 2px solid $border-color;
    display: block;
    margin-bottom: 20px;
  }

  .tabview-tab {
    background-color: $light-white;
    border-bottom: 2px solid $border-color;
    border-left: 2px solid $border-color;
    border-top: 2px solid $border-color;
    cursor: pointer;
    display: inline-block;
    margin-bottom: -2px;
    padding: 5px;

    .active {
      background-color: $area-color-1;
      border-bottom: 2px solid $area-color-1;
    }

    &:first-child {
      margin-left: 10px;
    }

    &:last-child {
      border-right: 2px solid $border-color;
    }
  }

  .tabview-panel {
    display: none;

    &.active {
      display: block;
    }
  }

  .uploader .button {
    background: $light-white;
    border-radius: 2px;
    margin-top: 10px;
    padding: 5px 45px;

    &:hover {
      cursor: pointer;
    }
  }

  .image-uploader-preview {
    background-position: top center;
    background-size: cover;
    border: 2px $border-color solid;
    height: 150px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    text-align: center;
    width: 150px;

    &.empty {
      background-image: url('/static/img/user-image.jpg');
      border: 2px $light-white dashed;
    }

    .image-uploader-remove {
      display: none;
    }
  }

  .image-uploader-remove {
    bottom: 5px;
    position: absolute;
    right: 5px;
  }

  .tag-selector {
    clear: both;
  }

  .tag-selector-tree {
    font-size: 12px;

    ul {
      list-style-type: none;
    }

    .closed {
      ul {
        display: none;
      }
    }

    li {
      margin: 0;
      white-space: nowrap;

      .label {
        white-space: nowrap;
      }

      &.matched ul {
        display: block !important;
      }

      input[type='checkbox'] {
        display: inline;
        height: auto;
        vertical-align: middle;
        width: auto;
      }
    }
  }

  .tag-container {
    border-bottom: 1px dotted $silver;
    border-left: 1px dotted $silver;
    border-right: 1px dotted $silver;
    height: 400px;
  }

  .tag-selector-tree-container {
    float: left;
    height: 400px;
    overflow: auto;
    width: 400px;
  }

  .tag-selector-list-container {
    float: left;
    height: 400px;
    overflow: auto;
    width: 700px;
  }

  .tag-selector-list-entry {
    padding: 5px;
    position: relative;

    a {
      position: absolute;
      right: 5px;
    }
  }

  .noselect {
    // -webkit-touch-callout: none; /* iOS Safari */
    // -webkit-user-select: none; /* Safari */
    // -moz-user-select: none; /* Old versions of Firefox */
    // -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently */
  }
}
