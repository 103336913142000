.profile-menu {
  .pagelike {
    font-size: 25px;
    height: 102%;
    margin: 0 74px 0 0;
    padding: 27px 10px 0;
    position: absolute;
    right: 30px;
    width: auto;

    &:hover {
      background: $border-color-2;
      border-left: 2px solid $border-color-2;
      cursor: pointer;
    }

    &.active {
      background-color: $accent-color !important;

      a {
        color: $text-color-1 !important;
      }

      .icon-heart-off {
        color: $text-color-1 !important;
      }

      .fa-spinner {
        color: $text-color-1 !important;
      }
    }
  }

  .dropdown {
    display: none;
    margin-bottom: 0 20px 0 0;
    padding-right: 35px 60px 0 0;
    position: absolute;
    right: -3px;
    width: 87px;

    &:hover {
      border-bottom: 2px solid $area-color-1;
      border-left: 2px solid $border-color-1;
      cursor: pointer;
      height: 91px;
    }

    @media #{$bp-extra-large} {
      display: block;
    }

    span {
      @media #{$bp-extra-large} {
        display: none;
      }
    }

    img {
      border-radius: 50%;
      position: absolute;
      right: 15px;
      top: 20px;
      width: 50px;
      z-index: 99;
    }

    .dropdown-content {
      background-color: $area-color-1;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      box-shadow: 5px 5px 5px rgba(95, 95, 95, 0.3),
        -5px 5px 5px rgba(95, 95, 95, 0.3);
      display: none;
      margin-top: 0;
      min-width: 230px;
      padding: 0 30px;
      position: absolute;
      z-index: 1;
      @media #{$bp-extra-large} {
        margin-top: 56px;
        right: 0;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .dropdown-content ul {
      margin: 0;
      padding-left: 0 5px;
    }

    .dropdown-content li {
      border-bottom: 1px solid $text-color-5;
      line-height: normal;
      padding: 15px 0;

      &:first-child {
        border-top: 1px solid $text-color-5;
        margin: 30px 0 0;
      }

      &:last-child {
        border-bottom: 1px solid $text-color-5;
        margin: 0 0 30px;
      }
    }
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .fa-spinner::before {
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-name: rotate;
    transform-origin: center center;
  }
}

.profile-mobile {
  background: $accent-color;
  color: $text-color-1;
  padding: 17px;

  .person-details {
    display: flex;
  }

  img {
    border-radius: 50%;
    width: 50px;
  }

  .profile-name {
    border: 0;
    border-bottom: 1px solid $text-color-1;
    color: $text-color-1;
    font-weight: bold;
    height: 35px;
    margin: 5px 0 0 15px;
    padding: 5px 0;
  }

  ul {
    color: $text-color-1;

    li {
      color: $text-color-1;
    }

    i {
      color: $text-color-1;
    }

    a {
      color: $text-color-1;
    }
  }
}
