.two-columns {
  --gap: 0;

  @media #{$bp-medium} {
    --gap: 2rem;
  }

  > header {
    margin-bottom: 2rem;
    padding: 0 39px;
    @media #{$bp-medium} {
      padding: 0;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    @media #{$bp-medium} {
      flex-direction: row;
    }

    .column {
      // flex-basis: 100%;

      @media #{$bp-medium} {
        flex-basis: 50%;
        max-width: calc(50% - (var(--gap) / 2));
      }
    }

    .first {
      order: 0;
    }

    .second {
      order: 1;
    }

    @media #{$bp-medium} {
      .first,
      .second {
        order: unset;
      }
    }
  }
}
