@mixin global-button-base($button-color) {
  --local-background-color: var(--btn-background-color, #{$button-color});
  --local-background-hover-color: var(
    --btn-background-hover-color,
    var(--local-background-color)
  );

  --local-text-color: var(--btn-text-color, #{$text-color-1});
  --local-text-hover-color: var(
    --btn-text-hover-color,
    var(--local-text-color)
  );

  --local-border-color: var(--btn-border-color, var(--local-background-color));
  --local-border-hover-color: var(
    --btn-border-hover-color,
    var(--local-background-hover-color)
  );

  /* ALLE Erben hiervon. Bedenke! Wenn nötig in den Company Styles Duplizieren */
  align-items: center;
  background-color: var(--local-background-color);
  border: 2px solid var(--local-border-color);
  border-radius: 5px;
  color: var(--local-text-color);
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  max-width: 100%;
  min-height: 42px;
  overflow: hidden;
  padding: 0.3em 2.9em;
  text-align: center;

  * {
    color: var(--btn-text-color, var(--local-text-color));
    font-weight: 500;
  }

  &:hover {
    background-color: var(--local-background-hover-color);
    border-color: var(--local-border-hover-color);
    box-shadow: 0 0 0 1px var(--local-border-hover-color);
    color: var(--btn-text-hover-color, var(--local-text-hover-color));
  }

  .button-text {
    color: var(--btn-text-color, var(--local-text-color));
  }

  .button-text:hover {
    color: var(--btn-text-hover-color, var(--local-text-hover-color));
  }

  @content;
}

@mixin button-base($button-color) {
  @include global-button-base($button-color) {
    @content;
  }
}
@mixin button($button-color: $accent-color) {
  @extend %vertical-center-inline;
  @include button-base($button-color);
}
@mixin button-block($button-color: $accent-color) {
  @extend %vertical-center;
  @include button-base($button-color);
}

@mixin button-outline($button-color: $accent-color) {
  @include button($button-color);
  background-color: $area-color-1;
  color: $button-color;
}
@mixin button-outline-block($button-color: $accent-color) {
  @include button-block($button-color);
  background-color: $area-color-1;
  color: $button-color;
}
