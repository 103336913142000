@mixin hover-grid {
  .base {
    box-shadow: 0 0 17px transparentize($shade-type-7, 0.7);
  }
}
@mixin hover-slider {
  .base {
    // border-bottom: 4px solid $accent-color;
  }
}
