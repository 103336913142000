.topnavigation .desktop-nav {
  .center-wrap {
    > div.navigation-image,
    > div.search-block,
    li.first-label {
      cursor: pointer;
      height: 90px;
      padding-bottom: 22px;
      padding-top: 20px;
    }
  }

  .navigation-image img {
    height: 48px;
  }
}
