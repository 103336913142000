.text-and-video {
  @include style-of-link;
  flex-wrap: wrap;
  padding: 40px;

  ol {
    li {
      padding: 0 0 0 10px;
    }
  }

  ul {
    display: table;
    list-style: none;
    padding-inline-start: 0;

    li {
      display: flex;
      line-height: 1.62;
      margin: 0 0 15px;

      &::before {
        color: $accent-color;
        content: '\e822';
        display: inline-block;
        font-family: 'tta-iconfont';
        margin-left: calc(-1.3em + 15px);
        margin-right: 15px;
        padding: 0 10px 0 0;
        //width: 1.3em;
        width: auto;
      }
    }

    &.custom-icon {
      li {
        margin: 0 0 25px;
      }
    }

    li {
      display: table-row;
      list-style: none;
    }

    li::before {
      display: table-cell;
      text-align: right;
    }
  }

  // X-TODO raus wegen vcard. gucken ob wichtig
  // _:-ms-lang(x),
  // ul {
  //   padding: 0;
  // }

  .content {
    box-sizing: border-box;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1140px;

    @media #{$bp-large} {
      flex-flow: nowrap;
      justify-content: space-between;
    }

    &.left {
      flex-direction: row-reverse;
    }
  }

  .more-button {
    order: 3;
  }

  section {
    display: block;
    flex-basis: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    order: 1;

    @media #{$bp-large} {
      flex-shrink: 1;
    }

    @media #{$bp-large} {
      flex-basis: calc((100% - 2rem) / 2);
    }

    header {
      h2 {
        color: $text-color-3;
        font-size: 20px;
        font-weight: 500;
        line-height: 35px;
        margin: 0;

        @media #{$bp-large} {
          font-size: 24px;
        }
      }
    }

    p,
    header,
    ul,
    ol {
      margin: 0 0 1rem;
    }
  }

  .video {
    border-radius: 4px;
    flex-basis: 100%;
    flex-shrink: 0;
    height: fit-content;
    order: 2;

    @media #{$bp-large} {
      flex-basis: calc((100% - 2rem) / 2);
    }

    &.fullwidth {
      flex-basis: 100%;
      height: auto;
    }

    .player,
    iframe {
      aspect-ratio: 16 / 9;
      display: block;
      height: 100%;
      width: 100%;
    }
  }

  img {
    width: 100%;
  }

  .cookie {
    flex-basis: 100%;
    flex-shrink: 0;
    order: 2;

    p {
      margin: 0;
    }

    @media #{$bp-medium} {
      flex-basis: 100%;
    }

    @media #{$bp-large} {
      flex-basis: 47%;
    }

    @media #{$bp-extra-large} {
      flex-basis: 45%;
    }
  }

  .edit-cookie {
    margin: 1em 0 0;
  }

  @media #{$bp-large} {
    flex-wrap: nowrap;
    padding: 0;
  }
}
