.multiple-vcards {
  display: flex;

  .printcard-container {
    margin-right: 5px;
  }
}

.printcard-container {
  @import '../../styles/base/variables';

  * {
    font-family: $vcard-font;
  }

  &.group {
    .content-container .company-logo {
      width: 450px;
    }

    .backside-container .company-logo {
      top: 100px;
    }
  }

  .overlay {
    height: 709px;
    opacity: 0.7;
    position: absolute;
    width: 1075px;
    z-index: 1;
  }

  .overlay-front {
    display: none;
  }

  .overlay-back {
    display: none;
  }

  .frontside-container {
    display: flex;
    flex-direction: row;
    height: 709px;
    width: 1075px;
  }

  .highlight {
    .top {
      height: 355px;
      width: 50px;
    }

    .bottom {
      height: 355px;
      margin-top: -1px;
      width: 50px;
    }

    &.left {
      .top {
        background-color: $printcard-blue;
      }

      .bottom {
        background-color: $printcard-orange;
      }
    }

    &.right {
      .top {
        background-color: $printcard-turquoise;
      }

      .bottom {
        background-color: $printcard-dark-blue;
      }
    }
  }

  .content-container {
    background-color: $area-color-1;
    position: relative;
    width: 975px;

    .company-details {
      margin: 293px 0 0 56px;
      position: absolute;
      width: 850px;
    }

    .company-logo {
      height: 59px;
      position: absolute;
      right: 53px;
      text-align: right;
      top: 75px;
      width: 275px;

      > img {
        width: 100%;
      }
    }
  }

  .person {
    color: $printcard-blue;
    font-size: 34px;
    font-weight: bold;
    line-height: 40px;
  }

  .title,
  .degree {
    color: $text-color-7;
    font-size: 30px;
    font-weight: 400;
    line-height: 40px;
  }

  .company {
    color: $printcard-blue;
    font-size: 30px;
    font-weight: bold;
    line-height: 38px;
  }

  .place,
  .email,
  .telephone {
    color: $text-color-7;
    font-size: 30px;
    font-weight: 400;
    line-height: 38px;
  }

  .contact-details {
    display: block;
    font-size: 20px;
    height: 120px;
    margin: 24px 0 28px 57px;

    img {
      height: 98px;
      width: 484px;
    }
  }

  .backside-container {
    background-color: $printcard-dark-blue;
    display: flex;
    flex-direction: column-reverse;
    height: 709px;
    justify-content: space-between;
    position: relative;
    width: 1075px;

    .company-logo {
      height: 99px;
      left: 105px;
      max-width: max-content;
      position: absolute;
      top: 60px;

      > img {
        width: 500px;
      }
    }

    > .company-details {
      align-items: flex-end;
      display: flex;
      flex-direction: row;
      height: 250px;
      justify-content: flex-start;
      margin: 0 133.5px;
      max-width: 594px;
      position: absolute;
      top: 372px;

      .column {
        align-content: flex-end;
        display: grid;
        grid-auto-rows: 65px;
        width: 500px;
      }
    }

    .qrcode {
      bottom: 103px;
      height: 218px;
      position: absolute;
      right: 100px;
      width: 219px;

      img {
        height: 218px;
        width: 219px;
      }
    }

    .information-container {
      display: flex;
      font-family: $vcard-font;
      font-size: 30px;
      line-height: 45px;
    }

    .home {
      height: 35px;
      position: relative;
      top: 3px;
      width: 35px;
    }

    .facebook {
      height: 65px;
      width: 70px;
    }

    img.facebook {
      left: -17px;
      position: relative;
    }

    .linkedin,
    .xing,
    .twitter {
      height: 65px;
      width: 65px;
    }

    .icon {
      flex-basis: 26px;
      margin-right: 28.5px;
      min-width: 26px;
      text-align: center;

      &.linkedin,
      &.xing,
      &.twitter {
        left: -14px;
        position: relative;
      }

      img {
        vertical-align: middle;
      }
    }

    .text {
      color: $text-color-1;
      margin-top: 10px;
      padding: 0;
    }
  }
}
