.ul-top {
  .list-title {
    color: $accent-color;
    height: auto;
    margin: 0;
  }

  .list-title.title-hidden {
    display: none;
  }

  .list-column {
    margin: 0 30px 0 0;
  }

  .element-text {
    line-height: 22.5px;
  }

  .element-standard {
    display: flex;
    margin: 0 0 2rem;
    @media #{$bp-medium} {
      align-items: center;
    }

    p {
      margin: 0;
    }

    &:last-child {
      margin: 0;
    }

    .element-icon {
      margin-top: 1px;
    }
  }

  .element-custom {
    display: flex;
    font-style: normal;
    margin: 0 0 2rem;
    @media #{$bp-medium} {
      align-items: center;
    }

    p {
      margin: 0;
    }

    &:last-child {
      margin: 0;
    }
  }

  .element-icon {
    margin: 3px 25px auto 0;
    min-width: 30px;

    > i {
      color: var(--icongrid-icon-color, $accent-color);
      line-height: 1em;

      &::before {
        font-size: 22px;

        margin: 0;
      }

      &.iconcolor-ffffff {
        color: var(--icongrid-icon-color, $iconcolor-ffffff);
      }

      &.iconcolor-005fa6 {
        color: var(--icongrid-icon-color, $iconcolor-005fa6);
      }

      &.iconcolor-5f5f5f {
        color: var(--icongrid-icon-color, $iconcolor-5f5f5f);
      }

      &.iconcolor-ff6600 {
        color: var(--icongrid-icon-color, $iconcolor-ff6600);
      }

      &.iconcolor-2b2b2b {
        color: var(--icongrid-icon-color, $iconcolor-2b2b2b);
      }

      &.iconcolor-1f909c {
        color: var(--icongrid-icon-color, $iconcolor-1f909c);
      }
    }
  }

  .custom {
    margin: 2px 25px auto 0;
    min-width: 30px;

    > i {
      color: var(--icongrid-icon-color, $text-color-3);
      display: block;

      &::before {
        text-align: start;
      }
    }

    [class^='fa-'],
    [class*=' fa-'] {
      margin-top: 3px;

      &::before {
        font-size: 19.5px;
      }
    }
  }

  .content-container {
    padding: 0;
  }

  .title-and-icon {
    margin: 0 0 1rem;
    padding: 0;
  }

  .entries-container {
    display: block;
    margin: 0;
    padding: 0;
  }

  .title-and-icon {
    align-items: baseline;
    display: flex;
    justify-content: space-between;
  }

  .header-icon {
    color: $accent-color;
    cursor: pointer;
    font-size: 20px;
    height: 20px;
    @media #{$bp-medium} {
      display: none;
    }
  }

  p {
    margin: 0;
  }
}
