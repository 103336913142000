%group-services-animation {
  $initial-position: translateY(100vh);

  @keyframes fly-up {
    from {
      transform: $initial-position;
    }

    to {
      transform: translateY(0);
    }
  }

  > * {
    animation-duration: 700ms;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
    transform: $initial-position;
  }

  &.in-view > * {
    animation-name: fly-up;
  }

  .header-area {
    animation-delay: 0ms;
  }

  .center-center-area {
    animation-delay: 200ms;
  }

  .pillar {
    animation-delay: 600ms;
  }

  .bottom-center-area {
    animation-delay: 700ms;
  }
}
