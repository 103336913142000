.two-column-richtext {
  @include style-of-link;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1110px;
  padding: 40px;

  a {
    color: $accent-color;
    font-weight: 500;
  }

  section {
    display: block;
    width: 100%;
  }

  p {
    font-size: 18px;
    line-height: 29px;
    margin: 0 0 2rem;
  }

  p:last-child {
    margin: 0;
  }

  .content {
    max-width: 1110px;

    &.left {
      @media #{$bp-medium} {
        float: left;
        margin-right: 2rem;
      }
    }

    &.right {
      @media #{$bp-medium} {
        float: left;
      }
    }

    @media #{$bp-medium} {
      width: 46%;
    }
  }
}
