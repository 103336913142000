.accordion {
  text-size-adjust: 100%;

  h3 {
    margin-bottom: 2rem;
  }

  .slice {
    background-color: var(--accordion-background-color, #{$area-color-14});
    border-left: 8px solid;
    border-left-color: var(--accordion-background-color, #{$area-color-14});
    border-radius: 4px;
    margin-bottom: 2px;
    transition: border-color 100ms linear;
    width: 100%;

    &.is-active {
      background-color: $area-color-1;
      border-color: var(
        --accordion-background-color,
        #{$accent-color}
      ) !important;

      .head i {
        color: unset;
        transform: rotate(-0.5turn);
      }

      .head {
        span.heading {
          color: unset;
        }
      }
    }

    .head {
      cursor: pointer;
      display: flex;
      flex-flow: row nowrap;
      height: auto;
      justify-content: space-between;
      padding: 1rem 24px 1rem 17px;

      i {
        color: var(--accordion-text-color);
        cursor: pointer;
        transition: transform 100ms ease-in-out;
      }

      span.heading {
        color: var(--accordion-text-color);
        display: inline-block;
        font-size: 20px;
        font-weight: 600;
        height: 26px;
        line-height: 26px;
        margin: 0;
      }
    }

    .body {
      max-height: 100vh;
      overflow: hidden;
      padding-bottom: 1rem;
      transition: max-height 100ms ease-in-out;

      p,
      span.subheader {
        margin-left: 17px;
        margin-right: 25px;
      }

      ul {
        padding-left: 13px;
      }

      ol {
        padding-left: 35px;
      }

      p {
        margin-top: 0;
      }

      span.subheader {
        display: block;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }

      &.collapsed {
        max-height: 0;
        padding: 0;
      }
    }

    .richtext-content {
      display: block;
      padding-left: 17px;
      padding-right: 25px;

      > * {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}
