.meta-nav {
  .meta-nav-part {
    .meta-text {
      padding-left: 18px;
      padding-right: 18px;

      @media #{$bp-large} {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }

  .meta-nav-elements {
    @media #{$bp-small} {
      padding-right: 60px;
    }

    @media #{$bp-large} {
      padding-right: 7.5em;
    }

    @media #{$bp-extra-large} {
      padding-right: 90px;
    }
  }

  .meta-nav-elements.no-lswitcher {
    padding-right: unset;

    @media #{$bp-medium} {
      margin-right: -10px;
    }

    @media #{$bp-large} {
      margin-right: 85px;
    }

    @media #{$bp-extra-large} {
      margin-right: 75px;
    }
  }
}
