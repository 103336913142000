.row-wrap {
  position: relative;
}

.slider-helper {
  overflow: hidden;
  width: 100%;
}

.slider-wrap {
  display: flex;
  font-size: 0;
  position: relative;
  width: 600%;
}

.slider-item {
  background-color: $accent-color;
  display: inline-block;
  flex: 1;
  height: auto;
  position: inherit;
  transition: transform 0.8s;
  width: calc(100% / 6);

  * {
    color: $text-color-1;
  }
}

.status-points {
  bottom: 0;
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 100;

  .point {
    background-color: transparent;
    border: 4px solid $area-color-1;
    border-radius: 13px;
    height: 13px;
    margin: 15px;
    transition: background-color 0.8s;
    width: 13px;

    &.active {
      background-color: $area-color-1;
    }
  }
}

.move {
  height: 100%;
  position: absolute;
  width: 10vw;
  z-index: 100;

  &.left {
    background: linear-gradient(
      to right,
      transparentize($shade-type-1, 0.81),
      $transparent
    );
    left: 0;
  }

  &.right {
    background: linear-gradient(
      to left,
      transparentize($shade-type-1, 0.81),
      $transparent
    );
    left: 90vw;
  }
}

.content-entry {
  color: $text-color-1;
  height: 100%;
  top: 0;
  width: 100%;

  .hidden,
  &.hidden {
    display: none !important;
  }

  .element {
    padding: 50px;
  }

  .slider-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;
    justify-content: space-between;

    * {
      margin: 0;
    }

    .image {
      flex-basis: 50%;
    }

    .left {
      display: flex;
      flex-basis: 50%;
      flex-direction: column;
      flex-wrap: nowrap;
      height: auto;
      justify-content: flex-start;
      width: 50%;

      div.title {
        height: calc(100% * (3 / 8));
      }

      div.text {
        height: calc(100% * (4 / 8));
      }

      div.link {
        align-items: center;
        display: flex;
        height: calc(100% * (1 / 8));
        justify-content: flex-start;
      }
    }
  }
}
