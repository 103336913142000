.contact-form-and-text {
  display: flex;
  flex-direction: column;

  @media #{$bp-medium} {
    flex-direction: row;
  }

  .form.module {
    padding: 0;
  }

  .contact-form {
    display: flex;
    flex: 1;
    @media #{$bp-medium} {
      flex-basis: 50%;
    }
    @media #{$bp-extra-large} {
      flex: 1.625 0 60%;
    }
  }

  .contact-text-person {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-wrap: break-word;
    padding-top: 20px;

    @media #{$bp-medium} {
      flex-basis: 50%;
      margin-left: 3rem;
      min-width: 350px;
    }

    @media #{$bp-extra-large} {
      margin-left: 4rem;
    }
  }

  .contact-text {
    @extend %richtext-content;
    width: 100%;
  }

  .contact-person {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0;

    &:not(:first-child) {
      margin-top: 4rem;
    }
  }

  .contact-person-img {
    border-radius: 100%;
    height: 145px;
    margin-bottom: 1rem;
    object-fit: cover;
    width: 145px;
  }

  .contact-person-text-block {
    line-height: 1.5;
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  .contact-person-text {
    display: block;
    text-align: center;
  }

  .contact-person-name {
    font-size: 28px;
    line-height: 38px;
  }

  .generic-button {
    @include button();
  }
}
