.counter {
  .headline {
    margin-bottom: 11px;
  }

  .subheadline {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 30px;
    @media #{$bp-large} {
      margin-bottom: 100px;
    }
  }

  .row {
    display: grid;
    gap: 1rem;
    grid-auto-rows: 200px;
    grid-template-columns: repeat(2, 1fr);
    @media #{$bp-medium} {
      grid-template-columns: repeat(3, 1fr);
    }
    @media #{$bp-large} {
      grid-template-columns: repeat(4, 1fr);
    }

    &.three-columns {
      @media #{$bp-large} {
        grid-auto-rows: 250px;
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }

  .tile {
    height: 100%;
    perspective: 1000px;

    &:hover,
    &.tile-touch {
      .tile-inner {
        transform: rotateY(180deg);
      }
    }
  }

  .tile-custom-color {
    .divider {
      background-color: var(--counter-divider-color, #{$accent-color});
    }

    .tile-front {
      border: 1px solid var(--counter-border-color, #{$border-color-3});
    }

    .tile-back {
      background-color: var(
        --counter-flipped-background-color,
        #{$accent-color}
      );
      border: 1px solid var(--counter-flipped-border-color);
      color: var(--counter-flipped-text-color, #{$text-color-1});
    }
  }

  .tile-gradient {
    background: linear-gradient(
      to right,
      var(--counter-gradient-one-color, #{$accent-color}) 0%,
      var(--counter-gradient-two-color, #{$accent-color}) 32%,
      var(--counter-gradient-three-color, #{$accent-color}) 67%,
      var(--counter-gradient-four-color, #{$accent-color}) 100%
    );
    border-radius: 4px;
    justify-content: center;

    .count-wrap {
      flex-grow: 0;
    }

    .divider {
      background-color: var(--counter-divider-color, #{$area-color-1});
      height: 3px;
      margin: 1.5rem auto 1rem;

      @media #{$bp-medium} {
        width: 125px;
      }

      @media #{$bp-large} {
        width: 175px;
      }
    }

    .count {
      color: var(--counter-text-color, #{$text-color-1});
    }

    .short-description {
      color: var(--counter-text-color, #{$text-color-1});
      font-size: 20px;
    }

    .tile-front {
      border: 0;
    }
  }

  .tile-inner {
    border-radius: 4px;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.5s;
    width: 100%;
  }

  .no-animation .tile-inner {
    transition: none;
  }

  .tile-front,
  .tile-back {
    backface-visibility: hidden;
    border-radius: 4px;
    height: 100%;
    padding: 14px;
    position: absolute;
    width: 100%;
  }

  .tile-front {
    border: 1px solid $border-color-3;
    display: flex;
    flex-direction: column;
  }

  .count-wrap {
    display: block;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    font-size: 32px;
    justify-content: center;
    line-height: 42px;
    text-align: center;

    @media #{$bp-medium} {
      font-size: 48px;
      line-height: 58px;
    }

    @media #{$bp-large} {
      font-size: 64px;
      line-height: 74px;
    }
  }

  .short-description {
    display: block;
    text-align: center;
  }

  .divider {
    background-color: $accent-color;
    border-radius: 2px;
    display: block;
    height: 4px;
    margin: 10px auto;
    width: 65px;
  }

  .tile-back {
    background-color: $accent-color;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    transform: rotateY(180deg);

    p {
      @include line-clamp(3, 1.62 * 18px);
      color: var(--counter-flipped-text-color, #{$text-color-1});
      display: block;
      font-size: 18px;
      line-height: 1.62;
      margin: 0;
      text-align: center;

      @media #{$bp-medium} {
        margin: 15px 0;
      }
    }

    i {
      color: var(--counter-flipped-text-color, #{$text-color-1}) !important;
      display: block;
      font-size: 40px;
      margin: 0 0 15px;
      text-align: center;

      @media #{$bp-medium} {
        margin: 5px 0 24px;
      }
    }

    .counter-icon {
      color: var(--counter-flipped-text-color, #{$text-color-1}) !important;
      display: block;
      font-size: 40px;
      margin: 0 0 15px;
      text-align: center;

      @media #{$bp-medium} {
        margin: 5px 0 11px;
      }
    }
  }
}
