@import './new-medium-tile-grid/hover';

.new-medium-tile-grid {
  @import './new-medium-tile-grid/vars';

  * {
    transition: all 150ms ease-in-out;
  }

  &.module-full {
    // Meaning slider
    display: flex;
    justify-content: center;
    position: relative;
  }

  .root-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 (-$gutter-width / 2);

    .grid-tile:hover {
      @include hover-grid;
    }

    &.dont-stretch-last-row {
      :nth-last-child(2),
      :last-child {
        @media #{$bp-medium} {
          flex-grow: 0;
        }
      }
    }
  }

  .grid-tile {
    display: block;
    height: 520px;
    margin: $gutter-width 0 ($gutter-width / 2);
  }

  .overlays {
    margin: 0 11px;
    pointer-events: none;
    z-index: 1;

    img,
    .publish-date,
    .author-name {
      color: $text-color-3;
    }
  }

  .base {
    background-color: $area-color-17;
    border-radius: 4px;
    margin: 0 11px;
    overflow: hidden;
    pointer-events: none;
  }

  .teaser {
    margin-bottom: 0;
  }

  .image-section {
    // TODO remove this when we reworked filters/search
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    img {
      display: block;
      height: 100%;
      margin: auto;
      object-fit: cover;
      width: 100%;
    }
  }

  .text-section {
    grid-area: text;
    padding: 0 1rem;

    .teaser {
      margin-top: 0.5rem;
    }
  }

  .grid-tile {
    height: auto;
    min-height: 520px;

    &[class*=' i'],
    &[class^='i'] {
      .learn-more-link-section {
        padding-bottom: 0;
      }
    }
  }

  .text-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    .teaser {
      color: $text-color-3;
    }
  }

  .learn-more-link-section {
    padding: 1rem 0;
    text-align: right;

    .learn-more-link {
      color: $accent-color;
      display: block;
      font-size: $mtg-teaser-size;
      line-height: $mtg-teaser-lineheight;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  @import './new-medium-tile-grid/typography';
  @import './new-medium-tile-grid/reuse';
  @import './new-medium-tile-grid/layouts';
  @import './new-medium-tile-grid/slider';
}
