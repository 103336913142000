.footer-company {
  background-color: $area-color-5;

  .module-content {
    padding: 60px 39px 102px !important;
  }

  .content-row {
    margin: -53px 0;

    @media #{$bp-medium} {
      display: flex;
      margin: -35px -33px -80px;
      min-height: 0;
      width: calc(100% + 78px);
    }

    @media #{$bp-large} {
      margin: -40px -37px -45px;
    }
  }

  .footer-navigation {
    border-bottom: 1px solid $area-color-6;
    display: flex;
    justify-content: space-around;

    @media #{$bp-medium} {
      border: 0;
      display: flex;
      flex-basis: 30%;
      justify-content: flex-start;
    }

    ul {
      list-style: none;
      margin-bottom: 19px;
      margin-top: 22px;
      padding: 0;

      @media #{$bp-medium} {
        margin-right: 74px;
      }

      @media #{$bp-large} {
        margin-right: 134px;
      }

      &:last-child {
        @media #{$bp-medium} {
          margin-right: 0;
        }
      }
    }

    li {
      font-size: 12px;
      line-height: 20px;
      margin-bottom: 9px;

      @media #{$bp-medium} {
        font-size: 12px;
      }

      @media #{$bp-large} {
        font-size: 14px;
      }
    }

    a {
      color: $text-color-1;
      vertical-align: sub;
    }

    img {
      margin: 15px 0;

      @media #{$bp-medium} {
        margin: 28px 0 45px;
      }
    }
  }

  .indicator {
    background-color: $text-color-4;
    border-radius: 100%;
    color: $text-color-1;
    font-size: 8px;
    padding: 2px;
    vertical-align: top;
  }

  .social-media {
    border-bottom: 1px solid $area-color-6;
    text-align: center;

    @media #{$bp-medium} {
      border: 0;
      flex-basis: 40%;
    }
  }

  .social-head {
    color: $text-color-1;
    font-size: 14px;
    line-height: 20px;
    margin-top: 26px;
  }

  .icons {
    margin: 9px 0 17px;

    .social-icon {
      color: $text-color-1;
      display: inline-block;
      height: 40px;

      .hover {
        display: none;
      }

      &:hover {
        .hover {
          display: inline-block;
        }

        .default {
          display: none;
        }
      }
    }
  }

  .quote {
    margin: 0 auto;
    margin-top: 25px;
    width: 223px;

    @media #{$bp-medium} {
      flex-basis: 30%;
    }

    div {
      color: $text-color-1;
      font-size: 12px;
      line-height: 20px;

      @media #{$bp-medium} {
        font-size: 9px;
        margin: 0 0 0 auto;
        max-width: 205px;
      }

      @media #{$bp-large} {
        font-size: 12px;
        max-width: 243px;
      }
    }

    .left {
      margin-left: 49px;

      @media #{$bp-medium} {
        margin-left: 79px;
      }

      @media #{$bp-large} {
        margin-left: 109px;
      }

      @media #{$bp-extra-large} {
        margin-left: 154px;
      }
    }

    .right {
      @media #{$bp-medium} {
        width: 177px;
      }

      @media #{$bp-large} {
        width: 223px;
      }
    }
  }

  .lower {
    background-color: $area-color-2;
    position: relative;

    .module-content {
      display: flex;
      justify-content: space-between;
      padding-bottom: 18px !important;
      padding-top: 17px !important;

      @media #{$bp-medium} {
        margin: -35px -16px -80px !important;
        max-width: 1001px !important;
        width: calc(100% + 32px) !important;
      }

      @media #{$bp-large} {
        margin: -35px auto -80px !important;
        max-width: none !important;
        width: 1014px !important;
      }

      @media #{$bp-extra-large} {
        width: 1187px !important;
      }
    }
  }

  .bottom-navigation {
    flex-basis: 33.33333%;
    padding-top: 3px;

    ul {
      list-style: none;
      margin: 0 0 50px;
      padding: 0;

      @media #{$bp-medium} {
        display: block;
        margin: 0 auto;
      }
    }

    li {
      line-height: 17px;
      margin-bottom: 13px;

      @media #{$bp-medium} {
        float: left;
        margin: 0 18px;
      }
    }

    a {
      color: $text-color-1;
      font-size: 12px;
      line-height: 20px;
    }
  }

  .copyright {
    bottom: 18px;
    color: $text-color-1;
    flex-basis: 33.33333%;
    font-size: 12px;
    line-height: 20px;
    padding-top: 3px;
    position: absolute;

    @media #{$bp-medium} {
      position: static;
    }
  }

  .language {
    flex-basis: 50%;
    text-align: right;

    @media #{$bp-medium} {
      flex-basis: 33.33333%;
    }

    span {
      color: $text-color-1;
      font-size: 18px;
      vertical-align: middle;
    }

    div {
      color: $text-color-1;
      display: inline;
      font-size: 12px;
      line-height: 20px;
    }
  }
}
