.split {
  @media #{$bp-extra-large} {
    align-items: flex-start;
    display: flex;
    margin-bottom: 40px !important;
    min-height: 100vh;
    padding-bottom: 0 !important;
  }

  > *:nth-child(1) {
    @media #{$bp-extra-large} {
      display: inline-block;
      flex: 0 0 75%;
      padding-right: 10px;
    }
  }

  > *:nth-child(2) {
    bottom: 0;
    display: flex;
    left: 0;
    max-height: 45vh;
    position: fixed;
    width: 100vw;

    @media #{$bp-extra-large} {
      display: inline-block;
      flex: 0 0 25%;
      height: calc(100vh - 120px);
      left: auto;
      max-height: none;
      position: relative;
      right: 0;
    }
  }
}
