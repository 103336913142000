@import './s-card';
@import './full-card';
@import './image-card';

$width: 100%;

@mixin layout-1-3 {
  flex: 1 1 $width;
  @media #{$bp-medium} {
    flex-basis: $width / 2;
  }
  @media #{$bp-large} {
    flex-basis: $width / 3;
  }
}

@mixin slider-layout-1-3 {
  flex: 1 1 $width;
  @media #{$bp-small} {
    flex-basis: $width;
  }
  @media #{$bp-medium} {
    flex-basis: $width / 2;
  }
  @media #{$bp-large} {
    flex-basis: $width / 3;
  }
}

@mixin layout-2-3 {
  flex: 1 1 $width;
  @media #{$bp-large} {
    flex-basis: $width * (2 / 3);
  }
}

@mixin slider-layout-2-3 {
  flex: 1 1 $width;
  @media #{$bp-small} {
    flex-basis: $width;
  }
  @media #{$bp-medium} {
    flex-basis: $width / 2;
  }
  @media #{$bp-large} {
    flex-basis: $width * (2 / 3);
  }
}

@mixin layout-3-3 {
  flex: 1 1 calc(100% - 2rem);
}

@mixin slider-layout-3-3 {
  flex: 1 1 calc(100% - 2rem);

  @media #{$bp-small} {
    flex-basis: calc((100% - 2 * (39px + 1rem)));
  }
  @media #{$bp-medium} {
    flex-basis: $max-width-medium;
  }
}

.s2-3,
.grid-tile[class*=' i'] {
  .image-section {
    position: relative;

    &::after {
      background-image: linear-gradient(
        to top,
        rgba(0, 0, 0, 0.5),
        transparent
      );
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

.grid-tile[class*=' i'],
.grid-tile[class^='i'] {
  @include image-card;
}

.i1-3 {
  @include layout-1-3;
}

.i2-3 {
  @include layout-2-3;
}

.i3-3 {
  @include layout-3-3;
}

.grid-tile[class*=' s'],
.grid-tile[class^='s'] {
  @include s-card-generics;
}

.s1-3 {
  @include s-card-layout(calc((100vw - 3rem) * 0.54));
  @include layout-1-3;
  @media #{$bp-medium} {
    @include mtg-text-clamper(3, 6);
    @include s-card-layout(190px);
  }
  @media #{$bp-large} {
    @include s-card-layout(162px);
  }
  @media #{$bp-extra-large} {
    @include s-card-layout(190px);
  }
}

.s2-3 {
  @include s-card-layout(278px);
  @include mtg-text-clamper(3, 3);
  @include layout-2-3;

  @media #{$bp-large} {
    @include s-card-layout(240px);
  }
  @media #{$bp-extra-large} {
    @include s-card-layout(278px);
  }

  .image-section {
    &::after {
      background-image: none;
    }
  }
}

.s3-3 {
  /* stylelint-disable-next-line media-feature-name-disallowed-list */
  @media screen and (max-width: 991px) {
    // An dieser Stelle habe ich entscheiden, einen Max-Width breakpoint zu benutzen, da die Styles von "unten" hier gar nicht mehr genutzt werden, da es sich in ein komplett anderes Layout wandelt und ich nicht alles überschreiben möchtes, da es sonst wieder zu Abhängigkeiten kommt, wo keine benötigt werden.
    @include s-card-layout(278px);
    @include mtg-text-clamper(3, 3);
    @include layout-1-3;
  }

  @media #{$bp-large} {
    @include full-card-layout;
    @include mtg-text-clamper(3, 6);
    @include layout-3-3;
  }

  .text-section {
    @media #{$bp-large} {
      margin-top: calc(#{$flag-height-in} + 6rem);
    }
  }
}

.root-flex-slider {
  .s1-3,
  .i1-3 {
    @include slider-layout-1-3;
  }

  .s2-3,
  .i2-3 {
    @include slider-layout-2-3;
  }

  .s3-3,
  .i3-3 {
    @include slider-layout-3-3;
  }
}
