body > article nav.breadcrumbs:first-child {
  padding-top: 1rem;
}

.breadcrumbs .wrap :where(.crumb, .index) {
  font-size: 12px;
  line-height: 1.62;
  @media #{$bp-medium} {
    font-size: unset;
  }

  a {
    color: $accent-color;
  }
}
