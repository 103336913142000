@import '../base/companies';

.small-image-tiles {
  .container {
    display: grid;
    gap: 20px; // fixme
    grid-auto-rows: calc((50vw - 48px) * 0.9);
    grid-template-columns: repeat(2, 1fr);

    @media #{$bp-small} {
      grid-auto-rows: calc((100vw - 98px) / 2 * 0.9);
    }
    @media #{$bp-medium} {
      grid-auto-rows: 295px;
    }
    @media #{$bp-large} {
      grid-auto-rows: 255px;
      grid-template-columns: repeat(3, 1fr);
    }
    @media #{$bp-extra-large} {
      grid-auto-rows: 220px;
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .item {
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    transition: box-shadow 150ms ease-in-out;
    word-break: break-word;

    &:hover {
      box-shadow: 0 0 17px transparentize($accent-color, 0.7);
    }

    * {
      color: $text-color-1;
    }

    .visual {
      background-position: center;
      background-size: cover;
      height: 100%;
    }

    .text-box {
      align-items: flex-start;
      background-clip: padding-box;
      background-image: linear-gradient(
        to top,
        transparentize($shade-type-1, 0.3),
        transparent
      );
      bottom: 0;
      display: flex;
      flex-flow: column nowrap;
      height: 100%;
      justify-content: flex-end;
      max-height: none;
      padding: 10px 15px 0;
      position: absolute;
      top: auto;
      width: 100%;
    }

    h3 {
      @include line-clamp(4, (1.3 * 12px));
      font-size: 12px;
      line-height: 1.3;
      margin: 0 0 11px;

      @media #{$bp-small} {
        @include line-clamp(6, (1.3 * 20px));
        font-size: 20px;
        max-height: none;
      }
      @media #{$bp-medium} {
        @include line-clamp(7, (1.3 * 24px));
        font-size: 24px;
      }
      @media #{$bp-large} {
        @include line-clamp(6, (1.3 * 24px));
      }
      @media #{$bp-extra-large} {
        @include line-clamp(6, (1.3 * 22px));
        font-size: 22px;
      }
    }

    .flag-label {
      background-color: $accent-color;
      display: inline-block;
      font-size: 8px;
      font-weight: bold;
      line-height: 14px;
      max-height: 34px;
      max-width: 130px;
      min-height: 20px;
      min-width: 65px;
      overflow: hidden;
      padding: 3px 10px;
      position: relative;
      text-align: center;
      text-transform: uppercase;
      top: 13px;

      @each $name, $color in $companies {
        /* stylelint-disable aditayvm/at-rule-no-children */
        &.#{$name} {
          background-color: $color;
        }
        /* stylelint-enable aditayvm/at-rule-no-children */
      }
    }
  }
}
