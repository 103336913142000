.language-switcher {
  display: none;
  font-weight: 500;
  gap: 3px;
  max-height: 100%;
  position: relative;
  z-index: 1;

  .language-option {
    display: inline;
  }

  .active {
    font-weight: 700;
  }
}

.mobile-nav {
  &.desktop {
    .ls-container {
      .language-switcher {
        align-items: center;
        display: flex;
        height: 45px;
        justify-content: flex-end;
        padding-top: 1rem;

        * {
          border: none;
        }

        a {
          min-height: 48px;
          min-width: 48px;
          padding: 10px;
        }

        .language-switcher-separator {
          min-height: 48px;
          padding: 10px 0;
        }
      }
    }
  }
}

.meta-nav {
  .language-switcher {
    display: none;
    justify-content: flex-end;
    margin-right: 0;
    width: auto;

    @media #{$bp-medium} {
      max-width: $max-width-medium;
    }

    @media #{$bp-large} {
      display: flex;
      max-width: $max-width-large;
    }

    @media #{$bp-extra-large} {
      max-width: $max-width-xl;
    }

    &.has-pipe {
      border-left: 1px solid $meta-nav-text;
      padding-left: 20px;
    }
  }

  .no-elements {
    .language-switcher {
      max-width: $max-width-xl;
      width: 100%;
    }
  }

  .nolist + .language-switcher {
    width: auto;
  }
}
