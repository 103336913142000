.content-navigation {
  @media #{$bp-medium} {
    flex-flow: row nowrap;
    margin: 0 22px;
    max-width: 100vw;
  }
  @media #{$bp-extra-large} {
    margin: 0 auto;
    max-width: $max-width-xl;
  }

  .navbar-underscore {
    border-bottom: 1px solid $border-color-3;
    border-top: 1px solid $border-color-3;
    display: none;
    margin: 20px 0;

    @media #{$bp-medium} {
      display: block;
    }
  }

  .navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px 10px 0;

    > div {
      cursor: pointer;
    }
  }

  .active {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-weight: bold;

    .underscore {
      border-bottom: 3px solid $company-color-nov;
      display: block;
      height: 3px;
      margin: 10px 0 0;
      min-width: 30px;
      width: 40%;
    }
  }

  .responsive-dropdown {
    display: block;
    margin: 5px 10px;

    @media #{$bp-medium} {
      display: none;
    }
  }

  .navbar {
    background-color: $area-color-14;
  }

  select {
    appearance: none;
    background: linear-gradient(45deg, transparent 50%, $company-color-nov 50%),
      linear-gradient(135deg, $company-color-nov 50%, transparent 50%);
    background-position: calc(100% - 21px) calc(1em + 2px),
      calc(100% - 16px) calc(1em + 2px), 100% 0;
    background-repeat: no-repeat;
    background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
    border-style: none;
    color: $area-color-5;
    cursor: pointer;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    height: 40px;
    outline: none;
    padding-left: 10px;
    width: 100%;
  }

  ​ select option {
    color: $area-color-5;
    cursor: pointer;
  }

  select:focus::-ms-value {
    background-color: transparent;
  }

  select::-ms-expand {
    display: none;
  }

  .content-container {
    > article {
      display: none;
    }

    > .active {
      display: block;
    }
  }
}
