.headline.module {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;

  h1,
  h2,
  h3,
  h4,
  h5 {
    padding-bottom: 2rem;
    padding-top: 0;
  }
}
