.logo-row {
  h4 {
    display: inline-block;
    line-height: 28px;
    margin: 0 0 17px;

    @media #{$bp-medium} {
      line-height: 42px;
      margin-bottom: 0;
    }
  }

  h3 {
    line-height: 21px;
    margin: 1rem 0;

    @media #{$bp-medium} {
      font-size: 24px;
      line-height: 28px;
    }
  }

  .active {
    display: none;
  }

  .item-wrapper {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 0;
    max-width: calc(100vw - 60px - 48px);
    min-width: 100%;
    padding: 0;
    white-space: nowrap;

    @media #{$bp-medium} {
      flex-flow: row nowrap;
    }

    @media #{$bp-large} {
      max-width: calc(1110px - 60px);
    }
  }

  .item-container {
    border-bottom: 0;
    display: flex;
    height: 40px;
    justify-content: center;
    margin: 0 0 10px;
    padding: 0;
    position: relative;
    width: calc(50% - 30px);
    @media #{$bp-medium} {
      display: inline-block;
      flex: 0 1 auto;
      height: auto;
      margin: 0 0 0 20px;
      width: auto;
    }

    &:first-child {
      margin: 0;
    }

    &.logo-clickable {
      img {
        cursor: pointer;
      }
    }

    &:hover {
      .active {
        display: inline-block;
      }

      .inactive {
        display: none;
      }
    }

    picture {
      display: flex;
      justify-content: center;
    }

    img {
      cursor: auto;
      height: auto;
      height: 43px;
      margin: auto;
      max-width: 100%;
      object-fit: scale-down;
      width: auto;
      @media #{$bp-medium} {
        margin: 0;
        width: 100%;
      }
    }
  }
}
