//Mixins

@mixin flex-basis-calc($cols, $span, $gap: 30px) {
  $included-gap: ($gap * ($span - 1));
  $total-gap: ($gap * ($cols - 1));
  flex-basis: calc(
    ((100% - #{$total-gap}) / #{$cols}) * #{$span} + #{$included-gap}
  );
}

@mixin flex-spacing($gap) {
  > * {
    margin-left: $gap / 2;
    margin-right: $gap / 2;
  }

  > *:first-child {
    margin-left: 0;
    margin-right: $gap / 2;
  }

  > *:last-child {
    margin-left: $gap / 2;
    margin-right: 0;
  }

  > *:first-child:last-child {
    margin-left: 0;
    margin-right: 0;
  }
}

@function fake-grid-item-width($columns, $gap) {
  @return calc((100% - #{$gap} * (#{$columns} - 1)) * (1 / #{$columns}));
}
@mixin fake-grid-item($columns, $gap) {
  flex-basis: fake-grid-item-width($columns, $gap);
  margin-bottom: $gap;
}

@mixin events-tile-item($columns) {
  flex-basis: calc((100% - 25px * (#{$columns} - 1)) * (1 / #{$columns}));
}

@mixin teaser-tile-item($columns) {
  flex-basis: calc((100% - 25px * (#{$columns} - 1)) * (1 / #{$columns}));
}

@mixin fake-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

@mixin letter-spacing($spacing) {
  letter-spacing: ($spacing / 1000) * 1em;
}

@mixin richtext {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1140px;
  padding: 50px 0 0 50px;
  position: relative;
}

@mixin form-focus($color) {
  transition: box-shadow 0.2s;

  &:focus {
    // TODO: Hierfür gibt es noch kein Design, daher einfach ein bisschen als bsps.
    box-shadow: 0 0 12px 0 $color;
    outline: none;
  }

  &:active {
    box-shadow: 0 0 12px 2px $color;
    outline: none;
  }
}
@mixin form-input-field($bcolor, $color) {
  @include form-focus($bcolor);
  background-color: transparent;
  border: 1px solid $bcolor;
  border-radius: 8px;
  color: $color;
  font-size: 18px;
  padding: 6px 12px;
  width: 100%;
}
@mixin form-button($bcolor, $color) {
  @include form-focus($bcolor);
  background-color: $bcolor;
  border: 0;
  border-radius: 10px;
  font-size: 24px;
  padding: 1px 71px;
  text-align: center;

  .button-text {
    color: $color;
    font-size: 24px;
    font-weight: 700;
  }
}
@mixin form-checkbox($bcolor, $color) {
  input[type='checkbox'] {
    @include form-focus($bcolor);
    appearance: none;
    border: 1px solid $bcolor;
    border-radius: 4px;
    color: transparent;
    height: 30px;
    width: 30px;

    &:checked {
      color: $color;
    }

    &::before {
      content: '\f00c';
      font-size: 25px;
      font-style: normal;
      margin: 1px;
      text-decoration: inherit;
    }
  }
}

//Mixins
@mixin style-of-link {
  ul {
    a {
      color: $accent-color;
      font-weight: 500;
    }
  }

  ol {
    a {
      color: $accent-color;
      font-weight: 500;
    }
  }
}

@mixin shake {
  .shake {
    animation-name: shake;
  }

  .animated {
    animation-duration: 0.8s;
    animation-fill-mode: both;
  }
  @keyframes shake {
    0%,
    to {
      transform: translateZ(0);
    }

    20%,
    60% {
      transform: translate3d(-7px, 0, 0);
    }

    40%,
    70% {
      transform: translate3d(7px, 0, 0);
    }
  }
}
@mixin loader($spinnercolor) {
  animation: spin 1s infinite;
  color: inherit;
  display: inline-block;
  font-size: inherit;
  height: 1em;
  left: auto;
  margin: -0.5em;
  opacity: 1;
  position: absolute;
  right: 1em;
  top: 50%;
  transition: all 0.3s;
  transition-timing-function: ease-in;
  width: 1em;
  z-index: auto;

  &::after {
    background: center center no-repeat;
    background-size: cover;
    border-color: $spinnercolor $spinnercolor $spinnercolor transparent;
    border-radius: 50%;
    border-style: solid;
    border-width: 0.15em;
    bottom: 0;
    box-sizing: border-box;
    content: ' ';
    display: inline-block;
    height: 100%;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
  @keyframes spin {
    0% {
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
      transform: rotate(0);
    }

    50% {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: rotate(900deg);
    }

    to {
      transform: rotate(1800deg);
    }
  }
}

%content-column {
  @media #{$bp-medium} {
    max-width: $max-width-medium;
  }
  @media #{$bp-large} {
    max-width: $max-width-large;
  }
  @media #{$bp-extra-large} {
    max-width: $max-width-xl;
  }
}

%module {
  @extend %content-column;
  margin: 0 auto 4rem;
  padding: 0 24px;
  width: 100%;

  @media #{$bp-small} {
    padding: 0 39px;
  }

  @media #{$bp-medium} {
    padding: 0;
  }

  &:first-child {
    border-top: 0;
  }
}

%hidescrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
    width: 0 !important;
  }
}

%vertical-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

%vertical-center-inline {
  @extend %vertical-center;
  display: inline-flex;
}

%font-icon-style {
  display: inline-block;
  font-family: 'tta-iconfont';
  -webkit-font-smoothing: antialiased;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  font-style: normal;
  /* opacity: .8; */

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;
  margin-right: 0.2em;
  speak: none;
  text-align: center;
  text-decoration: inherit;
  text-transform: none;
  width: 1em;
}

@mixin icon($content) {
  @extend %font-icon-style;
  content: $content;
  font-family: 'tta-iconfont';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant: normal;
  font-weight: normal;
}

@mixin icon-fa($content) {
  @extend %font-icon-style;
  content: $content;
  font-family: 'Font Awesome 5 Free';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant: normal;
  font-weight: normal;
}

@mixin clamped {
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}

%clamped {
  // Legacy
  @include clamped();
}

@mixin line-clamp-reset {
  -webkit-line-clamp: unset;
}

@mixin line-clamp($lines, $line-height) {
  @include clamped();
  -webkit-line-clamp: $lines;
  max-height: ($line-height * $lines);
}

%transparent-labeling {
  &:not(.short) {
    .text-box {
      align-items: flex-start;
      background-clip: padding-box;
      background-image: linear-gradient(
        to top,
        transparentize($shade-type-1, 0.3),
        transparent
      );
      bottom: 0;
      display: flex;
      flex-flow: column nowrap;
      height: 100%;
      justify-content: flex-end;
      max-height: none;
      padding: 0 25px;
      position: absolute;
      top: auto;
      width: 100%;
      @media #{$bp-medium} {
        padding: 0 15px 15px;
      }
      @media #{$bp-large} {
        padding: 0 25px 25px;
      }

      time {
        color: $text-color-1;
      }
    }

    .label {
      display: none;
    }

    .label-slick {
      border-bottom: 1px solid $area-color-1;
      color: $text-color-1;
      display: inline;
      font-size: 0.875rem;
      font-weight: 500;
      left: -25px;
      line-height: 1.1875rem;
      // margin-bottom: 5px;
      padding: 5px 0 5px 25px;
      position: relative;
      text-transform: uppercase;
    }

    h3 {
      margin-bottom: 5px;
    }
  }
}

@mixin class-prefix($class) {
  [class^='#{$class}'],
  [class*=' #{$class}'] {
    @content;
  }
}
@mixin class-prefix-same-level($class) {
  &[class^='#{$class}'],
  &[class*=' #{$class}'] {
    @content;
  }
}

@import 'base/background_variations';
