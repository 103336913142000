@mixin s-card-layout($image-height) {
  position: relative;

  .overlays {
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .base {
    display: grid;
    gap: 3rem;
    grid-template: $image-height 1fr / 1fr;
    grid-template-areas: 'image' 'text';
    height: 100%;
  }

  .meta-section {
    display: grid;
    grid-template-areas:
      'x x'
      'y date'
      'author author';

    grid-template-columns: auto 1fr;
    grid-template-rows: 2rem auto 1rem;
    left: 0;
    padding: 0 $meta-spacing-right 0 1rem;
    position: absolute;
    right: 0;
    top: calc(#{$image-height} - 3rem);

    .author-image {
      grid-area: image;
      grid-column: 3;
      grid-row: 1 / 3;
      place-self: end;
    }

    .content-type {
      grid-column: 1;
      grid-row: 2;
    }

    .author-name {
      align-self: end;
      grid-area: author;
      grid-column: 3;
      grid-row: 2;
      line-height: 1;
    }

    .publish-date {
      grid-area: date;
      line-height: 1;
      place-self: end;
    }
  }
}
