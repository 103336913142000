.only-headlines {
  @extend %background-variations;
  @extend %background-variations-padding;

  .animate-flyin {
    h2,
    h3,
    h4,
    h5 {
      @extend %variable-transition-duration;
      opacity: 0;
      position: relative;
      transform: translate(-5vw, 0);
      transition-property: all;
    }

    .subheadline {
      @extend %variable-transition-duration;
      opacity: 0;
      transition-property: all;
    }

    h2,
    h3,
    h4,
    h5 {
      &.animation-applied {
        opacity: 1;
        transform: translate(0, 0);
      }
    }

    .subheadline {
      &.animation-applied {
        opacity: 1;
      }
    }
  }

  &.module-full {
    margin-bottom: 0 !important; // Override stylesection
  }

  h2,
  h3,
  h4,
  h5 {
    @extend %background-variations;
    position: relative;

    @include class-prefix-same-level('variation-bg-') {
      display: inline-block;
      padding: 10px;
    }

    /*     &:hover::before {
      content: '\f0c1';
      display: inline-block;
      font-family: 'Font Awesome 5 Free';
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-style: normal;
      font-variant: normal;
      line-height: 1;
      position: absolute;
      text-rendering: auto;
      top: 6%;
      transform: translateX(-150%);
    } */

    // a {
    //   position: relative;
    // }

    a[id] {
      position: absolute;
      top: calc(-1 * var(--topnav-height));

      // &::before {
      // bottom: 100%;
      // content: '';
      // height: 90px;
      // position: absolute;
      // width: 10px;
      // }
    }
  }

  .left {
    text-align: left;
  }

  .center {
    text-align: center;
  }

  .right {
    text-align: right;
  }

  .module-content {
    padding-bottom: 2rem;

    .subheadline {
      @extend %richtext-content;
      margin-top: 1rem;
    }

    .subheadline {
      @extend %richtext-content;
      margin-top: 1rem;
    }
    @media #{$bp-medium} {
      max-width: $max-width-medium;
    }
    @media #{$bp-large} {
      max-width: $max-width-large;
    }
    @media #{$bp-extra-large} {
      max-width: $max-width-xl;
    }
  }

  .variation-accent {
    h2 {
      color: $accent-color !important;
    }
  }

  .variation-light {
    h2 {
      color: $text-color-1 !important;
    }
  }

  .variation-dark {
    h2 {
      color: $text-color-7 !important;
    }
  }

  .variation-textcolor {
    h2 {
      color: $default-text-color !important;
    }
  }
}
