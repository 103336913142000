.custom-dropdown {
  min-height: 50px;
  position: relative;

  &::after {
    color: $accent-color;
    content: '\e820';
    font-family: 'tta-iconfont';
    font-size: 25px;
    pointer-events: none;
    position: absolute;
    right: 11px;
    top: 50%;
    transform: translateY(-50%);
  }

  .disable {
    display: none;
  }

  select {
    appearance: none;
    background-color: $transparent;
    background-image: none;
    border-radius: 4px;
    color: $text-color-3;
    display: block;
    font-size: 16px;
    height: 50px;
    line-height: 1.75;
    margin: 0;
    padding: 11px 0 14px 10px;
    text-transform: capitalize;
    //margin: 5px 0;
    //max-width: 320px;
    width: 100%;
    word-break: normal;

    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 $shade-type-1;
    }

    &::-ms-expand {
      display: none;
    }
  }

  .dropdown {
    margin: 0;
  }

  .dropdown-open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .dropdown-options {
    background-color: $area-color-14;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0 8px 8px transparentize($shade-type-1, 0.7);
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0.75rem;
    position: absolute;
    scroll-behavior: smooth;
    width: 100%;
    z-index: 2;

    .dropdown-option {
      border-radius: 4px;
      flex-shrink: 0;
      font-size: 1rem;
      line-height: 2.5rem;
      margin-bottom: 3px;
      overflow: hidden;
      padding: 0.25rem 0.5rem;
      text-overflow: ellipsis;
      transition: background-color 150ms ease-out;
      white-space: nowrap;

      &.selected,
      &:hover {
        background-color: $area-color-4;
      }
    }
  }

  &.basic {
    display: flex;
    flex-basis: 100%;

    &.open::after {
      content: '';
    }

    @media #{$bp-small} {
      flex-basis: 300px;
    }

    .dummy-input {
      width: 100%;
    }

    .dropdown-options {
      border-radius: 4px;
    }
  }

  &.scrollable {
    .dropdown-options {
      max-height: 15rem;
      overflow-y: scroll;
    }
  }
}

.custom-dropdown-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 1rem;
}
