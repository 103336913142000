$company: 'syn';

//Font Families
$font-base: 'Noway Round Regular', 'Roboto', sans-serif;
$font-base-body-text: 'Melior LT STD Regular', 'Roboto', sans-serif;
$vcard-font: $font-base;

$syn-accent-color: rgba(255, 51, 102, 1);

$text-color-1: rgba(255, 255, 255, 1);
$text-color-3: rgba(255, 255, 255, 1);
$text-color-7: $syn-accent-color;
$text-color-9: $syn-accent-color;
$text-color-10: rgba(43, 43, 43, 1);
$text-color-11: rgba(99, 99, 99, 1);
$text-color-privacy-notice: rgba(0, 0, 0, 1);

$default-text-color: rgba(255, 255, 255, 1);

$headline-color: rgba(255, 255, 255, 1);
$accent-color: $syn-accent-color;

$area-color-1: rgba(49, 49, 49, 1);
$area-color-2: rgba(45, 44, 44, 1);
$area-color-4: rgba(74, 74, 74, 1);
$area-color-5: rgba(70, 70, 70, 1);
$area-color-6: rgba(17, 17, 17, 1);
$area-color-8: rgba(17, 17, 17, 1);
$area-color-10: rgba(59, 59, 59, 1);
$area-color-11: rgba(17, 17, 17, 1);
$area-color-12: rgba(136, 136, 136, 1);
$area-color-13: rgba(59, 59, 59, 1);
$area-color-14: rgba(247, 103, 139, 1);
$area-color-15: rgba(230, 46, 92, 1);
$area-color-16: rgba(255, 255, 255, 1);

$shade-type-2: rgba(255, 255, 255, 1);

$background-color: $area-color-8;
$background-color-alternate: $area-color-2;
$background-color-cookie-notice: rgba(255, 255, 255, 1);
$background-color-footer: rgba(0, 0, 0, 1);
$border-color-footer: rgba(255, 255, 255, 1);

/* Text Colors additional color for individual modules */
$text-color-module-featured-text: rgba(191, 191, 191, 1);
$text-color-2: rgba(255, 51, 102, 1);
$text-color-5: rgba(0, 0, 0, 1);

$color-form-box-shadow: 0 0 0 1px rgba(0, 95, 166, 1);
// $icon-grid-paragraph-color: rgba(255, 255, 255, 0);
// $icon-grid-hover-color: rgba(255, 255, 255, 255);
$tabs-border-color: rgba(112, 112, 112, 1);

//Named-Colors ------------------------------------
$part-of: rgba(222, 222, 222, 1);

//Metanavigation
$meta-nav-text: $area-color-12;
$meta-nav-background: $area-color-12;
$meta-nav-background-dark: $area-color-4;
$nav-font-color: rgba(255, 255, 255, 1);

//Printcard ---------------------------------
$printcard-black: rgb(0, 0, 0);
$printcard-pink: rgb(232, 57, 104);
