.desktop-nav {
  $color-light: $area-color-1; // Default
  $color-dark: $area-color-11; // Focus
  border-bottom: 0;
  box-shadow: $box-shadow-2;

  display: none;
  flex-flow: column nowrap;
  width: 100%;
  @media #{$bp-large} {
    display: flex;
  }

  .part-of {
    display: flex;
    flex-direction: column;

    .description {
      color: $part-of;
      font-size: 12px;
      font-weight: 400;
      line-height: 1.2;
    }

    .groupname {
      color: $part-of;
      font-size: 14px;
      font-weight: 600;
      line-height: 1.2;
    }
  }

  .badge::after {
    float: right;
    margin: 0.5rem 0 0;
    position: relative;
  }

  .menu > .layer-one-active,
  .menu > .layer-one-active > li,
  .menu .first-label:hover {
    color: $text-color-7;
    text-shadow: 0px 0px 0.1px currentColor !important;
  }

  .menu > .highlight,
  .menu > .highlight > li {
    color: $accent-color !important;
    text-shadow: 0px 0px 0.1px currentColor !important;
  }

  .workblog-container {
    display: none;
  }

  .center-wrap {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: space-evenly;
    margin: 0 auto;
    max-width: $max-width-xl;
    padding: 0 1rem;
    width: 100%;

    > div.navigation-image,
    > div.search-block,
    li.first-label {
      cursor: pointer;
    }

    .close-icon {
      margin-left: 10px;
    }

    @media #{$bp-extra-large} {
      padding: 0;
    }
  }

  .menu {
    display: flex;
    height: 100%;
    align-items: center;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-around;
    margin: 0 1rem 0 0;
    opacity: 1;

    a {
      height: 100%;
    }

    li {
      height: 100%;
      align-items: center;
      display: flex;
      font-size: 16px;
      position: relative;
    }

    > a > li:active {
      color: $accent-color;
    }
  }

  .navigation-image {
    flex-grow: 0;

    img {
      height: 50px;
      width: auto;
    }
  }

  .about-block {
    font-size: 12px;
    margin-left: 26px;

    a {
      white-space: nowrap;
    }
  }

  .search-wrap {
    flex-basis: 0;
    flex-grow: 0;
    margin-left: 30px;
    min-width: 37px;
    width: auto;

    &.is-active {
      display: flex;
      flex-grow: 2;

      .about-block {
        display: none;
      }

      .search-icon-meta {
        display: initial;
      }
    }
  }

  .search-form {
    width: 100%;

    input {
      height: 21px;
    }
  }

  .search-icon,
  .search-icon-meta,
  .close-icon {
    cursor: pointer;
    font-size: 25px;
    padding: 0 10px 0 0;
  }

  .search-icon-meta {
    display: none;
  }

  .sub-menu-wrap {
    display: flex;
    flex-flow: row nowrap;
    position: relative;
    transform: scaleY(0);
    transform-origin: top;
    /*    transition: transform 40ms ease-in-out; */

    &.is-active {
      transform: scaleY(1);
      /*       transition: transform 40ms ease-in-out 50ms; */
    }

    .block {
      background-color: transparent;
      flex-grow: 1;
      flex-shrink: 1;
      width: auto;
    }
  }

  .sub-menu {
    background-color: rgba($area-color-1, 1);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
      rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
      rgba(0, 0, 0, 0.07) 0px 16px 16px;
    display: flex;
    flex-flow: row nowrap;
    flex-shrink: 0;
    margin: auto;
    padding: 1rem 0 1rem 1rem;
    max-width: $max-width-xl;
    width: 100%;

    .badge::after {
      float: none;
      margin-left: 5px;
      position: absolute;
      top: -4px;
    }
  }

  div.second-level {
    flex-basis: calc(100% - 356px);
    flex-grow: 2;
    flex-shrink: 0;
    margin: 0;
    order: 2;
    overflow-x: hidden;
  }

  ul.second-level {
    display: none;
  }

  .is-active > ul {
    display: inline-flex;
  }

  ul.third-level {
    display: inline-flex;
  }

  div.third-level {
    display: none;

    &.is-active {
      display: block; //?
      width: 66.7%;
    }
  }

  ul {
    &.menu {
      .badge::after {
        float: right;
        margin: 0 0 1rem;
        position: relative;
      }
    }
  }

  ul.level {
    flex-flow: column nowrap;
    margin: 0;
    width: 100%;
    max-height: calc(100vh - var(--topnav-height) - var(--meta-nav-top) -1rem);
    overflow-y: auto;
    overscroll-behavior: contain;
    padding-right: 1rem;
    &:not(.forth-level) {
      padding-right: 0;
    }

    i {
      font-size: 18px;
    }

    /*     > a > li {
      flex-basis: 2.75rem;
      height: 2.75rem;
      font-size: 14px;
      line-height: 21px;
    } */

    li {
      border-bottom: 1px solid $area-color-11;

      span {
        font-size: 14px;
        line-height: 21px;
        height: 2.75rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
      }
    }

    a:last-child li,
    > li:last-child {
      border-bottom: 0;
    }

    span:hover,
    span.is-active {
      color: $text-color-7;
      text-shadow: 0 0;

      &::before {
        text-shadow: none;
      }

      &::after {
        text-shadow: none;
      }
    }

    > li span.second-label,
    > li span.third-label,
    > li span.forth-label {
      cursor: default;
    }

    span.second-label,
    span.third-label,
    span.forth-label {
      position: relative;

      &::before {
        display: flex;
        order: 2;
        margin: 0 0.5rem 0 auto;
      }
    }

    span.forth-label::before {
      display: none !important;
    }
  }

  ul.level.second-level {
    width: 33.3%;
  }

  ul.level.third-level {
    width: 50%;
  }
  ul.level.forth-level {
    width: 100%;
  }

  div.level {
    display: none;
    //  margin-left: 30px;

    &.is-active {
      display: inline-flex;
      &.forth-level {
        width: 50%;
      }

      // > div {
      //   flex-grow: 2;
      // }

      // > ul {
      //   flex-shrink: 0;
      // }
    }
  }

  div.second-level-detail {
    order: 1;
    text-align: left;
    width: 100%;

    ul {
      align-self: top;
    }

    span {
      text-align: left;
      padding-right: 1rem;
      font-size: 22px;
    }

    p {
      display: inline-block;
      padding-right: 1rem;
      margin: 0;
      font-size: 14px;
      position: relative;
      z-index: 5;
    }

    img {
      display: block;
      height: auto;
      width: 100%;
    }

    div.image-container {
      display: inline-block;
      max-width: 340px;
      position: relative;
      z-index: 5;
    }
  }

  .details {
    display: none;
    flex-flow: column wrap;
    margin-top: 0;
    width: 100%;

    /* stylelint-disable */
    // Disable Stylelint to use scss lists and foreach-variables

    /* stylelint-enable */

    &.is-active {
      display: grid;

      &:not(.only-details) {
        grid-template: 'top' 'mid' 'bottom' / 1fr;
      }

      &.only-details {
        grid-gap: 0 15px;
        grid-template:
          'leftfull righttop'
          'leftfull rightbottom' 1fr / 340px 1fr;
        padding-right: 15px;
      }
    }

    .image-container {
      grid-area: top;
    }

    span {
      grid-area: mid;
    }

    p {
      grid-area: bottom;
    }
  }

  .only-details {
    .image-container {
      grid-area: leftfull;
    }

    span {
      grid-area: righttop;
      margin: 0;
      text-align: left !important;
    }

    p {
      grid-area: rightbottom;
      padding: 0 !important;
      text-align: left;
    }
  }

  .manual-close-button {
    display: none;
  }
}

.desktop-nav.has-manual-close-button {
  .sub-menu-wrap {
    display: grid;
    grid-template-columns: 1fr 1110px 1fr;
  }

  .manual-close-button-wrapper {
    overflow: hidden;
    place-self: auto end;
    position: relative;
    width: 100%;
  }

  .manual-close-button {
    align-content: center;
    background: $accent-color;
    border: 0;
    border-radius: 0;
    display: grid;
    height: 3rem;
    margin-right: 1rem;
    outline: none;
    padding: 0;
    position: absolute;
    right: 0;
    width: 3rem;
    z-index: 1;

    &:hover {
      cursor: pointer;
    }

    i::before {
      color: $text-color-1;
      transform: scale(1.8);
      transform-origin: center;
    }

    + .sub-menu {
      margin-left: 0;
    }
  }
}

.desktop-nav.two-column {
  ul.level {
    width: 100%;
    max-height: calc(100vh - var(--topnav-height) - var(--meta-nav-top) -1rem);
    overflow-y: auto;
    overscroll-behavior: contain;
    padding-right: 1rem;
  }
  ul.level.second-level {
    max-width: 50%;
    padding-right: 1rem;
  }

  ul.level.third-level {
    padding-left: 0;
  }

  div.forth-level {
    display: none;
  }

  span.third-label::before {
    display: none !important;
  }
}

.desktop-nav.right-align {
  .center-wrap {
    height: var(--topnav-height);
    ul.menu {
      height: 100%;
      justify-content: flex-end;
    }

    li.first-label {
      padding: 0 14px;
    }

    a li.first-label,
    li.first-label {
      height: 100%;
      &.open {
        border: 0;
      }
    }

    .search-wrap {
      display: flex;
      margin-left: 10px;

      &.meta {
        display: none;

        &.is-active {
          display: flex;
        }
      }

      i {
        line-height: 42px;
      }
    }
  }
}

.desktop-nav {
  span.title-hide {
    display: none;
  }
}
