.rich-text-table {
  .site-layout {
    @extend %content-column;
    margin: 0 auto;

    @media #{$bp-medium} {
      flex-flow: row nowrap;
    }
  }

  .table-wrapper {
    background-color: $area-color-1;
    display: flex;
    overflow: auto;
    scroll-snap-type: both mandatory;
    width: 100%;

    table {
      margin: 0 auto;
      width: 100%;

      tr {
        padding: 0 13px;
      }

      th {
        @extend %background-variations;
      }

      td,
      th {
        padding: 0 13px;
        word-break: break-all;
      }

      &.no-border {
        border: none !important;
      }

      &.cell-bottom {
        border: none !important;

        td,
        th {
          border-bottom: 1px solid #e6e7e8;
          border-left: none;
          border-right: none;
          border-top: none;
        }
      }

      @media #{$bp-tablet} {
        table-layout: fixed;

        thead {
          th {
            scroll-snap-align: start;
            scroll-snap-stop: normal;
          }
        }

        td,
        th {
          max-width: unset !important;
          width: 100vw !important;
          word-break: break-all;
        }
      }
    }
  }

  .slide-arrows {
    display: flex;
    justify-content: flex-end;
    margin: 30px 11px 15px 0;

    @media #{$bp-medium} {
      display: none;
    }
  }

  .changing-arrow {
    background-color: $area-color-14;
    border-radius: 4px;
    cursor: pointer;
    font-size: 35px;
    height: 50px;
    margin: 0 0 0 10px;
    width: 50px;

    &::before {
      margin: 0.2em;
    }
  }
}
