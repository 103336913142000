@mixin image-card {
  position: relative;

  .overlays {
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .meta-section {
    display: none;
  }

  .alt-meta-section {
    display: grid;
    grid-template-areas:
      'x label y'
      'spacer spacer y'
      'z date date';
    grid-template-columns: 1rem auto 1fr;
    grid-template-rows: 18px 1px 18px;
    margin-left: -1rem;

    &::after {
      background-color: $text-color-1;
      content: '';
      grid-area: spacer;
      height: 100%;
      width: 100%;
    }

    .content-type,
    .publish-date {
      color: $text-color-1;
      font-size: 12px;
      line-height: 14px;
    }

    .content-type {
      align-self: start;
      grid-area: label;
    }

    .publish-date {
      align-self: end;
      grid-area: date;
    }
  }

  .base {
    height: 100%;
  }

  .image-section {
    height: 100%;
    width: 100%;

    img {
      display: block;
      margin: auto;
      max-height: 100%;
      max-width: 100%;
    }
  }

  .text-section {
    bottom: 0;
    margin-bottom: 1rem;
    position: absolute;

    * {
      color: $text-color-1;
    }

    .teaser {
      display: none;
    }
  }
}
