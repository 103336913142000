.text-bild {
  @extend %richtext-content;
  @include style-of-link;

  //General animation rules
  .animate-zoominleft {
    @extend %variable-transition-duration;
    opacity: 0;
    transform: translate(-15vw, 0) scale(0.7, 0.7);
    transition-property: all;

    &.animation-applied {
      opacity: 1;
      transform: translate(0, 0) scale(1, 1);
    }
  }

  //Animation rules for text-image variation
  .text-image {
    &.animate-flyin {
      &.right div {
        &:first-child {
          transform: translate(5vw, 0);
        }

        &:last-child {
          transform: translate(-5vw, 0);
        }
      }

      div {
        @extend %variable-transition-duration;
        opacity: 0;
        transition-property: all;

        &:first-child {
          transform: translate(-5vw, 0);
        }

        &:last-child {
          transform: translate(5vw, 0);
        }
      }

      .animation-applied {
        opacity: 1;

        &:first-child {
          transform: translate(0, 0) !important;
        }

        &:last-child {
          transform: translate(-0, 0) !important;
        }
      }
    }

    &.animate-fadein {
      img {
        @extend %variable-transition-duration;
        opacity: 0;
        transition-property: all;
      }

      .animation-applied {
        img {
          opacity: 1;
        }
      }
    }
  }

  //Animation rules for only-Image variation
  .only-image {
    @extend %variable-transition-duration;
    transition-property: all;

    &.animate-fadein {
      opacity: 0;
    }

    &.animation-applied {
      opacity: 1;
    }

    &.animate-flyin {
      .image-wrap {
        &:nth-child(-n + 2) {
          transform: translate(-5vw, 0);
        }

        &:nth-child(n + 3),
        &:last-child {
          transform: translate(5vw, 0);
        }
      }

      .subline {
        &:nth-child(-n + 2) {
          transform: translate(-5vw, 0);
        }

        &:nth-child(n + 3),
        &:last-child {
          transform: translate(5vw, 0);
        }
      }

      .image-wrap,
      .subline {
        @extend %variable-transition-duration;
        opacity: 0;
        transition-property: all;

        &.animation-applied {
          opacity: 1;
          transform: translate(0, 0) !important;
        }
      }
    }
  }

  //Animation rules for feature-text variation
  .feature-text {
    &.animate-flyin {
      > * {
        &:first-child,
        &:last-child {
          @extend %variable-transition-duration;
          opacity: 0;
          transition-property: all;
        }

        &:first-child {
          transform: translate(-5vw, 0);
        }

        &:last-child {
          transform: translate(5vw, 0);
        }
      }

      &.right {
        > *:first-child {
          transform: translate(5vw, 0);
        }

        > *:last-child {
          transform: translate(-5vw, 0);
        }
      }

      &.animation-applied > * {
        opacity: 1;

        &:first-child {
          transform: translate(0, 0);
        }

        &:last-child {
          transform: translate(-0, 0);
        }
      }
    }

    &.animate-fadein {
      @extend %variable-transition-duration;
      opacity: 0;
      transition-property: all;

      &.animation-applied {
        opacity: 1;
      }
    }
  }

  img {
    border-radius: 4px;
  }

  .intro-text p {
    font-size: 18px;
    @media #{$bp-medium} {
      font-size: 20px;
      line-height: 26px;
    }
  }

  .text-single {
    font-size: 16px;
  }

  ol,
  ul,
  p {
    margin: 0 0 2rem;
  }

  .text-double {
    a {
      color: var(--ss-link-color, $accent-color);
      font-weight: 500;
    }

    div {
      @media #{$bp-medium} {
        width: calc((100% - 20px) / 2);
      }
    }

    .left {
      margin-bottom: 20px;

      *:last-child {
        margin-bottom: 0;
      }

      @media #{$bp-medium} {
        margin-bottom: 0;
        margin-right: 2rem;
      }
    }

    .right {
      *:last-child {
        margin-bottom: 0;
      }
    }
    @media #{$bp-medium} {
      display: flex;
      flex-direction: row;
    }
  }

  .text-image {
    display: flex;
    flex-direction: column;

    > .text-container {
      flex: 1 1 100%;

      *:last-child {
        margin: 0;
      }
    }

    .autoscale-height {
      display: flex;
      flex-direction: column;

      img {
        background-position: 50%;
        background-size: cover;

        height: auto;

        @media #{$bp-medium} {
          flex-grow: 1;
          min-height: 235px; //minimal height for autoscaling
          object-fit: cover;
        }
      }
    }

    .img-container {
      flex: 1 0 100%;
      margin: 0 0 1rem;

      img {
        max-width: 100%;
        width: 100%;
      }

      &.extra-small {
        @media #{$bp-medium} {
          flex: 0 0 26%;
        }
      }

      &.small {
        @media #{$bp-medium} {
          flex: 0 0 33%;
        }
      }

      &.medium {
        @media #{$bp-medium} {
          flex: 0 0 39%;
        }
      }

      @media #{$bp-medium} {
        flex: 0 0 49%;
        margin: 0 2rem 0 0;
      }

      &.top {
        align-self: flex-start;
      }

      &.center {
        align-self: center;
      }

      &.bottom {
        align-self: flex-end;
      }
    }

    &.right {
      flex-direction: column;

      .img-container {
        margin-right: 0;

        @media #{$bp-medium} {
          margin-left: 2rem;
        }
      }

      @media #{$bp-medium} {
        flex-direction: row-reverse;
      }
    }
    @media #{$bp-medium} {
      flex-flow: row nowrap;
    }
  }

  .only-image {
    .image-wrap {
      flex-basis: 100%;
      margin-bottom: 1rem;

      img {
        display: block;
        width: 100%;
      }
      @media #{$bp-medium} {
        flex-shrink: 1;
      }
    }

    .image-wrap,
    .image-description {
      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &:only-child {
        margin-left: 0;
        margin-right: 0;
      }
      @media #{$bp-medium} {
        margin: 0 1rem;
      }
    }

    .image {
      // Container
      align-items: center;
      display: flex;
      flex-flow: row wrap;

      span {
        @media #{$bp-medium} {
          display: none;
        }
      }

      p {
        font-size: 12px;
        font-style: italic;
      }
      @media #{$bp-large} {
        flex-wrap: nowrap;
      }

      @media #{$bp-medium} {
        flex-flow: row nowrap;
      }
    }

    .image-descriptions {
      display: none;
      flex-flow: row nowrap;
      margin-top: 1rem;
      @media #{$bp-medium} {
        display: flex;
      }

      .image-description {
        display: block;
      }
    }

    &.size-auto {
      .image-description {
        flex: 0 1 100%;
      }
    }

    &.size-manually {
      .image-descriptions,
      .image {
        &.left {
          justify-content: flex-start;
        }

        &.center {
          justify-content: center;
        }

        &.right {
          justify-content: flex-end;
        }
      }

      .size-1-1 {
        @media #{$bp-medium} {
          flex: none;
          flex-basis: auto;
          width: calc(100%);
        }
      }

      .size-1-2 {
        @media #{$bp-medium} {
          flex: none;
          flex-basis: auto;
          width: calc((100% - 1 * 2rem) / 2);
        }
      }

      .size-1-3 {
        @media #{$bp-medium} {
          flex: none;
          flex-basis: auto;
          width: calc((100% - 2 * 2rem) / 3);
        }
      }

      .size-1-4 {
        @media #{$bp-medium} {
          flex: none;
          flex-basis: auto;
          width: calc((100% - 3 * 2rem) / 4);
        }
      }
    }
  }
}

.feature-text {
  p {
    margin-top: 7px;

    @media #{$bp-medium} {
      flex-basis: 100%;
      flex-grow: 1;
      margin-top: 0;
    }
  }

  span {
    color: $accent-color;
    font-size: 1.4em;
    line-height: 1.45em;

    @media #{$bp-medium} {
      flex-shrink: 0;
      margin-right: 2rem;
      width: 30%;
    }
  }

  &.right {
    flex-direction: row-reverse;

    span {
      @media #{$bp-medium} {
        margin-left: 2rem;
        margin-right: 0;
      }
    }
  }
  @media #{$bp-medium} {
    display: flex;
    flex-direction: row;
  }

  div {
    @media #{$bp-medium} {
      position: relative;
      top: 7px;
    }
  }
}

.button {
  .big-button {
    width: 100%;
  }
}
