.style-section {
  $spacing-bottom: 4rem;
  $inner-spacing: 4rem;
  $module-spacing-short: 0;
  $module-spacing-medium: 2rem;
  $module-spacing-large: 4rem;

  /* Set Custom Background, if available */
  /* Set Left Gradient to Background Color */
  /* Set Right Gradient to Gradient Color, if available, else same es left, equals solid color */
  background: var(
    --ss-custom-background,
    linear-gradient(
      90deg,
      var(--ss-background-color, transparent) 8%,
      var(--ss-gradient-color, var(--ss-background-color, transparent)) 100%
    )
  );

  background-position: center center;
  background-size: cover;
  color: var(--ss-text-color);
  margin-bottom: $spacing-bottom;

  :not(button) {
    &:not(option) {
      color: var(--ss-text-color);
    }
  }

  li {
    a {
      color: var(--ss-text-color) !important;
    }

    &::marker,
    &::before {
      color: var(--ss-text-color) !important;
    }
  }

  .only-headlines {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: var(--ss-headline-color, $accent-color) !important;
    }
  }

  .feature-text-span {
    color: var(--ss-text-color, $accent-color);
  }

  .quote {
    .slide-selector {
      /* Bewusst kein Fallback, damit bei "keinem Wert"
       * der die Deklaration invalid ist und nichts überschreibt. */
      > div {
        background-color: var(--ss-quote-element-color);
      }
    }
  }

  section:only-child {
    .only-headlines {
      .module-content {
        padding-bottom: 0;
      }
    }
  }

  &.has-background {
    padding-bottom: $inner-spacing;
    padding-top: $inner-spacing;

    + .has-background {
      margin-top: -1 * $spacing-bottom;
    }
  }

  section.content-row {
    background-color: $background-color-transparent;
    overflow: visible;

    &.alternative-color {
      background-color: $background-color-alternate-transparent;

      //no gradients in style-sections with alternative color
      .company-slider {
        .shadow {
          box-shadow: none;
        }
      }

      .medium-tile-grid {
        background-color: initial;

        /*         .overlay-right,
        .overlay-left {
          display: none;
        } */
      }
    }

    &:last-child {
      .module,
      .module-full {
        margin-bottom: 0;
      }
    }
  }

  .form {
    input,
    select,
    textarea {
      color: $text-color-3;
    }

    .checkmark {
      background-color: $area-color-1;
    }

    .upload-label {
      > span {
        color: var(--ss-text-color);
      }
    }

    .checkmark,
    input,
    select,
    textarea,
    .dummy-input {
      background-color: var(--ss-form-input-background-color, #{$area-color-4});
    }
  }

  :is(.richtext-content, .text-bild) {
    li {
      &::before {
        color: var(--ss-text-color);
      }
    }

    a {
      color: var(--ss-link-color);
    }

    li::marker {
      color: var(--ss-text-color);
    }
  }

  .person-wrap {
    .line,
    .name {
      color: var(--ss-text-color);
    }
  }

  .alternative-color {
    margin-bottom: $module-spacing-large;
    padding-top: $module-spacing-large;

    + .alternative-color {
      margin-top: -1 * $module-spacing-large;
    }
  }

  .module,
  .module-full {
    margin-bottom: $module-spacing-large;
  }

  &.short {
    .alternative-color {
      margin-bottom: $module-spacing-short;
      padding-top: $module-spacing-short;

      + .alternative-color {
        margin-top: -1 * $module-spacing-short;
      }
    }

    .module,
    .module-full {
      margin-bottom: $module-spacing-short;
    }
  }

  &.medium {
    .alternative-color {
      margin-bottom: $module-spacing-medium;
      padding-top: $module-spacing-medium;

      + .alternative-color {
        margin-top: -1 * $module-spacing-medium;
      }
    }

    .module,
    .module-full {
      margin-bottom: $module-spacing-medium;
    }
  }

  &.footer-modules {
    $module-spacing-footer: 1rem;
    margin-bottom: 0;

    .alternative-color {
      margin-bottom: $module-spacing-footer;
      padding-top: $module-spacing-footer;

      + .alternative-color {
        margin-top: -1 * $module-spacing-footer;
      }
    }

    .module,
    .module-full {
      margin-bottom: $module-spacing-footer;

      &.button {
        margin-bottom: 1rem !important;
      }
    }
  }

  &.has-video,
  &.has-image {
    position: relative;
  }

  .background-wrapper {
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;

    .video-background,
    .image-background {
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}
