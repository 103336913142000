.image-and-text-fullwidth {
  padding: 4rem 0;
  position: relative;

  .module-content {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  &.reverse .module-content {
    flex-flow: row-reverse nowrap;
  }

  .text-container {
    align-items: center;
    background-color: transparentize($shade-type-2, 0.4);
    border-radius: 5px;
    display: flex;
    flex: 1 0 100%;
    flex-flow: column nowrap;
    padding: 2rem;

    @media #{$bp-medium} {
      flex: 0 0 clamp(400px, 40%, 600px);
    }

    * {
      color: $text-color-1;
    }

    .headline {
      margin-bottom: 1rem;
    }

    .teaser {
      margin: 0 0 2rem;
    }
  }

  .background-wrapper {
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;

    .image-background {
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}
