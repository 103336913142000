small {
  color: $text-color-7;
  font-size: 12px;
}

.headline {
  color: $accent-color;
  font-size: $mtg-headline-size;
  line-height: $mtg-headline-lineheight;
  word-break: break-word;
}

.teaser {
  font-size: $mtg-teaser-size;
  line-height: $mtg-teaser-lineheight;
  word-break: break-word;
}
