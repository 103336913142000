section.content-row {
  .big-tile-grid .content-block {
    background-color: var(--block-color, #{$background-color-alternate});
  }
}

section.alternative-color.content-row {
  .big-tile-grid .content-block {
    background-color: var(--block-color, #{$background-color});
  }
}

.big-tile-grid {
  $gap: 30px;
  $max-width-med: 738px;

  h2 {
    margin-bottom: 1rem;
  }

  p {
    margin: 0 0 2rem;
  }

  .big-tile-grid-wrapper {
    flex-flow: column;
    max-width: $max-width-med;
    width: 100%;

    @media #{$bp-medium} {
      align-items: flex-start;
      display: inline-flex;
      flex-flow: row wrap;
      max-height: 1661px;
      max-width: 800px;
    }

    @media #{$bp-large} {
      max-width: 1140px;
    }
  }

  .teaser-big {
    border-radius: 4px;
    color: $text-color-3;
    overflow: hidden;
    position: relative;

    @media #{$bp-medium} {
      background-color: $accent-color;
    }

    &:hover {
      .content-block {
        top: -30px;
      }

      .label {
        bottom: 12px;
      }

      .read-on {
        bottom: -6px;
      }
    }

    @media #{$bp-medium} {
      flex: 1 auto;
      max-width: calc(50% - 1rem);
      width: 100%;
    }

    .read-on {
      bottom: -37px;
      color: $text-color-1;
      font-size: 35px;
      line-height: 62px;
      position: absolute;
      text-align: center;
      transition: bottom 0.3s;
      width: 100%;
    }

    img {
      display: block;
      width: 100%;
    }

    &.big {
      img {
        width: 100%;
      }
    }

    &.regular {
      img {
        width: 100%;
      }
    }

    &:nth-of-type(even) {
      margin-bottom: 30px;

      @media #{$bp-medium} {
        margin-bottom: 0;
        margin-left: 1rem;
      }
    }

    &:nth-of-type(odd) {
      margin-bottom: 2rem;

      @media #{$bp-medium} {
        margin-right: 1rem;
      }
    }

    &:nth-of-type(1) {
      order: 4;
    }

    &:nth-of-type(2) {
      order: 1;
    }

    &:nth-of-type(3) {
      order: 3;
    }

    &:nth-of-type(4) {
      order: 2;
      top: 0;

      @media #{$bp-medium} {
        top: -130px;
      }
    }

    &:nth-child(n) {
      @media #{$bp-medium} {
        order: 1;
      }
      @media #{$bp-large} {
        order: 1;
      }
    }

    &:nth-child(n + 3) {
      @media #{$bp-large} {
        margin-bottom: 0;
      }
    }
  }

  .labelbox {
    position: relative;
  }

  .content-block {
    height: 404px;
    overflow: hidden;
    padding: 17px 25px 36px;
    position: relative;
    top: 0;
    transition: top 0.3s;

    @media #{$bp-medium} {
      padding: 20px 25px 30px;
    }

    h3 {
      color: $text-color-3;
      font-size: 2rem;
      font-weight: 600;
      line-height: 42px;
      margin: 36px 0 1rem;
      max-width: $max-width-med;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    p {
      font-size: 1.25rem;
      font-size: 1rem;
      height: auto;
      line-height: 1.5;
      margin: 0;
      overflow: hidden;
      padding: 0;

      &.subheader {
        font-weight: 600;
        height: 50px;
        margin: 0 0 1rem;
      }
    }

    _:-ms-lang(x),
    p {
      @media #{$bp-medium} {
        height: 250px;
      }

      @media #{$bp-large} {
        height: 190px;
      }

      @media #{$bp-extra-large} {
        height: 145px;
      }
    }
  }

  .label {
    background-color: $accent-color;
    border-radius: 2px;
    bottom: -15px;
    color: $text-color-1;
    font-size: 0.875rem;
    height: 30px;
    left: 25px;
    line-height: 1.1875rem;
    padding: 5px 15px;
    position: absolute;
    text-transform: uppercase;
    transition: bottom 0.3s;
    z-index: 1;

    &.color-xin {
      background-color: $text-color-4;
    }
  }
}
