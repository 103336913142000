.contact-module {
  flex-wrap: wrap;
  padding: 40px;

  @media #{$bp-large} {
    flex-wrap: nowrap;
    padding: 0;
  }

  .contact-container {
    @extend %variable-transition-duration;
    box-shadow: 4px 4px 15px 0 transparentize($shade-type-1, 0.7);
    transition-property: box-shadow;

    &:hover {
      box-shadow: 4px 4px 20px 0 transparentize($shade-type-1, 0.5);
    }
  }

  &.animated {
    .contact-container {
      opacity: 0;
      transition-property: all;
      transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    }

    &.animate-right {
      .contact-container {
        transform: translateX(100vw);
      }
    }

    &.animate-left {
      .contact-container {
        transform: translateX(-100vw);
      }
    }

    &.animate-fadein {
      .contact-container {
        opacity: 0;
      }
    }

    &.animate-delay-first-to-last {
      @for $i from 1 through 12 {
        /* stylelint-disable-next-line aditayvm/at-rule-no-children */
        &:nth-child(#{$i}) {
          transition-delay: ($i * 100ms);
        }
      }
    }

    &.animate-delay-last-to-first {
      @for $i from 1 through 12 {
        /* stylelint-disable-next-line aditayvm/at-rule-no-children */
        &:nth-last-child(#{$i}) {
          transition-delay: ($i * 100ms);
        }
      }
    }

    &.animation-applied {
      .contact-container {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }

  @import 'contact-v1';
  @import 'contact-v2';
  @import 'contact-v3';
}
