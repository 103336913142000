.code-highlight {
  .line-numbers .line-numbers-rows {
    left: -3.5em;
  }
  /* stylelint-disable  */
  &.dark {
    pre[class*='language-'],
    code[class*='language-'] {
      color: #d4d4d4;
      direction: ltr;
      font-family: Menlo, Monaco, Consolas, 'Andale Mono', 'Ubuntu Mono',
        'Courier New', monospace;
      font-size: 13px;
      hyphens: none;
      hyphens: none;
      hyphens: none;
      hyphens: none;
      line-height: 1.5;
      tab-size: 4;
      tab-size: 4;
      tab-size: 4;
      text-align: left;
      text-shadow: none;
      white-space: pre;
      word-break: normal;
      word-spacing: normal;
    }

    pre[class*='language-']::selection,
    code[class*='language-']::selection,
    pre[class*='language-'] *::selection,
    code[class*='language-'] *::selection {
      background: #75a7ca;
      text-shadow: none;
    }

    @media print {
      pre[class*='language-'],
      code[class*='language-'] {
        text-shadow: none;
      }
    }

    pre[class*='language-'] {
      background: #1e1e1e;
      margin: 0.5em 0;
      overflow: auto;
      padding: 2em;
    }

    :not(pre) > code[class*='language-'] {
      background: #f9f2f4;
      border-radius: 0.3em;
      color: #db4c69;
      padding: 0.1em 0.3em;
    }
    /*********************************************************
    * Tokens
    */
    .namespace {
      opacity: 0.7;
    }

    .token.doctype .token.doctype-tag {
      color: #569cd6;
    }

    .token.doctype .token.name {
      color: #9cdcfe;
    }

    .token.comment,
    .token.prolog {
      color: #6a9955;
    }

    .token.punctuation,
    .language-html .language-css .token.punctuation,
    .language-html .language-javascript .token.punctuation {
      color: #d4d4d4;
    }

    .token.property,
    .token.tag,
    .token.boolean,
    .token.number,
    .token.constant,
    .token.symbol,
    .token.deleted,
    .token.unit {
      color: #b5cea8;
    }

    .token.selector,
    .token.attr-name,
    .token.string,
    .token.char,
    .token.builtin,
    .token.inserted {
      color: #ce9178;
    }

    .language-css .token.string.url {
      text-decoration: underline;
    }

    .token.operator,
    .token.entity {
      color: #d4d4d4;
    }

    .token.operator.arrow {
      color: #569cd6;
    }

    .token.atrule {
      color: #ce9178;
    }

    .token.atrule .token.rule {
      color: #c586c0;
    }

    .token.atrule .token.url {
      color: #9cdcfe;
    }

    .token.atrule .token.url .token.function {
      color: #dcdcaa;
    }

    .token.atrule .token.url .token.punctuation {
      color: #d4d4d4;
    }

    .token.keyword {
      color: #569cd6;
    }

    .token.keyword.module,
    .token.keyword.control-flow {
      color: #c586c0;
    }

    .token.function,
    .token.function .token.maybe-class-name {
      color: #dcdcaa;
    }

    .token.regex {
      color: #d16969;
    }

    .token.important {
      color: #569cd6;
    }

    .token.italic {
      font-style: italic;
    }

    .token.constant {
      color: #9cdcfe;
    }

    .token.class-name,
    .token.maybe-class-name {
      color: #4ec9b0;
    }

    .token.console {
      color: #9cdcfe;
    }

    .token.parameter {
      color: #9cdcfe;
    }

    .token.interpolation {
      color: #9cdcfe;
    }

    .token.punctuation.interpolation-punctuation {
      color: #569cd6;
    }

    .token.boolean {
      color: #569cd6;
    }

    .token.property,
    .token.variable,
    .token.imports .token.maybe-class-name,
    .token.exports .token.maybe-class-name {
      color: #9cdcfe;
    }

    .token.selector {
      color: #d7ba7d;
    }

    .token.escape {
      color: #d7ba7d;
    }

    .token.tag {
      color: #569cd6;
    }

    .token.tag .token.punctuation {
      color: #808080;
    }

    .token.cdata {
      color: #808080;
    }

    .token.attr-name {
      color: #9cdcfe;
    }

    .token.attr-value,
    .token.attr-value .token.punctuation {
      color: #ce9178;
    }

    .token.attr-value .token.punctuation.attr-equals {
      color: #d4d4d4;
    }

    .token.entity {
      color: #569cd6;
    }

    .token.namespace {
      color: #4ec9b0;
    }
    /*********************************************************
  * Language Specific
  */

    pre[class*='language-javascript'],
    code[class*='language-javascript'],
    pre[class*='language-jsx'],
    code[class*='language-jsx'],
    pre[class*='language-typescript'],
    code[class*='language-typescript'],
    pre[class*='language-tsx'],
    code[class*='language-tsx'] {
      color: #9cdcfe;
    }

    pre[class*='language-css'],
    code[class*='language-css'] {
      color: #ce9178;
    }

    pre[class*='language-html'],
    code[class*='language-html'] {
      color: #d4d4d4;
    }

    .language-regex .token.anchor {
      color: #dcdcaa;
    }

    .language-html .token.punctuation {
      color: #808080;
    }
    /*********************************************************
* Line highlighting
*/
    pre[data-line] {
      position: relative;
    }

    pre[class*='language-'] > code[class*='language-'] {
      position: relative;
      z-index: 1;
    }

    .line-highlight {
      background: #f7ebc6;
      box-shadow: inset 5px 0 0 #f7d87c;
      left: 0;
      line-height: inherit;
      margin-top: 1em;
      padding: inherit 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      white-space: pre;
      z-index: 0;
    }
  }

  &.light {
    /**
    * VS theme by Andrew Lock (https://andrewlock.net)
    * Inspired by Visual Studio syntax coloring
    */

    code[class*='language-'],
    pre[class*='language-'] {
      color: #393a34;
      direction: ltr;
      font-family: 'Consolas', 'Bitstream Vera Sans Mono', 'Courier New',
        Courier, monospace;
      font-size: 0.9em;

      hyphens: none;
      hyphens: none;
      hyphens: none;
      hyphens: none;
      line-height: 1.2em;

      tab-size: 4;
      tab-size: 4;
      tab-size: 4;
      text-align: left;
      white-space: pre;
      word-break: normal;
      word-spacing: normal;
    }

    pre > code[class*='language-'] {
      font-size: 1em;
    }

    pre[class*='language-']::selection,
    pre[class*='language-'] ::selection,
    code[class*='language-']::selection,
    code[class*='language-'] ::selection {
      background: #c1def1;
    }

    pre[class*='language-']::selection,
    pre[class*='language-'] ::selection,
    code[class*='language-']::selection,
    code[class*='language-'] ::selection {
      background: #c1def1;
    }

    /* Code blocks */
    pre[class*='language-'] {
      background-color: white;
      border: 1px solid #ddd;
      margin: 0.5em 0;
      overflow: auto;
      padding: 1em;
    }

    /* Inline code */
    :not(pre) > code[class*='language-'] {
      background: #f8f8f8;
      border: 1px solid #ddd;
      padding: 0 0 0 2rem;
      padding-bottom: 1px;
      padding-top: 1px;
    }

    .token.comment,
    .token.prolog,
    .token.doctype,
    .token.cdata {
      color: #008000;
      font-style: italic;
    }

    .token.namespace {
      opacity: 0.7;
    }

    .token.string {
      color: #a31515;
    }

    .token.punctuation,
    .token.operator {
      color: #393a34; /* no highlight */
    }

    .token.url,
    .token.symbol,
    .token.number,
    .token.boolean,
    .token.variable,
    .token.constant,
    .token.inserted {
      color: #36acaa;
    }

    .token.atrule,
    .token.keyword,
    .token.attr-value,
    .language-autohotkey .token.selector,
    .language-json .token.boolean,
    .language-json .token.number,
    code[class*='language-css'] {
      color: #00f;
    }

    .token.function {
      color: #393a34;
    }

    .token.deleted,
    .language-autohotkey .token.tag {
      color: #9a050f;
    }

    .token.selector,
    .language-autohotkey .token.keyword {
      color: #00009f;
    }

    .token.important,
    .token.bold {
      font-weight: bold;
    }

    .token.italic {
      font-style: italic;
    }

    .token.class-name,
    .language-json .token.property {
      color: #2b91af;
    }

    .token.tag,
    .token.selector {
      color: #800000;
    }

    .token.attr-name,
    .token.property,
    .token.regex,
    .token.entity {
      color: #f00;
    }

    .token.directive.tag .tag {
      background: #ff0;
      color: #393a34;
    }

    /* overrides color-values for the Line Numbers plugin
    * http://prismjs.com/plugins/line-numbers/
    */
    .line-numbers .line-numbers-rows {
      border-right-color: #a5a5a5;
    }

    .line-numbers-rows > span::before {
      color: #2b91af;
    }

    /* overrides color-values for the Line Highlight plugin
    * http://prismjs.com/plugins/line-highlight/
    */
    .line-highlight {
      background: rgba(193, 222, 241, 0.2);
      background: linear-gradient(
        to right,
        rgba(193, 222, 241, 0.2) 70%,
        rgba(221, 222, 241, 0)
      );
      background: linear-gradient(
        to right,
        rgba(193, 222, 241, 0.2) 70%,
        rgba(221, 222, 241, 0)
      );
    }
  }

  /* stylelint-enable */
}
