$company: '';

//Font Families
$font-base: 'Montserrat', sans-serif;
$vcard-font: 'Roboto';
//Media Queries

/**
* $bp-mobile nur benutzten, wenn exterene Styles im mobilen überschrieben werden müssen. 
* Sonst Abmahnung. Benutzung bitte mit Kommentar begründen, für Nachvollziehbarkeit. */
$bp-mobile: '(max-width: 575px)';
$bp-tablet: '(max-width: 767px)';

// Standard-Queries
$bp-small: '(min-width: 576px)';
$bp-medium: '(min-width: 768px)';
$bp-large: '(min-width: 992px)';
$bp-extra-large: '(min-width: 1200px)';
$bp-mega-large: '(min-width: 1440px)';
$bp-insanely-large: '(min-width: 2000px)';
$max-width-medium: 720px;
$max-width-large: 960px;
$max-width-xl: 1110px;

// TopNav height
$topnav-height-desktop: 90px;
$topnav-height-mobile: 80px;

//Company Colors
$company-color-ars: rgba(186, 13, 21, 1);
$company-color-cpl: rgba(52, 168, 83, 1);
$company-color-edc: rgba(31, 144, 156, 1);
$company-color-gis: rgba(5, 107, 180, 1);
$company-color-nov: rgba(245, 130, 32, 1);
$company-color-syn: rgb(0, 0, 0);
$company-color-tta: rgba(0, 95, 166, 1);
$company-color-xin: rgba(255, 102, 0, 1);
$company-color-group: rgba(8, 73, 130, 1);
$company-color-ipg: rgba(118, 184, 42, 1);
$company-color-pks: rgba(0, 64, 117, 1);
$company-color-cwx: rgb(242, 148, 0);
$company-color-wca: rgb(242, 148, 0);

//Initial - Color Scheme Template (TTA) ---------------------------------------
$accent-color: rgba(0, 95, 166, 1);
$hover-color: rgba(247, 247, 247, 1);

//Validation Red
$validation-color: rgba(200, 46, 45, 1);

//Profile VCard Call Button
$vcard-call: rgba(0, 156, 54, 1);

//Blog-proposal-form
$valid: rgba(0, 128, 0, 1);
$invalid: rgba(255, 0, 0, 1);
$border-color: rgba(0, 0, 0, 1);
$light-white: rgba(206, 206, 206, 1);
$silver: rgba(191, 191, 191, 1);

//Text Colors
$text-color-1: rgba(255, 255, 255, 1);
$text-color-2: rgba(0, 95, 166, 1);
$text-color-2-dark: rgba(4, 74, 128, 1);
$text-color-2-light: rgba(220, 234, 246, 1);
$text-color-3: rgba(95, 95, 95, 1);
$text-color-4: rgba(255, 102, 0, 1);
$text-color-5: rgba(230, 228, 228, 1);
$text-color-6: rgba(31, 144, 156, 1);
$text-color-7: rgba(43, 43, 43, 1);
$text-color-8: rgba(222, 222, 222, 1);
$text-color-9: rgba(153, 153, 153, 1);
$text-color-10: rgba(0, 0, 0, 1);
$text-color-12: rgba(109, 110, 113, 1);
$text-color-13: rgba(0, 0, 0, 1);

//Custom Icon Colors
$iconcolor-ffffff: rgba(255, 255, 255, 1); //ffffff
$iconcolor-005fa6: rgba(0, 95, 166, 1); //005fa6
$iconcolor-5f5f5f: rgba(95, 95, 95, 1); //5f5f5f
$iconcolor-ff6600: rgba(255, 102, 0, 1); //ff6600
$iconcolor-2b2b2b: rgba(43, 43, 43, 1); //2b2b2b
$iconcolor-1f909c: rgba(31, 144, 156, 1); //1f909c

//External Company Colors
$ext-facebook: rgba(59, 89, 152, 1);
$ext-linkedin: rgba(0, 119, 181, 1);
$ext-twitter: rgba(29, 161, 242, 1);
$ext-youtube: rgba(255, 0, 0, 1);
$ext-xing: rgba(2, 100, 102, 1);
$ext-instagram: rgba(227, 42, 163, 1);

//Shade & Area Colors ------------------------------------
$transparent: rgba(0, 0, 0, 0);
$area-color-1: rgba(255, 255, 255, 1);
$area-color-2: rgba(43, 43, 43, 1);
$area-color-3: rgba(196, 31, 47, 1);
$area-color-4: rgba(219, 220, 221, 1);
$area-color-5: rgba(95, 95, 95, 1);
$area-color-6: rgba(136, 136, 136, 1);
$area-color-7: rgba(4, 74, 128, 1);
$area-color-8: rgba(0, 0, 0, 1);
$area-color-9: rgba(167, 169, 172, 1);
$area-color-10: rgba(0, 95, 166, 1);
$area-color-11: rgba(200, 201, 202, 1);
$area-color-12: rgba(0, 144, 156, 1);
$area-color-13: rgba(245, 130, 32, 1);
$area-color-14: rgba(237, 240, 244, 1);
$area-color-15: rgba(115, 114, 114, 1);
$area-color-16: rgba(225, 242, 243, 1);
$area-color-17: rgba(242, 240, 243, 1);
$area-color-18: rgba(180, 188, 196, 1);
$area-color-19: rgba(235, 228, 229, 1);
$area-color-20: rgba(244, 244, 244, 1);
$area-color-21: rgba(235, 239, 244, 1);

$shade-type-1: rgba(0, 0, 0, 1);
$shade-type-2: rgba(43, 43, 43, 1);
$shade-type-3: rgba(242, 239, 237, 1);
$shade-type-4: rgba(95, 95, 95, 1);
$shade-type-5: rgba(190, 190, 190, 1);
$shade-type-6: rgba(0, 95, 166, 1);
$shade-type-7: $accent-color;

// Baukasten Color-palette
$color-palette-1: rgba(235, 228, 229, 1);
$color-palette-2: rgba(98, 163, 195, 1);
$color-palette-3: rgba(0, 95, 166, 1);
$color-palette-4: rgba(74, 98, 124, 1);
$color-palette-5: rgba(19, 18, 29, 1);

// Image Slider Overlays
$is-simple: rgba(69, 69, 69, 0.3);

//Border Colors
$border-color-1: rgba(230, 231, 232, 1);
$border-color-2: rgba(136, 136, 136, 1);
$border-color-3: rgba(167, 169, 172, 1);

//Background-Colors ------------------------------------
$bg-color-e30613: rgba(227, 6, 19, 1);
$bg-color-000000: rgba(0, 0, 0, 1);
$bg-color-be0002: rgba(190, 0, 2, 1);
$bg-color-f3f3f3: rgba(243, 243, 243, 1);
$bg-color-ffffff: rgba(255, 255, 255, 1);
$bg-color-414042: rgba(65, 64, 66, 1);
$bg-color-6d6e71: rgba(109, 110, 113, 1);
$bg-color-dbdcdd: rgba(219, 220, 221, 1);
$bg-color-c41f2f: rgba(196, 31, 47, 1);
$bg-color-0083ca: rgba(0, 131, 202, 1);
$bg-color-009848: rgba(0, 152, 72, 1);
$bg-color-34a853: rgba(52, 168, 83, 1);
$bg-color-fbbc05: rgba(251, 188, 5, 1);
$bg-color-4285f4: rgba(66, 133, 244, 1);
$bg-color-dceaf6: rgba(220, 234, 246, 1);
$bg-color-006fb7: rgba(0, 111, 183, 1);
$bg-color-005191: rgba(0, 81, 145, 1);
$bg-color-b8b9bb: rgba(184, 185, 187, 1);
$bg-color-edf8f8: rgba(237, 248, 248, 1);
$bg-color-4a525a: rgba(74, 82, 90, 1);
$bg-color-ff3366: rgba(255, 51, 102, 1);
$bg-color-29245c: rgba(41, 36, 92, 1);
$bg-color-e63429: rgba(230, 52, 41, 1);
$bg-color-00a199: rgba(0, 161, 153, 1);
$bg-color-2b2b2b: rgba(43, 43, 43, 1);
$bg-color-008ba8: rgba(0, 139, 168, 1);
$bg-color-8c002a: rgba(140, 0, 42, 1);
$bg-color-cc0025: rgba(204, 0, 37, 1);
$bg-color-ff002e: rgba(255, 0, 46, 1);
$bg-color-133881: rgba(19, 56, 129, 1);
$bg-color-707172: rgba(112, 113, 114, 1);

//Named-Colors ------------------------------------
$headline-color: $text-color-3;
$background-color: $area-color-1;
$background-color-transparent: transparent;
$background-color-alternate: $area-color-11;
$background-color-alternate-transparent: rgba(0, 0, 0, 0.15);
$part-of: rgba(132, 132, 132, 1);
$default-text-color: $text-color-3;

$indicator-inactive-color: rgba(187, 187, 187, 1);
$indicator-active-color: rgba(255, 255, 255, 0.8);

//Metanavigation
$meta-nav-text: $text-color-3;
$meta-nav-background: rgba(240, 240, 240, 1);
$meta-nav-background-dark: $text-color-8;
$meta-nav-arrows: $text-color-8;

$nav-font-color: #2f3944;

//Printcard ---------------------------------
$printcard-orange: rgba(245, 130, 32, 1);
$printcard-blue: rgba(31, 94, 169, 1);
$printcard-dark-blue: rgba(12, 74, 128, 1);
$printcard-turquoise: rgba(0, 144, 156, 1);

$printcard-tta-blue: rgb(0, 110, 184);
$printcard-tta-gray: rgb(135, 134, 134);

//Box-Shadows ---------------------------------
$box-shadow-1: rgba(33, 35, 38, 0.3) 0 3px 7px -3px;
$box-shadow-2: rgba(33, 35, 38, 0.3) 0 3px 7px -3px;
