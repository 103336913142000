.company-slider {
  h4 {
    display: inline-block;
    line-height: 28px;
    margin: 0 0 17px;

    @media #{$bp-medium} {
      line-height: 42px;
      margin-bottom: 0;
    }
  }

  h3 {
    line-height: 21px;
    margin: 1rem 0;

    @media #{$bp-medium} {
      font-size: 24px;
      line-height: 28px;
    }
  }

  .active {
    display: none;
  }

  .container {
    position: relative;
  }

  .item-wrapper {
    margin: 30px 30px 0;
    max-width: calc(100vw - 60px - 48px);
    -ms-overflow-style: none;
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 7px 0 30px;
    scrollbar-width: none;
    white-space: nowrap;
    width: 100%;

    @media #{$bp-large} {
      margin-top: 71px;
      max-width: calc(1110px - 60px);
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .item-container {
    border-bottom: 7px solid transparent;
    display: inline-block;
    margin-right: 15px;
    padding: 0 0 15px;
    position: relative;
    width: auto;

    &.logo-clickable {
      img {
        cursor: pointer;
      }
    }

    picture {
      display: flex;
      justify-content: center;
    }

    img {
      cursor: auto;
      height: auto;
      margin: auto 0;
      max-height: 43px;
      object-fit: scale-down;
    }

    @media #{$bp-medium} {
      // margin: 0 30px;
      margin-right: 30px;
    }

    @media #{$bp-large} {
      margin-right: 96px;
    }

    &:last-child {
      margin-right: 500px;
      @media #{$bp-medium} {
        margin-right: 700px;
      }
      @media #{$bp-large} {
        margin-right: 850px;
      }
      @media #{$bp-extra-large} {
        margin-right: 1000px;
      }

      &.first {
        @media #{$bp-medium} {
          margin-right: 1000px;
        }
      }
    }

    &.first {
      border-color: $shade-type-4;
      @media #{$bp-large} {
        margin-right: 96px;
      }

      .active {
        display: inline-block;
      }

      .inactive {
        display: none;
      }
    }

    &:only-child {
      @media #{$bp-medium} {
        max-height: 322px;
        max-width: 300px;
      }

      img {
        @media #{$bp-medium} {
          height: auto;
          max-height: 45px;
          max-width: calc(
            50vw - 24px
          ); //50vw - padding 24px -> 50% module width
          width: auto;
        }
        @media #{$bp-small} {
          max-width: calc(
            50vw - 39px
          ); //50vw - padding 24px -> 50% module width
        }

        @media #{$bp-medium} {
          max-height: 300px;
          max-width: 300px;
        }
      }
    }
  }

  .description-container {
    position: relative;

    .item {
      display: none;

      h3 {
        margin: 0 0 1rem;
        padding-top: 1rem;
      }

      p {
        margin: 1rem 0 0;
        white-space: normal;
      }

      &.line-visible {
        border-top: 2px solid $area-color-14;
      }

      &.visible {
        display: block;
      }

      .more-button {
        margin: 1rem 0 0;
      }
    }
  }

  .changing-arrow {
    cursor: pointer;
    font-size: 22px;
    padding: 9px 0 0;
    position: absolute;
    z-index: 1;

    &.left {
      left: 0;

      &::before {
        margin-left: -0.3em;
      }
    }

    &.right {
      margin-right: -0.3em;
      right: 0;
    }
  }
}

.company-slider.single-item {
  .item-wrapper {
    margin: 30px 0 0;
    max-width: 100%;
  }

  .changing-arrows {
    display: none;
  }

  .item-container {
    border: 0;
    height: auto;
    justify-content: flex-start;
    width: auto;
    @media #{$bp-medium} {
      max-height: 45px;
      max-height: 300px;
      max-width: 50vw;
      // width: 50%;
    }

    img {
      cursor: auto;
      margin: 0;
      /*  max-height: 45px;
      max-width: 50vw; */

      @media #{$bp-medium} {
        max-height: 300px;
        max-width: 300px;
      }
    }
  }

  .description-container {
    .item {
      display: block;
    }
  }
}

.grid {
  .changing-arrows {
    display: none;
  }

  .item-container {
    border-bottom: 0;
    display: flex;
    height: 40px;
    justify-content: center;
    margin: 0 0 10px;
    padding: 0;
    width: calc(50% - 30px);
    @media #{$bp-medium} {
      display: inline-block;
      flex: 0 1 auto;
      height: auto;
      margin: 0 0 0 20px;
      width: auto;
    }

    &.first {
      margin-right: 0 !important;
    }

    &:first-child {
      margin-right: 0;
    }

    &:hover {
      .active {
        display: inline-block;
      }

      .inactive {
        display: none;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    img {
      height: 100%;
      margin: auto;
      max-height: 43px;
      max-width: 100%;
      width: 100%;
      @media #{$bp-medium} {
        margin: 0;
      }
    }
  }

  .item-wrapper {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0 !important;
    padding: 15px 0 20px;

    @media #{$bp-medium} {
      flex-flow: row nowrap;
      padding-top: 10px;
    }
  }

  .description-container {
    @media #{$bp-large} {
      margin: 0;
    }
  }
}
