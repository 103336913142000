.search-results {
  @extend %hidescrollbar;
  padding-top: 0 !important;

  section.module-content {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;

    .results {
      width: 100%;
    }
  }

  button {
    margin-top: 30px;
    width: auto;
  }

  .result-delimiter {
    position: relative;
    top: -30vh;
  }

  a {
    animation: fadein 300ms ease-in-out normal backwards;
  }
  @keyframes fadein {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }

  .single-dropdown {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 15px;
    width: 100%;
  }
}
