@import 'accordion';
@import 'attribution';
@import 'big-tile-grid';
@import 'blog-details';
@import 'button';
@import 'captcha';
@import 'code-highlight';
@import 'company-slider';
@import 'contact-details';
@import 'contact-form';
@import 'contact-person-and-text';
@import 'contact-form-and-text';
@import 'contact-person';
@import 'contact';
@import 'content-image-slider';
@import 'content-quality';
@import 'copyright';
@import 'counter';
@import 'custom-dropdown';
@import 'detail-page';
@import 'detailpage';
@import 'employee-showcase';
@import 'event-details';
@import 'filter-liste';
@import 'filter';
@import 'footer-company';
@import 'footer';
@import 'form';
@import 'full-image';
@import 'gallery-grid';
@import 'gallery-slider';
@import 'group-services';
@import 'headline';
@import 'hero-slider';
@import 'icon-grid';
@import 'image-and-text-fullwidth';
@import 'image-and-text';
@import 'image-slider-maincontent-simple';
@import 'image-slider-maincontent';
@import 'image-slider-modern';
@import 'image-slider-nextgen';
@import 'image-slider';
@import 'imagevideo';
@import 'lightbox';
@import 'loading';
@import 'logo-slider';
@import 'map';
@import 'meta-head';
@import 'mini-tile-grid';
@import 'modal';
@import 'navbar-underscore';
@import 'new-medium-tile-grid';
@import 'only-headlines';
@import 'onpagenav';
@import 'parallax';
@import 'pricing';
@import 'printcard';
@import 'privacy-notice';
@import 'profile-prefooter';
@import 'profilemenu';
@import 'quote';
@import 'reference-text';
@import 'references';
@import 'richtext';
@import 'search-results';
@import 'seperator';
@import 'slider';
@import 'small-image-tiles';
@import 'split';
@import 'sticky-media-sidebar';
@import 'style-sections';
@import 'table';
@import 'tabs';
@import 'text-and-video';
@import 'text-bild';
@import 'two-column-content';
@import 'two-columns';
@import 'three-column-contact';
@import 'timeline';
@import 'topnavigation';
@import 'totop-button';
@import 'two-column-cards';
@import 'two-column-richtext';
@import 'unordered-list';
@import 'user-reactions';
@import 'vcard';
@import 'language-switcher';
@import 'image-slider-maincontent-simple';
@import 'tagline';
@import 'image-and-text-separator';
@import 'breadcrumbs';
@import 'logo-row';
@import 'blog-form-generics';
@import 'order-vcard';
@import 'printcard-import';
@import 'share-element';
@import 'metanav';
@import 'full-width-grid';
@import 'pks-image-slider';
@import 'rich-text-table';
@import 'image-header-with-icons';

@if $company != 'edc' {
  body {
    @extend %topnavigation;
  }
}
