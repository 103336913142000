.contact-form.form {
  section > h1 {
    color: $text-color-3;
    font-size: 1.75rem;
    font-weight: 500;
    line-height: 37px;
    margin: 0 0 5.5px;

    @media #{$bp-medium} {
      font-size: 3rem;
      line-height: 63px;
    }
  }

  section > h3 {
    display: block;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.625rem;
    margin: 0 0 2rem;
    @media #{$bp-medium} {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }

  span.info-text {
    display: block;
    margin-bottom: 21px;
  }

  .mandatory-fields {
    float: right;
    margin: 0;
  }

  button {
    margin: 0;
    width: 100%;
  }

  .checkbox {
    display: flex;
    margin: 20px 0 21px;
    margin-left: 0;
    overflow: hidden;
    padding: 0;

    .container-text {
      p {
        margin: 0;
      }
    }
  }
}
