.tagline {
  @extend %background-variations;
  display: flex;
  flex-direction: column;
  height: 130px;
  justify-content: center;
  padding: 0;

  @media #{$bp-medium} {
    height: 70px;
  }

  ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;

    @media #{$bp-medium} {
      flex-direction: row;
    }
  }

  li {
    @extend %content-column;
    border-right: 0 solid $text-color-1;
    font-size: 22px;
    margin: 0 auto;
    text-align: center;

    @media #{$bp-medium} {
      margin: 0;
      padding: 0 2rem;
    }

    @media #{$bp-medium} {
      font-size: 28px;
    }
  }

  * {
    &:not(:last-child) {
      li {
        @media #{$bp-medium} {
          border-right-width: 2px;
        }
      }
      @media #{$bp-medium} {
        border-right-width: 2px;
      }
    }
  }
}
