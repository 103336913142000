%topnavigation {
  .main-header {
    @media #{$bp-large} {
      margin-bottom: calc(-1 * var(--topnav-height));
      top: 0 !important;
    }
    transition: transform 300ms ease-out;
    width: 100%;
    z-index: 1000;

    &.meta-nav-hide {
      transform: translateY(calc(var(--meta-nav-height) * -1));
    }
  }

  .topnavigation {
    height: auto;
    transition: height 0.5s;

    @media #{$bp-large} {
      height: $topnav-height-desktop;
    }

    .second-level-detail {
      span {
        font-size: 1.5em;
        line-height: 1.3em;
        margin: 11px 0;
        text-align: left;
        width: 100%;
      }
    }

    * {
      list-style: none;
      padding-inline-start: 0;
      transition: all 100ms ease-in-out, visibility 0ms none;
    }

    &.shrink {
      height: 50px;

      .menu-wrap {
        height: 50px;
      }
    }

    .menu-wrap {
      background-color: $area-color-1;
      height: 90px;
      transition: height 0.5s;
      z-index: 1;
    }

    .collapsed {
      display: none;
      height: 0 !important;
      margin: unset !important;
      overflow: hidden !important;
      padding: unset !important;
    }

    .collapsed-horizontal {
      display: none !important;
      // Flex Stuff:
      flex-basis: 0% !important; // Chrome u. Firefox bug
      flex-grow: 0 !important;
      overflow: hidden !important;
      width: 0 !important;
    }

    .badge {
      &::after {
        background-color: $accent-color;
        border-radius: 16px;
        color: $text-color-1;
        content: attr(data-badge);
        font-size: 10px;
        height: 16px;
        line-height: 16px;
        margin-left: 5px;
        min-width: 16px;
        padding: 0 5px;
        position: absolute;
        text-align: center;
      }

      &.circle::after {
        padding: 0;
      }
    }

    input[name='search'] {
      appearance: none;
      background-color: transparent;
      border: 0;
      outline: none;
      padding: 0;
      width: 100%;
    }

    .search-wrap {
      align-items: space-between;
      align-items: center;
      border: 1px solid transparent;
      border-radius: 40px;
      display: flex;
      flex-direction: row-reverse;
      height: 40px;
      transition: border-colsor 600ms;
      width: 100%;
    }

    .search {
      margin-left: 10px;
      opacity: 0;
      transition: width 80ms, opacity 50ms linear;
      width: 0;
    }

    .search-wrap.is-active {
      border-color: $area-color-11;

      .search {
        opacity: 1;
        transition-delay: 100ms;
        transition-duration: 60ms;
        width: 100%;
      }
    }
  }

  @import 'topnav-desktop';
  @import 'topnav-mobile';
}
