.references {
  overflow: visible;

  .corporations-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: -1rem;
    max-width: 1110px;

    &.three-in-a-row {
      .corporation {
        @media #{$bp-large} {
          width: 25%;
        }
      }
    }
  }

  .corporation {
    height: 133px;
    margin: 0 1rem 1rem 0;
    padding: 0.5rem;
    width: fake-grid-item-width(2, 1rem);

    &:nth-child(even) {
      margin-right: 0;
      @media #{$bp-medium} {
        margin: 0 1rem 1rem 0;
      }
    }

    &:nth-child(3n + 3) {
      @media #{$bp-medium} {
        margin-right: 0;
      }
      @media #{$bp-large} {
        margin: 0 1rem 1rem 0;
      }
    }

    &:nth-child(4n + 4) {
      @media #{$bp-large} {
        margin-right: 0;
      }
    }

    @media #{$bp-medium} {
      height: 200px;
      width: fake-grid-item-width(3, 1rem);
    }

    @media #{$bp-large} {
      height: 224px;
      width: fake-grid-item-width(4, 1rem); // calc(25% - 3rem);
    }

    i.fa-arrow-right {
      display: none;
    }
  }

  .img-normal,
  .img-invert {
    flex-shrink: 0;
    height: auto;
  }

  .img-normal {
    display: block;
  }

  .img-invert {
    display: none;
  }

  .image {
    align-items: center;
    display: flex;
    height: 50%;
    margin-top: 15px;

    @media #{$bp-medium} {
      height: 55px;
      margin-top: 25px;
    }

    @media #{$bp-large} {
      margin-top: 42px;
    }

    img {
      height: auto;
      margin: 0 auto;
      max-height: 100%;
      max-width: 131px;
      width: auto;

      @media #{$bp-medium} {
        max-width: 190px;
      }
    }
  }

  .corp-description {
    @extend %textcolor-variations;
    @include line-clamp(2, 18px);
    align-items: center;
    -webkit-box-pack: center;
    color: $text-color-2;
    flex-basis: 100%;
    font-size: 14px;
    height: 100%;
    justify-content: center;
    line-height: 18px;
    margin: 5px 0 0;
    opacity: 0;
    overflow: hidden;
    text-align: center;

    @media #{$bp-medium} {
      font-size: 16px;
      -webkit-line-clamp: 4;
      line-height: 1.5;
      max-height: (1.5 * 16px * 4);
    }
  }

  .corporation:hover,
  .markactive {
    @extend %only-background-variations;
    background-color: $area-color-4;
    border-radius: 5px;
    // box-shadow: 0 3px 6px transparentize($shade-type-1, 0.7);
    cursor: pointer;
    z-index: 1;

    .corp-description {
      opacity: 1;

      @media #{$bp-medium} {
        justify-content: center;
      }
    }

    .img-normal {
      display: none;
    }

    .img-invert {
      display: block;
    }
  }
}
