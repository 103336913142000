.vcard {
  @extend %module;

  background-color: $area-color-1;
  margin-top: 2rem !important;
  // Overwrite module stype, since vcard butts up against Topnav otherwise

  //navbar muss mit einberechnet werden da wenn kein overflow-x: hidden gesetzt ist keine collision vorhanden ist.
  @media #{$bp-medium} {
    margin-top: calc(#{$topnav-height-desktop} + 2rem) !important;
  }

  .vcard-classic {
    .layout {
      i {
        color: $text-color-1;
      }
      @media #{$bp-medium} {
        display: flex;
        flex-direction: row;
      }
    }
  }

  .layout {
    display: flex;
    flex-flow: column nowrap;
    margin: 0 auto;
    padding: 0;

    @media #{$bp-medium} {
      padding: 85px 0 0;
    }

    @media #{$bp-extra-large} {
      max-width: 540px;
    }
  }

  .vcard-data {
    color: $text-color-3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 24px;

    * {
      color: $text-color-3;

      &.call {
        color: $text-color-1;
        margin: 10px 0 0;
      }

      &.mail-link {
        color: $text-color-2 !important;
      }
    }

    .academic-title,
    .contact-info-line,
    .fullname,
    .job-title,
    button,
    i,
    p {
      color: $text-color-3;

      &.mail-link {
        color: $text-color-2 !important;
      }
    }

    @media #{$bp-medium} {
      margin-top: 4rem;
    }

    @media #{$bp-large} {
      padding: 0 0 0 20px;
      width: 50%;
    }
  }

  .vcard-content {
    @media #{$bp-medium} {
      display: flex;
      font-size: 1rem;
      line-height: 0;
    }
  }

  .academic-title {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.75rem;

    @media #{$bp-large} {
      font-size: 32px;
    }

    @media #{$bp-extra-large} {
      font-size: 1rem;
    }
  }

  .fullname {
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 37px;
    margin: 5px 0 0;
    margin-bottom: 1rem;

    @media #{$bp-medium} {
      font-size: 2.25rem;
      line-height: 3rem;
      margin-bottom: 0.5rem;
    }

    @media #{$bp-extra-large} {
      font-size: 3rem;
      line-height: 2.5rem;
    }
  }

  .job-title {
    display: block;
    font-weight: 700;
    line-height: 1.6rem;
    margin-bottom: 20px;
    @media #{$bp-medium} {
      font-size: 1.125rem;
      margin-bottom: 65px;
    }

    @media #{$bp-large} {
      margin-bottom: 160px;
    }

    @media #{$bp-extra-large} {
      font-size: 1.5rem;
      line-height: 2rem;
      margin-bottom: 65px;
    }
  }

  .contact-info {
    line-height: 1.5rem;

    @media #{$bp-extra-large} {
      font-size: 1.5rem;
      line-height: 1.875rem;
    }
  }

  .vbottom-container {
    display: flex;
    flex-direction: column;
    padding: 0 24px;
    @media #{$bp-medium} {
      margin: 20px 0 0;
      padding: 0;
    }
  }

  .download-text {
    margin-top: 20px;
    @media #{$bp-medium} {
      margin: 0 0 10px;
      margin-top: 0;
    }
  }

  .vbutton-container {
    a {
      align-items: center;
      display: flex;
      height: auto;
      justify-content: center;
      margin-bottom: 0;
      width: 100%;

      @media #{$bp-medium} {
        font-size: 1rem;

        margin: 0;
        padding: 0;
      }

      @media #{$bp-large} {
        width: 48%;
      }
    }

    @media #{$bp-medium} {
      display: flex;
      justify-content: space-between;
      padding: 0;
    }
  }

  button.download-vcard {
    @include button($accent-color);
    align-items: center;
    color: $text-color-1;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-weight: 600;
    height: 3rem;

    i {
      margin: 0 10px 0 0;

      &::before {
        font-size: 30px;
        font-weight: 400;
      }
    }

    &:first-child {
      margin: 10px 0 0;
      @media #{$bp-medium} {
        margin: 0;
      }
    }

    @media #{$bp-medium} {
      height: 50px;
    }
    @media #{$bp-large} {
      height: 60px;
      width: 100%;
    }
  }

  .contact-info-line {
    display: block;
    width: 100%;

    @media #{$bp-medium} {
      margin-bottom: 0;
    }
    @media #{$bp-large} {
      margin-bottom: 0;
    }
    @media #{$bp-extra-large} {
      margin-bottom: 15px;
    }
  }

  .v-card-info {
    display: none;
    @media #{$bp-extra-large} {
      display: block;
      font-size: 1.5rem;
      line-height: 1.875rem;
    }
  }

  button {
    @include button();
    display: flex;
    font-size: 0.8rem;
    height: 3rem;
    justify-content: center;
    width: 100%;

    &.call {
      @include button($vcard-call);
      align-items: center;
      color: $text-color-1;
      display: flex;
      flex-direction: row;
      height: 3rem;
      @media #{$bp-medium} {
        display: none;
      }
    }

    i {
      color: $text-color-1;
      font-size: 30px;
    }

    @media #{$bp-medium} {
      font-size: 1rem;
      height: 80px;
    }
  }

  .call {
    background-color: $vcard-call;

    @media #{$bp-medium} {
      display: none;
    }
  }

  .social-media {
    margin: 10px 0;
    max-width: 1110px;
    text-align: left;

    i::before {
      color: $text-color-3;
      cursor: pointer;
      font-size: 30px;
    }

    @media #{$bp-medium} {
      margin: 20px 0;
    }

    @media #{$bp-extra-large} {
      margin: 0 0 1rem;
      max-width: 1110px;
      width: 100%;
    }
  }

  .wimg {
    padding: 0;
    @media #{$bp-extra-large} {
      margin: 0 auto;
      max-width: $max-width-xl;
    }
  }

  .image-container {
    display: block;
    margin-bottom: 24px;

    img {
      display: block;
      height: auto;
      width: 100%;

      @media #{$bp-medium} {
        margin-right: 20px;
        width: 330px;
      }

      @media #{$bp-large} {
        width: 454px;
      }

      @media #{$bp-extra-large} {
        width: 400px;
      }
    }

    @media #{$bp-medium} {
      display: flex;
      flex-direction: column;
      justify-content: end;
      margin-bottom: 0;
      margin-top: 4rem;
      width: 50%;
    }
  }
}
