.like-ul {
  align-items: baseline;
  display: flex;
  font-size: 24px;
  justify-content: flex-end;
  line-height: 1.5;
  list-style: none;
  width: 100%;

  .like-nr {
    color: $text-color-3;
    margin: 0 1rem;
    opacity: 0;
    transition: opacity 1s;
  }

  i {
    cursor: pointer;
  }

  .liked {
    color: $accent-color;
    cursor: default;
  }

  > i:active:not(.liked) {
    filter: brightness(1.1);
    transform: scale(1.1);
  }
}
