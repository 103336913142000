@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

section.content-row .tabs {
  padding-left: 0;
  padding-right: 0;
}

.tabs {
  .img-container {
    align-items: flex-end;
    display: grid;
    margin: 0 auto;

    picture {
      align-content: flex-end;
      display: grid;
      justify-content: center;
      justify-items: center;
      padding: 0 0.5rem;
      width: 100%;

      img {
        max-height: 100%;
        max-width: 100%;
      }
    }

    .icon {
      --icon-size: 100px;
      display: flex;
      font-size: var(--icon-size);
      justify-content: center;
      line-height: var(--icon-size);
      max-height: var(--icon-size);
      padding: 0 0.5rem;
    }
  }

  .small {
    .img-container {
      picture img {
        max-height: 100px;
      }
    }

    .icon {
      --icon-size: 100px;
    }
  }

  .medium {
    .img-container {
      picture img {
        max-height: 200px;
      }
    }

    .icon {
      --icon-size: 150px;
    }
  }

  .large {
    .img-container {
      picture img {
        max-height: 300px;
      }
    }

    .icon {
      --icon-size: 200px;
    }
  }

  .header-mobile {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 24px 2rem;

    @media #{$bp-small} {
      padding: 0 39px 2rem;
    }

    @media #{$bp-medium} {
      padding: 0 0 2rem;
    }

    @media #{$bp-large} {
      display: none;
    }

    .img-container-mobile {
      display: none;

      &.active {
        display: block;
      }
    }
  }

  .hidden {
    opacity: 0;
  }

  .headers {
    display: grid;
    justify-items: center;
    position: relative;

    // .header-background {
    //   background-color: $shade-type-3;
    //   height: calc(100% - 4px);
    //   margin-top: 4px;
    //   min-width: 1110px;
    //   position: absolute;
    //   z-index: -1;
    // }

    .headers-content {
      align-items: stretch;
      // background: $area-color-14;
      display: none;
      flex-flow: row wrap;
      justify-content: center;
      justify-content: flex-start;
      margin: 0 0 2rem;
      overflow: hidden;

      width: 100%;

      @media #{$bp-large} {
        box-sizing: content-box;
        display: grid;
        flex-wrap: nowrap;
        grid-auto-columns: 1fr;
        grid-auto-flow: column;
        grid-template-rows: 1fr;
        padding: 1rem;
        position: relative;
        row-gap: 10px;
      }
    }

    .arrow {
      align-content: center;
      background: hsla(0, 0%, 100%, 0.5);
      cursor: pointer;
      display: flex;
      flex-direction: column;
      grid-row: 2 / span 1;
      height: calc(100%);
      justify-content: center;
      margin-top: 2px;
      position: absolute;
      text-align: center;
      top: 0;
      width: 1rem;
      z-index: 10;

      &.left {
        left: 0;
      }

      &.right {
        right: 0;
      }
    }

    .img-container-desktop {
      grid-column: span 1;
      grid-row: 1 / span 1;
    }

    button {
      align-items: center;
      background: none;
      background: $area-color-14;
      border: 0;
      border-top: 4px solid $background-color;
      cursor: pointer;
      display: flex;
      flex-flow: column nowrap;
      flex-grow: 1;
      grid-row: 2 / span 1;
      justify-content: center;
      margin: 0;
      outline: 0;
      padding: 18px 29px 19px;

      span {
        display: inline;
        font-size: 15px;
        text-align: center;
      }

      &.active {
        background-color: $background-color;
        border-left: 1px solid $area-color-14;
        border-radius: 4px 4px 0 0;
        border-right: 1px solid $area-color-14;
        border-top: 4px solid $accent-color;
        padding-left: 20px;
        padding-right: 20px;

        span {
          font-weight: bold;
        }
      }
      // &.active {
      //   background-color: $background-color;
      //   border: 1px solid $background-color-alternate;
      //   border-top: 2px solid $accent-color;
      //   padding: 18px 19px 19px;
      // }
    }
  }

  .body {
    position: relative;
    width: 100%;

    > div {
      display: none;
      height: 100%;
      left: 0;
      top: 0;
      width: 100%;

      &.active {
        animation: 150ms fadein;
        display: block;
        opacity: 1;
      }
    }
  }
}
