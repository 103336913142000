.contact-details {
  display: flex;
  justify-content: center;

  ul {
    display: block;
    font-size: 20px;
    justify-content: center;
    list-style: none;
    padding: 0;

    @media #{$bp-medium} {
      display: flex;
    }

    li {
      flex-shrink: 0;
      margin: 0 1rem;
      padding-bottom: 10px;
      padding-top: 10px;

      &::before {
        display: none;
      }

      i {
        color: $accent-color;
        margin-right: 10px;
      }

      a {
        white-space: nowrap;

        &:hover {
          color: $accent-color;
        }
      }

      &.locations {
        flex-shrink: 1;
      }
    }
  }
}
