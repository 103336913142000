body ~ .printcard-container {
  background: $area-color-16;
}

.printcard-container {
  @import '../../../styles/base/variables_edc';

  &.edc {
    * {
      font-family: $vcard-font;
      font-weight: 100;
    }

    .highlight {
      &.left,
      &.right {
        .top {
          background-color: $printcard-turquoise;
          height: 354px;
          width: 65px;
        }

        .bottom {
          background-color: $printcard-gray;
          height: 355px;
          width: 65px;
        }
      }
    }

    .content-container {
      .company-logo {
        height: 94px;
        right: 53px;
        text-align: right;
        top: 73px;
        width: 226px;

        > img {
          width: 100%;
        }
      }

      .person,
      .company {
        color: $printcard-turquoise;
        font-size: 34px;
        font-weight: 600;
        line-height: 40px;
      }

      .title {
        font-size: 30px;
        font-weight: 400;
        line-height: 40px;
      }

      .place,
      .email,
      .telephone {
        color: $text-color-13;
        font-size: 30px;
        font-weight: 400;
        line-height: 38px;
      }

      .contact-details {
        height: 114px;
        margin: 19px 0 31px 57px;
      }
    }

    .backside-container {
      background-color: $printcard-turquoise;

      .company-details {
        display: flex;
        height: 250px;
        justify-content: flex-start;

        .left {
          // justify-content: flex-start;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          width: 275px;
        }

        .right {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          width: 300px;
        }
      }
    }
  }
}
