.timeline-module {
  $center-width: 12rem;
  $mobile-spacing-left: 5vw;
  $mobile-center-width: 40vw;

  $spacing-top: 0;

  %hover-transition {
    transition: all 200ms ease-in-out;
  }

  .timeline-wrapper {
    opacity: 0;
    transition: opacity 400ms ease-out;

    &.visible {
      opacity: 1;
    }
  }

  * {
    @extend %hover-transition;
  }

  @import './timeline/center-elements';
  @import './timeline/content-block';
  @import './timeline/row-block';
  @import './timeline/coloring';
}
