.contact-person {
  @extend %module;

  .person {
    margin: 0 0 1rem;

    @media #{$bp-medium} {
      margin: 0 0 2rem;
    }

    &:nth-child(2n + 1) {
      @media #{$bp-medium} {
        margin-right: 1rem;
      }
    }
  }
}
