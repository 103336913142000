.printcard-container {
  @import '../../../styles/base/variables_ars';

  &.ars {
    * {
      font-family: 'Raleway', sans-serif;
      font-weight: 100;
    }

    .content-container {
      .company-logo {
        height: 62px;
        right: 54px;
        text-align: right;
        top: 60px;
        width: 220px;
      }
    }

    .highlight {
      &.left,
      &.right {
        .top {
          background-color: $printcard-red;
        }

        .bottom {
          background-color: $printcard-gray;
        }
      }
    }

    .person,
    .company {
      color: $printcard-red;
      font-size: 34px;
      font-weight: 200;
    }

    .title,
    .place,
    .email,
    .telephone {
      font-size: 30px;
      line-height: 40px;
    }

    .company {
      font-size: 30px;
      line-height: 38px;
    }

    .title {
      font-size: 30px;
      line-height: 40px;
    }

    .contact-details {
      height: 114px;
      margin: 21px 0 31px 57px;
    }

    .backside-container {
      background-color: $printcard-red;

      .company-details {
        margin: 0 128px;

        .column {
          width: 550px;
        }

        .left {
          width: 550px;
        }

        .right {
          width: 0;
        }
      }

      .icon {
        margin-right: 18px;
      }
    }
  }
}
