@mixin cell-height($height) {
  .grouped,
  .info-grouped,
  .description-element,
  .info-element {
    height: $height;
  }

  .info-row {
    height: $height;
  }
}

@mixin cell-cropping($linenumber, $height) {
  .grouped,
  .info-element,
  .description-element,
  .info-grouped {
    > span {
      @include line-clamp($linenumber, $height);
    }
  }
}

.table {
  @include cell-height($height: 63px);
  @include cell-cropping($linenumber: 2, $height: 63px);

  &.medium-cells {
    @include cell-height($height: 100px);
    @include cell-cropping($linenumber: 3, $height: 100px);
  }

  &.big-cells {
    @include cell-height($height: 130px);
    @include cell-cropping($linenumber: 5, $height: 130px);
  }

  .site-layout {
    // @media #{$bp-extra-large} {
    //   margin: 0 auto;
    //   max-width: $max-width-xl;
    // }
    @extend %content-column;
    margin: 0 auto;

    @media #{$bp-medium} {
      flex-flow: row nowrap;
      // max-width: 100vw;
    }
  }

  .table-wrapper {
    background-color: $area-color-1;
    display: flex;
    width: 100%;

    section {
      margin: 0 0 5px;
    }

    .header-element {
      @extend %vertical-center;
      @extend %background-variations;

      background-color: $accent-color;
      height: 110px;
      margin: 0 0 5px 5px;
      min-width: 100%;
      padding: 5px 15px 5px 5px;
      text-align: center;

      > span {
        @include line-clamp(4, 110px);
        color: $text-color-1;
      }

      @media #{$bp-medium} {
        min-width: 110px;
        padding: 5px;
      }

      @media #{$bp-extra-large} {
        padding: 5px 20px;
      }
    }

    .special-header {
      background-color: $area-color-9;
      border-radius: 0 8px 0 0;
    }

    .sticky-leftcolumn {
      box-shadow: 7px 0 7px -3px $shade-type-5;
      min-width: 60%;
      position: relative;
      width: 40%;
      z-index: 2;

      @media #{$bp-medium} {
        min-width: 200px;
      }
    }

    .scrollable-rightcolumn {
      display: flex;
      min-width: 40%;
      overflow: hidden;
      scroll-snap-type: x mandatory;
      width: 80%;

      @media #{$bp-medium} {
        min-width: 0;
      }

      div {
        overflow: hidden;
        scroll-snap-align: start;

        @media #{$bp-medium} {
          overflow: visible;
        }
      }
    }

    .scrollable-rightcolumn.bigtable {
      overflow-x: scroll;

      @media #{$bp-large} {
        overflow-x: auto;
      }
    }

    .info-column {
      background-color: $area-color-1;
      flex: 0 1 100%;
      min-width: 100%;
      transition: transform 0.5s;

      @media #{$bp-medium} {
        min-width: 0;
        transition: 0;
      }
    }

    .grouped,
    .info-grouped,
    .description-element {
      display: flex;
      flex-direction: column;
      font-weight: normal;
      justify-content: center;
      line-height: 24px;

      > span {
        padding: 0 15px;
      }
    }

    .description-element {
      background-color: $area-color-1;
      border-bottom: 1px solid $border-color-1;
      color: $text-color-3;
    }

    .grouped {
      @extend %background-variations;
      background-color: $area-color-9;

      > span {
        color: $text-color-1;
      }
    }

    .header {
      color: $text-color-1;
      height: 110px;
      margin: 0 0 5px;
    }

    .sticky-header {
      align-items: center;
      background-color: $area-color-5;
      border-radius: 8px 0 0;
      display: flex;
      font-weight: bold;
      justify-content: center;
      padding: 0 15px;

      > span {
        @include line-clamp(4, 110px);
        color: $text-color-1;
      }
    }

    .info-row {
      display: flex;
      width: 100%;
    }

    .info-grouped {
      @extend %background-variations;
      background-color: $area-color-9;
      color: $text-color-1;
      min-width: 110px;
      overflow: hidden;
      width: 100%;

      @media #{$bp-medium} {
        position: relative;
      }

      > span {
        color: $text-color-1;

        @media #{$bp-medium} {
          left: 0;
          padding: 0 10px;
          position: absolute;
          text-align: center;
          width: calc(#{$max-width-medium} - 240px);
          z-index: 20;
        }

        @media #{$bp-large} {
          width: calc(#{$max-width-large} - 320px);
        }

        @media #{$bp-extra-large} {
          width: calc(#{$max-width-xl} - 370px);
        }
      }
    }

    .text-added {
      line-height: 24px;
      @media #{$bp-small} {
        color: $text-color-1;
        line-height: 24px;
        position: absolute;
        width: 600px;
      }
    }

    .info-element {
      @extend %vertical-center;
      align-items: center;
      border-bottom: 1px solid $border-color-1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      // max-width: 190px;
      // min-width: 110px;
      padding: 0 13px;
      text-align: center;
      width: 100%;

      > span {
        width: 100%;
      }
    }

    .icon {
      border-radius: 20px;
      color: $text-color-3;
      display: flex;
      flex-direction: column;
      font-size: 25.5px;
      justify-content: center;
      // &::before {
      //   margin: 0.2em;
      // }
    }

    .icon-check {
      background-color: $accent-color;
      color: $text-color-1;
    }

    .icon-cancel-close {
      background-color: $area-color-3;
      color: $text-color-1;

      // &::before {
      //   margin-left: 0.4rem;
      //   margin-right: 0.2rem;
      // }
    }

    .icon-check,
    .icon-cancel-close {
      &::before {
        display: flex;
        flex-direction: column;
        height: 40px;
        justify-content: center;
        margin: 0;
        width: 40px;
      }
    }
  }

  .slide-arrows {
    display: flex;
    justify-content: flex-end;
    margin: 30px 11px 15px 0;

    @media #{$bp-medium} {
      display: none;
    }
  }

  .changing-arrow {
    background-color: $area-color-14;
    border-radius: 4px;
    cursor: pointer;
    font-size: 35px;
    height: 50px;
    margin: 0 0 0 10px;
    width: 50px;

    &::before {
      margin: 0.2em;
    }
  }
}
