/* stylelint-disable selector-class-pattern */
.hero-slider {
  $sidebar-width: 55px;
  //Fallback height, do not remove
  height: calc(100vh - var(--topnav-height));
  //Module height = inner window height - navigation height
  height: calc(var(--vh, 1vh) * 100 - var(--topnav-height));
  position: relative;

  @media #{$bp-large} {
    height: calc(100vh - var(--topnav-height));
  }

  &.module-full {
    // margin: var(--topnav-height) auto 4rem;
    margin: 0 auto 4rem;

    // @media #{$bp-large} {
    // margin: 0 auto 4rem;

    // }
  }

  .hidden {
    display: none;
  }

  .hero-row {
    display: flex;
    height: 100%;
    width: 100vw;
  }

  .pagination,
  .right-bar {
    display: none;

    @media #{$bp-large} {
      background-color: $area-color-5;
      background-position: center;
      display: flex;
      min-width: $sidebar-width;
      width: $sidebar-width;
    }
  }

  .right-bar {
    display: flex;
    flex-direction: column;
  }

  .pagination {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .count {
      margin: 10px 0;
      padding-top: 4px;
      transform: rotate(-90deg);
    }

    .bar {
      background: $area-color-13;
      display: flex;
      flex-direction: column;
      height: 200px;
      justify-content: flex-end;
      margin: 0;
      width: 4px;

      .status {
        background: $area-color-12;
        height: 33.3%;
        transition: height 0.4s ease;
        width: 100%;
      }
    }

    .wrapper {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 300px;
    }
  }

  .socials,
  .contacts {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    justify-content: space-around;
    width: 100%;

    .social-icons {
      align-items: center;
      display: flex;
      height: 50px;
      justify-content: center;
      width: 50px;

      i {
        color: $text-color-1;
        transition: all 0.3s ease;
      }

      &:hover {
        i {
          color: $accent-color;
        }
      }
    }
  }

  .contacts {
    background-color: $area-color-2;
  }

  .main-column {
    background: $accent-color;
    display: flex;
    flex-grow: 2;
    position: relative;

    ul {
      margin: 0;
    }

    .hero-slide {
      align-items: center;
      background-image: url('https://www.spektrum.de/fm/912/thumbnails/Meer_EpicStockMedia_Fotolia_61981641.jpg.2646799.jpg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      height: calc(100% - 160px);
      width: 100%;
    }

    .overlay {
      margin: 100px 17px 0 20px;

      .headline {
        @include line-clamp(3, 40px);
        color: $text-color-1;
        font-weight: 350;

        @media #{$bp-medium} {
          @include line-clamp(4, 58px);
        }
        @media #{$bp-extra-large} {
          @include line-clamp(5, 58px);
        }
      }

      .subheadline {
        @include line-clamp(2, 56px);
        color: $text-color-1;
        margin-bottom: 30px;

        @media #{$bp-medium} {
          @include line-clamp(4, 140px);
        }
      }

      .cta-button {
        background-color: $accent-color;
        border: 0;
        border-radius: 5px;
        color: $text-color-1;
        cursor: pointer;
        font-size: 16px;
        padding: 16px 48px;
        transition: all 0.4s ease;

        &:hover {
          background-color: $area-color-15;
        }
      }

      @media #{$bp-medium} {
        margin: 100px 0 0 20px;
      }
    }
  }

  .navigation-backdrop {
    background: $area-color-8;
    bottom: 0;
    height: 160px;
    position: absolute;
    width: 100%;
    z-index: 1;
  }

  .navigation-active-backdrop {
    bottom: 0;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 1;

    .background {
      background: $accent-color;
      height: 180px;
      width: 100%;
    }

    .navigator {
      display: flex;
      height: 48px;

      .nav-btn {
        background: $accent-color;
        border: 0;
        border-bottom: 1px solid $area-color-14;
        border-right: 1px solid $area-color-14;
        cursor: pointer;
        height: 100%;
        transition: background-color 0.4s ease;
        width: 50%;

        &:hover {
          background: $area-color-15;
        }

        &:last-child {
          border-right: 0;
        }

        @media #{$bp-medium} {
          width: 80px;
        }
      }
    }

    @media #{$bp-small} {
      width: 50%;
    }

    @media #{$bp-medium} {
      width: calc(33% - 7px);
    }
    @media #{$bp-small} {
      width: 50%;
    }

    @media #{$bp-medium} {
      width: calc(33% - 7px);
    }
  }

  .hero-slides-wrapper {
    height: 100%;
    position: absolute;
    width: 100%;

    .glide {
      height: 100%;
    }

    .glide__track {
      height: 100%;
    }

    .glide__slides {
      height: 100%;
      transform: translate3d(0, 0, 0) !important;
      width: 100vw !important;
    }

    .glide__slide {
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      transition: opacity 0.4s ease;
      user-select: text;
      z-index: 0;

      &.active {
        opacity: 1;
        z-index: 1;
      }
    }
  }

  .navigation {
    bottom: 0;
    position: absolute;
    width: 100%;
    z-index: 2;

    .glide__slides {
      align-items: flex-end;
      overflow-y: visible;
    }

    .glide__slide {
      position: relative;
    }

    ul {
      margin: 0;
    }

    .active-tile {
      height: 160px;

      .navigator {
        display: flex;
      }
    }

    .tile {
      height: 160px;
      padding: 20px 0 0 28px;
      transition: all 0.2s ease;

      .tile-header {
        color: $text-color-3;
      }
    }
  }
}
