.style-element-container {
  $container-width: $center-width - 2rem;
  height: 2.5rem;
  left: 0;
  position: absolute;
  top: 2rem;
  transform: translateY(-50%) translateX(calc(-50% + #{$mobile-spacing-left}));
  width: $mobile-center-width;

  @media #{$bp-medium} {
    top: 4.5rem;
    transform: translateY(-50%) translateX(-50%);
    width: $container-width;
  }
}

.left .style-element-container {
  @media #{$bp-medium} {
    left: auto;
    right: 0;
    transform: translateY(-50%) translateX(50%);
  }
}

.w-icon .style-element-container {
  height: 3rem;
}

.style-element-wrapper {
  height: 100%;
  position: relative;
  width: 100%;
}

.circle {
  $wh: 40px;
  background-color: $area-color-1;
  border: 2px solid $border-color-3;
  border-radius: 50%;
  height: $wh;
  left: 50%;
  position: absolute;
  transform: translate(calc(-50% + 1px));
  width: $wh;

  &.has-colored-center {
    .colored-center {
      height: 100%;
      position: relative;
      width: 100%;

      &::after {
        $padding: 5px;
        @extend %hover-transition;
        background-color: $accent-color;
        border-radius: 50%;
        bottom: $padding;
        content: '';
        left: $padding;
        position: absolute;
        right: $padding;
        top: $padding;
        transform: scale(0);
        transform-origin: center;
      }
    }
  }

  &.icon {
    $wh: 3rem;
    background-position: center;
    background-size: cover;
    height: $wh;
    width: $wh;
  }

  &.offset {
    $wh: 20px;
    height: $wh;
    left: auto;
    right: 0;
    top: 50%;
    transform: translateY(calc(-50%));
    width: $wh;

    .colored-center {
      height: 100%;
      position: relative;
      width: 100%;

      &::after {
        $padding: 3px;
        bottom: $padding;
        left: $padding;
        right: $padding;
        top: $padding;
      }
    }
  }
}

.center-line {
  height: 100%;
  left: $mobile-spacing-left;
  position: absolute;
  width: 2px;
  @media #{$bp-medium} {
    left: 50%;
  }
}

.horizontal-line {
  background-color: $border-color-3;
  height: 2px;
  left: 50%;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

%row-block-footer-overrides-center-elements {
  .style-element-container {
    $wh: 4rem;
    $padding: 2rem;
    height: $wh + $padding;
    padding-top: $padding;
    right: auto;
    top: auto;
    transform: translateX(calc(-50% + #{$mobile-spacing-left}));

    @media #{$bp-medium} {
      height: $wh;
      margin: auto;
      padding: 0;
      position: relative;
      transform: none;
    }

    .horizontal-line,
    .circle.offset {
      @media #{$bp-medium} {
        display: none;
      }
    }
  }

  .center-line {
    height: 4rem;

    @media #{$bp-medium} {
      display: none;
    }
  }
}

%row-block-head {
  .center-line {
    background-color: transparent;
    background-image: linear-gradient(45deg, $border-color-3, transparent);
  }
}

%row-block-footer-empty {
  .center-line {
    background-color: transparent;
    background-image: linear-gradient(135deg, $border-color-3, transparent);
  }
}
