.content-row:first-child {
  > .share-element {
    margin-top: calc(2rem + #{$topnav-height-mobile});

    @media #{$bp-large} {
      margin-top: calc(2rem + #{$topnav-height-desktop});
    }
  }
}

.share-element {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .hidden {
    display: none;
  }

  .desktop {
    border-bottom: 1px solid $text-color-13;
    border-top: 1px solid $text-color-13;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    max-width: max-content;
    padding: 10px 20px;

    i {
      cursor: pointer;
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        &.fa-facebook-f {
          &::before {
            color: $ext-facebook;
          }
        }

        &.fa-linkedin-in {
          &::before {
            color: $ext-linkedin;
          }
        }

        &.fa-twitter {
          &::before {
            color: $ext-twitter;
          }
        }

        &.fa-link {
          &::before {
            color: $accent-color;
          }
        }
      }

      &:active {
        &.fa-facebook-f {
          transform: scale(1.1);
        }

        &.fa-linkedin-in {
          transform: scale(1.1);
        }

        &.fa-twitter {
          transform: scale(1.1);
        }

        &.fa-link {
          transform: scale(1.1);
        }
      }
    }
  }

  .mobile {
    // @include button-block();
    @include button-outline-block($text-color-3);
    display: flex;
    flex-direction: row;
    width: max-content;

    i {
      color: $text-color-3;
      font-weight: 900;
      margin-left: 1rem;
      margin-top: 1px;
    }

    &:hover {
      i {
        color: $text-color-1;
      }
    }
  }
}
