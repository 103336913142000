.date-flag {
  .month {
    align-items: center;
    background-color: $accent-color;
    border-radius: 2px 2px 0 0;
    color: $text-color-1;
    display: flex;
    font-size: 16px;
    font-weight: 400;
    height: $flag-height-out;
    justify-content: center;
    text-transform: uppercase;
  }

  .day {
    align-items: center;
    background-color: $area-color-1;
    border-radius: 0 0 2px 2px;
    color: $accent-color;
    display: flex;
    font-size: 28px;
    font-weight: 500;
    height: $flag-height-in;
    justify-content: center;
  }
}

.company-marker {
  background-color: $area-color-1;
  border-radius: 2px;
  color: $accent-color;
  display: grid;
  font-size: 12px;
  font-weight: 600;
  height: 28px;
  padding: 4px 6px;
  place-content: center;
}

.author-image {
  border-radius: 4px;
  height: 4rem;
  width: 4rem;
}

.head-section {
  display: grid;
  grid-template-areas:
    'flag x y'
    'flag x company';
  grid-template-columns: 4rem 1fr auto;
  grid-template-rows: $flag-height-out $flag-height-in;
  padding: 0 $meta-spacing-right 0 1rem;
  position: relative;
  top: -$flag-height-out;

  .date-flag {
    grid-area: flag;
  }

  .company-marker {
    align-self: end;
    grid-area: company;
  }
}

.meta-section {
  /*
  // RKW-1873: Einheitliche Darstellung von Medium-Tile-Grid-Items
  // content-type (Label) wurde versteckt wenn author-image vorhanden ist
  .author-image ~ .content-type {
    display: none !important;
  }
  */
  .author-image ~ .author-name {
    grid-row: 3 !important;
  }

  .author-name ~ .publish-date {
    grid-column: 1 !important;
  }

  .author-name ~ .content-type ~ .publish-date {
    grid-row: 3 !important;
    place-self: end !important;
  }
}

.alt-meta-section {
  display: none;
}

@mixin s-card-generics() {
  .content-type {
    background-color: $accent-color;
    border-radius: 2px;
    color: $text-color-1;
    display: grid;
    font-size: 14px;
    line-height: 18px;
    padding: 7px 8px;
    place-content: center;
  }
}

@mixin mtg-text-clamper($clamp-headline, $clamp-teaser) {
  .text-section {
    .headline {
      @include line-clamp(
        $clamp-headline,
        $mtg-headline-lineheight * $mtg-headline-size
      );
    }

    .teaser {
      @include line-clamp(
        $clamp-teaser,
        $mtg-teaser-lineheight * $mtg-teaser-size
      );
    }
  }
}
