.multiple-vcards {
  display: flex;

  .printcard-container {
    margin-right: 5px;
  }
}

.printcard-container {
  @import '../../../styles/base/variables';

  &.tta {
    * {
      font-family: 'Roboto', sans-serif;
      font-weight: 100;
    }

    .highlight {
      &.left,
      &.right {
        .top {
          background-color: $printcard-tta-blue;
          height: 354px;
          width: 65px;
        }

        .bottom {
          background-color: $printcard-tta-gray;
          height: 355px;
          width: 65px;
        }
      }
    }

    .content-container {
      background-color: $area-color-1;

      .company-logo {
        width: 380px;
      }

      .company-details {
        margin: 291px 0 0 56px;

        .person,
        .company {
          font-family: $vcard-font;
          font-weight: 600;
        }

        .place,
        .email,
        .telephone {
          color: $text-color-10;
          font-size: 30px;
          font-weight: 400;
          line-height: 38px;
        }
      }
    }

    .person {
      color: $text-color-2;
      font-size: 34px;
      line-height: 40px;
    }

    .company {
      color: $text-color-2;
      font-size: 30px;
      line-height: 38px;
    }

    .title {
      color: $text-color-10;
      font-size: 30px;
      font-weight: 400;
      line-height: 40px;
    }

    .contact-details {
      height: 114px;
      margin: 21px 0 31px 57px;
    }

    .backside-container {
      background-color: $printcard-tta-blue;

      .company-details {
        display: flex;
        height: 250px;
        justify-content: flex-start;

        .left {
          // justify-content: flex-start;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          width: 275px;
        }

        .right {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          width: 300px;
        }

        img {
          vertical-align: middle;
        }
      }

      .text {
        color: $text-color-1;
      }
    }
  }
}
