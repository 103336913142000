body ~ .printcard-container {
  background: $area-color-16;
}

.printcard-container {
  @import '../../../styles/base/variables_syn';

  &.syn {
    * {
      font-family: 'Noway Round Light', sans-serif;
      font-weight: 100;
    }

    .highlight {
      &.left,
      &.right {
        .top {
          background-color: $printcard-black;
        }

        .bottom {
          background-color: $printcard-pink;
        }
      }
    }

    .content-container {
      background-color: $area-color-16;

      .company-details {
        margin: 291px 0 0 56px;

        .person,
        .company {
          font-family: $vcard-font;
          font-weight: 600;
        }

        .place,
        .email,
        .telephone {
          color: $text-color-10;
          font-size: 30px;
          font-weight: 400;
          line-height: 38px;
        }
      }
    }

    .person {
      color: $text-color-10;
      font-size: 34px;
      line-height: 40px;
    }

    .company {
      color: $text-color-10;
      font-size: 30px;
      line-height: 38px;
    }

    .title {
      color: $text-color-10;
      font-size: 30px;
      font-weight: 400;
      line-height: 40px;
    }

    .contact-details {
      height: 114px;
      margin: 21px 0 31px 57px;
    }

    .backside-container {
      background-color: $printcard-black;

      .company-details {
        display: flex;
        height: 250px;
        justify-content: flex-start;

        .column {
          align-content: flex-end;
          display: grid;
          grid-auto-rows: 65px;
        }
      }
    }
  }
}
