.profile-prefooter {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 0 !important;
  padding-top: 0 !important;

  @media #{$bp-medium} {
    flex-direction: row;
    justify-content: space-between;
  }

  div {
    margin-bottom: 45px;
    @media #{$bp-medium} {
      margin-bottom: 24px;
    }
  }

  div > h4 {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.625rem;
    margin: 0 0 10px;
  }

  div > span {
    display: block;
    line-height: 1.5rem;
  }

  .social-media {
    display: flex;

    i {
      font-size: 28px;
      margin: 0 10px;

      @media #{$bp-medium} {
        margin-top: 40px;
      }
    }
  }
}
