.meta-nav {
  @media #{$bp-large} {
    justify-content: center;
    padding-left: 0;
  }
  background-color: $meta-nav-background;
  display: flex;
  font-size: 12px;
  height: 39px;
  overflow: hidden;
  width: 100%;

  .meta-nav-part {
    background-color: $meta-nav-background-dark;
    height: 39px;
    text-align: center;
    z-index: 2;

    .meta-text {
      padding-left: 20px;
      padding-right: 20px;

      @media #{$bp-mobile} {
        padding-left: unset;
      }
      @media #{$bp-large} {
        padding-left: 20px;
        padding-right: 20px;
      }

      .logo {
        height: 15px;
        position: relative;
        top: 12px;

        &:not(:first-child) {
          margin-left: 5px;
        }

        &:not(:last-child) {
          margin-right: 5px;
        }
      }

      .description {
        color: $meta-nav-text;
        position: relative;
        top: 9px;
      }
    }

    .meta-arrows {
      background-color: $meta-nav-background;
      display: block;
      height: 39px;
      position: relative;

      .apos {
        position: absolute;
      }

      &.arrows-left {
        display: none;
        float: left;

        @media #{$bp-large} {
          display: unset;
        }

        .apos {
          &.p1 {
            left: -76px;
          }

          &.p2 {
            left: -50px;
          }

          &.p3 {
            left: -24px;
          }

          &.p4 {
            background-color: $meta-nav-background-dark;
            float: right;
            position: unset;
          }
        }
      }

      &.arrows-right {
        float: right;

        .apos {
          &.p1 {
            left: -1px;
          }

          &.p2 {
            left: 5px;
          }

          &.p3 {
            left: 31px;
          }

          &.p4 {
            left: 57px;
          }
        }
      }

      .arrow {
        height: 39px;

        &.a1 {
          width: 33px;
        }

        &.a2 {
          width: 16px;
        }
      }
    }
  }

  .meta-nav-elements {
    align-items: center;
    display: flex;
    height: 39px;
    justify-content: center;
    max-width: $max-width-xl;
    position: absolute;
    transition: opacity 250ms ease;
    width: 100%;

    @media #{$bp-small} {
      padding-right: 50px;
    }
    @media #{$bp-large} {
      max-width: $max-width-xl;
      padding-right: 70px;
    }
    @media #{$bp-extra-large} {
      padding-right: 50px;
    }
  }

  .meta-nav-elements.noninit {
    opacity: 0;
  }

  .meta-nav-elements.no-lswitcher {
    padding-right: unset;

    .meta-entries {
      ul {
        > li {
          top: 0;
        }
      }
    }
  }

  .meta-nav-elements.no-elements {
    max-width: unset;

    @media #{$bp-small} {
      padding-right: 20px;
    }
    @media #{$bp-large} {
      margin-right: 60px;
      padding-right: unset;
    }
    @media #{$bp-extra-large} {
      margin-right: unset;
      padding-right: 20px;
    }
  }

  .hidden {
    visibility: hidden;
  }

  .meta-entries {
    margin-right: -25px;
    max-height: 100%;
    position: relative;
    // top: 10px;
    width: 100%;

    @media #{$bp-large} {
      display: flex;
      flex: 1 0 auto;
      justify-content: flex-end;
      margin-right: 0;
    }

    .meta-nav-search {
      align-items: center;
      display: flex;
    }

    .meta-list {
      display: flex;
      line-height: 22px;
      margin: 0;
      padding: 0;
      z-index: 2;

      li {
        color: #000c;
        font-size: 12px;
        letter-spacing: 0;
        list-style: none;
        position: relative;
      }

      > li {
        margin-right: 14px;

        &:hover {
          > a {
            color: $accent-color;
          }

          .meta-nav-sub {
            display: grid;
            gap: 5px;
            grid-template-columns: max-content;
            padding: 6.5px 0 5px;
          }
        }

        .meta-nav-sub {
          background-color: $meta-nav-background;
          display: none;
          left: -10px;
          margin: unset;
          padding: unset;
          position: absolute;
          top: 100%;

          > li {
            padding: 0 10px;

            &:hover {
              background-color: darken($meta-nav-background, 10%);

              a {
                color: $accent-color;
              }
            }
          }
        }
      }
    }

    .search-icon,
    .close-search-icon {
      cursor: pointer;
      font-size: 18px;
      padding: 0 15px 0 0;
    }
  }

  .meta-nav-elements.right-shift {
    justify-content: end;
    margin-right: unset;
    max-width: unset;
    padding-right: 20px;
  }

  .right-shift {
    .language-switcher {
      width: unset;
    }
  }

  @media #{$bp-mobile} {
    // yes yes, it's bp-mobile but it really looks bad if not used when the elements are visible...
    .meta-nav-elements {
      display: none;
    }

    .meta-nav-part {
      width: 100%;

      .meta-arrows {
        display: none;
      }

      .meta-entries {
        margin-right: unset;
      }

      .meta-text {
        padding: unset;
      }
    }
  }
}

.meta-nav.no-part {
  @media #{$bp-mobile} {
    display: none;
  }
}
