.text-bild,
.richtext,
.text-and-video,
.image-and-text {
  ol,
  ul {
    line-height: 24px;
    margin: 0 0 15px;
  }

  ul {
    list-style: none;
    padding-inline-start: 0;

    li::marker,
    li::before {
      color: $accent-color;
      content: '\e822';
      display: inline-block;
      font-family: 'tta-iconfont';
      margin-left: calc(-1.3em + 15px);
      margin-right: 15px;
      //width: 1.3em;
      width: 1em;
    }
  }

  ol li::marker {
    color: $accent-color;
  }
}
