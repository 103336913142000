@mixin full-card-layout {
  $text-width: 378px;
  position: relative;

  .overlays {
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .head-section {
    left: 0;
    position: absolute;
    right: $text-width;
    top: -$flag-height-out;
  }

  .meta-section {
    display: grid;
    grid-template-areas:
      'x x'
      'y date'
      'author author';
    grid-template-columns: auto 1fr;
    grid-template-rows: 37px 4rem 1rem;
    padding: 0 1rem;
    position: absolute;
    right: 0;
    top: 0;
    width: 378px;

    .content-type {
      grid-area: image;
      grid-column: 1;
      grid-row: 2;
      place-self: end;
    }

    .author-image {
      grid-area: image;
      grid-column: 3;
      grid-row: 1 / 3;
      place-self: end;
    }

    .author-name {
      align-self: end;
      grid-area: author;
      grid-column: 3;
      grid-row: 2;
      line-height: 1;
    }

    .publish-date {
      grid-area: date;
      line-height: 1;
      place-self: end;
    }
  }

  .base {
    display: grid;
    grid-template: 1fr / 1fr $text-width;
    grid-template-areas: 'image text';
    height: 100%;
    overflow: hidden;
  }
}
