.image-slider-maincontent {
  $main-color: $accent-color;
  $secondary-color: transparentize($shade-type-2, 0.2);
  overflow: hidden;
  position: relative;

  .slide-container {
    display: flex;
    flex-flow: row nowrap;
  }

  .slide {
    flex: 0 0 calc(100vw - (100vw - 100%)); // VW without Scrollbar
    overflow: hidden;
    padding: 4rem 24px;
    position: relative;
    transition: transform 200ms ease-in-out;

    @media #{$bp-medium} {
      padding: 4rem 0;
    }

    img {
      height: 100%;
      left: 0;
      object-fit: cover;
      object-position: center;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;
    }
  }

  .text-wrap {
    @extend %content-column;
    margin: auto;
    word-break: keep-all;

    .title,
    .teaser {
      color: $text-color-1;
      display: block;
      font-family: 'Montserrat', sans-serif;
      padding: 15px;
      width: 100%;
    }

    .title {
      background-color: var(--imageslider-background-color, #{$main-color});
      font-family: 'Montserrat', sans-serif;
      font-size: 30px;
      font-weight: 500;
      line-height: 1.2;

      // @media #{$bp-medium} {
      //   font-size: 48px;
      // }

      // @media #{$bp-large} {
      //   font-size: 56px;
      // }
    }

    .teaser {
      background-color: $secondary-color;
      font-size: 16px;
      line-height: 1.62;
    }

    .button {
      @include button;
      margin-top: 20px;
    }

    .custom-color-button {
      background-color: var(--imageslider-button-color);
      border: 2px solid var(--imageslider-button-color);

      &:hover {
        background-color: var(--imageslider-button-color);
        border: 2px solid var(--imageslider-button-color);
        box-shadow: 0 0 0 1px var(--imageslider-button-color);
      }
    }
  }

  .slide-indicator {
    background-image: linear-gradient(
      90deg,
      $secondary-color,
      $secondary-color 50%,
      $main-color 50%,
      $main-color
    );
    background-position: calc(24px);
    background-size: 200%;
    bottom: 0;
    height: 6px;
    position: absolute;
    width: 100%;

    @media #{$bp-medium} {
      background-position: calc(-100% + (100% - #{$max-width-medium}) / 2);
    }
    @media #{$bp-large} {
      background-position: calc(-100% + (100% - #{$max-width-medium}) / 2);
    }
    @media #{$bp-extra-large} {
      background-position: calc(-100% + (100% - #{$max-width-xl}) / 2);
    }
  }

  .tabs-container {
    display: flex;
    justify-content: center;
    margin: 0 0 1rem;
    position: relative;
    top: -2rem;
    width: 100vw;

    .arrow {
      display: flex;

      i {
        color: $text-color-1;
      }
    }

    .arrow.left {
      margin-right: 1rem;
    }

    .arrow.right {
      margin-left: 1rem;
    }

    li {
      cursor: pointer;
      flex-grow: 1;
      height: 18px;
      padding: 7px;
      position: relative;
      width: 24px;

      &::before {
        background-color: $area-color-1;
        border: 1px solid $text-color-3;
        border-radius: 20px;
        content: '';
        height: 10px;
        left: 50%;
        max-width: 10px;
        outline: 0;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 10px;
      }

      &.active {
        &::before {
          background-color: var(
            --imageslider-indicator-color,
            #{$accent-color}
          );
          border-color: var(--imageslider-indicator-color, #{$accent-color});
        }
      }
    }

    .controls {
      align-items: center;
      padding: 0;
    }
  }

  .controls {
    display: flex;
    list-style: none;
    margin: 0;
  }

  .element {
    @extend %background-variations;
    @extend %background-variations-outline;
    @include button-outline-block($text-color-3);
    font-size: 12px;
    height: 100%;
    margin: 0 15px 0 0;
    min-width: 150px;
    padding: 0 15px;
    white-space: normal;

    &:hover {
      @extend %background-variations-button-shadow;
    }
  }

  .controls {
    + li:only-child {
      display: none;
    }
  }

  // .arrow {
  //   background-repeat: no-repeat;
  //   display: block;
  //   height: 30px;
  //   position: absolute;
  //   top: 45%;
  //   width: 30px;

  //   @media #{$bp-medium} {
  //     height: 50px;
  //     width: calc((100% - #{$max-width-medium}) / 2);
  //   }
  //   @media #{$bp-large} {
  //     height: 70px;
  //     width: calc((100% - #{$max-width-large}) / 2);
  //   }
  //   @media #{$bp-extra-large} {
  //     width: calc((100% - #{$max-width-xl}) / 2);
  //   }

  //   &.left {
  //     background-image: url('/group/static/arrow-left.svg');
  //     background-position: 20%;
  //     filter: brightness(0) invert(1);
  //     left: 0;
  //   }

  //   &.right {
  //     background-image: url('/group/static/arrow-right.svg');
  //     background-position: 80%;
  //     filter: brightness(0) invert(1);
  //     right: 0;
  //   }
  // }
}
