.privacy-root {
  position: fixed;
  z-index: 997;
}

.privacy-notice-background {
  background-color: rgba(0, 0, 0, 0.5);
  display: block;
  height: 100%;
  position: fixed;
  top: 0;
  transition: opacity 0.2s ease-out, visibility 0.2s;
  visibility: visible;
  width: 100%;
  z-index: 998;

  &.collapsed {
    opacity: 0;
    // transition: opacity 0.2s ease-out, visibility 0s linear 0s;
    visibility: hidden;

    .privacy-notice {
      transform: translateX(-110%);

      @media #{$bp-medium} {
        transform: scale(0) translate(-350%, -50%);
      }
    }
  }
}

.privacy-notice {
  background-color: $area-color-1;
  border-radius: 5px 5px 0 0;
  bottom: 0;
  box-shadow: 0 0 15px 0 transparentize($area-color-8, 0.6);
  display: flex;
  flex-flow: column nowrap;
  font-size: 14px;
  height: auto;
  justify-content: space-between;
  min-height: fit-content;
  overflow: hidden;
  padding: 1.5rem;
  position: fixed;
  transform: scale(1);
  transform: translateZ(0);
  width: 100vw;
  z-index: 999;

  @media #{$bp-medium} {
    border-radius: 5px;
    bottom: auto;
    left: 50%;
    // bottom: 10vh;
    max-height: 90vh;
    min-width: 300px;
    min-width: 6;
    overflow-y: auto;
    top: 50%;
    transform: scale(1) translate(-50%, -50%);
    width: 30vw;
  }
  @media #{$bp-large} {
    width: 60vw;
  }

  span {
    display: block;
  }

  span.title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  div.text {
    margin-bottom: 1rem;
    overflow: hidden;

    :first-child {
      margin-top: 0;
    }

    :last-child {
      margin-bottom: 0;
    }
  }

  .settings-open {
    div.text {
      margin: 0;
    }
  }

  div.text,
  .settings-container {
    transition: all 120ms ease-in-out;
  }

  .show-more {
    color: $accent-color;
    display: block;
    margin-top: 16px;
    text-align: center;

    @media #{$bp-large} {
      text-align: right;
    }
  }

  .settings-container {
    $unit: 20px;
    height: 0;
    overflow: hidden;

    .setting {
      align-items: center;
      display: flex;
      flex-flow: row nowrap;
      margin-bottom: $unit / 2;
    }

    span {
      flex: 1 1 auto;
    }

    .slider {
      background: $area-color-5;
      border: 3px solid $area-color-5;
      border-radius: $unit;
      cursor: pointer;
      flex: 0 0 auto;
      height: $unit;
      margin: 0 20px 0 0;
      position: relative;
      transition: all 100ms ease-in-out;
      width: 2 * $unit;

      &.disabled {
        background: $area-color-6 !important;
        border: 3px solid $area-color-6 !important;
        cursor: not-allowed;

        .inner {
          border: 3px solid $area-color-6 !important;
        }
      }

      &[data-active='true'] {
        background: $accent-color;
        border: 3px solid $accent-color;

        .inner {
          border: 3px solid $accent-color;
          left: $unit - 3px;
        }
      }

      .inner {
        background: $area-color-1;
        border: 3px solid $area-color-5;
        border-radius: 100%;
        height: $unit;
        left: -3px;
        position: absolute;
        top: -3px;
        transition: left 120ms ease-out;
        width: $unit;
      }
    }
  }

  .button-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  button {
    flex: 0 0 calc(50% - 8px);
    justify-self: flex-end;
    margin-top: 16px;
    padding: 0 !important;

    &.accept {
      @include button();

      @media #{$bp-large} {
        max-width: 49%;
      }
    }

    &.decline {
      @include button-outline();

      @media #{$bp-large} {
        max-width: 49%;
      }
    }

    @media #{$bp-medium} {
      flex: 1 0 100%;
    }
  }
}
