.content-view2 {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -1rem;

  &.accent-color {
    .contact-container {
      background-color: $accent-color;
    }

    * {
      color: $text-color-1;
    }
  }

  .contact-container {
    background-color: $area-color-1;
    border-radius: 4px;
    flex-flow: row;
    margin: 0 1rem;
    overflow: hidden;

    @mixin two-col-style {
      flex-basis: 100%;
      height: 40vw;
      margin-top: 2rem;
      @media #{$bp-small} {
        flex-basis: calc((100% - 4rem) / 2);
        height: 160px;
        margin-top: 0;
      }
      @media #{$bp-large} {
        height: 246px;
      }

      &:nth-child(n + 3) {
        margin-top: 2rem;
      }

      img {
        flex-shrink: 0;
        height: auto;
        width: 40vw;
        @media #{$bp-small} {
          height: 160px;
          width: auto;
        }
        @media #{$bp-large} {
          height: 246px;
        }
      }

      .contact {
        display: flex;
        flex-direction: row;
      }

      .info-overlay {
        font-size: 10px;
        height: auto;
        padding: 1rem;
        @media #{$bp-small} {
          padding: 1rem;
        }
        @media #{$bp-large} {
          padding: 2.625rem 2rem;
        }
      }

      .center-wrap {
        font-size: 10px;
        max-width: 100%;
        width: auto;
        @media #{$bp-medium} {
          font-size: 12px;
          width: 158px;
        }
      }

      .name {
        font-size: 12px;
        @media #{$bp-medium} {
          font-size: 14px;
        }
      }
    }

    @mixin three-col-style($nrOfItems) {
      flex-basis: calc((100% - 4rem) / 2);
      height: auto;

      @media #{$bp-medium} {
        flex-basis: calc((100% - 6rem) / 3);
        margin-top: 0 !important;
      }

      img {
        width: 100%;
      }

      .contact {
        display: block;
      }

      .info-overlay {
        padding: 1.25rem 0.5rem;
      }

      .center-wrap {
        font-size: 12px;
        max-width: 100%;
        width: 158px;
      }

      &:nth-child(n + 3) {
        margin-top: 2rem;
      }

      /*       @if $nrOfItems == 3 {
        &:nth-child(n + 3) {
          @media #{$bp-small} {
            @include two-col-style;
          }
        }
      } */
    }

    @mixin four-col-style {
      flex-basis: calc((100% - 8rem) / 4);
      height: auto;

      &:nth-child(n + 4) {
        margin-top: 0; // Neutralize three col
      }

      img {
        width: 100%;
      }

      .contact {
        display: block;
      }

      .info-overlay {
        padding: 1.5rem 0.5rem;
      }

      .center-wrap {
        font-size: 10px;
        max-width: 100%;
        width: 146px;
      }
    }

    &:first-child:nth-last-child(2),
    &:first-child:nth-last-child(2) ~ * {
      @include two-col-style;
    }

    &:first-child:nth-last-child(3),
    &:first-child:nth-last-child(3) ~ * {
      @include three-col-style(3);
    }

    &:first-child:nth-last-child(4),
    &:first-child:nth-last-child(4) ~ * {
      @include three-col-style(4);
      @media #{$bp-medium} {
        @include four-col-style;
      }
    }

    img {
      display: block;
      height: 100%;
    }

    .info-overlay {
      font-size: 12px;
      height: 136px;
      width: 100%;

      .name {
        font-weight: bold;
      }

      .line {
        display: block;
      }
    }

    .center-wrap {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      margin: 0 auto;
    }
  }
}
