$global-palette: (
  'variation-bg-white': $area-color-1,
  'variation-bg-alternative-color': $background-color-alternate,
  'variation-bg-accent': $accent-color,
  'variation-bg-e30613': $bg-color-e30613,
  'variation-bg-000000': $bg-color-000000,
  'variation-bg-be0002': $bg-color-be0002,
  'variation-bg-f3f3f3': $bg-color-f3f3f3,
  'variation-bg-ffffff': $bg-color-ffffff,
  'variation-bg-414042': $bg-color-414042,
  'variation-bg-6d6e71': $bg-color-6d6e71,
  'variation-bg-dbdcdd': $bg-color-dbdcdd,
  'variation-bg-c41f2f': $bg-color-c41f2f,
  'variation-bg-0083ca': $bg-color-0083ca,
  'variation-bg-009848': $bg-color-009848,
  'variation-bg-34a853': $bg-color-34a853,
  'variation-bg-fbbc05': $bg-color-fbbc05,
  'variation-bg-4285f4': $bg-color-4285f4,
  'variation-bg-dceaf6': $bg-color-dceaf6,
  'variation-bg-006fb7': $bg-color-006fb7,
  'variation-bg-005191': $bg-color-005191,
  'variation-bg-b8b9bb': $bg-color-b8b9bb,
  'variation-bg-edf8f8': $bg-color-edf8f8,
  'variation-bg-4a525a': $bg-color-4a525a,
  'variation-bg-ff3366': $bg-color-ff3366,
  'variation-bg-29245c': $bg-color-29245c,
  'variation-bg-e63429': $bg-color-e63429,
  'variation-bg-008ba8': $bg-color-008ba8,
  'variation-bg-00a199': $bg-color-00a199,
  'variation-bg-nov': $company-color-nov,
  'variation-bg-nov1': transparentize($area-color-2, 0.5),
  'variation-bg-tta': $company-color-tta,
  'variation-bg-xin': $company-color-xin,
  'variation-bg-edc': $company-color-edc,
  'variation-bg-transparent': $transparent,
  'variation-bg-2b2b2b': transparentize($bg-color-2b2b2b, 0.5),
  'variation-bg-8c002a': $bg-color-8c002a,
  'variation-bg-cc0025': $bg-color-cc0025,
  'variation-bg-ff002e': $bg-color-ff002e,
  'variation-bg-133881': $bg-color-133881,
  'variation-bg-707172': $bg-color-707172
);

$global-palette-additional: (
  'variation-bg-white': (
    textcolor: $text-color-3
  ),
  'variation-bg-alternative-color': (
    quoteBackground: $area-color-1
  ),
  'variation-bg-accent': (
    textcolor: $text-color-1,
    quoteBackground: $area-color-1
  ),
  'variation-bg-e30613': (
    textcolor: $text-color-1,
    quoteBackground: $area-color-1
  ),
  'variation-bg-000000': (
    textcolor: $text-color-1,
    quoteBackground: $area-color-1
  ),
  'variation-bg-be0002': (
    textcolor: $text-color-1,
    quoteBackground: $area-color-1
  ),
  'variation-bg-f3f3f3': (
    textcolor: $text-color-7
  ),
  'variation-bg-ffffff': (
    textcolor: $text-color-7
  ),
  'variation-bg-414042': (
    textcolor: $text-color-1,
    quoteBackground: $area-color-1
  ),
  'variation-bg-6d6e71': (
    textcolor: $text-color-1,
    quoteBackground: $area-color-1
  ),
  'variation-bg-dbdcdd': (
    textcolor: $text-color-7,
    quoteBackground: $area-color-1
  ),
  'variation-bg-c41f2f': (
    textcolor: $text-color-1,
    quoteBackground: $area-color-1
  ),
  'variation-bg-0083ca': (
    textcolor: $text-color-1,
    quoteBackground: $area-color-1
  ),
  'variation-bg-009848': (
    textcolor: $text-color-1,
    quoteBackground: $area-color-1
  ),
  'variation-bg-34a853': (
    textcolor: $text-color-1,
    quoteBackground: $area-color-1
  ),
  'variation-bg-fbbc05': (
    textcolor: $text-color-1,
    quoteBackground: $area-color-1
  ),
  'variation-bg-4285f4': (
    textcolor: $text-color-1,
    quoteBackground: $area-color-1
  ),
  'variation-bg-dceaf6': (
    textcolor: $area-color-7,
    quoteBackground: $area-color-1
  ),
  'variation-bg-006fb7': (
    textcolor: $text-color-1,
    quoteBackground: $area-color-1
  ),
  'variation-bg-005191': (
    textcolor: $text-color-1,
    quoteBackground: $area-color-1
  ),
  'variation-bg-b8b9bb': (
    textcolor: $area-color-7,
    quoteBackground: $area-color-1
  ),
  'variation-bg-edf8f8': (
    textcolor: $text-color-7
  ),
  'variation-bg-4a525a': (
    textcolor: $text-color-1,
    quoteBackground: $area-color-1
  ),
  'variation-bg-ff3366': (
    textcolor: $text-color-1,
    quoteBackground: $area-color-1
  ),
  'variation-bg-29245c': (
    textcolor: $text-color-1,
    quoteBackground: $area-color-1
  ),
  'variation-bg-e63429': (
    textcolor: $text-color-1,
    quoteBackground: $area-color-1
  ),
  'variation-bg-008ba8': (
    textcolor: $text-color-1,
    quoteBackground: $area-color-1
  ),
  'variation-bg-00a199': (
    textcolor: $text-color-1,
    quoteBackground: $area-color-1
  ),
  'variation-bg-nov': (
    textcolor: $text-color-1,
    quoteBackground: $area-color-1
  ),
  'variation-bg-nov1': (
    textcolor: $text-color-1,
    quoteBackground: $area-color-1
  ),
  'variation-bg-tta': (
    textcolor: $text-color-1,
    quoteBackground: $area-color-1
  ),
  'variation-bg-xin': (
    textcolor: $text-color-1,
    quoteBackground: $area-color-1
  ),
  'variation-bg-edc': (
    textcolor: $text-color-1,
    quoteBackground: $area-color-1
  ),
  'variation-bg-transparent': (
    textcolor: $text-color-7
  ),
  'variation-bg-2b2b2b': (
    textcolor: $text-color-1,
    quoteBackground: $area-color-1
  )
);

%background-variations-padding {
  &[class*=' variation'],
  &[class^='variation'] {
    padding-top: 4rem;
  }
}

/* stylelint-disable */
// Disable Stylelint to use scss lists and foreach-variables

$atf: variable-exists(above-the-fold);

@if not $atf {
  %background-variations {
    @each $name, $value in $global-palette {
      &.#{$name} {
        background-color: $value;

        // More custom settings
        @if map-has-key($global-palette-additional, $name) {
          $currentMap: map-get($global-palette-additional, $name);

          @if map-has-key($currentMap, 'textcolor') {
            color: map-get($currentMap, 'textcolor');
            * {
              color: inherit;
            }
          }

          @if map-has-key($currentMap, 'quoteBackground') {
            .quote {
              .slide-selector {
                > div {
                  background-color: map-get($currentMap, 'quoteBackground');
                }
              }
            }
          }
        }
      }
    }
  }

  %background-variations-outline {
    @each $name, $value in $global-palette {
      &.#{$name} {
        border-color: $value;
      }
    }
  }

  %background-variations-button-shadow {
    @each $name, $value in $global-palette {
      &.#{$name} {
        box-shadow: 0 0 0 1px $value;
      }
    }
  }

  %textcolor-variations {
    @each $name, $value in $global-palette {
      &.#{$name} {
        color: $value;
      }
    }
  }

  %only-background-variations {
    @each $name, $value in $global-palette {
      &.#{$name} {
        background-color: $value;
      }
    }
  }

  /* stylelint-enable */
}
