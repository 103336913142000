.content-quality {
  display: flex;

  > div {
    width: 100%;
  }

  .button-container {
    align-items: flex-start;
    display: flex;
    gap: 2px;
  }

  button {
    &.now {
      @include button-outline(rgb(0, 70, 0));
    }

    &.never {
      @include button-outline(rgb(156, 0, 0));
    }

    :is(&.now, &.never) {
      min-height: 24px;
      padding: 2px 4px;
    }
  }

  .content-quality-filter {
    display: grid;
    gap: 2rem;
    grid-auto-flow: column;
    justify-content: space-between;
    margin-inline: 1rem;
    margin-top: 1rem;

    .company-filter {
      flex-wrap: wrap;
    }

    input {
      display: none;
    }

    label {
      @include button-outline();
      min-height: 24px;
      padding: 2px 4px;
    }

    .checked + label,
    .download-link {
      @include button($accent-color);
      min-height: 24px;
      padding: 2px 4px;
    }

    .download-link {
      flex-direction: row;
      gap: 1rem;

      i {
        font-weight: 900;
      }
    }
  }
}
