%profile-page {
  body.profile-page {
    .go-to-top {
      background-color: $area-color-11;

      .icon-simple-arrow-down::before {
        cursor: pointer;
        padding: 0 0 1px;
      }
    }

    .contact-form.form {
      section {
        margin-bottom: 0;
        padding-bottom: 90px;
      }
    }
  }
}
