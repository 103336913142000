.mini-tile-grid-2,
.mini-tile-grid {
  .small-teaser {
    display: none;
  }

  .module-header {
    h4 {
      font-size: 24px;
      font-weight: 400;
      margin-top: 5px;
    }
  }

  .visual {
    height: 100%;

    img {
      display: block;
      height: 100%;
      width: 100%;
    }
  }

  .label {
    border-bottom: 1px solid $area-color-1;
    color: $text-color-1;
    display: inline;
    font-size: 0.875rem;
    font-weight: 500;
    left: -25px;
    line-height: 1.1875rem;
    padding: 5px 0 5px 25px;
    position: relative;
    text-transform: capitalize;
    transition: bottom 0.3s;
  }

  .text-box {
    align-items: flex-start;
    background-clip: padding-box;
    background-image: linear-gradient(
      to top,
      transparentize($shade-type-1, 0.3),
      transparent
    );

    bottom: 0;
    display: flex;
    flex-flow: column nowrap;
    height: auto;
    justify-content: flex-end;
    padding: 15px 25px 20px;
    position: relative;
    width: 100%;
    @media #{$bp-medium} {
      padding: 20px 15px 15px;
    }
    @media #{$bp-large} {
      padding: 25px;
    }

    * {
      color: $text-color-1;
    }
  }

  .more-button {
    border-radius: 4px;
    margin: 0 auto;
    padding: 14px 30px;
    width: auto;

    width: 100%;
    @media #{$bp-medium} {
      width: fake-grid-item-width(3, 20px);
    }
    @media #{$bp-large} {
      width: fake-grid-item-width(3, 30px);
    }

    a {
      font-weight: 500;
    }
  }

  .results .content .tile {
    flex-grow: 0;
  }

  .results {
    display: grid;
    gap: 2rem;
  }

  .content {
    $gap: 2rem;
    display: flex;
    flex-wrap: wrap;
    gap: $gap;

    .tile-hide {
      display: none;
      margin: 0 !important;
      max-height: 0 !important;
    }

    .tile {
      background-position: 50%;
      background-size: cover;
      border-radius: 4px;
      cursor: pointer;
      display: flex;
      flex: 1 0 100%;
      flex-direction: column;
      height: 340px;
      justify-content: flex-end;
      overflow: hidden;
      position: relative;
      transition: box-shadow 150ms ease-in-out;

      @media #{$bp-large} {
        flex-basis: calc((100% - #{$gap} * 2) / 3);
      }

      &:hover {
        box-shadow: 0 0 17px transparentize($shade-type-7, 0.7);
      }

      &.large {
        flex-basis: 100%;
      }

      &.medium {
        flex-basis: calc(100% / 2);
      }

      .bg-image {
        height: 100%;
        inset: 0;
        object-fit: cover;
        position: absolute;
        width: 100%;
      }

      h3 {
        color: $text-color-1;
        font-size: 2rem;
        font-weight: 600;
        line-height: 44px;
        margin: 9px 0 15px;
        max-width: calc(100vw - 118px);
        overflow: hidden;
        width: inherit;
      }

      .text {
        color: $text-color-1;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 41px;
        margin-top: 0;
      }

      a {
        height: 100%;
      }

      &:hover .overlay {
        opacity: 0.8;
      }
    }
  }

  .content-mobile-slider {
    display: flex;

    .tile-wrap {
      flex-wrap: nowrap;

      @media #{$bp-medium} {
        flex-wrap: wrap;
      }

      @media #{$bp-large} {
        flex-wrap: nowrap;
      }
    }

    .tile {
      margin-right: 23px;

      @media #{$bp-medium} {
        margin-right: initial;
      }
    }

    @media #{$bp-medium} {
      display: block;
    }
  }

  .slider-controls {
    display: flex;
    flex-direction: row;
    height: 2px;
    justify-content: center;
    list-style-type: none;
    margin-bottom: 50px;
    padding: 0;

    li {
      background-color: $area-color-11;
      border-radius: 20px;
      margin: 0 2px;
      outline: 0;
      width: 40px;

      &.active {
        background-color: $text-color-2;
      }
    }

    @media #{$bp-medium} {
      display: none;
    }
  }
}

//ipg search results
.mini-tile-grid-2 {
  .label {
    display: none;
  }

  h3 {
    @include line-clamp(4, 43.5px);
    color: $text-color-1;
    font-family: $font-base;
    font-size: 28px;
    font-weight: 600;
    line-height: inherit;
    margin: 0 0 0.5rem !important;
  }

  .small-teaser {
    @include line-clamp(2, 22px);
    color: $text-color-1;
    display: block;
    font-family: $font-base;
    font-size: 16px;
    font-weight: 400;
  }

  .text-box {
    background-image: linear-gradient(to top, transparent, transparent);
  }
}

//switch between mini tile visuals
.mini-tile-grid-2,
.mini-tile-grid {
  &.no-label {
    .label {
      display: none;
    }

    h3 {
      &.caption-big {
        @include line-clamp(4, 43.5px);
        color: $text-color-1;
        font-family: $font-base;
        font-size: 28px;
        font-weight: 600;
        line-height: inherit;
        margin: 0 0 0.5rem !important;
      }
    }

    .small-teaser {
      @include line-clamp(2, 22px);
      color: $text-color-1;
      display: block;
      font-family: $font-base;
      font-size: 16px;
      font-weight: 400;
    }

    .text-box {
      background-image: linear-gradient(to top, transparent, transparent);
    }
  }
}

.split .mini-tile-grid {
  padding: 0 !important;

  .content {
    margin-top: 0;
  }
}
