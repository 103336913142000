.footer {
  background-color: $area-color-5;

  @media #{$bp-large} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 240px;
  }

  .upper {
    margin-bottom: 0;
    overflow: hidden;
  }

  .company-row {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 2rem 0 1rem;

    @media #{$bp-medium} {
      margin: 0;
      padding: 3rem 0 2rem;
    }
  }

  .img-container {
    flex-basis: calc(50% - 32px);
    margin-bottom: 1rem;
    max-height: 21px;
    max-width: calc(50% - 32px);
    text-align: center;

    @media #{$bp-medium} {
      flex-basis: 25%;
      max-height: 25px;
    }

    @media #{$bp-extra-large} {
      flex-basis: auto;
    }

    img {
      max-height: 21px;
      max-width: 100%;

      @media #{$bp-medium} {
        max-height: 25px;
      }
    }
  }

  .content-row {
    @media #{$bp-medium} {
      display: flex;
      margin: 2rem 0;
      min-height: 0;
    }
  }

  .footer-block {
    margin: 1rem 0;

    @media #{$bp-medium} {
      margin: 0;
    }
  }

  .footer-navigation {
    // align-self: flex-start;
    // display: flex;
    //flex: 1; //needed for correct image scaling (png + svg)
    // flex-direction: column; //needed for correct image scaling (png + svg)
    //justify-content: space-around;
    padding: 1rem 0;

    @media #{$bp-medium} {
      border: 0;
      display: flex;
      flex: 0 0 auto;
      //justify-content: flex-start;
      padding: 0;
      width: (100% / 3);
    }

    img {
      display: block;
      max-height: 100%;
      max-width: 100%;
    }

    ul {
      list-style: none;
      margin-bottom: 0;
      margin-top: 0;
      padding: 0;
    }

    li {
      font-size: 1rem;
      line-height: 20px;
      margin: 0 1rem 0.5rem;
      text-align: center;
      @media #{$bp-medium} {
        font-size: 16px;
        text-align: left;
      }
    }

    a {
      color: $text-color-1;
      vertical-align: sub;
    }
  }

  .indicator {
    background-color: $text-color-4;
    border-radius: 100%;
    color: $text-color-1;
    font-size: 8px;
    padding: 2px;
    vertical-align: top;
  }

  .social-media {
    text-align: center;

    @media #{$bp-medium} {
      border: 0;
      flex-basis: 33.333%;
    }
  }

  .social-head {
    color: $text-color-1;
    font-size: 16px;
    line-height: 20px;
    margin-top: 0;
  }

  .icons {
    margin: 9px 0 0;

    .social-icon {
      color: $text-color-1;
      display: inline-block;
      height: 40px;

      .hover {
        display: none;
      }

      &:hover {
        .hover {
          display: inline-block;
        }

        .default {
          display: none;
        }
      }
    }
  }

  .quote {
    background: initial;
    margin: 3rem auto;
    overflow-y: hidden;
    width: 265px;

    @media #{$bp-medium} {
      flex-basis: 33.333%;
      margin: 0;
    }

    div {
      color: $text-color-1;
      cursor: default;
      font-size: 28px;
      font-style: italic;
      line-height: 30px;
      opacity: 0.6;
      text-align: center;

      @media #{$bp-medium} {
        margin: 0 0 0 auto;
        text-align: start;
      }
    }

    .line1,
    .line2 {
      cursor: default;
    }

    a {
      .line1,
      .line2 {
        cursor: pointer;
      }
    }
  }

  .lower {
    background-color: $area-color-2;
    margin: 0;

    .module-content {
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;

      padding-bottom: 18px !important;
      padding-top: 17px !important;

      @media #{$bp-medium} {
        flex-direction: row;
        max-width: $max-width-medium;
      }

      @media #{$bp-large} {
        max-width: $max-width-large;
      }

      @media #{$bp-extra-large} {
        max-width: $max-width-xl;
      }
    }
  }

  .bottom-navigation {
    flex: 1 0 100%;
    @media #{$bp-medium} {
      flex-basis: 33.33333%;
    }

    ul {
      display: flex;
      justify-content: center;
      list-style: none;
      padding: 0;

      @media #{$bp-medium} {
        margin: 0 auto;
      }
    }

    li {
      border-right: 0 solid $text-color-1;
      line-height: 17px;
      padding: 0 0.5rem;

      @media #{$bp-medium} {
        float: left;
      }

      &:not(:last-child) {
        border-right-width: 2px;
      }
    }

    a {
      color: $text-color-1;
      font-size: 12px;
      line-height: 20px;
    }
  }

  .copyright {
    color: $text-color-1;
    cursor: default;
    flex-basis: 33.33333%;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    @media #{$bp-medium} {
      margin: 0 0 0 1rem;
      position: static;
      text-align: left;
    }

    a {
      color: $text-color-1;
    }
  }

  .language {
    flex-basis: 50%;
    text-align: right;

    @media #{$bp-medium} {
      flex-basis: 33.33333%;
    }

    span {
      color: $text-color-1;
      font-size: 18px;
      vertical-align: middle;
    }

    div {
      color: $text-color-1;
      display: inline;
      font-size: 12px;
      line-height: 20px;
    }
  }
}

.style-section.has-background + .footer-container {
  margin-top: -4rem;
}

.style-section.has-background + .content-row.hidden + .footer-container {
  margin-top: -4rem;
}
