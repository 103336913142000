.printcard-container {
  @import '../../../styles/base/variables_cpl';

  &.cpl {
    * {
      font-family: 'Roboto', sans-serif;
      font-weight: 100;
    }

    .highlight {
      &.left {
        .top {
          background-color: $printcard-yellow;
        }

        .bottom {
          background-color: $printcard-blue;
        }
      }

      &.right {
        .top {
          background-color: $printcard-red;
        }

        .bottom {
          background-color: $printcard-green;
        }
      }
    }

    .content-container {
      background-color: $area-color-1;

      .company-details {
        margin: 291px 0 0 56px;

        .person,
        .company {
          color: $printcard-red;
          font-family: $vcard-font;
          font-weight: 600;
        }

        .place,
        .email,
        .telephone {
          font-size: 30px;
          font-weight: 400;
          line-height: 38px;
        }
      }
    }

    .person {
      color: $printcard-red;
      font-size: 34px;
      line-height: 40px;
    }

    .company {
      color: $printcard-red;
      font-family: $vcard-font;
      font-size: 30px;
      line-height: 38px;
    }

    .title {
      color: $printcard-red;
      font-size: 30px;
      font-weight: 400;
      line-height: 40px;
    }

    .contact-details {
      height: 114px;
      margin: 21px 0 31px 57px;
    }

    .backside-container {
      background-color: $printcard-red;

      .company-details {
        display: flex;
        height: 250px;
        justify-content: flex-start;

        .left {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          width: 275px;
        }

        .right {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          width: 300px;
        }
      }
    }
  }
}
