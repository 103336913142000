.button {
  position: relative;

  // Default
  // .small-button {
  // }
  .button-container {
    display: inline-flex;
    white-space: pre;
    width: 100%;

    &.right {
      justify-content: flex-end;
    }

    &.left {
      justify-content: flex-start;
    }

    &.center {
      justify-content: center;
    }
  }

  .button-teaser {
    @include button-block();
    transition: all 100ms ease-in-out;
    white-space: normal;
  }

  .medium-button {
    .button-teaser {
      font-size: 18px;
      font-weight: 600;
    }
  }

  .big-button {
    .button-teaser {
      font-size: 20px;
      font-weight: 700;
      width: 100%;
    }
  }

  :where(.medium-button, .big-button) {
    .button-teaser {
      padding-bottom: 0.6em;
      padding-top: 0.6em;
    }
  }

  .button-out {
    display: none;
  }

  > .content {
    height: 0;
    margin: 0 -24px;
    overflow: hidden;
    position: static;
    transition: height 400ms ease-out;
    @media #{$bp-small} {
      margin: 0;
    }

    &.out {
      height: 0;
      position: absolute;
      top: 0;
    }

    .content-row:last-child .module,
    .content-row:last-child .module-full {
      margin-bottom: 0;
    }
  }
}
