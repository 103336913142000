@import 'group-services-horizontal';
@import 'group-services-tabs';
@import 'group-services-modal';
@import 'group-services-animation';
$darkblue: rgb(0, 32, 96);
$blue: rgb(0, 111, 183);
$lightblue: rgb(12, 150, 226);
$lighterblue: rgb(107, 203, 245);

%facade {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &.open {
    .facade {
      flex-basis: 50px;
    }

    .content {
      flex: 0 1 100%;
      opacity: 1;
    }
  }

  &.vertical {
    writing-mode: vertical-lr;
  }

  .facade {
    display: grid;
    flex: 1 0 100%;
    gap: 1rem;
    grid-template-columns: 1fr auto 1fr;
    padding: 0 1rem;
    place-content: center;

    .facade-title {
      grid-column: 2;
      line-height: 1.6;
      text-align: center;
    }

    .plus-icon {
      place-self: center start;
    }
  }

  .content {
    opacity: 0;
  }
}

.group-services {
  @extend %horizontal-boxes;
  @extend %tab-boxes;
  @extend %modal;
  display: block;

  overflow: hidden;
  position: relative;

  * {
    transition: all 200ms ease-in-out;
  }

  .clickable {
    cursor: pointer;
  }

  .container {
    background-color: $darkblue;
    padding: 20px;
    position: relative;

    h2,
    h3,
    h4 {
      color: $text-color-1;
      line-height: 1.6;
      margin: 0;
      word-break: break-word;
    }

    h3 {
      font-size: 11px;
      @media #{$bp-small} {
        font-size: 14px;
      }
      @media #{$bp-medium} {
        font-size: 20px;
      }

      @media #{$bp-large} {
        font-size: 24px;
      }
    }

    h4 {
      font-size: 11px;
      line-height: 1.2;
      @media #{$bp-small} {
        font-size: 13px;
      }
      @media #{$bp-large} {
        font-size: 16px;
      }
      @media #{$bp-extra-large} {
        font-size: 18px;
      }
    }

    .headline {
      font-size: clamp(24px, 4vw, 48px);
      font-size: 18px;
      margin-bottom: 1rem;
      text-align: center;
      @media #{$bp-small} {
        font-size: 24px;
        margin-bottom: 20px;
      }
    }

    .grid-area {
      @extend %group-services-animation;

      display: grid;
      gap: 1rem;
      grid-template-areas:
        'header header header'
        'top top top'
        'center center center'
        'pillarleft bottom pillarright';

      grid-template-columns: repeat(3, 1fr);
      @media #{$bp-small} {
        gap: 20px;
        grid-template-columns: 140px auto 140px;
      }
      @media #{$bp-large} {
        grid-template-areas:
          'header header header'
          'pillarleft top pillarright'
          'pillarleft center pillarright'
          'pillarleft bottom pillarright';
        // Reset to default
        //grid-template-rows: 140px 350px 140px;
        //grid-template-rows: 140px 350px 140px;
      }

      // &.center-expanded {
      //   grid-template-rows: 140px 350px 140px;
      // }
    }
  }

  .center-area {
    grid-area: center;

    .center-area-area {
      background-color: $blue;
    }
  }

  .header-area {
    @include horizontal-boxes-color-template($blue, $lightblue);

    grid-area: header;

    .facade {
      display: grid;
      gap: 0;
      grid-auto-flow: row;
      grid-template-columns: none;
      grid-template-rows: 1fr auto;
      height: 100%;
      padding: 10px;
      place-items: center;

      @media #{$bp-medium} {
        gap: 1rem;
        grid-template-columns: 1fr 3fr 1fr;
        grid-template-rows: none;
      }
      @media #{$bp-large} {
        grid-template-columns: 1fr auto 1fr;
      }

      .facade-title {
        grid-column: auto;
        text-align: center;
        @media #{$bp-medium} {
          grid-column: 2;
        }
      }

      .plus-icon {
        @media #{$bp-medium} {
          place-self: center start;
        }
      }
    }
  }

  .center-center-area {
    grid-area: center;
    // @include horizontal-boxes-color-template($darkblue, $blue);
    .tab-register,
    .facade {
      background-color: $lightblue;

      &:hover {
        background-color: lighten($lightblue, 3);
      }
    }

    .tab-register:hover {
      .plus-icon::before {
        color: lighten($lighterblue, 3);
      }
    }

    .plus-icon::before {
      color: $lighterblue;
    }

    .boxes {
      background-color: $lightblue;
    }

    .row {
      background-color: $lighterblue;

      &:hover {
        background-color: lighten($lighterblue, 3);
      }

      .plus-icon::before {
        color: $lightblue;
      }
    }
  }

  .top-center-area,
  .bottom-center-area {
    @include horizontal-boxes-color-template($blue, $lightblue);

    .facade {
      display: grid;
      gap: 0;
      grid-auto-flow: row;
      grid-template-columns: none;
      grid-template-rows: 1fr auto;
      height: 100%;
      padding: 10px;
      place-items: center;

      @media #{$bp-medium} {
        gap: 1rem;
        grid-template-columns: 1fr 3fr 1fr;
        grid-template-rows: none;
      }
      @media #{$bp-large} {
        grid-template-columns: 1fr auto 1fr;
      }

      .facade-title {
        grid-column: auto;
        text-align: center;
        @media #{$bp-medium} {
          grid-column: 2;
        }
      }

      .plus-icon {
        @media #{$bp-medium} {
          place-self: center start;
        }
      }
    }
  }

  .top-center-area {
    @include horizontal-boxes-color-template($lightblue, $lighterblue);
    grid-area: top;
  }

  .bottom-center-area {
    grid-area: bottom;
  }

  .pillar {
    background-color: $blue;

    &:hover {
      background-color: lighten($blue, 3);
    }

    * {
      transition: none;
    }

    &.left {
      grid-area: pillarleft;
    }

    &.right {
      grid-area: pillarright;
    }

    .facade {
      display: grid;
      grid-auto-flow: row;
      grid-template-rows: 1fr auto;
      height: 100%;
      padding: 10px;
      place-items: center;
      @media #{$bp-large} {
        grid-auto-flow: column;
        grid-template-rows: none;
      }
    }

    .facade-title {
      font-size: 11px;
      text-align: center;
      transform: rotate(-0); //rotate(0.5turn);
      writing-mode: unset; //vertical-lr;
      @media #{$bp-small} {
        font-size: 14px;
      }
      @media #{$bp-large} {
        font-size: 24px;
        place-self: center end;

        transform: rotate(-180deg);
        writing-mode: vertical-lr;
      }
    }

    .plus-icon {
      margin: auto;
      place-self: center start;
    }

    .facade .plus-icon::before {
      color: $lightblue;
    }

    /*     .facade:hover .plus-icon::before {
      color: lighten($lightteal, 4);
    } */
  }

  .diagram-modal {
    @mixin modal-color-template($color, $lightcolor) {
      background-color: $color;

      .plus-icon::before {
        color: $lightcolor;
      }

      .modal-button {
        @include button-block($lightcolor);
      }
    }

    &.blue {
      @include modal-color-template($blue, $lightblue);
    }

    &.lightblue {
      @include modal-color-template($blue, $lightblue);
    }

    &.lighterblue {
      @include modal-color-template($blue, $lightblue);
    }
  }
}
