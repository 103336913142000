.meta-head {
  .module,
  .module-content {
    padding: 0 !important;
  }
}

.meta-head.module {
  display: grid;
  gap: 2rem;

  grid-template-areas:
    'left-head'
    'tags'
    'body'
    'body'
    'body';
  grid-template-columns: 1fr;
  grid-template-rows: repeat(5, auto);

  .richtext-content {
    .module-content,
    .module {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .main {
    @media #{$bp-medium} {
      grid-column: 2;
      grid-row: 1 / -1;
    }
  }

  .left-head {
    grid-area: left-head;

    .content-type {
      font-size: 50px;
      font-weight: bold;
      margin-left: -3px;

      @media #{$bp-medium} {
        margin-left: 0;
      }
    }

    .date,
    .location {
      display: block;
    }
  }

  .main-head {
    display: none; // Event only
  }

  .tag-group {
    grid-area: tags;

    .tags {
      margin: 0 0 1rem;
      @media #{$bp-medium} {
        margin-bottom: 2rem;
      }
    }
  }

  .cta {
    display: none;
    grid-area: button;
    place-self: start;

    @media #{$bp-medium} {
      place-self: start end;
    }
  }

  .body {
    grid-area: body;
  }

  .left-head,
  .tag-group {
    @media #{$bp-medium} {
      text-align: right;
    }
  }

  .heading {
    display: block;
    line-height: 1;
    text-transform: uppercase;
  }

  .teaser {
    font-weight: bold;
    margin-top: 0;
  }

  &.event {
    grid-template-areas:
      'left-head'
      'main-head'
      'button'
      'tags'
      'body';
    @media #{$bp-medium} {
      grid-template-areas:
        'left-head'
        'main-head'
        'button'
        'body'
        'tags';
    }

    .main-head {
      display: block;

      grid-area: main-head;

      .date {
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 0.5rem;
        @media #{$bp-medium} {
          font-size: 50px;
        }
      }

      .location {
        font-size: 20px;
      }
    }

    .left-head {
      .date,
      .location {
        display: none;
      }
    }

    .cta {
      display: inline-flex;
    }
    @media #{$bp-medium} {
      grid-template-areas:
        'left-head main-head'
        'tags body'
        'button body';
    }
  }

  @media #{$bp-medium} {
    display: grid;
    grid-template-areas:
      'left-head body'
      'tags body'
      'button body';
    grid-template-columns: 1fr 4fr;
    grid-template-rows: repeat(2, auto) 1fr;
  }
}
