.imagevideo {
  &.module-full {
    article > div > img {
      display: inline-block;
      max-width: 100%;
    }
  }

  article > div {
    text-align: center;

    > img {
      width: 100%;
    }
  }
}
