.contact-person-and-text {
  @media #{$bp-large} {
    align-items: center;
    display: flex;
  }

  .text {
    @media #{$bp-large} {
      flex: 2;
    }
  }

  .heading {
    font-size: 34px;
    line-height: 40px;
    margin-bottom: 2rem;

    @media #{$bp-large} {
      font-size: 40px;
      line-height: 50px;
    }
  }

  .contact-person {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0;
    margin-top: 4rem;
    padding: 0;

    @media #{$bp-large} {
      flex: 1;
      margin-top: 0;
      padding-left: 2rem;
    }
  }

  .img-wrap {
    border-radius: 100%;
    height: 280px;
    margin-bottom: 2rem;
    overflow: hidden;
    position: relative;
    width: 280px;

    &:hover {
      .overlay-contact {
        background-color: transparentize($accent-color, 0.25);
        @media #{$bp-large} {
          opacity: 1;
        }
      }
    }

    .contact-person-img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    .overlay-contact {
      background-color: $accent-color;
      background-image: url('../static/Hovereffekt_Ansprechpartner.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 60%;
      display: block;
      inset: 0;
      opacity: 0;
      position: absolute;

      @media #{$bp-large} {
        transition: opacity linear 200ms;
      }
    }
  }

  .contact-person-text-block {
    margin: 0;
    margin-bottom: 2rem;
  }

  .contact-person-text {
    display: block;
    text-align: center;
  }

  .contact-person-name {
    font-size: 28px;
    line-height: 38px;
  }

  .generic-button {
    @include button();
  }
}
