@import './image-slider-nextgen/module';

.image-slider-nextgen {
  transition: margin 200ms ease-in-out;
  @media #{$bp-medium} {
    margin: 0 auto calc(4rem - 25px);
  }

  ul,
  ol {
    line-height: 1.3;
    padding: 0;

    li:last-child {
      .element {
        margin-right: 0;
      }
    }
  }

  .hidden {
    display: none;
  }

  .slide-content {
    background-color: var(--imageslider-background-color, #{$area-color-19});
    display: flex;
    flex-direction: column-reverse;
    max-height: 630px;
    overflow: hidden;
    width: 100%;
    @media #{$bp-large} {
      flex-direction: row;
      height: 420px;
      margin-top: 0;
    }
  }

  .slide {
    //display: none;
    flex: 0 0 100vw;
    transition: transform 100ms ease-out;
    @media #{$bp-medium} {
      transition: none;
    }
    @media #{$bp-large} {
      display: none;
      flex: auto;
      transform: none !important;
    }

    &.active {
      display: flex;
      flex-direction: column;
    }

    &.only-title {
      justify-content: center;
    }

    img {
      height: 100%;
      left: 0;
      object-fit: cover;
      object-position: center;
      position: absolute;
      top: 0;
      width: 100%;
    }

    .preview-image {
      filter: blur(3vw);
      transform: scale(1.1);
    }

    img.first {
      opacity: 0; // opacity rule will be overwritten by js when img is loaded
      transition: opacity 0.3s ease;
    }
  }

  .text-arrow-container {
    display: flex;
    flex-direction: column;
    position: relative;

    @media #{$bp-large} {
      max-width: 485px;
      min-width: 485px;
      padding: 0;
    }
    @media #{$bp-extra-large} {
      margin: 0 0 0 calc((100% - 1110px) / 2);
      max-width: 525px;
      min-width: 525px;
    }

    .arrow {
      cursor: pointer;
    }

    .arrow-mobile {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 1.5rem;

      @media #{$bp-medium} {
        display: none;
        justify-content: center;
      }
    }

    .left {
      left: 0;
    }

    .right {
      right: 0;
    }

    .text-container {
      align-items: center;
      display: flex;
      flex-direction: row;
      height: 100%;
      justify-content: space-between;
      padding: 0 0 0 calc((100vw - 960px) / 2);

      @media #{$bp-medium} {
        width: 100%;
      }

      @media #{$bp-large} {
        position: absolute;
      }

      @media #{$bp-extra-large} {
        padding: 0;
      }

      .title {
        @include line-clamp(3, 108px);
        font-size: 30px;
        font-weight: 500;
        line-height: 1.2;
        padding-bottom: 2px;
        text-align: center;

        @media #{$bp-large} {
          text-align: left;
        }
      }

      .teaser {
        @include line-clamp(3, 80px);
        font-size: 16px;
        line-height: 1.62;
        text-align: center;

        @media #{$bp-large} {
          text-align: left;
        }
      }

      .button {
        @extend %background-variations !optional;
        @extend %background-variations-outline !optional;
        @include button-block();
        font-size: 12px;
        height: 30px;
        margin: 1rem auto 0;
        margin-bottom: 2rem;
        min-width: 125px;
        padding: 5px 18px;
        white-space: normal;

        &:hover {
          @extend %background-variations-button-shadow !optional;
        }

        @media #{$bp-large} {
          margin: 1rem auto 0 0;
        }
      }

      .button-text {
        @include line-clamp(1, 42px);
        font-size: 12px;
        font-weight: 500;
      }

      .attribution {
        color: $text-color-9;
        font-size: 10px;
        font-weight: 300;
        height: 1rem;
        left: 50%;
        line-height: 1rem;
        position: absolute;
        text-align: center;
        top: 1rem;
        transform: translateX(-50%);

        @media #{$bp-large} {
          font-size: 16px;
          left: calc((100vw - #{$max-width-large}) / 2);
          top: 1.25rem;
          transform: none;
        }

        @media #{$bp-extra-large} {
          left: 0;
        }
      }
    }

    .text-content {
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-name: fadein;
      display: flex;
      flex-direction: column;
      height: fit-content;
      opacity: 0;
      padding: 2rem;
      width: 100%;

      @media #{$bp-large} {
        display: none;
        flex: auto;
        padding: 0;
      }

      &.active {
        @media #{$bp-large} {
          display: flex;
        }
      }
    }
  }

  .teaser {
    margin-top: 1rem;
  }

  .highlight-container {
    display: flex;
    flex-direction: row;
    height: 260px;
    justify-content: flex-end;
    width: 100%;

    @media #{$bp-large} {
      height: 420px;
    }
  }

  .container-spacing {
    background-color: $accent-color;
    display: flex;
    flex-direction: row;
    height: 100%;
    position: relative;
    width: 100%;
  }

  .image-highlight {
    background-position: center;
    background-size: cover;

    flex-flow: column nowrap;
    height: 100%;
    justify-content: flex-end;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  .desktop-small {
    bottom: 0;
    position: absolute;
    width: 100%;
  }
}
