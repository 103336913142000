.filter {
  margin-bottom: 1rem !important;

  .header {
    background-color: $accent-color;
    border-radius: 4px;
    cursor: pointer;
    padding: 6px 14px 8px 20px;

    i,
    span {
      color: $text-color-1;
    }

    span {
      font-size: 20px;
      font-weight: 500;

      @media #{$bp-medium} {
        font-size: 24px;
      }
    }

    .show {
      float: right;
      font-size: 21px;
      line-height: 32px;
      transition: transform 100ms linear;
    }

    &.active {
      border-radius: 4px 4px 0 0;

      .show {
        transform: rotate(0.5turn);
      }
    }
  }

  .icon-filter {
    font-size: 20px;
    margin-right: 8px;
  }

  .active .content {
    max-height: unset;
  }

  .content {
    border-radius: 0 0 4px 4px;
    // background-color: $area-color-11;
    box-shadow: $box-shadow-1;
    overflow: hidden;
    position: relative;
    width: 100%;

    &.collapsed {
      margin: 0;
      max-height: 0;
    }

    form {
      padding: 0 17px;

      .form-block.pre {
        display: none;
      }

      > *:last-child() {
        margin-bottom: 23px;

        @media #{$bp-medium} {
          margin-bottom: 21px;
        }

        @media #{$bp-large} {
          margin-bottom: 17px;
        }
      }
    }

    h2 {
      color: $accent-color;
      font-size: 18px;
      line-height: auto;
      margin-bottom: 15px;

      @media #{$bp-medium} {
        font-size: 20px;
      }

      @media #{$bp-large} {
        font-size: 24px;
      }
    }
  }

  .check-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .checkbox {
    flex-basis: 50%;
    height: 49px;
    overflow: hidden;

    label {
      align-items: center;
      cursor: pointer;
      display: inline-block;
      display: flex;
      font-size: 10px;
      font-weight: 400;
      hyphens: auto;
      margin-bottom: 21px;
      position: relative;
      text-transform: none;
      user-select: none;
      word-break: break-word;

      @media #{$bp-small} {
        font-size: 14px;
        word-break: normal;
      }

      @media #{$bp-medium} {
        font-size: 16px;
      }
    }

    .checkmark-wrap {
      align-self: flex-start;
      display: inline-block;
      flex-shrink: 0;
      height: 16px;
      position: relative;
      width: 16px;
      @media #{$bp-small} {
        height: 25px;
        width: 25px;
      }

      div {
        border: 2px solid;
        border-radius: 2px;
        height: 100%;
        position: absolute;
        width: 100%;
        @media #{$bp-small} {
          border-radius: 4px;
          border-width: 3px;
        }
      }

      .active {
        background-color: $accent-color;
        border-color: $accent-color;
        opacity: 0;
        transition: opacity 50ms ease;
      }

      ::before {
        color: $area-color-1;
        font-size: 12px;
        height: 100%;
        margin: 0;
        width: 100%;

        @media #{$bp-small} {
          font-size: 18px;
        }

        @media #{$bp-medium} {
          position: relative;
          //top: -2px;
        }
      }

      .inactive {
        border-color: $area-color-11;
      }
    }

    .checkmark-wrap.checked {
      .active {
        opacity: 1;
      }
    }

    .checkbox-name {
      margin: 0 10px;
    }

    /*     .checkmark {
      border: 2px solid $area-color-1;
      border-radius: 2px;
      cursor: pointer;
      display: inline-block;
      height: 16px;
      left: 0;
      position: relative;
      top: 2px;
      width: 16px;
      @media #{$bp-small} {
        border-radius: 4px;
        border-width: 3px;
        height: 25px;
        width: 25px;
      }
    } */

    .count {
      color: lighten($text-color-3, 15%);
    }

    @media #{$bp-medium} {
      flex-basis: 33%;
    }

    @media #{$bp-large} {
      flex-basis: 25%;
    }

    /*     @media #{$bp-extra-large} {
      flex-basis: 20%;
    } */
  }

  .checkbox-input {
    cursor: pointer;
    display: none;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;
  }
}

.split .filter {
  display: flex;
  flex-direction: column;
  padding: 0 40px !important;
  position: relative;
  z-index: 50;

  @media #{$bp-medium} {
    padding: 0 !important;
  }

  .header {
    border-radius: 4px 4px 0 0;

    &.active {
      i {
        transform: rotate(0turn);
      }
    }

    i {
      transform: rotate(0.5turn);
      @media #{$bp-extra-large} {
        display: none;
      }
    }
  }

  .content {
    @extend %hidescrollbar;
    overflow-y: scroll;

    .check-container {
      display: block;
    }
    @media #{$bp-extra-large} {
      height: 100%;
      max-height: none;
      overflow-y: auto;
    }
  }
  @media #{$bp-extra-large} {
    height: 100%;
    padding: 0 !important;
  }
}

@import 'searchbar';
