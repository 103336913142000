.contact-container {
  width: 100%;
}

.contact {
  border-radius: 4px;
  position: relative;

  + .contact {
    margin-top: 1rem;
  }

  img {
    border-radius: 4px;
    display: block;
    height: auto;
    width: 100%;
  }
}

.info-overlay {
  background-color: transparentize($area-color-8, 0.5);

  border-radius: 0 0 4px 4px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 25%;
  padding: 2% 5% 5%;
  position: absolute;
  text-align: center;
  width: 100%;

  @media #{$bp-small} {
    min-height: 30%;
  }

  h3 {
    color: $text-color-1;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.2;
    margin: 0 0 auto;

    @media #{$bp-medium} {
      font-size: 22px;
      line-height: 25px;
    }

    @media #{$bp-large} {
      font-size: 30px;
      line-height: 35px;
    }
  }

  span {
    color: $text-color-1;
    font-size: 24px;
    line-height: (7/6);

    @media #{$bp-medium} {
      font-size: 18px;
      line-height: 21px;
    }

    @media #{$bp-large} {
      font-size: 21px;
      line-height: 28px;
    }
  }

  .position {
    display: block;
  }

  .company {
    display: block;
  }

  .contact-info {
    display: none;

    @media #{$bp-extra-large} {
      display: block;
      font-size: 16px;
      line-height: 21px;
    }
  }
}
