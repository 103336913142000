/* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
/* Unavoidable! */
.image-slider-nextgen {
  .desktop {
    display: none;

    @media #{$bp-medium} {
      bottom: 25px;
      display: flex;
      height: 40px;
      justify-content: center;
      margin: 0 auto;
      max-width: $max-width-medium;
      position: relative;
      z-index: 10;
    }

    @media #{$bp-large} {
      max-width: $max-width-large;
    }

    @media #{$bp-extra-large} {
      max-width: $max-width-xl;
    }

    &.invisible {
      opacity: 0;
    }

    .element {
      align-items: center;
      background-color: $border-color-1;
      border: 0;
      border-radius: 5px;
      cursor: pointer;

      margin: 0 15px 0 0;
      max-width: 100%;
      min-height: 42px;
      min-width: 150px;
      padding: 0;
      z-index: 1;

      &:hover {
        box-shadow: none;

        .text {
          color: $accent-color;
        }
      }
    }

    .text-wrap {
      background-color: $area-color-1;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      margin: 2px;
      overflow: hidden;
      padding: 0 15px;
      width: calc(100% - 4px); // Shoudln't be necesary, but is.
      z-index: 3;
    }

    .text {
      @include line-clamp(1, 38px);
      color: $default-text-color;
      display: flex;
      font-size: 12px;
      font-weight: 500;
      justify-content: center;
    }

    .top-right,
    .right,
    .bottom,
    .left,
    .top-left {
      background-color: $accent-color;
      position: absolute;
    }

    .top-right {
      height: 4px;
      left: 50%;
      top: 0;
    }

    .right {
      right: 0;
      top: 0;
      width: 4px;
    }

    .bottom {
      bottom: 0;
      height: 4px;
      right: 0;
    }

    .left {
      bottom: 0;
      left: 0;
      width: 4px;
    }

    .top-left {
      height: 4px;
      left: 0;
      top: 0;
    }

    .active {
      &.permanent {
        color: $accent-color;

        * {
          animation-duration: 200ms !important;
          animation-fill-mode: forwards;
          animation-timing-function: linear;
        }

        .top-right {
          animation-name: top-right-bar;
        }

        .right {
          animation-name: right-bar;
        }

        .bottom {
          animation-name: bottom-bar;
        }

        .left {
          animation-name: left-bar;
        }

        .top-left {
          animation-name: top-left-bar;
        }
      }

      .element {
        animation-name: color-element;
        position: relative;
        vertical-align: middle;

        * {
          animation-direction: normal;
          animation-fill-mode: forwards;
          animation-timing-function: linear;
        }

        .text {
          color: $accent-color;
        }

        .top-right {
          animation-name: top-right-bar;
        }

        .right {
          animation-name: right-bar;
        }

        .bottom {
          animation-name: bottom-bar;
        }

        .left {
          animation-name: left-bar;
        }

        .top-left {
          animation-name: top-left-bar;
        }
      }
    }
  }

  .mobile {
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    padding-bottom: 1rem;
    right: 0;

    li {
      background-color: $area-color-1;
      border: 1px solid $text-color-3;
      border-radius: 20px;
      cursor: pointer;
      flex-grow: 1;
      height: 10px;
      margin: 0 7px;
      max-width: 10px;
      outline: 0;
      width: 10px;

      &.active {
        background-color: $accent-color;
        border: 1px solid $accent-color;
      }
    }

    .controls {
      justify-content: center;
      padding: 0;
    }
    @media #{$bp-medium} {
      display: none;
    }
  }

  .desktop-small {
    display: none;
    justify-content: center;
    margin: 2rem 0 1rem;

    .arrow.arrow-desktop {
      display: flex;
      paint-order: stroke fill;
      -webkit-text-stroke: 1px $text-color-3;
    }

    .arrow.left {
      margin-right: 1rem;
    }

    .arrow.right {
      margin-left: 1rem;
    }

    li {
      cursor: pointer;
      flex-grow: 1;
      height: 18px;
      padding: 7px;
      position: relative;
      width: 24px;

      &::before {
        background-color: $area-color-1;
        border: 1px solid $text-color-3;
        border-radius: 20px;
        content: '';
        height: 10px;
        left: 50%;
        max-width: 10px;
        outline: 0;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 10px;
      }

      &.active {
        &::before {
          background-color: var(
            --imageslider-indicator-color,
            #{$accent-color}
          );
          border-color: var(--imageslider-indicator-color, #{$accent-color});
        }
      }
    }

    .controls {
      align-items: center;
      padding: 0;
    }
    @media #{$bp-medium} {
      display: flex;
    }
  }

  .active {
    // color: $accent-color;

    .element {
      // border: 2px solid $accent-color;
      // color: $accent-color;
      cursor: pointer;
    }
  }

  .controls {
    display: flex;
    list-style: none;
    margin: 0;
  }

  .element {
    @extend %background-variations !optional;
    @extend %background-variations-outline !optional;
    @include button-outline-block($text-color-3);
    font-size: 12px;
    height: 100%;
    margin: 0 15px 0 0;
    min-width: 150px;
    padding: 0 15px;
    white-space: normal;

    &:hover {
      @extend %background-variations-button-shadow !optional;
    }
  }

  .controls {
    + li:only-child {
      display: none;
    }
  }
}

/* stylelint-enable selector-max-compound-selectors */
