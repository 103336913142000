%tab-boxes {
  .tab-boxes {
    // I'm setting the height of the center area in the block and not in the row definition, because row-height-transitions (animations) are bugged!
    $small-height: 140px;
    $large-height: 350px;

    height: $small-height;
    overflow: hidden;

    @media #{$bp-large} {
      height: $large-height;
    }

    &.open {
      height: $large-height;

      .facade,
      .content {
        top: -100%;
      }
    }

    .facade,
    .content {
      position: relative;
      top: 0;
    }

    .facade {
      display: grid;
      flex: 1 0 100%;
      gap: 1rem;

      grid-template-columns: 1fr auto 1fr;
      height: 100%;
      padding: 0 1rem;
      place-content: center;

      .facade-title {
        font-size: 16px;
        grid-column: 2;
        text-align: center;
        @media #{$bp-small} {
          font-size: 20px;
        }
      }

      .plus-icon {
        place-self: center start;
      }
    }

    .head-section {
      display: grid;
      gap: 10.3px; // Rounding errors an sub pixel gaps
      grid-auto-columns: auto;
      grid-auto-flow: column;
      grid-template-rows: 65px;

      @media #{$bp-large} {
        gap: 10.6px; // :(
      }

      .tab-register {
        display: grid;
        @media #{$bp-small} {
          grid-template-columns: 1fr 40px;
          padding-left: 10px;
        }
      }

      .tab-title {
        display: inline-grid;
        place-self: center;
        text-align: center;
        @media #{$bp-small} {
          text-align: left;
        }
      }

      .plus-icon {
        place-self: center;
      }
    }

    .boxes {
      display: grid;
      gap: 10px;
      grid-auto-rows: 45px;
      margin: 0;
      padding: 10px;

      .row {
        align-items: center;
        display: inline-grid;
        grid-template-columns: 1fr 4fr 1fr;
        @media #{$bp-small} {
          grid-template-columns: 40px auto 40px;
        }
      }

      .plus-icon {
        font-size: 18px;
        place-self: center;
      }

      .box-text {
        color: $text-color-1;
        font-size: 11px;
        font-weight: bold;
        grid-column: 2;
        line-height: 1.6;
        text-align: center;

        @media #{$bp-small} {
          font-size: 16px;
        }

        @media #{$bp-medium} {
          font-size: 18px;
        }
      }
    }
  }
}
