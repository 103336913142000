.blog-details {
  padding-bottom: 0;

  .details {
    @media #{$bp-medium} {
      display: flex;
    }

    .date {
      width: 20%;
    }

    .place {
      margin: 20px 0 0;
      width: 40%;
      @media #{$bp-medium} {
        margin: 0;
      }
    }

    .context {
      margin: 20px 0 0;
      width: 20%;
      @media #{$bp-medium} {
        margin: 0;
      }
    }

    p {
      margin: 0;
    }

    .description {
      color: $text-color-3;
      font-size: 13px;
      margin-bottom: 1rem;
    }

    .desc-content {
      font-size: 18px;
      font-weight: 500;
    }
  }

  .button-container {
    margin: 40px 0 0;

    &.hidden {
      display: none;
    }

    &.show {
      align-items: center;
      display: flex;
      // &.left {
      //   justify-content: flex-start;
      // }
      // &.center {
      //   justify-content: center;
      // }
      // &.right {
      //   justify-content: flex-end;
      // }
    }
  }
}
