.quote {
  @extend %background-variations;
  @extend %background-variations-padding;
  position: relative;
  overflow: hidden;

  * {
    color: inherit;
  }

  &.variation-image {
    background-position: center;
    background-size: cover;

    * {
      color: $text-color-1;
    }

    .icon-quote-99 {
      color: $text-color-1;
    }
  }

  .background-image {
    position: absolute;
    inset: 0;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  .module-content {
    position: relative;
  }

  .slide-nav {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 4;

    i {
      cursor: pointer;
      display: none;
      position: relative;
      transform-origin: center;
      z-index: 6;
      @media #{$bp-medium} {
        display: block;
      }
      @media #{$bp-large} {
        display: block;
      }
    }

    .nav-spacer {
      height: 100%;
      width: calc(328px + 180px * 2);
      // Content Width + Spacing
      @media #{$bp-medium} {
        width: calc(476px + 180px * 2);
      }
      @media #{$bp-large} {
        width: calc(730px + 180px * 2);
      }
    }
  }

  .slide-selector {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin: 27px 0 -20px;
    padding-bottom: 4rem;
    position: relative;
    z-index: 6;

    > div {
      background-color: $area-color-6;
      border-radius: 20px;
      cursor: pointer;
      height: 2px;
      margin: 0 5px;
      opacity: 0.4;
      width: 40px;

      &.active {
        opacity: 1;
      }
    }
    @media #{$bp-medium} {
      margin: 0;
      padding: 15px 0 0;
    }
  }

  .wrap {
    display: flex;
    flex-basis: 100%;
    flex-flow: row nowrap;
    flex-grow: 1;
    flex-shrink: 0;
    margin-top: -50px;
    -ms-overflow-style: none;
    overflow-x: scroll;
    overflow-y: visible;
    padding-top: 50px;
    pointer-events: none;
    position: relative;
    scrollbar-width: none;
    z-index: 5;
    scroll-snap-type: x mandatory;

    &::-webkit-scrollbar {
      display: none;
      width: 0 !important;
    }
    @media #{$bp-medium} {
      margin: 0;
      padding: 0;
    }
  }

  .inner-wrap {
    display: block;
    flex-basis: 100%;
    flex-shrink: 0;
    scroll-snap-align: start;

    .inner {
      align-items: center;
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      margin: auto;
      max-width: 328px;
      pointer-events: initial;
      position: relative;

      img {
        display: none;
      }

      i {
        font-size: 32px;
        position: absolute;
        top: -33px;
        @media #{$bp-medium} {
          font-size: 42px;
          left: -100px;
          top: 15px;
        }
        @media #{$bp-large} {
          font-size: 62px;
        }
      }

      p {
        font-size: 16px;
        font-style: italic;
        line-height: 1.7;
        margin: 0;
        text-align: center;
        width: inherit;
        @media #{$bp-medium} {
          font-size: 24px;
          line-height: 1.25;
        }
        @media #{$bp-large} {
          font-size: 28px;
          line-height: 1.375;
        }
      }

      .subline-1 {
        font-size: 14px;
        line-height: 24px;
        @media #{$bp-medium} {
          font-size: 16px;
          line-height: 24px;
        }
        @media #{$bp-large} {
          font-size: 24px;
          line-height: 30px;
        }
      }

      .subline-2 {
        font-size: 12px;
        line-height: 20px;
        @media #{$bp-large} {
          font-size: 16px;
          line-height: 30px;
        }
      }

      .subline-1,
      .subline-2 {
        @media #{$bp-large} {
          text-align: start;
        }
      }

      .text-content {
        align-items: center;
        display: flex; // ohne flex und nur für Safari 10!
        flex-flow: column nowrap;
        //margin-bottom: 46px;
        width: 100%;
      }
      @media #{$bp-medium} {
        max-width: inherit;
        width: 476px;
      }
      @media #{$bp-large} {
        width: calc(100% - 300px);
      }
    }

    .inner.wimg {
      flex-flow: column wrap;
      padding: 0;

      img {
        border-radius: 4px;
        display: block;
        height: 100%;
        margin-bottom: 30px;
        max-height: 152px;
        max-width: 228px;

        &.horizontal {
          height: auto;
          width: 100%;
        }
        @media #{$bp-medium} {
          height: auto;
          margin-bottom: 45px;
          max-height: 160px;
          max-width: 160px;
        }
        @media #{$bp-large} {
          max-height: 255px;
          max-width: 255px;
        }
      }

      i {
        bottom: -21px;
        left: 16px;
        top: auto;

        @media #{$bp-medium} {
          bottom: -27px;
          left: 25px;
        }
        @media #{$bp-large} {
          bottom: -41px;
          left: 27px;
        }
      }

      p {
        @media #{$bp-medium} {
          text-align: left;
        }
      }

      span {
        display: block;
      }

      .subline-1 {
        margin: 2rem 0 0;
        @media #{$bp-medium} {
          font-size: calc(2em / 3);
          line-height: 1.25em;
        }
      }

      .subline-2 {
        @media #{$bp-medium} {
          font-size: 0.5em;
          line-height: 1.2em;
        }
      }

      .text-content {
        @media #{$bp-medium} {
          align-items: flex-start;
          margin-left: 20px;
        }
      }
      @media #{$bp-medium} {
        align-items: flex-start;
        flex-flow: row nowrap;
        width: 600px;
      }
      @media #{$bp-large} {
        width: 730px;
      }
    }
  }

  .img-container {
    position: relative;
  }
  @media #{$bp-medium} {
    font-size: 24px;
  }
  @media #{$bp-large} {
    font-size: 32px;
  }
  /* only for Safari 10.1 */
  /* stylelint-disable */
  @media not all and (min-resolution: 0.001dpcm) {
    .wimg .text-content {
      @supports (-webkit-appearance: none) and (not (stroke-color: transparent)) {
        display: initial !important;
        text-align: center;
      }
    }
  }
}
