$company: 'cpl';

//Font Families
$font-base: 'Open Sans', sans-serif;

//Text Colors
$text-color-6: rgba(187, 182, 182, 1);

//Element Colors
$accent-color: rgba(28, 26, 27, 1);
$accent-color-70: rgba(28, 26, 27, 0.7);
$accent-color-30: rgba(28, 26, 27, 0.3);
$accent-color1: rgba(234, 67, 53, 1);
$accent-color2: rgb(179, 159, 159);
$accent-color3: rgba(255, 255, 255, 1);
$accent-color4: rgba(52, 168, 83, 1);
$accent-color5: rgba(251, 188, 5, 1);
$accent-color6: rgba(66, 133, 244, 1);

//Shade & Area Colors ------------------------------------
$area-color-21: rgba(111, 111, 111, 1);
$area-color-22: rgba(192, 194, 198, 1);
$area-color-23: rgba(255, 0, 128, 1);

//Text Colors
$text-color-2: rgba(28, 26, 27, 1);

// Baukasten Color-palette
$color-palette-1: rgba(230, 210, 185, 1);
$color-palette-2: rgba(176, 156, 137, 1);
$color-palette-3: rgba(118, 120, 124, 1);
$color-palette-4: rgba(180, 188, 196, 1);
$color-palette-5: rgba(168, 172, 189, 1);

//Standard text-color
$text-color-3: rgba(28, 26, 27, 1);

//Printcard ---------------------------------
$printcard-yellow: rgb(251, 187, 0);
$printcard-red: rgb(232, 62, 47);
$printcard-green: rgb(45, 169, 81);
$printcard-blue: rgb(67, 120, 188);
