.mobile-nav {
  $spacing-left: 79px;
  box-shadow: $box-shadow-1;
  display: block;
  height: $topnav-height-mobile;
  @media #{$bp-large} {
    display: none;
  }

  .icon-simple-arrow-right::before {
    background-color: $nav-font-color;
    content: '\0000a0';
    mask: url('/group/static/icon-arrow.svg') no-repeat 50% 50%;
  }

  .icon-search::before {
    background-color: $nav-font-color;
    content: '\0000a0';
    mask: url('/group/static/icon-search.svg') no-repeat 50% 50%;
  }

  .icon-menu::before {
    background-color: $nav-font-color;
    content: '\0000a0';
    mask: url('/group/static/icon-burger.svg') no-repeat 50% 50%;
  }

  .icon-cancel-close::before {
    background-color: $nav-font-color;
    content: '\0000a0';
    mask: url('/group/static/icon-close.svg') no-repeat 50% 50%;
  }

  .about-block-container {
    width: 100%;
  }

  .profile-mobile {
    padding-inline-start: 27px !important;
  }

  .link-hidden {
    display: none;
  }

  .hidden {
    cursor: pointer;
  }

  a {
    max-width: 100%;
    position: relative;

    &::after {
      left: calc(100% + 5px);
    }
  }

  i {
    cursor: pointer;
    font-size: 24px;
  }

  &.desktop {
    //mobile nav on desktop touch device
    background-color: $area-color-1;

    .menu-wrap {
      margin: 0 auto;
      max-width: 1110px;
      width: 100%;
    }
  }

  .icon-simple-arrow-right {
    &::before {
      font-size: 21px;
    }
  }

  .content {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    height: 100%;
    justify-content: space-between;
    margin: 0 auto;
    padding-left: 1rem;
    width: 100%;
  }

  .navigation-logo-link {
    align-items: center;
    display: flex;
    height: 100%;
    max-width: calc(100% - 160px);
    width: auto;

    .navigation-logo {
      height: 36px;
      max-height: max-content;
      max-width: 100%;
      object-fit: contain;
      width: auto;
    }
  }

  .menu-block {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-grow: 2;
    height: 100%;
    justify-content: flex-end;
    margin: 0;
    width: auto;

    i {
      align-items: center;
      display: flex;
      justify-content: center;
      width: 80px;
    }

    &.search-active {
      .close-search-icon {
        display: flex;
      }
    }
  }

  .search-container {
    background: $area-color-1;
    box-shadow: $box-shadow-1;
    display: none;
    left: 0;
    position: absolute;
    top: calc(var(--topnav-height) + var(--meta-nav-height));
    width: 100%;

    &.is-active {
      display: flex;
    }

    .search-button {
      @include button();
      color: white !important;
      height: 54px;
      margin-right: 1rem;
    }
  }

  .search-wrap {
    align-items: center;
    background-color: $area-color-1;
    border: 3px solid;
    border-color: $nav-font-color;
    border-radius: 5px;
    display: flex;
    flex-direction: row-reverse;
    height: 54px;
    margin: 0 1rem 2rem;
    transition: border-colsor 600ms;
    width: 100%;

    .search-icon {
      left: 1.75rem;
      position: absolute;
    }

    .icon-search::before {
      background-size: 24px;
      height: 24px;
      margin-right: 20px;
      width: 24px;
    }

    .search-form {
      position: relative;
      width: 100%;

      i {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      input {
        font-size: 18px;
        height: 27px;
        opacity: 1;
        padding-left: 2.25rem;
      }
    }
  }

  .first-level {
    background-color: $meta-nav-background;
    height: calc(100svh - var(--topnav-height) - var(--meta-nav-top));
    margin: 0;
    overflow-y: scroll;
    position: relative;
    width: 100vw;

    .part-of {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      line-height: 12px;
      margin: 48px 0 20px 40px;

      .description {
        border: 0;
        color: $part-of;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.2;
      }

      .groupname {
        border: 0;
        color: $part-of;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.2;
      }

      > * {
        display: block;
      }
    }
  }

  .label {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 1.25rem;
    font-weight: 600;
    height: 100%;
    justify-content: space-between;
    line-height: 1.875rem;
    margin: 0 1rem;

    .link-hoverable {
      max-width: 90%;
    }

    i {
      line-height: 39px;
      width: 1.5rem;
    }
  }

  .first {
    background-color: $area-color-1;
    margin: 0;

    .nav-second-block {
      background-color: $meta-nav-background;
    }

    &.is-active {
      height: 100%;

      span {
        min-height: 45px;
      }
    }

    .second-level {
      background-color: $area-color-1;

      .label {
        font-size: 1.15rem;
        font-weight: 600;
        line-height: 1.875rem;
      }
    }
  }

  .second-detail {
    display: none;
  }

  .level {
    > ul {
      display: none;
    }

    &.is-active {
      > ul {
        display: block;
      }
    }
  }

  a.label {
    padding-left: 30px;
  }

  span,
  a.label {
    border-bottom: 1px solid $area-color-11;
    padding: 1rem 0;

    &.collapsed {
      border: none;
    }

    span {
      color: $text-color-3;
      font-size: 24px;
      font-weight: 600;
      left: $spacing-left;
      position: relative;
      text-decoration: none;
    }
  }

  li.is-active,
  .go-back {
    > .label {
      padding-left: 0;
    }

    > span {
      flex-direction: row-reverse;
      justify-content: flex-end;

      i {
        margin-right: 5px;
        margin-top: -6px;
        transform: rotate(180deg) translateY(-3px);
      }

      a {
        left: 0;
        width: 90%;
      }
    }
  }

  li.overview.is-active {
    background-color: $area-color-1;

    > span {
      a {
        color: $accent-color;
        font-weight: 700;
      }

      a::before {
        content: attr(data-prefix) '';
      }

      i {
        display: none;
      }
    }
  }

  li.current-position > span {
    font-weight: bold;
  }

  .go-home {
    align-items: center;
    display: flex;
    gap: 0.15rem;

    i {
      font-size: 16px;
    }
  }
}
