.image-slider-pks {
  $main-color: $accent-color;
  $secondary-color: transparentize($shade-type-2, 0.2);
  overflow: hidden;
  position: relative;

  .slide-container {
    display: flex;
    flex-flow: row nowrap;
    @media #{$bp-medium} {
      height: calc(558px + 4rem);
    }
  }

  .slide {
    display: flex;
    flex: 0 0 calc(100vw - (100vw - 100%)); // VW without Scrollbar
    flex-direction: column;
    overflow: hidden;
    padding: 0 calc((100% - 1110px) / 2);
    position: relative;
    transition: transform 200ms ease-in-out;

    @media #{$bp-medium} {
      display: grid;
      grid-template-rows: 1fr max-content;
      height: 100%;
    }

    &.left {
      place-content: end left;
    }

    &.right {
      place-content: end right;
    }

    > a {
      inset: 0;
      position: absolute;
    }

    img {
      height: 100%;
      left: 0;
      object-fit: cover;
      top: 0;
      width: 100%;

      @media #{$bp-medium} {
        height: calc(100% - 4rem);
        margin-bottom: 4rem;
        position: absolute;
      }
    }

    @media #{$bp-medium} {
      // padding: 0 50px; // Left right padding ist arrow button width + 2px;
    }

    .image-wrap {
      position: relative;

      @media #{$bp-medium} {
        position: unset;
      }
    }

    .preview-image-wrap {
      height: 100%;
      left: 0;
      overflow: hidden;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;

      @media #{$bp-medium} {
        height: calc(100% - 4rem);
        margin-bottom: 4rem;
      }
    }

    .preview-image {
      filter: blur(3vw);
      height: 100%;
      transform: scale(1.1);
    }

    img.first {
      opacity: 0; // opacity rule will be overwritten by js when img is loaded
      transition: opacity 0.3s ease;
    }
  }

  .text-card {
    align-items: flex-start;
    background-color: transparentize($color: $accent-color, $amount: 0.2);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    display: flex;
    flex: 1 0;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    justify-self: flex-end;

    padding: 4rem 2rem;
    position: relative;
    width: 100%;

    @media #{$bp-medium} {
      height: 384px;
      width: 384px;
      // height: 100%;
      // min-height: 346px;
      // width: 346px;
      // max-width: 410px;
    }

    .title,
    .teaser {
      background-color: unset;
      color: $text-color-1;
      display: block;
      font-family: $font-base;
      letter-spacing: 0;
      opacity: 1;
      overflow-wrap: break-word;
    }

    .title {
      font: normal normal 300 32px/40px $font-base;
    }

    .teaser {
      font: normal normal 600 32px/40px $font-base;
    }

    .light {
      color: $text-color-1;
    }

    .dark {
      color: $text-color-3;
    }
  }

  .tabs-container {
    display: flex;
    justify-content: center;
    margin: 0 0 1rem;
    position: relative;
    top: -2rem;
    width: 100%;

    @media #{$bp-medium} {
      top: -6rem;
    }

    li {
      cursor: pointer;
      flex-grow: 1;
      height: 18px;
      margin-right: 1rem;
      padding: 7px;
      position: relative;
      width: 24px;

      &::before {
        background-color: $indicator-inactive-color;
        border: 1px solid $indicator-inactive-color;
        content: '';
        height: 1px;
        left: 50%;
        max-width: 30px;
        outline: 0;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 30px;
      }

      &.active {
        &::before {
          background-color: var(
            --imageslider-indicator-color,
            #{$indicator-active-color}
          );
          border-color: var(
            --imageslider-indicator-color,
            #{$indicator-active-color}
          );
        }
      }
    }

    .controls {
      align-items: center;
      padding: 0;
    }
  }

  .controls {
    display: flex;
    list-style: none;
    margin: 0;
  }

  .arrow {
    align-items: center;
    display: flex;
    height: 35px;
    justify-content: center;
    position: absolute;
    top: 47%;
    transform: translateY(-50%);
    width: 35px;

    @media #{$bp-medium} {
      height: 48px;
      width: 48px;
    }

    i {
      background-color: white;
      cursor: pointer;
      filter: opacity(20%);
      font-size: 25px;
      height: 35px;
      line-height: 1.35;
      width: 35px;

      &.fa-chevron-left {
        color: #5f5f5f;
        padding-left: 7px;
      }

      &.fa-chevron-right {
        color: #5f5f5f;
        padding-left: 11px;
      }

      @media #{$bp-medium} {
        font-size: 28px;
        height: 48px;
        line-height: 1.7;
        width: 48px;

        &.fa-chevron-left {
          padding-left: 12px;
        }

        &.fa-chevron-right {
          padding-left: 15px;
        }
      }
    }
  }

  .left {
    left: 0;
  }

  .right {
    right: 0;
  }
}
