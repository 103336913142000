.image-and-text-separator {
  &.normal {
    padding: 0 0 0 24px;
    @media #{$bp-extra-large} {
      margin-left: calc((100% - 1110px) / 2);
      padding: 0;
    }

    .background {
      position: absolute;
      right: 0;
    }

    .right {
      section.section-container {
        padding-right: 0.5rem;
        @media #{$bp-extra-large} {
          padding-right: calc(((100vw - 1110px) / 2) - 0.5rem);
        }
      }
    }
  }

  &.reverse {
    padding: 0 24px 0 0;
    @media #{$bp-extra-large} {
      margin-right: calc((100% - 1110px) / 2);
      padding: 0;
    }

    .background {
      left: 0;
      position: absolute;
    }

    .right {
      section.section-container {
        @media #{$bp-extra-large} {
          padding-left: calc(((100vw - 1110px) / 2) - 0.5rem);
        }
      }
    }
  }

  .richtext-content {
    color: var(--text-color, #{$text-color-1});
    margin: 0;

    &.small-text p {
      font-size: 18px;
    }

    p {
      font-size: 18px;
      @media #{$bp-medium} {
        font-size: 20px;
      }

      @media #{$bp-large} {
        font-size: 30px;
      }
    }

    * {
      color: var(--text-color, #{$text-color-1});
    }
  }

  .wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-wrap: nowrap;
    overflow: hidden;

    @media #{$bp-medium} {
      flex-direction: row;
    }

    @media #{$bp-extra-large} {
      min-height: 450px;
      width: calc(100% - (100% - 1110px) / 2);
    }

    .right {
      background-color: transparent;
      flex: 1;
      justify-content: space-between;
      padding: 0;
      position: relative;

      .section-container {
        padding: 2rem 1rem;
        position: relative;

        @media #{$bp-large} {
        }

        @media #{$bp-large} {
          padding: 4rem 2rem;
          top: 0;
        }
      }
    }

    .left {
      flex-basis: 33.33%;
      @media #{$bp-extra-large} {
        flex-basis: 555px;
      }

      img {
        object-fit: cover;
      }
    }

    .background {
      background-color: var(--main-color, #{$accent-color});
      height: 100%;
      width: 100%;

      &.animate-fill-background {
        @media #{$bp-large} {
          background-color: transparent;
          width: 0%;
        }

        &.animation-applied {
          @media #{$bp-large} {
            animation: 0.4s increasewidth forwards;
            background-color: var(--main-color, #{$accent-color});
          }
        }
      }
    }
  }

  &.reverse .wrap {
    @media #{$bp-medium} {
      flex-direction: row-reverse;
    }

    .more-button {
      align-self: flex-start;
    }
  }

  .more-button {
    @include button-block(var(--button-color, #{$accent-color}));
    margin: 2rem 0 0;
  }

  img {
    background-position: center;
    display: block;
    height: 100%;
    width: 100%;
  }

  section {
    display: block;
    width: 100%;
  }
}
