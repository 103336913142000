.two-column-cards {
  clear: both;
  display: table;

  &.side-by-side {
    .left,
    .right {
      width: 100%;

      @media #{$bp-medium} {
        padding: 3rem 4rem;
        width: calc(50% - 20px);
      }
    }

    .left {
      margin-top: 0;
      @media #{$bp-large} {
      }
    }

    .right {
      margin-left: 0;
      margin-top: 0;

      @media #{$bp-medium} {
        margin-left: 40px;
      }
    }

    .video-container {
      top: 24px;

      @media #{$bp-medium} {
        top: 40px;
      }
    }

    .logo {
      margin-bottom: 1rem;
    }
  }

  .logo-set {
    .left {
      @media #{$bp-medium} {
        margin-top: -4rem;
      }
    }

    .right {
      margin-top: 0;
    }
  }

  .logo {
    align-items: end;
    display: flex;
    height: 8rem;
    justify-content: flex-end;
    margin-bottom: 1rem;
    text-align: right;
    width: 100%;

    @media #{$bp-medium} {
      margin-bottom: unset;
    }

    img {
      height: max-content;
    }
  }

  .left,
  .right {
    background: var(--twocolumncard-background-color);
    border-color: var(--twocolumncard-border-color);
    border-radius: 6px;
    border-style: solid;
    border-width: 2px;
    display: flex;
    flex-direction: column;
    float: none;
    // justify-content: center;
    padding: 2rem 1rem;
    width: 100%;

    p {
      color: var(--twocolumncard-text-color);
      margin-top: 0;

      * {
        color: inherit;
      }
    }

    ul,
    ol {
      padding-left: 20px;

      > li,
      li::marker {
        color: var(--twocolumncard-text-color) !important;
      }

      > li * {
        color: inherit;
      }
    }

    @media #{$bp-medium} {
      float: left;
      padding: 3rem 5rem;
      width: 53%;
    }
  }

  .left {
    margin-bottom: 24px;
    margin-top: 0;
  }

  .right {
    margin-left: 0;
    position: relative;
    z-index: 1;
    /* 
    .more-button {
      background-color: var(--twocolumncard-background-color);
      border-color: var(--twocolumncard-border-color);
      color: var(--twocolumncard-text-color);

      &:hover {
        background-color: var(--twocolumncard-background-hover-color);
        border-color: var(--twocolumncard-border-hover-color);
        color: var(--twocolumncard-text-hover-color);
      }
    } */

    @media #{$bp-medium} {
      margin: 4rem 0 0 -6%;
    }
  }

  .module-header {
    margin-bottom: 0;

    i {
      border: 2px solid;
      border-color: var(--twocolumncard-border-color);
      border-radius: 50%;
      color: var(--twocolumncard-text-color);
      height: 55px;
      padding: 15px;
      text-align: center;
      width: 55px;
    }

    p {
      font-weight: 200;
      margin: 0.5rem 0;
    }

    h3,
    p {
      color: var(--twocolumncard-text-color);
    }
  }

  .video-container {
    align-items: flex-end;
    clear: both;
    display: block;
    position: relative;
    top: 24px;
    width: 100%;

    @media #{$bp-medium} {
      display: flex;
      top: -2rem;
    }

    .cookie {
      margin-top: 128px;
    }

    .video {
      border-radius: 6px;
      height: 360px;
      margin: 0 0 24px;
      overflow: hidden;

      @media #{$bp-medium} {
        margin: 0 0 0 4rem;
      }
    }

    .text {
      color: $text-color-1;
      margin-left: 0;
      width: 100%;

      @media #{$bp-medium} {
        margin-left: 2rem;
        width: calc(40% - 6rem);
      }

      i {
        border: 2px solid $text-color-3;
        border-radius: 50%;
        height: 55px;
        padding: 17px 20px;
        text-align: center;
        width: 55px;
      }

      p {
        font-size: 0.9rem;
        margin-bottom: 0;
      }
    }
  }
}
