.copyright-container {
  .copyright {
    display: none;

    .copyright-row {
      font-size: 11px;
      margin: 2px;
    }
  }

  h5 {
    font-size: 11px;
    margin: 10px 0;
  }

  .copyright-divider {
    border: 0.5px solid $border-color-2;
    margin-bottom: 12px;
  }

  .toggle-copyright {
    color: $text-color-3;
    font-size: 11px;
    width: fit-content !important;

    &:hover {
      cursor: pointer;
    }
  }
}
