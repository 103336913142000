/* stylelint-disable aditayvm/at-rule-no-children */

.seperator {
  hr {
    &.onlygap {
      border: 0;
      border-color: transparent;
      color: transparent;
      height: 1px;
    }
  }

  .dotted {
    border: 0;
    border-bottom: 1px dashed;
    border-color: $area-color-5;

    &.accent {
      // Legacy
      border-color: $accent-color;
    }

    @each $name, $value in $global-palette {
      &.#{$name} {
        border-color: $value;
      }
    }
  }

  .gradient {
    background-image: linear-gradient(
      to right,
      transparent,
      $area-color-5,
      transparent
    );
    border: 0;
    height: 1px;

    &.accent {
      // Legacy
      background-image: linear-gradient(
        to right,
        transparent,
        $accent-color,
        transparent
      );
    }

    @each $name, $value in $global-palette {
      &.#{$name} {
        background-image: linear-gradient(
          to right,
          transparent,
          $value,
          transparent
        );
      }
    }
  }

  .shadow {
    border: 0;
    box-shadow: inset 0 12px 12px -12px $area-color-5;
    height: 12px;

    &.accent {
      // Legacy
      box-shadow: inset 0 12px 12px -12px $accent-color;
    }

    @each $name, $value in $global-palette {
      &.#{$name} {
        box-shadow: inset 0 12px 12px -12px $value;
      }
    }
  }

  .line {
    border-color: $area-color-5;
    border-top: 0;
    color: $area-color-5;

    &.accent {
      border-color: $accent-color;
      color: $accent-color;
    }

    @each $name, $value in $global-palette {
      &.#{$name} {
        border-color: $value;
        color: $value;
      }
    }
  }

  .standard {
    margin: 0 0 4rem;
  }

  .paragraph {
    margin: 0 0 2rem;
  }
}
/* stylelint-enable aditayvm/at-rule-no-children */
