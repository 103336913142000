// .dropdown-overlay {
//   align-items: center;
//   background-color: $accent-color;
//   color: $area-color-1;
//   font-size: 100px;
//   // height: 90px;
//   justify-content: center;
//   left: 0;
//   opacity: 0.5;
//   overflow: hidden;
//   position: fixed;
//   right: 0;
//   top: 0;
//   width: 100%;
//   z-index: 1000 !important;
// }

@keyframes new-item {
  0% {
    background-color: $background-color-alternate;
  }

  30% {
    background-color: $background-color;
  }

  100% {
    background-color: $background-color-alternate;
  }
}

.form {
  .checkmark,
  input,
  select,
  textarea,
  .dummy-input {
    background-color: var(--block-color, #{$area-color-4});
  }
}

.alternative-color {
  .form {
    .checkmark,
    input,
    select,
    textarea,
    .dummy-input {
      background-color: var(--block-color, #{$area-color-1});
    }

    .checkmark::before {
      background-color: $transparent;
    }
  }
}

article {
  > section {
    .hbspt-form {
      input,
      select,
      textarea {
        appearance: unset !important;
      }
    }

    .hbspt-form,
    .form {
      .checkmark,
      input,
      select,
      textarea,
      .dummy-input {
        background-color: var(--block-color, #{$area-color-4});
      }

      .checkmark::before {
        color: $text-color-1;
      }
    }

    &.alternative-color {
      padding: 4rem 0 1px;

      .form {
        .checkmark,
        input,
        select,
        textarea,
        .dummy-input {
          background-color: var(--block-color, #{$area-color-1});
        }

        .checkmark::before {
          background-color: $transparent;
        }
      }
    }
  }
}

.variation-bg-alternative-color {
  .form {
    .checkmark,
    input,
    select,
    textarea {
      background-color: var(--block-color, #{$area-color-1});
    }

    .checkmark::before {
      background-color: $transparent;
    }
  }
}

.hbspt-form {
  [id*='LEGAL'] {
    &.hs-input {
      align-items: center;
      appearance: checkbox !important;
      display: flex;
      height: 20px;
      margin-right: 10px;
      width: 20px;
    }
  }

  .hs-form-booleancheckbox {
    align-items: center;
    display: flex;
    margin-left: 17px;

    &::before {
      margin-right: 0;
    }
  }

  .hs-form-booleancheckbox-display {
    align-items: center;
    display: inline-flex;
    margin-left: 0;
    width: 90%;

    span {
      position: relative;
      top: 1px;
    }
  }
}

.form,
.hbspt-form {
  form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    overflow: hidden;
    overflow-wrap: anywhere;
  }

  ul {
    li {
      &::before {
        display: none;
      }

      label {
        color: $text-color-3;
      }
    }
  }

  a {
    color: $accent-color;
  }

  .half-column {
    flex-basis: 100%;
    margin-bottom: 21px;

    input,
    select,
    textarea {
      width: 100%;
    }

    @media #{$bp-medium} {
      display: inline-block;
      flex-basis: calc(50% - 17px);
    }

    &.sidebar {
      @media #{$bp-large} {
        flex-basis: 100%;
        margin-bottom: 21px;
      }
    }
  }

  .full-column,
  .hs-form-field {
    flex-basis: 100%;
    margin-bottom: 21px;
    //ist zu viel :/
    // margin-top: 20px;

    input,
    select,
    textarea {
      width: 100%;

      @media #{$bp-medium} {
        width: 100%;
      }
    }
  }

  .date,
  .dropdown,
  .mail,
  .money,
  .number,
  .phone,
  .text,
  .textarea,
  .upload {
    margin-bottom: 21px;
    vertical-align: top;
  }

  input,
  select,
  textarea,
  .dummy-input {
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    padding: 14px 0 14px 10px;

    &:focus {
      outline-color: $accent-color;
    }
  }

  input,
  select {
    height: 48px;
  }

  label {
    color: $text-color-3;
    display: block;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 3px;
  }

  textarea {
    height: 215px;
  }

  .date {
    input {
      appearance: textfield;
    }
  }

  .upload {
    input {
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      width: 0.1px;
      z-index: -1;
    }
  }

  .upload-label {
    cursor: pointer;

    span {
      color: $accent-color;
      line-height: 40px;
      text-transform: none;
      vertical-align: bottom;
    }
  }

  .upload-add {
    align-items: center;
    background-color: $accent-color;
    border-radius: 50%;
    display: inline-block;
    display: inline-flex;
    height: 40px;
    justify-content: center;
    margin-right: 20px;
    width: 40px;

    .icon {
      color: $text-color-1;
      font-size: 16px;

      &::before {
        font-weight: bold;
        margin: 0;
        transform: rotate(45deg);
      }
    }

    &.selected {
      background-color: $area-color-6;

      .icon::before {
        transform: rotate(0deg);
      }
    }
  }

  .checkbox {
    display: inline-flex;
    margin-left: 5px;
    overflow: hidden;

    .container {
      height: 30px;
      width: 30px;
    }

    .checkmark {
      border-radius: 4px;
      cursor: pointer;
      display: block;
      height: 100%;
      width: 100%;

      &::before {
        font-size: 21px;
        opacity: 0;
        padding-top: 3px;
      }
    }

    div.container {
      height: 30px;
      margin-right: 20px;
      width: 30px;
    }

    div.container-text {
      margin-left: 15px;
      margin-top: 1px;
    }

    label {
      cursor: pointer;
      display: inline-block;
      font-size: 16px;
      font-weight: 400;
      height: 30px;
      line-height: 22px;
      //margin-bottom: 21px;
      position: relative;
      text-transform: none;
      user-select: none;
      width: 30px;

      a {
        color: $accent-color;
        font-weight: 500;
      }
    }
  }

  .checkbox-input {
    cursor: pointer;
    display: none;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;

    &:checked {
      ~ span.checkmark {
        background-color: $accent-color;

        &::before {
          opacity: 1;
        }
      }
    }
  }

  .hs-button {
    @include button($accent-color);
    margin: 0 5px 30px 0;
  }

  .hs-submit {
    width: 100%;

    div {
      input {
        margin-top: 25px;
        width: 100%;
      }
    }
  }

  .form-buttons {
    margin-top: 21px;

    button {
      @include button(var(--button-color, #{$accent-color}));
      appearance: initial;
      margin: 0 5px 30px 0;

      &:last-child {
        margin: 0;
      }

      @media #{$bp-medium} {
        margin: 0 30px 30px 0;
      }
    }

    span {
      float: right;
    }
  }

  .error {
    border: 2px solid $validation-color;
  }

  .error-text {
    color: $validation-color;
    display: block;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }

  .info-line {
    font-size: 14px;
    margin-bottom: 0;
    margin-top: 0;
    position: relative;
    text-align: right;
    top: -15px;
  }
}

.modal.multi-upload-modal {
  .modal-window {
    @media #{$bp-medium} {
      margin-top: 15vh;
    }
  }
  @media #{$bp-medium} {
    justify-content: flex-start;
  }
}

.multi-file-upload {
  ul {
    list-style: none;
    max-height: 60vh;
    overflow: auto;
    padding: 0;
  }

  li {
    align-items: center;
    animation: new-item 200ms;
    background-color: $background-color-alternate;
    border-radius: 4px;
    display: flex;
    flex-flow: row nowrap;
    height: 2em;
    margin-bottom: 5px;
    overflow: hidden;
    width: 100%;

    .file-name {
      flex: 0 0 50%;
      overflow: hidden;
      padding: 0 10px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .file-type {
      flex: 0 0 20%;
    }

    .file-size {
      flex: 0 0 20%;
    }

    button {
      background-color: transparent;
      border: 0;
      flex: 0 0 10%;
      outline: 0;

      &:hover {
        i::before {
          text-shadow: 0 0 1px $text-color-3;
        }
      }
    }
  }

  .upload-group {
    label {
      color: $accent-color;
      cursor: pointer;

      .fab-style {
        align-items: center;
        background-color: $accent-color;
        border-radius: 50%;
        display: inline-block;
        display: inline-flex;
        height: 40px;
        justify-content: center;
        margin-right: 20px;
        width: 40px;

        &:hover {
          box-shadow: 0 0 0 1px $shade-type-6;
        }
      }

      i::before {
        color: $text-color-1;
        font-weight: bold;
      }
    }

    input {
      display: none;
    }
  }

  .action-buttons {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;

    .done-button,
    .close-button {
      margin-top: 15px;
    }

    .done-button {
      @include button;
    }

    .close-button {
      @include button-outline;
    }
    @media #{$bp-medium} {
      flex-flow: row nowrap;
    }
  }
}
