.content-row[data-parallax-url] {
  .module,
  .module-full {
    h2 {
      background-color: transparentize($accent-color, 0.4);
      color: $text-color-1;
      margin: 24px 0 10px;
      margin-right: auto;
      padding: 15px;
    }

    .module-header p,
    span.subheadline {
      background-color: transparentize($area-color-15, 0.4);
      color: $text-color-1;
      display: inline-block;
      line-height: 22px;
      padding: 15px;
    }
  }
}

.parallax {
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  transform-origin: top;
  z-index: -1;
}
