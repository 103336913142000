%modal {
  * {
    color: $text-color-1;
  }

  .modal-container {
    align-items: center;
    background-color: transparentize($area-color-1, 0.2);
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }

  .diagram-modal {
    display: flex;
    flex-flow: column nowrap;
    //display: grid;
    //grid-template-columns: 30px auto 30px;
    max-height: 90%;
    overflow-y: auto;
    padding: 40px;
    place-self: center;
    position: relative;
    // row-gap: 30px;
    width: 95%;

    @media #{$bp-small} {
      width: 435px;
    }

    /*     &::after {
      content: '';
      grid-column: 2;
      height: 10px;
      width: 100%;
    } */

    * {
      grid-column: 2;
    }

    .modal-title {
      font-size: 18px;
      // margin-top: 30px;
      text-align: center;

      @media #{$bp-medium} {
        font-size: 20px;
      }
      @media #{$bp-large} {
        font-size: 24px;
      }
    }

    .plus-icon {
      grid-column: 3;
      grid-row: 1;
      padding: 5px;
      place-self: start end;
      position: absolute;
      right: 13px;
      top: 13px;
      transform: rotate(45deg);
    }

    .modal-member + .modal-member {
      margin-top: 30px;
    }

    // Overwrite
    /* stylelint-disable selector-max-compound-selectors */
    .richtext-content ul li::before {
      color: $text-color-1;
    }
  }
}
