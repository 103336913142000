.row-wrapper {
  @extend %content-column;
  height: 150px;
  margin: auto;
  position: relative;

  .center-line {
    // background-image: linear-gradient(
    //   45deg,
    //   $accent-color,
    //   $accent-color 50%,
    //   $border-color-3 50%,
    //   $border-color-3
    // );
    // background-position-y: calc(-50% + 4rem);
    // background-size: 200%;

    background-color: $border-color-3;
  }
  @media #{$bp-medium} {
    overflow: hidden;
  }
}

@include class-prefix('row-block-') {
  width: 100%;
}

.row-block-head,
.row-block-footer-empty {
  height: 80px;
  position: relative;

  &.row-block-head {
    @extend %row-block-head;
  }

  &.row-block-footer-empty {
    @extend %row-block-footer-empty;
  }
}

.row-block-footer {
  @extend %row-block-footer-overrides-content-block;
  @extend %row-block-footer-overrides-center-elements;
  opacity: 0;
  position: relative;
  transition: opacity 400ms ease-in-out 400ms;

  &.visible {
    opacity: 1;
  }

  .row-block-wrapper {
    position: relative;
    @media #{$bp-medium} {
      flex-direction: column;
    }
  }
}

.row-block-normal {
  opacity: 0;
  position: absolute;
  right: 0;

  &.left {
    .row-block-wrapper {
      @media #{$bp-medium} {
        justify-content: flex-start;
      }
    }

    .content-block {
      @media #{$bp-medium} {
        text-align: right;
      }
    }

    .horizontal-line {
      @media #{$bp-medium} {
        transform: translateX(-100%);
      }
    }

    .circle.offset {
      @media #{$bp-medium} {
        left: 0;
        right: auto;
      }
    }
    @media #{$bp-medium} {
      left: 0;
      right: auto;
    }
  }

  &.visible {
    animation-duration: 600ms;
    animation-fill-mode: forwards;
    animation-name: timeline-fadein;
  }

  @media #{$bp-medium} {
    width: 50%;
  }

  @keyframes timeline-fadein {
    from {
      opacity: 0;
      transform: translateY(80vh);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.row-block-normal,
.row-block-footer {
  .row-block-wrapper {
    display: flex;
    justify-content: flex-end;
    position: relative;
  }

  &.hover,
  &:hover {
    .content-block {
      @media #{$bp-medium} {
        border: 1px solid $area-color-4;
      }
    }

    .horizontal-line {
      background-color: $accent-color;
    }

    .circle {
      border-color: $accent-color;
    }

    .colored-center::after {
      transform: scale(1);
    }
  }
}

/* &.shadow .row-block-normal:hover {
  .content-block {
    box-shadow: 15px 15px 45px $area-color-4,
      -15px -15px 45px lighten($area-color-4, 30);
  }
}
 */
