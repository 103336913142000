.image-slider-nextgen {
  .fe-hidden {
    display: none !important;
  }

  /*   .divider-container {
    bottom: 0;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
 */
  .divider-group,
  .divider-general {
    display: flex;
    height: 100%;
    justify-content: center;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);

    @media #{$bp-large} {
      left: 0;
      transform: none;
    }
  }

  .divider-general {
    display: flex;
    width: 100%;

    @media #{$bp-large} {
      width: auto;
    }

    .triangle {
      @media #{$bp-large} {
        border-color: transparent transparent transparent
          var(--imageslider-background-color, #{$area-color-19});
        border-style: solid;
        border-width: 210px 0 210px 120px;
        height: 100%;
        width: 0;
      }
    }

    .chevron-right {
      height: 100%;
      margin: 0;
      margin-bottom: 6px;
      position: absolute;
      text-align: center;
      width: 20px;
      z-index: 1;

      &.one {
        left: 105px;

        @media #{$bp-small} {
          left: 14%;
        }
        @media #{$bp-large} {
          left: 295px;
        }
      }

      &.two {
        left: 270px;

        @media #{$bp-small} {
          left: 39%;
        }

        @media #{$bp-large} {
          left: calc(275px * 2);
        }
      }

      &.three {
        display: none;

        @media #{$bp-small} {
          display: block;
          left: 64%;
        }

        @media #{$bp-large} {
          display: none;
        }

        @media #{$bp-extra-large} {
          display: block;
          left: calc(275px * 3 - 20px);
        }
      }

      &.four {
        display: none;
        @media #{$bp-small} {
          display: block;
          left: 89%;
        }

        @media #{$bp-large} {
          display: none;
        }
      }

      @media #{$bp-large} {
        width: 35px;
      }
    }

    .chevron-right::before {
      background: $area-color-19;
      content: '';
      height: 50%;
      left: -7px;
      position: absolute;
      top: 50%;
      transform: skew(-30deg);
      width: 51%;

      @media #{$bp-large} {
        left: 0;
      }
    }

    .chevron-right::after {
      background: $area-color-19;
      content: '';
      height: 50%;
      position: absolute;
      right: 17px;
      top: 0;
      transform: skew(30deg);
      width: 50%;
    }
  }

  .divider-group {
    width: 1000px;

    @media #{$bp-large} {
      justify-content: flex-start;
    }

    .chevron-spacing {
      height: 100%;
      margin: 0;
    }

    .zero {
      &.chevron-spacing::before,
      &.chevron-spacing::after {
        // background: transparentize($area-color-13, 0.5);

        @media #{$bp-small} {
          background: transparentize($area-color-2, 0.5);
          width: 300px;
        }

        @media #{$bp-large} {
          display: none;
        }
      }
    }

    .one {
      &.chevron-spacing::before,
      &.chevron-spacing::after {
        // background: transparentize($area-color-13, 0.5);
        background: transparentize($accent-color, 0.5);
        width: 300px;

        @media #{$bp-small} {
          width: 150px;
        }

        @media #{$bp-large} {
          width: 235px;
        }
      }
    }

    .two {
      &.chevron-spacing::before,
      &.chevron-spacing::after {
        background: transparentize($area-color-12, 0.5);
        width: 150px;
        @media #{$bp-large} {
          width: 235px;
        }
      }
    }

    .three {
      &.chevron-spacing::before,
      &.chevron-spacing::after {
        background: transparentize($area-color-13, 0.5);
        width: 300px;

        @media #{$bp-small} {
          width: 150px;
        }
        @media #{$bp-large} {
          width: 235px;
        }
      }
    }

    .four {
      &.chevron-spacing::before,
      &.chevron-spacing::after {
        // background: transparentize($area-color-13, 0.5);

        @media #{$bp-small} {
          background: transparentize($area-color-2, 0.5);
          width: 300px;
        }

        @media #{$bp-large} {
          display: none;
        }

        @media #{$bp-extra-large} {
          display: block;
          width: 235px;
        }
      }
    }

    .chevron-spacing::after {
      content: '';
      display: block;
      height: 50%;
      top: -5px;
      transform: skew(-30deg);
      width: 100%;
      @media #{$bp-large} {
        left: 0;
      }
    }

    .chevron-spacing::before {
      content: '';
      display: block;
      height: 50%;
      transform: skew(30deg);
      width: 100%;
    }

    .triangle {
      display: none;
      @media #{$bp-large} {
        border-color: transparent transparent transparent
          var(--imageslider-background-color, #{$area-color-19});
        border-style: solid;
        border-width: 210px 0 210px 120px;
        display: block;
        height: 100%;
        margin-right: -60px;
        width: 0;
        z-index: 10;
      }
    }

    .chevron-right {
      height: 100%;
      margin: 0;
      margin-bottom: 6px;
      width: 10px;
      z-index: 10;

      &.one {
        display: none;

        @media #{$bp-small} {
          display: block;
        }

        @media #{$bp-large} {
          display: none;
        }
      }

      &.four {
        display: none;
        @media #{$bp-small} {
          display: block;
        }

        @media #{$bp-large} {
          display: none;
        }

        @media #{$bp-extra-large} {
          display: block;
        }
      }
    }

    .chevron-right::after {
      background: $area-color-19;
      content: '';
      display: block;
      height: 50%;
      transform: skew(-30deg);
      width: 10px;

      @media #{$bp-large} {
        width: 17px;
      }
    }

    .chevron-right::before {
      background: $area-color-19;
      content: '';
      display: block;
      height: 50%;
      top: 0;
      transform: skew(30deg);
      width: 10px;

      @media #{$bp-large} {
        width: 17px;
      }
    }
  }
}
