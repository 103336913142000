@import '../base/companies';

.attribution-flag {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  color: $text-color-1;
  height: max-content;
  padding: 0.2rem 0.4rem;
  right: 24px;
  transform: none;
  transition: transform 300ms ease-out;
  z-index: -1;

  @each $name, $color in $companies {
    /* stylelint-disable aditayvm/at-rule-no-children */
    &.#{$name} {
      background-color: $color;
    }
    /* stylelint-enable aditayvm/at-rule-no-children */
  }

  &.collapsed {
    transform: translateY(-100%);
  }

  * {
    color: inherit;
    font-size: 80%;
  }
}
