.totop-button {
  background: $accent-color;
  border-radius: 35px;
  bottom: 15px;
  display: block;
  height: 52px;
  position: fixed;
  right: 20px;
  text-decoration: none;
  transform: translateY(calc(100% + 20px));
  transition: transform 200ms ease-out;
  width: 52px;
  z-index: 998;

  .icon-arrow-up {
    &::before {
      margin-right: 0.15em;
    }
  }

  &.visible {
    transform: translateY(0);
  }

  > i {
    align-items: center;
    color: $text-color-1;
    display: flex;
    font-size: 24px;
    height: 50px;
    justify-content: center;
    margin: 0;
    position: relative;
  }
}

.totop-button:hover {
  background: transparentize($shade-type-1, 0.81);
  cursor: pointer;
}

.totop-button:hover i {
  color: $accent-color;
}
