.root-flex-slider {
  // @extend %content-column;
  @extend %hidescrollbar;
  display: flex;
  flex-wrap: nowrap;
  margin: 0 24px;
  overflow: auto;
  padding: 0;
  position: relative;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  width: 100%;

  @media #{$bp-small} {
    margin: 0 39px;
  }

  @media #{$bp-medium} {
    margin: 0;
    max-width: $max-width-medium;
  }

  @media #{$bp-large} {
    max-width: $max-width-large;
    scroll-snap-type: x mandatory;
  }
  @media #{$bp-extra-large} {
    max-width: $max-width-xl;
  }

  * {
    flex-grow: 0 !important;
    flex-shrink: 0 !important;
  }

  .grid-tile {
    scroll-snap-align: start;

    &:hover {
      @include hover-slider;
    }
  }
}

.navigator {
  align-items: center;
  display: none;
  height: 520px;
  justify-content: center;
  overflow: hidden;
  position: absolute;
  top: 2rem;
  width: 100%;
  z-index: 20;

  @media #{$bp-large} {
    display: flex;
    z-index: 0;
  }

  &.desktop-disabled {
    @media #{$bp-large} {
      display: none;
    }
  }

  .left {
    padding-left: 0;
    transform-origin: right;

    &::before {
      margin-left: -0.2em;

      @media #{$bp-large} {
        margin-left: 0.2em;
      }
    }

    @media #{$bp-large} {
      padding-left: 8;
    }
  }

  .right {
    padding-right: 0;
    transform-origin: left;

    &::before {
      margin-right: -0.1em;

      @media #{$bp-large} {
        margin-right: 0.2em;
      }
    }

    @media #{$bp-large} {
      padding-right: 8;
    }
  }

  .blocker {
    display: block;
    width: calc(100vw - 6rem);

    @media #{$bp-medium} {
      flex-basis: $max-width-medium;
    }
    @media #{$bp-large} {
      flex-basis: $max-width-large;
    }
    @media #{$bp-extra-large} {
      flex-basis: $max-width-xl;
    }
  }
}

.navigator,
.mobile-arrow {
  .arrow-container {
    display: block;
    transform: scale(2.5);
    transition: all 100ms ease-in-out;

    @media #{$bp-large} {
      transform: scale(2);
      z-index: 0;
    }
    @media #{$bp-extra-large} {
      transform: scale(2.5);
    }
    @media #{$bp-extra-large} {
      padding: 10px;
    }
  }

  .arrow {
    background: none;
    border: 0;
    color: $accent-color;
    cursor: pointer;
    outline: 0;
    padding: 0;

    &:disabled {
      color: $text-color-5;
      cursor: auto;
    }
  }
}

.mobile-arrow {
  position: absolute;
  top: 50%;
  z-index: 30;

  @media #{$bp-large} {
    display: none;
  }

  &.left {
    left: 0;
    margin-left: 1rem;

    @media #{$bp-medium} {
      margin-left: 22px;
    }
  }

  &.right {
    margin-right: 1rem;
    right: 0;

    @media #{$bp-medium} {
      margin-right: 24px;
    }
  }

  .arrow {
    &::before {
      margin: 0;
    }
  }
}
