.image-slider-nextgen {
  .divider-general {
    .chevron-right {
      &.one,
      &.two,
      &.three,
      &.four {
        display: none;
      }
    }

    .triangle {
      border-color: transparent transparent transparent
        var(--imageslider-background-color, #{$area-color-16});
      @media #{$bp-large} {
        border-width: 0 0 420px 140px;
      }
    }

    .chevron-spacing {
      display: none;
    }
  }

  .slide-content {
    background-color: var(--imageslider-background-color, #{$area-color-16});
  }

  .text-container {
    .title {
      font-family: $font-base;
      font-size: 30px;
      font-weight: 700;
      line-height: 1.2;
    }

    .teaser {
      font-family: $font-base;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.62;
    }
  }
}
