.onpagenav-container {
  display: none;
  @media #{$bp-extra-large} {
    background-color: $area-color-1;
    border-radius: 0 5px 5px 0;
    border-radius: 5px 5px 0 0;
    box-shadow: 0 0 15px 0 transparentize($area-color-8, 0.6);
    display: flex;
    flex-flow: column nowrap;
    height: auto;
    justify-content: space-between;
    left: 0;
    max-height: 80vh;
    min-width: 300px;
    overflow: hidden;
    overflow-y: auto;
    position: fixed;
    top: 10vh;
    transform: scale(1) translateZ(0);
    transition: all 300ms ease-out;
    width: 20vw;
    z-index: 999;
  }

  &.collapsed {
    transform: translateX(-110%);
  }

  &.minimized {
    transform: translateX(calc(-100% + 1.5rem));

    .wrapper .head .icon::after {
      transform: translateY(-50%) rotateY(-0.5turn);
    }
  }

  .wrapper {
    @extend %hidescrollbar;
    max-height: 100%;
    overflow: scroll;

    .head {
      align-items: stretch;
      background: $area-color-1;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      position: sticky;
      top: 0;

      .icon {
        flex: 0 0 1rem;
        width: 1.5rem;

        &::after {
          @extend %font-icon-style;
          content: '\e821';
          font-size: 1.25rem;
          height: 1.25rem;
          margin: 0;
          position: relative;
          top: 50%;
          transform: translateY(-50%);
          transition: transform 500ms ease-in-out;
          width: 1.5rem;
          // position: absolute;
          // right: 0.25rem;
        }
      }
    }
  }

  ul {
    list-style: none;
    margin-block: 0;
    padding-inline-start: 0;
  }

  a.entry {
    border-bottom: 1px solid $border-color-1;
    display: block;
    line-height: 2.25;
    margin-right: 1.5rem;
    overflow: hidden;
    padding-left: 0.5rem;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.focused {
      color: $text-color-2;
      text-shadow: 0 0 1rem $text-color-2;
    }

    &.h1 {
      //  border-bottom: 0;
      display: inline-block;
      flex-basis: 100%;
      font-size: 1.25rem;
    }
  }

  .h3-list {
    margin-left: 1.5rem;
  }
}
