@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes increasewidth {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

%variable-transition-duration {
  transition-duration: 300ms;
  @media #{$bp-small} {
    transition-duration: 400ms;
  }
  @media #{$bp-medium} {
    transition-duration: 500ms;
  }
  @media #{$bp-large} {
    transition-duration: 600ms;
  }
  @media #{$bp-extra-large} {
    transition-duration: 700ms;
  }
}
