.detailpage {
  @import 'contact-person';
  background-color: $area-color-1;
  text-size-adjust: 100%;

  .detailpage-container {
    > .module {
      border: 0;
      padding-bottom: 0;
      padding-top: 0;
      @media #{$bp-medium} {
        padding: 0;
      }
    }
  }

  .list-container {
    .richtext {
      margin: 0 !important;
    }
  }

  .header-icon {
    color: $accent-color;
    cursor: pointer;
    font-size: 20px;
    height: 20px;
    @media #{$bp-medium} {
      display: none;
    }
  }

  .title-and-icon {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }

  .list-container {
    @media #{$bp-large} {
      width: 60%;
    }
  }

  ul,
  ol {
    li {
      line-height: 29px;
    }
  }

  .richtext-content {
    background-color: $area-color-1;
    border-radius: 5px;
    margin: 2px 0;
    @media #{$bp-medium} {
      background-color: transparent;
    }
    @media #{$bp-medium} {
      margin: 0 0 2rem;
    }

    &:last-child {
      @media #{$bp-medium} {
        margin-bottom: 0;
      }
    }

    > .title-and-icon {
      padding: 19px 10px 0 23px;
      @media #{$bp-medium} {
        padding: 0;
      }
    }

    ul {
      li {
        &::before {
          width: auto;
        }
      }
    }
  }

  /*   .title {
    color: $accent-color;
    padding-bottom: 0 !important;
    @media #{$bp-medium} {
      margin-top: 65px !important;
      padding: 0 !important;
    }
  }
 */
  .richtext-content.active {
    border-left: 8px solid $accent-color;
    border-radius: 5px;
    padding: 1px 0 5px 15px;

    > .title-and-icon {
      padding: 19px 10px 0 0;
      @media #{$bp-medium} {
        padding: 0;
      }
    }
  }

  .list-title {
    color: $accent-color;
    margin: 0;
  }

  h2 {
    padding: 0;
  }

  .richtext-container {
    .content {
      margin-top: 0;
    }
  }

  .overview-container {
    @media #{$bp-large} {
      display: flex;
      justify-content: space-between;
    }
  }

  .details-title {
    color: $accent-color;
    height: 43px;
  }

  .contact-column {
    width: 40%;
  }

  .contact-container-course {
    margin: 20px 0;

    @media #{$bp-large} {
      margin: 0 0 20px 40px;
    }

    .person-wrap {
      display: block;
    }

    .contact-title {
      margin: 0 0 1rem;

      @media #{$bp-medium} {
        margin: 0 0 2rem;
      }
    }

    .contact-person {
      padding: 0;
    }

    .person-career {
      margin: 0;
    }

    /*     .contact-title {
      margin-bottom: 1rem;
    } */

    .person {
      flex: 1;
      margin-right: 0;
    }
  }

  .contact-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
    @media #{$bp-medium} {
      justify-content: start;
      margin-left: -15px;
    }
    @media #{$bp-large} {
      display: block;
      margin: 0;
      width: 40%;
    }

    .person-wrap {
      @media #{$bp-medium} {
        flex: 0 0 100%;
        margin-right: 0;
      }

      .person {
        margin: 0 0 1rem;
        @media #{$bp-medium} {
          flex: 0 0 100%;
          margin: 0 0 2rem;
        }
      }
    }
  }
}
