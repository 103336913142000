%igh-richtext-content {
  @extend %richtext-content;

  * {
    color: $text-color-1;
  }
}

.icon-grid.hover-variant {
  overflow: hidden;

  .grid-logo {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    margin: auto;
    max-width: 200px;
    width: 100%;
  }

  .icon-grid-icon {
    display: flex;
    justify-content: center;
    margin: 0 auto;

    &::before {
      color: $text-color-1;
    }
  }

  .icon-grid-card-paragraph {
    color: $text-color-1;
  }

  &.default,
  &.transparent,
  &.scheme2,
  &.scheme3,
  &.scheme4 {
    .icon-grid-card-paragraph {
      color: $text-color-1;
    }

    //background-color: $area-color-7;

    .icon-grid-card {
      background-color: $area-color-5;
    }

    .icon-grid-card-headline,
    .overlay-paragraph,
    .icon-grid-header *,
    i::before,
    .icon-grid-icon::before {
      color: $text-color-1;
    }

    .icon-grid-card-headline {
      border-bottom-color: $text-color-1;
    }
  }

  &.white {
    .icon-grid-card {
      background-color: $area-color-5;
    }

    [class^='icon-']::before,
    [class*=' icon-']::before {
      display: block;
    }
  }

  &.blue {
    background-color: $accent-color;

    .icon-grid-card {
      background-color: $area-color-8;
    }

    .overlay-paragraph {
      color: $text-color-1;
    }

    .icon-grid-header * {
      color: $area-color-1;
    }

    .icon-grid-card-headline,
    .icon-grid-icon::before {
      color: $accent-color;
    }
  }

  &.dark-blue {
    background-color: $area-color-7;

    .icon-grid-card {
      background-color: $accent-color;
    }

    .icon-grid-card-headline,
    .overlay-paragraph,
    .icon-grid-header *,
    .icon-grid-icon::before {
      color: $text-color-1;
    }

    .icon-grid-card-headline {
      border-bottom-color: $text-color-1;
    }
  }

  &.grey {
    background-color: transparentize($text-color-1, 0.5);

    .icon-grid-card {
      background-color: inherit;
    }

    .icon-grid-card i::before,
    .overlay-paragraph,
    .icon-grid-header *,
    .icon-grid-icon::before {
      color: $area-color-1;
    }

    .icon-grid-card-headline {
      border-bottom-color: $area-color-1;
      color: $area-color-1;
    }
  }

  .icon-grid-card {
    align-items: center;
    background-color: $area-color-5;
    border-radius: 4px;
    display: grid;
    justify-content: center;
    margin: 0;
    min-height: 150px;
    padding: 28px 0;
    position: relative;
    text-align: center;

    @media all and (-ms-high-contrast: none) {
      flex-basis: calc(((100% - 330px) / 12) * 4 + 60px);
      min-width: 0;
    }

    @media #{$bp-medium} {
      padding: 32px 28px;
    }

    @media #{$bp-large} {
      padding: 28px 25px;
    }
  }

  .visual-container {
    height: 200px;
    line-height: 0;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    max-height: 200px;
    max-width: 100%;
    width: 300px;

    @media #{$bp-medium} {
      max-width: 350px;
    }
  }

  ::before {
    color: $text-color-1;
    font-size: 3rem;
  }

  .overlay-paragraph {
    align-items: center;
    background-color: transparentize($accent-color, 0.2);
    border-radius: 4px;
    display: flex;
    flex-flow: wrap;
    font-size: 14px;
    font-weight: 400;
    height: 100%;
    justify-content: center;
    left: 0;
    line-height: 21px;
    margin: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    transition: opacity 0.2s ease-in-out;
    width: 100%;

    &:hover {
      color: $text-color-1;
      opacity: 0.9;
    }

    p {
      @extend %igh-richtext-content;
      @include line-clamp(6, 21px);
      border-radius: 4px;
      color: $text-color-1;
      font-size: 14px;
      font-weight: 400;
      left: 0;
      line-height: 21px;
      margin: 0;
      overflow: hidden;
      padding: 0 15px;
      width: 100%;
      word-wrap: break-word;

      @media #{$bp-medium} {
        -webkit-line-clamp: 7;
        max-height: (21px * 7);
      }
    }

    @media all and (-ms-high-contrast: none) {
      word-wrap: break-all;
    }
  }

  .no-background {
    .icon-grid-card {
      background-color: $area-color-5;
    }
  }
}
