@keyframes top-right-bar {
  0% {
    width: 0;
  }

  20% {
    width: 50%;
  }

  100% {
    width: 50%;
  }
}
@keyframes right-bar {
  0% {
    height: 0;
  }

  20% {
    height: 0;
  }

  30% {
    height: 100%;
  }

  100% {
    height: 100%;
  }
}
@keyframes bottom-bar {
  0% {
    width: 0;
  }

  30% {
    width: 0;
  }

  70% {
    width: 100%;
  }

  100% {
    width: 100%;
  }
}
@keyframes left-bar {
  0% {
    height: 0;
  }

  70% {
    height: 0;
  }

  80% {
    height: 100%;
  }

  100% {
    height: 100%;
  }
}

@keyframes top-left-bar {
  0% {
    width: 0;
  }

  80% {
    width: 0;
  }

  100% {
    width: 50%;
  }
}

@keyframes fadein {
  100% {
    opacity: 1;
  }
}
