.logo-slider {
  &::selection,
  *::selection {
    background-color: transparent;
  }

  .header {
    margin: 0 auto;
    padding: 0 20px 2rem;
    width: 100%;

    @media #{$bp-medium} {
      padding: 0 9px 2rem;
    }
    @media #{$bp-large} {
      padding: 0 0 2rem;
    }

    h2,
    h3,
    h4,
    h5 {
      padding-bottom: 1rem;
    }

    p {
      margin: 0;
    }
  }

  //.module-content {}

  .container {
    // margin: 0 -25px;
    display: flex;
    justify-content: center;
    padding: 25px 0;
    position: relative;
  }

  &.blue {
    background-color: $accent-color;

    .header {
      padding-top: 14px;

      @media #{$bp-medium} {
        padding-top: 40px;
      }

      @media #{$bp-large} {
        padding-top: 45px;
      }
    }

    h2,
    p {
      color: $text-color-1;
    }

    .changing-arrow {
      color: $text-color-1;
    }

    .blue {
      display: block;
    }

    .white {
      display: none;
    }
  }

  .item-container {
    @extend %hidescrollbar;
    align-items: stretch;
    display: flex;
    overflow: hidden;

    .item {
      display: flex;
    }
  }

  @mixin logo-class($width, $height, $padding, $count) {
    .item-container {
      height: $height;
      width: $width * $count;
    }

    .item {
      min-width: $width;
      padding: $padding;
      width: $width;

      a {
        width: 100%;

        // X-TODO raus wegen vcard. gucken ob wichtig
        // &[href=''],
        // &[href='#'] {
        //   cursor: default;
        // }
      }

      picture {
        width: 100%;
      }

      img {
        /* stylelint-disable-next-line */
        image-rendering: -webkit-optimize-contrast;
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
  }

  .big-logos {
    @include logo-class(164px, 84px, 0 15px, 2);

    @media #{$bp-small} {
      @include logo-class(249px, 100px, 0 40px, 2);
    }

    @media #{$bp-medium} {
      @include logo-class(240px, 120px, 0 22px, 3);
    }

    @media #{$bp-extra-large} {
      @include logo-class(277px, 125px, 0 40px, 4);
    }
  }

  .medium-logos {
    @include logo-class(164px, 84px, 0 15px, 2);

    @media #{$bp-small} {
      @include logo-class(166px, 84px, 0 16px, 3);
    }

    @media #{$bp-medium} {
      @include logo-class(240px, 100px, 0 39px, 3);
    }

    @media #{$bp-extra-large} {
      @include logo-class(222px, 100px, 0 30px, 5);
    }
  }

  .small-logos {
    @include logo-class(164px, 84px, 0 15px, 2);

    @media #{$bp-small} {
      @include logo-class(166px, 84px, 0 16px, 3);
    }

    @media #{$bp-medium} {
      @include logo-class(180px, 84px, 0 23px, 4);
    }

    @media #{$bp-extra-large} {
      @include logo-class(185px, 84px, 0 25px, 6);
    }
  }

  .item {
    display: block;
    transition: transform 1s;
  }

  .blue {
    display: none;
  }

  .changing-arrow {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 22px;
    height: 100%;
    justify-content: center;
    line-height: 22px;
    margin-top: -25px;
    position: absolute;
    z-index: 1;
    transition: opacity ease 0.3s;

    &.left {
      left: -25px !important;

      @media #{$bp-medium} {
        left: -15px !important;
      }

      @media #{$bp-large} {
        left: -25px !important;
      }
    }

    &.right {
      right: -25px !important;

      @media #{$bp-medium} {
        right: -10px !important;
      }

      @media #{$bp-large} {
        right: -25px !important;
      }
    }

    &.disable {
      cursor: default;
      opacity: 0.5;
    }
  }
}
